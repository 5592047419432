export default {
  en: {
    my_acct: {
      headings: {
        settings: 'Account Settings',
        account_info: 'Account Information',
        general: 'General Information'
      },
      labels: {
        security: 'Security',
        acct_type: {
          heading: 'Account Type',
          regular: 'Consumer',
          dealer: 'Dealership',
          service: 'Service Center'
        }
      },
      helper_text: {
        change_password:
          'This process will send you an email with instructions and a link to a page where you can change your password.'
      },
      buttons: {
        update: 'Update Account',
        change_password: 'Change Password'
      },
      success: {
        update: 'Account updated successfully'
      },
      error: {
        404: 'The current user could not be found.',
        405: 'Some of the information required was either missing or incorrectly formatted. Please double check your submission and try again.',
        500: 'There was a technical issue updating your account information. Our staff has been notified of the error.'
      }
    }
  },
  de: {
    my_acct: {
      headings: {
        settings: 'Kontoeinstellungen',
        account_info: 'Kontoinformationen',
        general: 'Allgemeine Informationen'
      },
      labels: {
        security: 'Sicherheit',
        acct_type: {
          heading: 'Konto Typ',
          regular: 'Verbraucher',
          dealer: 'Händler',
          service: 'Service Center'
        }
      },
      helper_text: {
        change_password:
          'Bei diesem Vorgang erhalten Sie eine E-Mail mit Anweisungen und einem Link zu einer Seite, auf der Sie Ihr Passwort ändern können.'
      },
      buttons: {
        update: 'Account aktualisieren',
        change_password: 'Passwort ändern'
      },
      success: {
        update: 'Konto erfolgreich aktualisiert'
      },
      error: {
        404: 'Der aktuelle Benutzer konnte nicht gefunden werden.',
        405: 'Einige der erforderlichen Informationen fehlten oder waren falsch formatiert. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.',
        500: 'Beim Aktualisieren Ihrer Kontoinformationen ist ein technisches Problem aufgetreten. Unsere Mitarbeiter wurden über den Fehler informiert.'
      }
    }
  },
  jp: {
    my_acct: {
      headings: {
        settings: 'アカウント設定',
        account_info: '口座情報',
        general: '一般情報'
      },
      labels: {
        security: '安全',
        acct_type: {
          heading: '口座の種類',
          regular: '消費者',
          dealer: 'ディーラー',
          service: 'サービスセンター'
        }
      },
      helper_text: {
        change_password:
          'このプロセスでは、手順とパスワードを変更できるページへのリンクが記載されたメールが送信されます。'
      },
      buttons: {
        update: 'アカウントを更新する',
        change_password: 'パスワードを変更する'
      },
      success: {
        update: 'アカウントが正常に更新されました'
      },
      error: {
        404: '現在のユーザーが見つかりませんでした。',
        405: '必要な情報の一部が欠落しているか、正しくフォーマットされていませんでした。提出物を再確認して、再試行してください。',
        500: 'アカウント情報の更新に技術的な問題がありました。エラーが発生したことがスタッフに通知されました。'
      }
    }
  },
  it: {
    my_acct: {
      headings: {
        settings: "Impostazioni dell'account",
        account_info: 'Informazioni account',
        general: 'Informazioni generali'
      },
      labels: {
        security: 'Sicurezza',
        acct_type: {
          heading: 'Tipo di account',
          regular: 'Consumatore',
          dealer: 'Concessionario',
          service: 'Centro Servizi'
        }
      },
      helper_text: {
        change_password:
          "Questo processo ti invierà un'e-mail con le istruzioni e un link a una pagina in cui puoi cambiare la tua password."
      },
      buttons: {
        update: 'Aggiorna account',
        change_password: 'Cambia la password'
      },
      success: {
        update: 'Account aggiornato correttamente'
      },
      error: {
        404: "Impossibile trovare l'utente corrente.",
        405: "Alcune delle informazioni richieste erano mancanti o formattate in modo errato. Si prega di ricontrollare l'invio e riprovare.",
        500: "Si è verificato un problema tecnico durante l'aggiornamento delle informazioni sull'account. Il nostro personale è stato informato dell'errore."
      }
    }
  }
};
