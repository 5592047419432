import {
  FETCH_PART_TYPES_SUCCESS,
  FETCH_PART_MODEL_ATTRIBUTES_SUCCESS,
  RESET_PART_MODEL_ATTRIBUTES,
  FETCH_PART_TYPE_ATTRIBUTES_SUCCESS,
  RESET_PART_TYPE_ATTRIBUTES,
  FETCH_PART_BY_SERIAL_SUCCESS,
  FETCH_MODEL_DIAGNOSES_SUCCESS,
  RESET_DIAGNOSES,
  FETCH_PART_TYPE_DIAGNOSES_SUCCESS,
  FETCH_ATTRIBUTES_SUCCESS,
} from "Actions/part/types";

const initialState = {
  attributes: [],
  part: {},
  types: [],
  typeAttributes: {},
  modelAttributes: {},
  diagnoses: [],
};

const partReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PART_TYPES_SUCCESS: {
      return {
        ...state,
        types: action.payload,
      };
    }
    case FETCH_PART_MODEL_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        modelAttributes: action.payload,
      };
    }
    case RESET_PART_MODEL_ATTRIBUTES: {
      return {
        ...state,
        modelAttributes: {},
      };
    }
    case FETCH_PART_TYPE_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        typeAttributes: action.payload,
      };
    }
    case RESET_PART_TYPE_ATTRIBUTES: {
      return {
        ...state,
        typeAttributes: {},
      };
    }
    case FETCH_PART_BY_SERIAL_SUCCESS: {
      return {
        ...state,
        part: action.payload.data,
      };
    }
    case FETCH_PART_TYPE_DIAGNOSES_SUCCESS: {
      return {
        ...state,
        diagnoses: action.payload,
      };
    }
    case FETCH_MODEL_DIAGNOSES_SUCCESS: {
      return {
        ...state,
        diagnoses: action.payload.data,
      };
    }
    case RESET_DIAGNOSES: {
      return {
        ...state,
        diagnoses: [],
      };
    }
    case FETCH_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        attributes: action.payload,
      };
    }
    default:
      return state;
  }
};

export default partReducer;
