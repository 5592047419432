import React from 'react';
import PropTypes from 'prop-types';

const OverflowIcon = ({ size }) => (
  <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
    <g
      id="table/icon/overflow"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M8.5,12 C9.32842712,12 10,12.6715729 10,13.5 C10,14.3284271 9.32842712,15 8.5,15 C7.67157288,15 7,14.3284271 7,13.5 C7,12.6715729 7.67157288,12 8.5,12 Z M8.5,7 C9.32842712,7 10,7.67157288 10,8.5 C10,9.32842712 9.32842712,10 8.5,10 C7.67157288,10 7,9.32842712 7,8.5 C7,7.67157288 7.67157288,7 8.5,7 Z M8.5,2 C9.32842712,2 10,2.67157288 10,3.5 C10,4.32842712 9.32842712,5 8.5,5 C7.67157288,5 7,4.32842712 7,3.5 C7,2.67157288 7.67157288,2 8.5,2 Z"
        id="Combined-Shape"
        fill="#504E50"
      />
    </g>
  </svg>
);

OverflowIcon.propTypes = {
  size: PropTypes.string
};

OverflowIcon.defaultProps = {
  size: '19px'
};

export default OverflowIcon;
