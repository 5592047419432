import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@bootstrap-styled/v4/lib/Container';
import { space, colors, breakpoints } from 'Theme';
import Button from '../Button';

const PageHeader = ({ backButtonUrl, backButtonText, history, children }) => {
  return (
    <PageHeaderWrapper>
      <Container className="full-height" fluid>
        <ContentWrapper>
          {backButtonUrl && (
            <Button
              onClickFunc={() => {
                if (!!history) {
                  history.push(backButtonUrl);
                } else {
                  window.location.href = `${backButtonUrl}`;
                }
              }}
              color="secondary"
              type="button"
              width="max-content"
              className="mb-0"
              skinny
            >
              {backButtonText}
            </Button>
          )}
          {children}
        </ContentWrapper>
      </Container>
    </PageHeaderWrapper>
  );
};

PageHeader.propTypes = {
  backButtonUrl: PropTypes.string,
  backButtonText: PropTypes.string,
  children: PropTypes.node
};

PageHeader.defaultProps = {
  backButtonUrl: '',
  backButtonText: '',
  children: null
};

const PageHeaderWrapper = styled.div`
  width: 100%;
  background-color: ${colors.gray.dark_1};
  a {
    color: ${colors.white.base};
  }
  .mb-0 {
    margin-bottom: 0;
  }
  button {
    height: 42px;
  }
  .btn {
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }
  .form-group,
  button {
    position: relative;
  }
  z-index: 2;
  position: absolute;
  overflow: hidden;
  left: 0px;
  top: 0px;
  height: 72px;
  .row {
    padding: ${space[1] + space[2]}rem 0 0 0;
    height: 72px;
  }
  .full-height {
    height: 100%;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 0 ${space[3]}rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default PageHeader;
