import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Option from '@bootstrap-styled/v4/lib/Option';
import { createDealerLocation } from 'Actions/dealership';
import { hideModal } from 'Actions/modal';
import {
  Heading,
  Paragraph,
  Form,
  Input as SsgInput,
  Button,
  Alert
} from 'Components';
import { space, headerHeight } from 'Theme';
import { FormGroup } from '@bootstrap-styled/v4';
import { withTranslation } from 'react-i18next';
import countries from 'Components/Utils/countryData';

const mapStateToProps = state => ({
  user: state.user,
  dealership: state.dealership
});

const mapDispatchToProps = dispatch => ({
  createDealerLocation: (id, formData) =>
    dispatch(createDealerLocation(id, formData)),
  hideModal: data => dispatch(hideModal(data))
});

class CreateDealerLocationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      hasError: false,
      error: '',
      form: {
        name: '',
        country: 'United States',
        address_one: '',
        address_two: '',
        city: '',
        state: '',
        zip: '',
        countryCode: 'US',
        phone: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async onSubmit() {
    const {
      createDealerLocation,
      hideModal,
      dealership: {
        dealership: { id }
      }
    } = this.props;
    const { form } = this.state;

    this.setState(() => ({
      isFetching: true
    }));

    await createDealerLocation(id, form).catch(err =>
      // TODO: Use translation string based on err.status or message
      this.setState(() => ({ error: err.message }))
    );

    this.setState(() => ({
      isFetching: false
    }));

    hideModal();
  }

  handleChange(event) {
    const { form } = this.state;
    const { value, checked } = event.target;
    let newValue;
    if (event.target.type === 'checkbox') {
      newValue = checked;
    } else {
      newValue = value;
    }
    form[event.target.name] = newValue;
    this.setState(() => ({ form }));
  }

  render() {
    const { form, isFetching, hasError, error } = this.state;
    const { t } = this.props;
    const genericRequiredRule = {
      rule: 'required',
      messages: { error: t('form.required_feedback'), success: t('required') }
    };
    return (
      <FormContainer>
        <Form
          onSubmitFunc={() => this.onSubmit()}
          validation={{
            name: [genericRequiredRule],
            country: [genericRequiredRule],
            address_one: [genericRequiredRule],
            address_two: [],
            city: [genericRequiredRule],
            state: [genericRequiredRule],
            zip: [genericRequiredRule],
            phone: [
              {
                rule: 'phone',
                messages: {
                  error: t('form.invalid.phone_number'),
                  success: ''
                }
              }
            ]
          }}
          render={status => (
            <React.Fragment>
              <Heading size={4}>{t('create_dealer_loc.heading_main')}</Heading>
              <Paragraph>{t('create_dealer_loc.heading_sub')}</Paragraph>
              <FormGroup>
                <SsgInput
                  label={t('form.labels.location')}
                  inputId="name"
                  name="name"
                  value={form.name}
                  onChangeFunc={this.handleChange}
                  status={status.name.status}
                  feedbackMsg={status.name.feedback}
                  placeholder={t('form.placeholders.location')}
                  isRequired
                  width="100%"
                />
              </FormGroup>
              <FormGroup>
                <SsgInput
                  label={t('form.labels.company_country')}
                  inputId="country"
                  onChangeFunc={this.handleChange}
                  type="select"
                  value={form.country}
                  status={status.country.status}
                  feedbackMsg={status.name.feedback}
                  isRequired
                  name="country"
                  width="100%"
                  isDisabled
                >
                  <Option value="United States">United States</Option>
                </SsgInput>
                <SsgInput
                  label={t('form.labels.company_street_address')}
                  inputId="address_one"
                  name="address_one"
                  value={form.address_one}
                  onChangeFunc={this.handleChange}
                  status={status.address_one.status}
                  feedbackMsg={status.address_one.feedback}
                  placeholder={t('form.placeholders.company.address1')}
                  isRequired
                  width="100%"
                />
              </FormGroup>
              <SsgInput
                label={t('form.labels.company_address_two')}
                inputId="address_two"
                name="address_two"
                value={form.address_two}
                onChangeFunc={this.handleChange}
                status={status.address_two.status}
                feedbackMsg={status.address_two.feedback}
                placeholder={t('form.placeholders.company.address2')}
                width="100%"
              />
              <SsgInput
                label={t('form.labels.company_city')}
                inputId="city"
                name="city"
                value={form.city}
                onChangeFunc={this.handleChange}
                status={status.city.status}
                feedbackMsg={status.city.feedback}
                placeholder={t('form.placeholders.company.city')}
                isRequired
                width="100%"
              />
              <SsgInput
                label={t('form.labels.company_state')}
                inputId="state"
                name="state"
                value={form.state}
                onChangeFunc={this.handleChange}
                status={status.state.status}
                feedbackMsg={status.state.feedback}
                placeholder={t('form.placeholders.company.state')}
                isRequired
                width="100%"
              />
              <SsgInput
                label={t('form.labels.company_zip')}
                inputId="zip"
                name="zip"
                value={form.zip}
                onChangeFunc={this.handleChange}
                status={status.zip.status}
                feedbackMsg={status.zip.feedback}
                placeholder={t('form.placeholders.company.zip')}
                isRequired
                width="100%"
              />
              <PhoneWrapper>
                <SsgInput
                  label={t('form.labels.country_code')}
                  inputId="countryCode"
                  name="countryCode"
                  value={form.countryCode}
                  onChangeFunc={this.handleChange}
                  status="muted"
                  type="select"
                  minWidth="100px"
                  isDisabled
                >
                  {countries &&
                    countries
                      .sort((a, b) => {
                        return a.countryCode - b.countryCode;
                      })
                      .map(country => (
                        <Option
                          key={country.iso2}
                          value={country.iso2.toUpperCase()}
                        >
                          +{country.countryCode}
                        </Option>
                      ))}
                </SsgInput>
                <SsgInput
                  label={t('form.labels.phone')}
                  inputId="company-phone"
                  type="tel"
                  name="phone"
                  value={form.phone}
                  onChangeFunc={this.handleChange}
                  status="muted"
                  feedbackMsg={status.phone.feedback}
                  placeholder={t('form.placeholders.company.phone')}
                  width="100%"
                />
              </PhoneWrapper>
              <Alert
                alertIsOpen={hasError}
                color="danger"
                onCloseFunc={() => {
                  this.setState(() => ({ hasError: false }));
                }}
              >
                {error}
              </Alert>
              <Button
                className="w-100 mt-4 mb-2"
                color="primary"
                type="submit"
                isFetching={isFetching}
              >
                {t('create_dealer_loc.submit_button')}
              </Button>
            </React.Fragment>
          )}
        />
      </FormContainer>
    );
  }
}

CreateDealerLocationForm.propTypes = {
  createDealerLocation: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  dealership: PropTypes.shape({
    dealership: PropTypes.shape({
      id: PropTypes.number
    })
  }),
  t: PropTypes.func.isRequired
};

CreateDealerLocationForm.defaultProps = {
  dealership: {
    dealership: {}
  }
};

const FormContainer = styled.div`
  padding: ${space[3]}rem;
  max-width: 480px;
  margin: auto;
  margin-bottom: ${headerHeight}px;
`;

const PhoneWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > *:first-child {
    display: flex;
  }
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateDealerLocationForm));
