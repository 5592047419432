export default {
  en: {
    dealership_locations: {
      labels: {
        create_users: 'Create',
        filter_users: 'Filter',
        search_users: 'Search...'
      }
    }
  },
  de: {
    dealership_locations: {
      labels: {
        create_users: 'Schaffen',
        filter_users: 'Filter',
        search_users: 'Suche...'
      }
    }
  },
  jp: {
    dealership_locations: {
      labels: {
        create_users: '作成',
        filter_users: 'フィルター',
        search_users: '検索...'
      }
    }
  },
  it: {
    dealership_locations: {
      labels: {
        create_users: 'Creare',
        filter_users: 'Filtro',
        search_users: 'Ricerca...'
      }
    }
  }
};
