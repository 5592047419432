import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import TicketsList from 'Containers/TicketsList';
import DealershipList from 'Containers/DealershipList';
import Dealership from 'Containers/Dealership';
import Ticket from 'Containers/Ticket';
import DealershipInvitation from 'Containers/DealershipInvitation';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Redirect to={{ pathname: '/tickets', state: { from: '/' } }} />
          )}
        />
        <Route exact path="/tickets" render={() => <TicketsList t={t} />} />
        {/* Service Center User Routes: */}
        <Route exact path="/service-center/techs" render={() => 'Techs Here'} />
        <Route
          exact
          path="/service-center/settings"
          render={() => 'Settings Here'}
        />
        <Route
          exact
          path="/dealerships"
          render={() => <DealershipList t={t} />}
        />
        {/* Dealership User Routes: */}
        <Route
          exact
          path="/dealership/join"
          render={() => <DealershipInvitation />}
        />
        <Route
          path="/dealership/:dealership"
          render={() => <Dealership t={t} />}
        />
        <Route path="/ticket/:ticket" render={() => <Ticket t={t} />} />
      </Switch>
    </Fragment>
  );
};

Dashboard.propTypes = {
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired
  }).isRequired
};

export default Dashboard;
