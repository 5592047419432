import { api, retryApiCall } from "../api";
import {
  FETCH_USERS_LOADING,
  FETCH_USERS_ERROR,
  FETCH_USERS_SUCCESS,
  FETCH_USER_LOADING,
  FETCH_USER_ERROR,
  FETCH_USER_SUCCESS,
  FETCH_SCOPES_LOADING,
  FETCH_SCOPES_ERROR,
  FETCH_SCOPES_SUCCESS,
  SEARCH_USERS_LOADING,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_ERROR,
  FETCH_USERS_BY_SCOPE_LOADING,
  FETCH_USERS_BY_SCOPE_ERROR,
  FETCH_USERS_BY_SCOPE_SUCCESS,
  ADD_USERS_FILTER,
  REMOVE_USERS_FILTER
} from "./types";

export const userCallTypes = {};
export const apiCall = retryApiCall.use(userCallTypes);

// Fetch All Users
export const fetchUsersLoading = data => ({
  type: FETCH_USERS_LOADING,
  payload: data
});

export const fetchUsersFailure = data => ({
  type: FETCH_USERS_ERROR,
  payload: data
});

export const fetchUsersSuccess = data => ({
  type: FETCH_USERS_SUCCESS,
  payload: data
});

export const fetchUsers = data => dispatch => {
  dispatch(apiCall(fetchUsersLoading, fetchUsers, data));
  return api
    .get(`/users`, {
      params: {
        ...data
      }
    })
    .then(res => {
      dispatch(fetchUsersSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(fetchUsersFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

// Fetch Single User
export const fetchUserLoading = data => ({
  type: FETCH_USER_LOADING,
  payload: data
});

export const fetchUserFailure = data => ({
  type: FETCH_USER_ERROR,
  payload: data
});

export const fetchUserSuccess = data => ({
  type: FETCH_USER_SUCCESS,
  payload: data
});

export const fetchUser = data => dispatch => {
  dispatch(apiCall(fetchUserLoading, fetchUser, data));
  return api
    .get(`/user/${data.id}`, {})
    .then(res => {
      dispatch(fetchUserSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(fetchUserFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

// Fetch All Scopes
export const fetchScopesLoading = data => ({
  type: FETCH_SCOPES_LOADING,
  payload: data
});

export const fetchScopesFailure = data => ({
  type: FETCH_SCOPES_ERROR,
  payload: data
});

export const fetchScopesSuccess = data => ({
  type: FETCH_SCOPES_SUCCESS,
  payload: data
});

export const fetchUsersScopes = data => dispatch => {
  dispatch(apiCall(fetchScopesLoading, fetchUsersScopes, data));
  return api
    .get("/users/scopes", {
      ...data
    })
    .then(res => {
      dispatch(fetchScopesSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(fetchScopesFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

// Search Users
export const searchUsersLoading = data => ({
  type: SEARCH_USERS_LOADING,
  payload: data
});

export const searchUsersSuccess = data => ({
  type: SEARCH_USERS_SUCCESS,
  payload: data
});

export const searchUsersFailure = data => ({
  type: SEARCH_USERS_ERROR,
  payload: data
});

export const searchUsers = data => dispatch => {
  dispatch(apiCall(searchUsersLoading, searchUsers, data));
  return api
    .get(`/users/search`, {
      params: { ...data }
    })
    .then(res => {
      dispatch(searchUsersSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(searchUsersFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

// Fetch Users By Scope
export const fetchUsersByScopeLoading = data => ({
  type: FETCH_USERS_BY_SCOPE_LOADING,
  payload: data
});

export const fetchUsersByScopeFailure = data => ({
  type: FETCH_USERS_BY_SCOPE_ERROR,
  payload: data
});

export const fetchUsersByScopeSuccess = data => ({
  type: FETCH_USERS_BY_SCOPE_SUCCESS,
  payload: data
});

export const fetchUsersByScope = data => dispatch => {
  dispatch(apiCall(fetchUsersByScopeLoading, fetchUsersByScope, data));
  return api
    .get(`/users/scope/${data.scope}`, {
      params: { ...data }
    })
    .then(res => {
      dispatch(fetchUsersByScopeSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(fetchUsersByScopeFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

export const addUsersFilter = data => ({
  type: ADD_USERS_FILTER,
  payload: data
});

export const removeUsersFilter = data => ({
  type: REMOVE_USERS_FILTER,
  payload: data
});

export const addFilter = data => dispatch => {
  dispatch(addUsersFilter(data));
};

export const removeFilter = data => dispatch => {
  dispatch(removeUsersFilter(data));
};
