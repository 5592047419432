import React from 'react';
import Container from '@bootstrap-styled/v4/lib/Container';
import { Link } from 'Components';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="row py-3">
        <div className="col-md-8 offset-md-2 text-center">
          &copy; {t('footer.copyright')} {new Date().getFullYear()} SR SUNTOUR
          Inc. {t('footer.all_rights')}.{' '}
          <Link
            external
            href="https://www.srsuntour.com/footer/c-2019-sr-suntour-all-rights-reserved/privacy/"
          >
            {t('footer.privacy')}
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
