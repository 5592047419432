import React from 'react';
import PropTypes from 'prop-types';
import BootstrapBlockquote from '@bootstrap-styled/v4/lib/Blockquote';
import styled from 'styled-components';
import { colors, typography, space } from 'Theme';

const { fonts, fontSizes } = typography;

const Blockquote = ({ children, source }) => (
  <StyledBlockquote>
    {children}
    {source && <Citation>{source}</Citation>}
  </StyledBlockquote>
);

Blockquote.propTypes = {
  children: PropTypes.node.isRequired,
  source: PropTypes.string
};

Blockquote.defaultProps = {
  source: ''
};

const StyledBlockquote = styled(BootstrapBlockquote)`
  &,
  &.blockquote {
    font-family: ${fonts.sansSerif};
    font-stretch: condensed;
    font-size: ${fontSizes[1]}rem;
    line-height: 1.6;
    color: ${colors.gray.darker};
    border-left: 1px solid ${colors.gray.base};
    padding: 0 1.25rem 0 1.1875rem;
    // TODO: Confirm with Andrew that removing the 0.5625rem padding-top is approved in order to match the look of the style guide.
  }
`;

const Citation = styled.p`
  color: ${colors.black.base};
  font-size: ${fontSizes[0]}rem;
  &::before {
    content: ' \\2014';
    margin-right: ${space[1]}rem;
  }
`;

export default Blockquote;
