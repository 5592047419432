export default {
  en: {
    countries: {
      us: 'United States'
    }
  },
  esMX: {
    countries: {
      us: 'Los Estados Unidos'
    }
  },
  de: {
    countries: {
      us: 'Vereinigte Staaten'
    }
  },
  jp: {
    countries: {
      us: 'アメリカ'
    }
  },
  fr: {
    countries: {
      us: 'États-Unis'
    }
  },
  it: {
    countries: {
      us: 'stati Uniti'
    }
  }
};
