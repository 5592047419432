import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'Theme';

const MagnifyingGlass = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    className="search"
  >
    <g id="Content" fill={color}>
      <path
        d="M10.877.093a7.604 7.604 0 0 0-6.514 11.529L.676 15.308l.013.013a2.08 2.08 0 0 0-.635 1.516c0 1.167.93 2.08 2.096 2.08a2.1 2.1 0 0 0 1.509-.626l.009.009 3.804-3.804A7.603 7.603 0 0 0 18.48 7.697 7.603 7.603 0 0 0 10.877.093zm.046 12.218a4.752 4.752 0 1 1 0-9.505 4.752 4.752 0 0 1 4.752 4.752 4.751 4.751 0 0 1-4.752 4.753z"
        id="Shape"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

MagnifyingGlass.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};

MagnifyingGlass.defaultProps = {
  color: colors.gray.darkest,
  size: '19px'
};

export default MagnifyingGlass;
