import axios from 'axios';
import baseURL from '../config';

// eslint-disable-next-line import/prefer-default-export
export const api = axios.create({
  baseURL,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const refreshToken = () => {
  return api
    .post(`/user/token`)
    .then(res => {
      const userJSON = localStorage.getItem('user');
      if (userJSON !== null) {
        const userFromStorage = JSON.parse(userJSON);
        userFromStorage.token = res.data.token;
        localStorage.setItem('user', JSON.stringify(userFromStorage));
        api.defaults.headers.Authorization = `Bearer ${res.data.token}`;
      }
      return Promise.resolve(res.data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

/* Wrap API calls in their respective 'ACTION_START'
   action as well as prep axios interceptors to know which
   functions to re-try in the custom middleware
*/
export const retryApiCall = {
  use: mappingObj => (starterFunc, apiFunc, data) => dispatch => {
    const { type } = starterFunc();
    // eslint-disable-next-line no-param-reassign
    mappingObj[type] = apiFunc;
    dispatch(
      starterFunc({
        parameters: data
      })
    );
  }
};
