import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { hideModal } from 'Actions/modal';
import { connect } from 'react-redux';
import { space } from 'Theme';
import { inviteUser } from 'Actions/dealership';
import {
  Heading,
  Paragraph,
  Form,
  Input as SsgInput,
  Button,
  Alert
} from 'Components';
import { FormGroup } from '@bootstrap-styled/v4';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  user: state.user,
  dealership: state.dealership
});

const mapDispatchToProps = dispatch => ({
  inviteUser: (dealerId, formData) => dispatch(inviteUser(dealerId, formData)),
  hideModal: data => dispatch(hideModal(data))
});

class CreateDealerUserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      hasError: false,
      errorMsg: '',
      form: {
        email: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
  }

  onSubmit = async () => {
    const {
      t,
      inviteUser,
      hideModal,
      dealership: {
        dealership: { id: dealerId }
      }
    } = this.props;

    const { form } = this.state;
    form.dealerId = dealerId;

    this.setState(() => ({
      hasError: false,
      isFetching: true
    }));

    await inviteUser(form)
      .then(() => {})
      .catch(errStatus => {
        this.setState(() => ({
          hasError: true,
          errorMsg: t(`create_dealer_user.error.${errStatus}`)
        }));
      });

    this.setState(() => ({
      isFetching: false
    }));

    const { hasError } = this.state;
    if (!hasError) {
      hideModal();
    }
  };

  handleChange(event) {
    const { form } = this.state;
    const { value, checked } = event.target;
    let newValue;
    if (event.target.type === 'checkbox') {
      newValue = checked;
    } else {
      newValue = value;
    }
    form[event.target.name] = newValue;
    this.setState(() => ({
      form
    }));
  }

  render() {
    const { form, isFetching, hasError, errorMsg } = this.state;
    const { t } = this.props;
    const genericRequiredRule = {
      rule: 'required',
      messages: { error: t('form.required_feedback'), success: t('required') }
    };
    return (
      <FormContainer>
        <Form
          onSubmitFunc={() => this.onSubmit()}
          ignoreRequiredOnBlur
          validation={{
            email: [
              genericRequiredRule,
              {
                rule: 'email',
                messages: {
                  error: t('form.invalid.email_address'),
                  success: t('form.valid.email_address')
                }
              }
            ]
          }}
          render={status => (
            <React.Fragment>
              <Heading size={4}>{t('create_dealer_user.heading_main')}</Heading>
              <Paragraph>{t('create_dealer_user.heading_sub')}</Paragraph>
              <FormGroup>
                <SsgInput
                  label={t('form.labels.email')}
                  inputId="email"
                  name="email"
                  value={form.email}
                  onChangeFunc={this.handleChange}
                  status={status.email.status}
                  feedbackMsg={status.email.feedback}
                  placeholder={t('form.labels.email')}
                  isRequired
                  width="100%"
                />
              </FormGroup>
              <Button
                className="w-100 mt-4 mb-2"
                color="primary"
                type="submit"
                isFetching={isFetching}
              >
                {t('create_dealer_user.submit_button')}
              </Button>
              <Alert
                alertIsOpen={hasError}
                color="danger"
                onCloseFunc={() => this.setState(() => ({ hasError: false }))}
              >
                {errorMsg}
              </Alert>
            </React.Fragment>
          )}
        />
      </FormContainer>
    );
  }
}

CreateDealerUserForm.propTypes = {
  dealership: PropTypes.shape({
    dealership: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  inviteUser: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const FormContainer = styled.div`
  padding: ${space[3]}rem;
  max-width: 480px;
  margin: auto;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateDealerUserForm));
