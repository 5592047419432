import { push } from "connected-react-router";
import { startIdle } from "redux-idle-callback";
import { api, retryApiCall } from "../api";
import {
  LOCAL_LOGIN,
  LOGOUT,
  LOGIN_LOADING,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  SEND_PASSWORD_RESET_LOADING,
  SEND_PASSWORD_RESET_FAILURE,
  SEND_PASSWORD_RESET_SUCCESS,
  SIGN_UP_LOADING,
  SIGN_UP_FAILURE,
  SIGN_UP_SUCCESS,
  FINISH_ACCOUNT_SETUP_LOADING,
  FINISH_ACCOUNT_SETUP_FAILURE,
  FINISH_ACCOUNT_SETUP_SUCCESS,
  UPDATE_USER_LOADING,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  ACCEPT_INVITE_LOADING,
  ACCEPT_INVITE_ERROR,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_RESET,
  DECLINE_INVITE_LOADING,
  DECLINE_INVITE_ERROR,
  DECLINE_INVITE_SUCCESS,
  SESSION_TIMEOUT_SUCCESS,
  RESEND_VERIFYCODE,
  RESEND_VERIFYCODE_SUCCESS,
  RESEND_VERIFYCODE_FAILURE,
  RESEND_VERIFYCODE_LOADING,
  ARCHIVE_USER_LOADING,
  ARCHIVE_USER_SUCCESS,
  ARCHIVE_USER_FAILURE
} from "./types";

export const userCallTypes = {};
export const apiCall = retryApiCall.use(userCallTypes);

export const localLogin = data => ({
  type: LOCAL_LOGIN,
  payload: data
});

export const logoutSuccess = () => ({
  type: LOGOUT
});

export const logout = referrer => dispatch => {
  dispatch(logoutSuccess());
  dispatch(push(`/logout?referrer=${referrer || "/"}`));
};

export const sessionTimeout = () => ({
  type: SESSION_TIMEOUT_SUCCESS
});

export const autoLogOut = () => dispatch => {
  dispatch(sessionTimeout());
  dispatch(logout());
};

/**
 * Log in with Existing Account
 */
export const loginLoading = data => ({
  type: LOGIN_LOADING,
  payload: data
});

export const loginFailure = data => ({
  type: LOGIN_FAILURE,
  payload: data
});

export const loginSuccess = data => ({
  type: LOGIN_SUCCESS,
  payload: data
});

export const login = (data, referrer = "/") => dispatch => {
  dispatch(apiCall(loginLoading, login, data));
  return api
    .post(`/user/login`, {
      ...data
    })
    .then(async res => {
      await dispatch(loginSuccess(res.data));
      await dispatch(startIdle);
      dispatch(push(`${referrer}`));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(loginFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

/**
 * Submit Email Address to Create an Account
 */
export const signUpRequestLoading = data => ({
  type: SIGN_UP_LOADING,
  payload: data
});

export const resendVerifyCodeLoading = data => ({
  type: RESEND_VERIFYCODE_LOADING,
  payload: data
});

export const signUpRequestFailure = data => ({
  type: SIGN_UP_FAILURE,
  payload: data
});

export const resendVerifyCodeFailure = data => ({
  type: RESEND_VERIFYCODE_FAILURE,
  payload: data
});

export const signUpRequestSuccess = data => ({
  type: SIGN_UP_SUCCESS,
  payload: data
});

export const resendVerifyCodeSuccess = data => ({
  type: RESEND_VERIFYCODE_SUCCESS,
  payload: data
});

export const signUpRequest = data => dispatch => {
  dispatch(apiCall(signUpRequestLoading, signUpRequest, data));
  return api
    .post(`/user`, data)
    .then(res => {
      dispatch(signUpRequestSuccess());
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(signUpRequestFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

export const resendVerifyCode = data => dispatch => {
  dispatch(apiCall(resendVerifyCodeLoading, resendVerifyCode, data));
  return api
    .post(`/user/resend_code`, data)
    .then(res => {
      dispatch(resendVerifyCodeSuccess());
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(resendVerifyCodeFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

/**
 * Submit Form to Complete Account Creation
 */

export const finishAccountSetupLoading = data => ({
  type: FINISH_ACCOUNT_SETUP_LOADING,
  payload: data
});

export const finishAccountSetupFailure = data => ({
  type: FINISH_ACCOUNT_SETUP_FAILURE,
  payload: data
});

export const finishAccountSetupSuccess = data => ({
  type: FINISH_ACCOUNT_SETUP_SUCCESS,
  payload: data
});

export const finishAccountSetup = data => dispatch => {
  dispatch(apiCall(finishAccountSetupLoading, finishAccountSetup, data));
  return api
    .put(`/user/profile`, {
      ...data
    })
    .then(() => dispatch(finishAccountSetupSuccess()))
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(finishAccountSetupFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

/**
 * Submit Request to Reset Password
 */

export const sendPasswordResetLoading = data => ({
  type: SEND_PASSWORD_RESET_LOADING,
  payload: data
});

export const sendPasswordResetFailure = () => ({
  type: SEND_PASSWORD_RESET_FAILURE
});

export const sendPasswordResetSuccess = () => ({
  type: SEND_PASSWORD_RESET_SUCCESS
});

export const sendPasswordReset = data => dispatch => {
  dispatch(apiCall(sendPasswordResetLoading, sendPasswordReset, data));
  return api
    .post(`/user/passwordreset`, {
      ...data
    })
    .then(() => dispatch(sendPasswordResetSuccess()))
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(sendPasswordResetFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

/**
 * Submit a new Password via Reset Password Form
 */

export const resetPasswordLoading = data => ({
  type: RESET_PASSWORD_LOADING,
  payload: data
});

export const resetPasswordFailure = () => ({
  type: RESET_PASSWORD_FAILURE
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS
});

export const resetPassword = data => dispatch => {
  dispatch(apiCall(resetPasswordLoading, resetPassword, data));
  return api
    .post(`/user/password/reset`, {
      ...data
    })
    .then(() => dispatch(resetPasswordSuccess()))
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(resetPasswordFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

/** Update User Information */
export const updateUserLoading = data => ({
  type: UPDATE_USER_LOADING,
  payload: data
});

export const updateUserFailure = data => ({
  type: UPDATE_USER_ERROR,
  payload: data
});

export const updateUserSuccess = data => ({
  type: UPDATE_USER_SUCCESS,
  payload: data
});

export const updateUser = data => dispatch => {
  dispatch(apiCall(updateUserLoading, updateUser, data));
  return api
    .patch(`/user/${data.user_id}`, {
      ...data
    })
    .then(res => {
      dispatch(updateUserSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(updateUserFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

/**
 * accept invitation to join [read: associate themself with] a dealership
 */
export const acceptInviteLoading = data => ({
  type: ACCEPT_INVITE_LOADING,
  payload: data
});

export const acceptInviteFailure = data => ({
  type: ACCEPT_INVITE_ERROR,
  payload: data
});

export const acceptInviteSuccess = data => ({
  type: ACCEPT_INVITE_SUCCESS,
  payload: data
});

export const acceptInviteReset = () => ({
  type: ACCEPT_INVITE_RESET
});

export const acceptInvite = (id, data) => dispatch => {
  dispatch(apiCall(acceptInviteLoading, acceptInvite, data));
  const endpoint = `/dealer/${id}/accept`;
  return api
    .put(endpoint, {
      ...data
    })
    .then(res => {
      dispatch(acceptInviteSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      dispatch(acceptInviteFailure());
      return Promise.reject(errStatus);
    });
};

/**
 * decline invitation to join a dealership
 */
export const declineInviteLoading = data => ({
  type: DECLINE_INVITE_LOADING,
  payload: data
});

export const declineInviteFailure = data => ({
  type: DECLINE_INVITE_ERROR,
  payload: data
});

export const declineInviteSuccess = data => ({
  type: DECLINE_INVITE_SUCCESS,
  payload: data
});

export const declineInvite = data => dispatch => {
  dispatch(apiCall(declineInviteLoading, declineInvite, data));
  const endpoint = `/dealer/${data.id}/decline`;
  return api
    .put(endpoint, {
      ...data
    })
    .then(res => {
      dispatch(declineInviteSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      dispatch(declineInviteFailure());
      return Promise.reject(errStatus);
    });
};

/**
 * Archive a user
 */
export const archiveUserLoading = data => ({
  type: ARCHIVE_USER_LOADING,
  payload: data
});

export const archiveUserFailure = data => ({
  type: ARCHIVE_USER_FAILURE,
  payload: data
});

export const archiveUserSuccess = data => ({
  type: ARCHIVE_USER_SUCCESS,
  payload: data
});

export const archiveUser = data => dispatch => {
  dispatch(apiCall(archiveUserLoading, login, data));
  return api
    .patch(`/user/${data.id}/archive`)
    .then(res => {
      dispatch(archiveUserSuccess(res.data));
      return res.data;
    })
    .catch(status => {
      if (status !== 401) {
        dispatch(archiveUserFailure(status));
      }
      if (status === 405) {
        return Promise.reject(
          `User belongs to dealerships with active members`
        );
      }
      return Promise.reject(status);
    });
};
