import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoints } from 'Theme';
import { PlusIcon } from 'Assets/images/icons';
import { Modal } from '@bootstrap-styled/v4';

const PopOver = ({ isOpen, children, toggleFunc, className }) => {
  return (
    <Fragment>
      <StyledModal
        className={className}
        isOpen={isOpen}
        toggle={() => toggleFunc()}
      >
        <PlusIcon onClickFunc={() => toggleFunc()} />
        {children}
      </StyledModal>
    </Fragment>
  );
};
PopOver.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  toggleFunc: PropTypes.func
};

PopOver.defaultProps = {
  className: '',
  toggleFunc: () => {}
};

const StyledModal = styled(Modal)`
  align-items: center;
  img {
    display: block;
    max-width: 100%;
  }
  svg {
    position: absolute;
    right: 0;
    transform: rotate(45deg);
    cursor: pointer;
    size: 18px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    .modal-dialog {
      margin: 20px;
    }
  }
`;

export default PopOver;
