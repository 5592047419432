/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Badge as BSBadge } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import { space, colors } from 'Theme';
import { PlusIcon as CloseIcon } from 'Assets/images/icons';

const Badge = ({
  label,
  onRemove,
  pill,
  color,
  isRemovable,
  inTable,
  handle,
  className
}) => (
  <BadgeWrapper inTable={inTable}>
    <StyledBadge className={className} pill={pill} color={color}>
      {label}
      {isRemovable && (
        <CloseIcon handle={handle} onClickFunc={e => onRemove(e)} />
      )}
    </StyledBadge>
  </BadgeWrapper>
);

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  pill: PropTypes.bool,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'success',
    'info',
    'warning',
    'danger'
  ]),
  isRemovable: PropTypes.bool,
  inTable: PropTypes.bool,
  handle: PropTypes.string
};

Badge.defaultProps = props => ({
  pill: false,
  color: 'default',
  onRemove: () => {},
  isRemovable: false,
  inTable: false,
  handle: props.label
});

const BadgeWrapper = styled.span`
  .badge {
    margin-bottom: ${props => (props.inTable ? 0 : `${space[1]}rem`)};
  }
`;

const StyledBadge = styled(BSBadge)`
  border: 1px solid ${colors.white.base};
  flex-wrap: nowrap;
  text-transform: uppercase;
  color: ${colors.black.base};
  margin-right: ${space[1]}rem;
  margin-bottom: ${props => (props.inTable ? 0 : `${space[1]}rem`)};
  &.badge {
    display: inline-flex;
    align-items: center;
  }
  svg {
    transform: rotate(45deg);
    cursor: pointer;
    margin-left: 4px;
  }
`;

export default Badge;
