import React, { Component } from 'react';
import BSForm from '@bootstrap-styled/v4/lib/Form/index';
import PropTypes from 'prop-types';
import { parsePhoneNumber } from 'libphonenumber-js';

class Form extends Component {
  constructor(props) {
    super(props);
    const status = {};

    // We need to initialize state given the validation rules we passed in props
    Object.keys(props.validation).forEach(rule => {
      if (rule === 'required') {
        status[rule] = {
          status: 'muted',
          feedback: 'Required'
        };
      } else {
        status[rule] = {
          status: 'muted',
          feedback: ''
        };
      }
    });

    // Which is why this comes after the line above
    this.state = {
      status: {
        ...status,
        exampleInput: {
          status: 'success',
          feedback: ''
        }
      },
      numFields: 0
    };

    // Create a reference to the form and bind the onSubmit handler
    this.formElm = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
  }

  componentDidMount = () => {
    this.setState(() => ({
      numFields: this.formElm.current.querySelectorAll('input, select').length
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    const inputs = this.formElm.current.querySelectorAll('input, select');
    const numInputs = inputs.length;
    const { validation } = this.props;
    const { status } = this.state;
    // On Forms where fields are hidden/sections are not displayed by default, the event handler
    // binding in CDM will not affect those fields. When fields are added/removed to/from the form,
    // then we should make sure those fields validate onBlur as well

    if (numInputs !== prevState.numFields) {
      this.unbindOnBlurHandlers(inputs);
      this.bindOnBlurHandlers(inputs);
      Object.keys(validation).forEach(rule => {
        if (rule === 'required') {
          status[rule] = {
            status: 'muted',
            feedback: 'Required'
          };
        } else {
          status[rule] = {
            status: 'muted',
            feedback: ''
          };
        }
      });
    }
  }

  onSubmit = e => {
    const { onSubmitFunc } = this.props;
    e.preventDefault();
    if (this.validateForm() === true) {
      onSubmitFunc();
    }
  };

  componentWillUnmount = () => {
    const inputs = this.formElm.current.querySelectorAll('input, select');
    this.unbindOnBlurHandlers(inputs);
  };

  bindOnBlurHandlers = inputs => {
    for (let i = 0; i < inputs.length; i += 1) {
      inputs[i].addEventListener('blur', this.validateField);
    }
  };

  unbindOnBlurHandlers = inputs => {
    for (let i = 0; i < inputs.length; i += 1) {
      inputs[i].removeEventListener('blur', this.validateField);
    }
  };

  validateEmail = value => {
    /* eslint-disable-next-line */
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
  };

  validateRequired = (field, value) => {
    if (field.type === 'checkbox') {
      return field.checked;
    }
    if (value === null || !value.length) {
      return false;
    }
    return true;
  };

  validateMatches = (value, fieldToMatch) => {
    const comparisonInput = this.formElm.current.querySelector(
      `[name='${fieldToMatch}'] input`
    );
    if (
      comparisonInput.value !== value ||
      comparisonInput.getAttribute('value') !== value
    ) {
      return false;
    }
    return true;
  };

  validatePassword = value => {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
    return re.test(value);
  };

  validatePhoneNumber = value => {
    const countryCode = this.formElm.current.querySelector('#countryCode')
      .value;
    try {
      const phoneNumber = parsePhoneNumber(value, countryCode);
      return phoneNumber.isValid();
    } catch (error) {
      if (value === '') {
        return true;
      }
      return false;
    }
  };

  validateRule = (rule, value, field, fieldToMatch = null) => {
    switch (rule) {
      case 'email':
        return this.validateEmail(value);
      case 'required':
        return this.validateRequired(field, value);
      case 'matches':
        return this.validateMatches(value, fieldToMatch);
      case 'password':
        return this.validatePassword(value);
      case 'phone':
        return this.validatePhoneNumber(value);
      default:
        return rule.test(value);
    }
  };

  validateField = event => {
    const field = event.target;
    const { status } = this.state;
    const { validation, ignoreRequiredOnBlur } = this.props;
    const fieldName = field.getAttribute('name');
    const validationRules = validation[fieldName];
    event.stopPropagation();

    // Iterate through rules for the given input based on name attribute
    if (validation && validationRules) {
      let hasError = false;
      let errorRuleDef = {};
      let successRuleDef = {};

      validationRules.forEach(ruleDef => {
        const isRequired = ruleDef.rule === 'required';
        const fieldToMatch = ruleDef.fieldToMatch ? ruleDef.fieldToMatch : null;
        // If the rule validation is successful, define the success rule object,
        // otherwise, define the error rule object
        if (this.validateRule(ruleDef.rule, field.value, field, fieldToMatch)) {
          successRuleDef = ruleDef;
        } else if (
          (isRequired && !ignoreRequiredOnBlur) ||
          (!isRequired && field.value.length)
        ) {
          hasError = true;
          errorRuleDef = ruleDef;
        }
      });
      let newFieldState = {};
      // If no errors have been found (i.e. all validation rules have passed), define the field state by the last successfully passed rule.
      if (!hasError && Object.values(successRuleDef).length) {
        newFieldState =
          // If the rule was 'required', then it stays gray and it says 'Required', otherwise it turns green and prints a success message based on the rule defined in props.validation
          successRuleDef.rule === 'required'
            ? {
                status: 'muted',
                feedback:
                  !ignoreRequiredOnBlur && field.type !== 'checkbox'
                    ? 'Required'
                    : ''
              }
            : {
                status: 'success',
                feedback: successRuleDef.messages.success
              };
        // If an error was found, define the field state by the last failed rule in the loop
      } else if (hasError) {
        newFieldState = {
          status: 'danger',
          feedback: errorRuleDef.messages.error
        };
      }
      if (Object.values(newFieldState).length) {
        this.setState({
          status: {
            ...status,
            [fieldName]: newFieldState
          }
        });
      }
    }
  };

  validateForm = () => {
    const { state } = this;
    const inputFields = this.formElm.current.querySelectorAll('input, select');
    let hasError = false;

    const newState = {
      ...state,
      status: {
        ...state.status
      }
    };

    const testRule = (ruleDef, field, fieldName) => {
      const fieldToMatch = ruleDef.fieldToMatch ? ruleDef.fieldToMatch : null;

      if (
        !this.validateRule(
          ruleDef.rule,
          field.getAttribute('value') || field.value,
          field,
          fieldToMatch
        )
      ) {
        hasError = true;
        newState.status[fieldName].status = 'danger';
        newState.status[fieldName].feedback = ruleDef.messages.error;
      }
    };

    for (const field of inputFields) {
      const fieldName = field.getAttribute('name');
      const { validation } = this.props;
      const validationRules = validation[fieldName];
      if (validation && validationRules) {
        validationRules.map(ruleDef => testRule(ruleDef, field, fieldName));
      }
    }
    this.setState(newState);
    return !hasError;
  };

  render() {
    const { status } = this.state;
    const { render } = this.props;
    return (
      <BSForm onSubmit={this.onSubmit}>
        {/*  Wrap render in a div to ref onto to prevent issues accesing child form elements */}
        <div ref={this.formElm}>{render(status)}</div>
      </BSForm>
    );
  }
}

Form.propTypes = {
  render: PropTypes.func.isRequired,
  validation: PropTypes.shape().isRequired,
  onSubmitFunc: PropTypes.func.isRequired,
  ignoreRequiredOnBlur: PropTypes.bool
};

Form.defaultProps = {
  ignoreRequiredOnBlur: false
};

export default Form;
