export default {
  en: {
    user: {
      heading_info: "User Information",
      buttons: {
        archive: "Archive User"
      }
    }
  }
};
