import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import Row from "@bootstrap-styled/v4/lib/Row";
import Col from "@bootstrap-styled/v4/lib/Col";
import { formatDate, setBadgeColorByStatus } from "Components/Utils";
import { space, colors, headerHeight } from "Theme";
import { fetchPartTypes } from "Actions/part";
import {
  fetchTicketDetails,
  createTicketMessage,
  updateTicketStatus,
  resetUpdateTicketDetails
} from "Actions/ticket";
import {
  Badge,
  Heading,
  InfoCard,
  Message,
  PageHeader,
  Scrim,
  TabbedContent,
  Throbber,
  Alert
} from "Components";
import { withTranslation } from "react-i18next";
import AddMessageForm from "./AddMessageForm";
import PartsOrder from "./PartsOrderForm";
import OrderTable from "./PartsOrderTable";
import StatusUpdate from "./StatusUpdate";
import TicketDetails from "./TicketDetails";
import { apiDomainUrl } from "../../config";
import { updateTicketMessage } from "../../actions/ticket";

class Ticket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isFetching: false,
      form: {
        status: ""
      }
    };

    this.onSaveTicketStatus = this.onSaveTicketStatus.bind(this);
  }

  async componentDidMount() {
    const {
      match: {
        params: { ticket }
      },
      fetchTicketDetails,
      fetchPartTypes,
      history
    } = this.props;

    let hasError = false;
    await fetchTicketDetails({
      id: ticket
    }).catch(errStatus => {
      if (errStatus === 403 || errStatus === 404) {
        hasError = true;
        history.push("/tickets");
      }
    });

    const {
      ticket: {
        current,
        current: { order }
      }
    } = this.props;

    if (order && hasError === false) {
      await fetchPartTypes();
    }
    this.setState(state => ({
      ...state,
      isLoading: false,
      form: {
        status: current.status
      },
      initialStatus: current.status
    }));
  }

  componentWillUnmount() {
    const { resetUpdateTicketDetails } = this.props;
    resetUpdateTicketDetails();
  }

  onChange = async e => {
    const {
      target: { name, value }
    } = e;
    const { form } = this.state;
    form[name] = value;
    this.setState(state => ({ ...state, form }));
  };

  onSaveTicketStatus = async () => {
    const {
      initialStatus,
      form: { status }
    } = this.state;
    const {
      updateTicketStatus,
      ticket: { current },
      user
    } = this.props;
    this.setState(state => ({ ...state, initialStatus: status }));
    if (initialStatus !== status) {
      this.setState(state => ({ ...state, isFetching: true }));
      await updateTicketStatus({
        id: current.id,
        status,
        user: { ...user }
      }).then(() => {
        this.setState(state => ({ ...state, isFetching: false }));
      });
    }
  };

  render() {
    const { isLoading } = this.state;
    const {
      user,
      ticket,
      history,
      ticket: {
        current,
        current: { customer, dealer, messages, order }
      },
      t,
      createTicketMessage,
      resetUpdateTicketDetails,
      updateTicketMessage,
      status: { UPDATE_TICKET_DETAILS, UPDATE_TICKET_MESSAGE },
      part: { types: partTypes }
    } = this.props;
    const isServiceUser = user.level === "service_center_user";
    const threadColNum = isServiceUser === true ? 8 : 12;
    const detailColNum = isServiceUser === true ? 4 : 3;

    return isLoading ? (
      <Fragment>
        <Scrim isShown={isLoading} />
        <StyledThrobber isFullPage />
      </Fragment>
    ) : (
      <Fragment>
        <PageHeader
          backButtonUrl="/tickets"
          backButtonText={t(`ticket.buttons.view_tickets`)}
          history={history}
        />
        <AlertWrapper>
          <Alert
            alertIsOpen={UPDATE_TICKET_DETAILS === "success"}
            color="success"
            onCloseFunc={() => resetUpdateTicketDetails()}
          >
            {t("ticket.success.updated")}
          </Alert>
        </AlertWrapper>
        {/* Ticket Number & Status Display */}
        <TicketHeader>
          <Heading className="pr-2" size={3}>
            {!!current.parts?.attributes?.length &&
              (current.parts.attributes.find(
                ({ attribute_name }) => attribute_name === "diagnosis"
              )?.attribute_value ||
                current.parts.attributes[0].attribute_value)}
          </Heading>
          <Badge
            label={t(`data.status.${current.status}`)}
            pill
            color={setBadgeColorByStatus(current && current.status)}
          />
        </TicketHeader>
        <Row>
          <Col lg={threadColNum}>
            <MessageThreadContainer>
              <StatusUpdate
                key={current.id}
                status="new"
                customerName={customer.name}
                createdAt={formatDate(current.created_at)}
              />
              {/* Main Ticket Details Display */}
              <TicketDetails
                ticket={current}
                customer={customer}
                user={user}
                apiDomainUrl={apiDomainUrl}
                detailColNum={detailColNum}
              />
              {/* Messages */}
              {messages &&
                messages.length &&
                current.user_id &&
                messages.map(message =>
                  message && message.message_type === "chat" ? (
                    <Message
                      key={message.id}
                      onEdit={updateTicketMessage}
                      isEditable={user.level === "service_center_user"}
                      ticketCreatorId={current.user_id}
                      message={message}
                      apiDomainUrl={apiDomainUrl}
                      user={user}
                    />
                  ) : (
                    // We don't display "new" status updated in-feed because we hard-code
                    // The 'new'/created status update before the ticket details
                    message.text.toLowerCase() !== "new" && (
                      <StatusUpdate
                        key={message.id}
                        status={message.text}
                        createdAt={formatDate(message.created_at)}
                      />
                    )
                  )
                )}
            </MessageThreadContainer>

            {/* Forms to either Add Message or Part Order */}
            <FormsWrapper>
              <Flexbox>
                <AlignFlexStart>
                  {isServiceUser ? (
                    <FormFieldsWrapper>
                      <TabbedContent>
                        <Heading size={6}>
                          {t(`ticket.headings.message`)}
                        </Heading>
                        <Heading size={6}>
                          {t(`ticket.headings.parts_and_shipping`)}
                        </Heading>
                        <AddMessageForm
                          user={user}
                          ticket={ticket}
                          createTicketMessageFunc={createTicketMessage}
                          onTicketStatusSelect={status => {
                            this.setState(state => ({
                              form: { ...state.form, status }
                            }));
                          }}
                          onSaveTicketStatus={this.onSaveTicketStatus}
                        />
                        <PartsOrder ticket={ticket} />
                      </TabbedContent>
                    </FormFieldsWrapper>
                  ) : (
                    <FormFieldsWrapper>
                      {order && order.parts && order.parts.length > 0 && (
                        <OrderTable
                          order={order}
                          isLoading={isLoading}
                          partTypes={partTypes}
                        />
                      )}
                      <AddMessageForm
                        user={user}
                        ticket={ticket}
                        createTicketMessageFunc={createTicketMessage}
                      />
                    </FormFieldsWrapper>
                  )}
                </AlignFlexStart>
              </Flexbox>
            </FormsWrapper>
          </Col>

          {/* Informational Cards for Customer & Dealer */}
          {isServiceUser && (
            <Col lg={4}>
              {customer && (
                <InfoCard
                  title={t(`ticket.headings.customer`)}
                  person={customer}
                  isCustomer
                />
              )}
              {dealer && (
                <InfoCard
                  title={t(`ticket.headings.dealership`)}
                  person={dealer}
                />
              )}
            </Col>
          )}
        </Row>
      </Fragment>
    );
  }
}

const TicketHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: ${space[2] + space[1]}rem;
  > *:first-child {
    margin-right: ${space[1]}rem;
  }
`;

const Flexbox = styled.div`
  display: flex;
  align-items: center;
  h1,
  h6 {
    margin: 0 ${space[1]}rem 0 0;
  }
`;
const AlignFlexStart = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const MessageThreadContainer = styled.div`
  border-left: 2px solid #cacaca;
  margin-left: 1.15rem;
  margin-bottom: ${space[2]}rem;
  padding-bottom: ${space[3] + space[1]}rem;
  box-sizing: border-box;
  position: relative;
  left: ${space[1] * 3}rem;
  overflow: visible;
`;

const FormFieldsWrapper = styled.div`
  width: 100%;
`;

const FormsWrapper = styled.div`
  border-top: 2px solid ${colors.gray.base};
  width: 100%;
  position: relative;
  top: -16px;
  padding-top: ${space[2] + space[2]}rem;
`;

const StyledThrobber = styled(Throbber)`
  top: 2rem;
`;

const AlertWrapper = styled.div`
  margin-top: calc(${headerHeight}px + ${space[1]}rem);
  margin-bottom: ${space[3]}rem;
`;

StatusUpdate.propTypes = {
  status: PropTypes.string.isRequired,
  customerName: PropTypes.string,
  createdAt: PropTypes.string
};

StatusUpdate.defaultProps = {
  customerName: "",
  createdAt: Date.now().toString()
};

Ticket.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      ticket: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  fetchPartTypes: PropTypes.func.isRequired,
  fetchTicketDetails: PropTypes.func.isRequired,
  updateTicketStatus: PropTypes.func.isRequired,
  updateTicketMessage: PropTypes.func.isRequired,
  createTicketMessage: PropTypes.func.isRequired,
  resetUpdateTicketDetails: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  ticket: PropTypes.shape().isRequired,
  part: PropTypes.shape({
    types: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    )
  }).isRequired,
  status: PropTypes.shape({
    UPDATE_TICKET_DETAILS: PropTypes.string.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  ticket: state.ticket,
  part: state.part,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  fetchTicketDetails: data => dispatch(fetchTicketDetails(data)),
  createTicketMessage: data => dispatch(createTicketMessage(data)),
  fetchPartTypes: data => dispatch(fetchPartTypes(data)),
  updateTicketStatus: data => dispatch(updateTicketStatus(data)),
  resetUpdateTicketDetails: () => dispatch(resetUpdateTicketDetails()),
  updateTicketMessage: data => dispatch(updateTicketMessage(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Ticket))
);
