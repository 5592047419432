import { api, retryApiCall } from "../api";
import {
  FETCH_PART_BY_SERIAL_LOADING,
  FETCH_PART_BY_SERIAL_ERROR,
  FETCH_PART_BY_SERIAL_SUCCESS,
  FETCH_PART_TYPES_LOADING,
  FETCH_PART_TYPES_ERROR,
  FETCH_PART_TYPES_SUCCESS,
  FETCH_PART_MODEL_ATTRIBUTES_LOADING,
  FETCH_PART_MODEL_ATTRIBUTES_ERROR,
  FETCH_PART_MODEL_ATTRIBUTES_SUCCESS,
  RESET_PART_MODEL_ATTRIBUTES,
  FETCH_PART_TYPE_ATTRIBUTES_LOADING,
  FETCH_PART_TYPE_ATTRIBUTES_ERROR,
  FETCH_PART_TYPE_ATTRIBUTES_SUCCESS,
  RESET_PART_TYPE_ATTRIBUTES,
  FETCH_MODEL_DIAGNOSES_LOADING,
  FETCH_MODEL_DIAGNOSES_ERROR,
  FETCH_MODEL_DIAGNOSES_SUCCESS,
  RESET_DIAGNOSES,
  FETCH_PART_TYPE_DIAGNOSES_LOADING,
  FETCH_PART_TYPE_DIAGNOSES_ERROR,
  FETCH_PART_TYPE_DIAGNOSES_SUCCESS,
  DUMP_PARTS_LOADING,
  DUMP_PARTS_ERROR,
  DUMP_PARTS_SUCCESS,
  UPDATE_PART_ERROR,
  UPDATE_PART_LOADING,
  UPDATE_PART_SUCCESS,
  CREATE_PART_LOADING,
  CREATE_PART_SUCCESS,
  CREATE_PART_ERROR,
  FETCH_ATTRIBUTES_ERROR,
  FETCH_ATTRIBUTES_LOADING,
  FETCH_ATTRIBUTES_SUCCESS,
  DELETE_PART_LOADING,
  DELETE_PART_SUCCESS,
  DELETE_PART_ERROR,
} from "./types";

export const partCallTypes = {};
const apiCall = retryApiCall.use(partCallTypes);

/**
 * Fetch All Part Types
 */
export const fetchPartTypesLoading = (data) => ({
  type: FETCH_PART_TYPES_LOADING,
  payload: data,
});

export const fetchPartTypesFailure = (data) => ({
  type: FETCH_PART_TYPES_ERROR,
  payload: data,
});

export const fetchPartTypesSuccess = (data) => ({
  type: FETCH_PART_TYPES_SUCCESS,
  payload: data,
});

export const fetchPartTypes = () => (dispatch) => {
  dispatch(apiCall(fetchPartTypesLoading, fetchPartTypes, {}));
  return api
    .get(`/parts/types`)
    .then((res) => dispatch(fetchPartTypesSuccess(res.data.data)))
    .catch((err) => dispatch(fetchPartTypesFailure(err)));
};

/**
 * Fetch PartModelAttributes
 */
export const fetchPartModelAttributesLoading = (data) => ({
  type: FETCH_PART_MODEL_ATTRIBUTES_LOADING,
  payload: data,
});

export const fetchPartModelAttributesFailure = (data) => ({
  type: FETCH_PART_MODEL_ATTRIBUTES_ERROR,
  payload: data,
});

export const fetchPartModelAttributesSuccess = (data) => ({
  type: FETCH_PART_MODEL_ATTRIBUTES_SUCCESS,
  payload: data,
});

export const fetchPartModelAttributes = (data) => (dispatch) => {
  dispatch(
    apiCall(fetchPartModelAttributesLoading, fetchPartModelAttributes, data)
  );
  return api
    .get(`/parts/model/${data.modelId}/attributes`)
    .then((res) => dispatch(fetchPartModelAttributesSuccess(res.data)))
    .catch((err) => dispatch(fetchPartModelAttributesFailure(err)));
};

export const resetPartModelAttributes = () => ({
  type: RESET_PART_MODEL_ATTRIBUTES,
});

/**
 * Fetch PartTypeAttributes
 */
export const fetchPartTypeAttributesLoading = (data) => ({
  type: FETCH_PART_TYPE_ATTRIBUTES_LOADING,
  payload: data,
});

export const fetchPartTypeAttributesError = (data) => ({
  type: FETCH_PART_TYPE_ATTRIBUTES_ERROR,
  payload: data,
});

export const fetchPartTypeAttributesSuccess = (data) => ({
  type: FETCH_PART_TYPE_ATTRIBUTES_SUCCESS,
  payload: data,
});

export const fetchPartTypeAttributes = (data) => (dispatch) => {
  dispatch(
    apiCall(fetchPartTypeAttributesLoading, fetchPartTypeAttributes, data)
  );
  return api
    .get(`/parts/type/${data.typeId}/attributes`)
    .then((res) => dispatch(fetchPartTypeAttributesSuccess(res.data)))
    .catch((err) => dispatch(fetchPartTypeAttributesError(err)));
};

export const resetPartTypeAttributes = () => ({
  type: RESET_PART_TYPE_ATTRIBUTES,
});

/**
 * Fetch Diagnoses based on Part Type
 */
export const fetchPartTypeDiagnosesLoading = (data) => ({
  type: FETCH_PART_TYPE_DIAGNOSES_LOADING,
  payload: data,
});

export const fetchPartTypeDiagnosesFailure = (data) => ({
  type: FETCH_PART_TYPE_DIAGNOSES_ERROR,
  payload: data,
});

export const fetchPartTypeDiagnosesSuccess = (data) => ({
  type: FETCH_PART_TYPE_DIAGNOSES_SUCCESS,
  payload: data,
});

export const fetchPartTypeDiagnoses = (data) => (dispatch) => {
  dispatch(
    apiCall(fetchPartTypeDiagnosesLoading, fetchPartTypeDiagnoses, data)
  );
  return api
    .get(`/parts/type/${data.partTypeId}/diagnoses`)
    .then((res) => dispatch(fetchPartTypeDiagnosesSuccess(res.data)))
    .catch((err) => dispatch(fetchPartTypeDiagnosesFailure(err)));
};

/**
 * Fetch Diagnoses based on Part Model
 */
export const fetchModelDiagnosesLoading = (data) => ({
  type: FETCH_MODEL_DIAGNOSES_LOADING,
  payload: data,
});

export const fetchModelDiagnosesFailure = (data) => ({
  type: FETCH_MODEL_DIAGNOSES_ERROR,
  payload: data,
});

export const fetchModelDiagnosesSuccess = (data) => ({
  type: FETCH_MODEL_DIAGNOSES_SUCCESS,
  payload: data,
});

export const fetchModelDiagnoses = (data) => (dispatch) => {
  dispatch(apiCall(fetchModelDiagnosesLoading, fetchModelDiagnoses, data));
  return api
    .get(`/parts/model/${data.modelId}/diagnoses`)
    .then((res) => dispatch(fetchModelDiagnosesSuccess(res.data)))
    .catch((err) => dispatch(fetchModelDiagnosesFailure(err)));
};

export const resetDiagnoses = () => ({
  type: RESET_DIAGNOSES,
});

/**
 * Fetch Part by Serial Number
 */
export const fetchPartBySerialLoading = (data) => ({
  type: FETCH_PART_BY_SERIAL_LOADING,
  payload: data,
});

export const fetchPartBySerialSuccess = (data) => ({
  type: FETCH_PART_BY_SERIAL_SUCCESS,
  payload: data,
});

export const fetchPartBySerialFailure = (data) => ({
  type: FETCH_PART_BY_SERIAL_ERROR,
  payload: data,
});

export const fetchPartBySerialNumber = (serialNumber) => (dispatch) => {
  dispatch(
    apiCall(fetchPartBySerialLoading, fetchPartBySerialNumber, serialNumber)
  );
  return api
    .get(`/part/${serialNumber}`)
    .then((res) => dispatch(fetchPartBySerialSuccess(res.data)))
    .catch((err) => dispatch(fetchPartBySerialFailure(err.response.status)));
};

// These actions are only used for super admins
// It will dump all parts and their relationships as a json payload, which can be edited and re-imported

export const dumpPartsLoading = (data) => ({
  type: DUMP_PARTS_LOADING,
  payload: data,
});

export const dumpPartsSuccess = (data) => ({
  type: DUMP_PARTS_SUCCESS,
  payload: data,
});

export const dumpPartsFailure = (data) => ({
  type: DUMP_PARTS_ERROR,
  payload: data,
});

export const dumpParts = () => (dispatch) => {
  dispatch(apiCall(dumpPartsLoading, dumpParts, {}));
  return api
    .get(`/parts/dump`)
    .then((res) => {
      const { attributes, partTypes } = res.data;
      // If either attributes or partTypes are missing, throw an error
      if (!attributes || !partTypes)
        throw new Error("Invalid data, missing attributes or parts");
      // Finally, dispatch the success action with the data
      dispatch(dumpPartsSuccess({ attributes, partTypes }));
      // Return the data for testing purposes
      return res.data;
    })
    .catch((err) => dispatch(dumpPartsFailure(err)));
};

// An "enum" of all the resource types for parts that a user can update
const partTypeEnum = [
  "part-type", // A part type
  "part-model", // A part model
  "part-type-attribute", // A part attribute
  "part-model-attribute", // A part model attribute
];

// This function will return the appropriate update endpoint for the given resource type
const getUpdatePartAPI = (resourceType, data) => {
  switch (resourceType) {
    case "part-type":
      return api.put(`/parts/types/${data.id}`, data);
    case "part-model":
      return api.put(`/partModels/${data.id}`, data);
    case "part-type-attribute":
      return api.put(`/parts/types/attributes/${data.id}`, data);
    case "part-model-attribute":
      return api.put(`/parts/model/attributes/${data.id}`, data);
    default:
      throw new Error("Invalid resource type");
  }
};

export const updatePartLoading = (data) => ({
  type: UPDATE_PART_LOADING,
  payload: data,
});

export const updatePartSuccess = (data) => ({
  type: UPDATE_PART_SUCCESS,
  payload: data,
});

export const updatePartFailure = (data) => ({
  type: UPDATE_PART_ERROR,
  payload: data,
});

export const updatePart = (resourceType, data) => (dispatch) => {
  // Check if the resource type is valid
  if (!partTypeEnum.includes(resourceType))
    return dispatch(updatePartFailure("Invalid resource type"));
  // Dispatch the loading action
  dispatch(updatePartLoading({ resourceType, data }));
  // Get the appropriate API endpoint
  const apiCall = getUpdatePartAPI(resourceType, data);
  // Make the API call
  return apiCall
    .then((res) => dispatch(updatePartSuccess(res.data)))
    .catch((err) => dispatch(updatePartFailure(err)));
};

// This function will get the appropriate create endpoint for the given resource type
const getCreatePartAPI = (resourceType, data) => {
  switch (resourceType) {
    case "part-type":
      return api.post(`/parts/types`, data);
    case "part-model":
      return api.post(`/partModels`, data);
    case "part-type-attribute":
      return api.post(`/parts/types/attributes`, data);
    case "part-model-attribute":
      return api.post(`/parts/model/attributes`, data);
    default:
      throw new Error("Invalid resource type");
  }
};

export const createPartLoading = (data) => ({
  type: CREATE_PART_LOADING,
  payload: data,
});

export const createPartSuccess = (data) => ({
  type: CREATE_PART_SUCCESS,
  payload: data,
});

export const createPartFailure = (data) => ({
  type: CREATE_PART_ERROR,
  payload: data,
});

export const createPart = (resourceType, data) => (dispatch) => {
  // Check if the resource type is valid
  if (!partTypeEnum.includes(resourceType))
    return dispatch(createPartFailure("Invalid resource type"));
  // Dispatch the loading action
  dispatch(createPartLoading(data));
  // Get the appropriate API endpoint
  const apiCall = getCreatePartAPI(resourceType, data);
  // Make the API call
  return apiCall
    .then((res) => dispatch(createPartSuccess(res.data)))
    .catch((err) => dispatch(createPartFailure(err)));
};

export const fetchAttributesLoading = (data) => ({
  type: FETCH_ATTRIBUTES_LOADING,
  payload: data,
});

export const fetchAttributesSuccess = (data) => ({
  type: FETCH_ATTRIBUTES_SUCCESS,
  payload: data,
});

export const fetchAttributesFailure = (data) => ({
  type: FETCH_ATTRIBUTES_ERROR,
  payload: data,
});

export const fetchAttributes = () => (dispatch) => {
  dispatch(apiCall(fetchAttributesLoading, fetchAttributes, {}));
  return api
    .get(`/parts/attributes`)
    .then((res) => dispatch(fetchAttributesSuccess(res.data)))
    .catch((err) => dispatch(fetchAttributesFailure(err)));
};

export const getDeletePartAPI = (resourceType, data) => {
  switch (resourceType) {
    case "part-type":
      return api.delete(`/parts/types/${data.id}`);
    case "part-model":
      return api.delete(`/partModels/${data.id}`);
    case "part-type-attribute":
      return api.delete(`/parts/types/attributes/${data.id}`);
    case "part-model-attribute":
      return api.delete(`/parts/model/attributes/${data.id}`);
    default:
      throw new Error("Invalid resource type");
  }
};

export const deletePartLoading = (data) => ({
  type: DELETE_PART_LOADING,
  payload: data,
});

export const deletePartSuccess = (data) => ({
  type: DELETE_PART_SUCCESS,
  payload: data,
});

export const deletePartFailure = (data) => ({
  type: DELETE_PART_ERROR,
  payload: data,
});

export const deletePart = (resourceType, data) => (dispatch) => {
  if (!partTypeEnum.includes(resourceType))
    return dispatch(deletePartFailure("Invalid resource type"));
  dispatch(deletePartLoading(data));
  const apiCall = getDeletePartAPI(resourceType, data);
  return apiCall
    .then((res) => dispatch(deletePartSuccess(res.data)))
    .catch((err) => dispatch(deletePartFailure(err)));
};
