import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'Theme';

const Scrim = ({ isShown, inModal }) =>
  isShown && <StyledScrim inModal={inModal} />;

Scrim.propTypes = {
  isShown: PropTypes.bool.isRequired,
  inModal: PropTypes.bool
};

const StyledScrim = styled.div`
  position: ${props => (props.inModal ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.transparent.gray};
  z-index: 1;
`;

export default Scrim;
