import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { hideModal } from 'Actions/modal';
import { fetchTickets } from 'Actions/ticket';
import { acceptInvite, declineInvite } from 'Actions/user';
import { Heading, Paragraph, Button, Alert } from 'Components';
import { withTranslation } from 'react-i18next';
import { space } from 'Theme';

class DealershipInvitation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccepting: false,
      isDeclining: false,
      hasError: false,
      error: ''
    };
    this.onAccept = this.onAccept.bind(this);
    this.onDecline = this.onDecline.bind(this);
  }

  onAccept() {
    const { user, hideModal, t, acceptInvite, fetchTickets } = this.props;

    this.setState(() => ({ isAccepting: true, hasError: false }));

    acceptInvite(user.dealership.id, {
      accept_invite_token: user.dealership.inviteToken
    })
      .then(async () => {
        await fetchTickets({ paginationPerPage: 25 });
        this.setState(() => ({ isAccepting: false }));
      })
      .catch(errorStatus => {
        const error = t(`accept_invite_dealer_user.error.${errorStatus}`);
        this.setState(() => ({
          hasError: true,
          error
        }));
      })
      .finally(() => {
        hideModal();
      });
  }

  onDecline() {
    const { user, hideModal, declineInvite, t } = this.props;

    this.setState(() => ({ isDeclining: true, hasError: false }));
    const data = {
      id: user.dealership.id
    };
    declineInvite(data)
      .then(() => {
        this.setState(() => ({ isDeclining: false }));
        hideModal();
      })
      .catch(errorStatus => {
        const error = t(`accept_invite_dealer_user.error.${errorStatus}`);
        this.setState(() => ({
          hasError: true,
          error
        }));
      });
  }

  render() {
    const { user, t } = this.props;
    const { isAccepting, isDeclining, hasError, error } = this.state;

    return (
      <InviteContainer>
        <React.Fragment>
          {user.dealership && (
            <MessageContainer>
              <Alert
                alertIsOpen={hasError}
                color="danger"
                onCloseFunc={() => this.setState(() => ({ hasError: false }))}
              >
                {error}
              </Alert>
              <Heading size={4}>
                {t('accept_invite_dealer_user.heading_main', {
                  dealer_name: `${user.dealership.name}`
                })}
              </Heading>
              <Paragraph>
                {t('accept_invite_dealer_user.heading_sub', {
                  dealer_name: `${user.dealership.name}`
                })}
              </Paragraph>
            </MessageContainer>
          )}
          <Button
            className="w-100 mt-4 mb-2"
            color="primary"
            isFetching={isAccepting}
            onClickFunc={() => this.onAccept()}
          >
            {t('accept_invite_dealer_user.button')}
          </Button>
          <Button
            className="w-100 mt-4 mb-2 btn-outline-white"
            color="outline-secondary"
            isFetching={isDeclining}
            onClickFunc={() => this.onDecline()}
            throbberIsDark
          >
            {t('decline_invite_dealer_user.button')}
          </Button>
        </React.Fragment>
      </InviteContainer>
    );
  }
}

DealershipInvitation.propTypes = {
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.shape({
    dealership: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      inviteToken: PropTypes.string
    })
  }).isRequired,
  t: PropTypes.func.isRequired,
  acceptInvite: PropTypes.func.isRequired,
  declineInvite: PropTypes.func.isRequired,
  fetchTickets: PropTypes.func.isRequired
};

const InviteContainer = styled.div`
  padding: ${space[3]}rem;
  max-width: 480px;
  margin: auto;
`;

const MessageContainer = styled.div`
  padding-bottom: ${space[3]}rem;
`;

const mapStateToProps = state => ({
  modal: state.modal,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  hideModal: data => dispatch(hideModal(data)),
  acceptInvite: (id, token) => dispatch(acceptInvite(id, token)),
  declineInvite: (id, token) => dispatch(declineInvite(id, token)),
  fetchTickets: data => dispatch(fetchTickets(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DealershipInvitation));
