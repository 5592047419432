export const FORM_CREATE_DEALER_LOCATION = "FORM_CREATE_DEALER_LOCATION";
export const FORM_CREATE_DEALER_USER = "FORM_CREATE_DEALER_USER";
export const FORM_CREATE_TICKET = "FORM_CREATE_TICKET";
export const FORM_UPDATE_DEALER_USER = "FORM_UPDATE_DEALER_USER";
export const FORM_UPDATE_TICKET = "FORM_UPDATE_TICKET";
export const CONFIRM_ACCEPT_DEALER_INVITE = "CONFIRM_ACCEPT_DEALER_INVITE";
export const CONFIRM_ARCHIVE_USER = "CONFIRM_ARCHIVE_USER";
export const FORM_INVITE_SERVICE_CENTER_TECH =
  "FORM_INVITE_SERVICE_CENTER_TECH";
export const FORM_ADD_PART_TYPE = "FORM_ADD_PART_TYPE";
export const FORM_ADD_PART_TYPE_ATTRIBUTE = "FORM_ADD_PART_TYPE_ATTRIBUTE";
export const FORM_ADD_PART_MODEL = "FORM_ADD_PART_MODEL";
export const FORM_ADD_PART_MODEL_ATTRIBUTE = "FORM_ADD_PART_MODEL_ATTRIBUTE";
