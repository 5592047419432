import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { parseISO } from "date-fns";
import { withTranslation } from "react-i18next";
import Option from "@bootstrap-styled/v4/lib/Option";
import Row from "@bootstrap-styled/v4/lib/Row";
import Col from "@bootstrap-styled/v4/lib/Col";
import { hideModal } from "Actions/modal";
import { createTicket } from "Actions/ticket";
import { fetchBikeMakes } from "Actions/bike";
import forkSerial from "Assets/images/forkSerial.png";
import {
  fetchDealershipsAll,
  fetchDealershipDetails
} from "Actions/dealership";
import {
  fetchPartBySerialNumber,
  fetchPartTypes,
  fetchPartModelAttributes,
  resetPartModelAttributes,
  fetchModelDiagnoses,
  fetchPartTypeDiagnoses,
  fetchPartTypeAttributes,
  resetPartTypeAttributes,
  resetDiagnoses
} from "Actions/part";
import {
  Alert,
  Button,
  Datepicker,
  FileUploader,
  Form,
  Heading,
  PopOver,
  HR,
  Throbber,
  Input as SsgInput
} from "Components";
import { space } from "Theme";
import DealerFields from "./DealerFields";

class CreateTicket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      hasSubmitError: false,
      submitErrorMsg: "",
      isSearching: false,
      form: {
        dealer_name: "",
        user: "",
        dealer_location: "",
        bike_serial: "",
        bike_make: "",
        bike_make_other: "",
        part_serial: "",
        part_type: "",
        part_model: "",
        date_purchased: "",
        diagnosis: "",
        notes: "",
        files: [],
        attributes: {}
      },
      models: [],
      isOpen: false
    };
    this.setPondRef = this.setPondRef.bind(this);
    this.handleUpdateFiles = this.handleUpdateFiles.bind(this);
  }

  async componentDidMount() {
    const { user, fetchPartTypes, fetchBikeMakes } = this.props;
    // Set the user id in the form, ONLY if the user is not a service-center level user,
    // since service-center level users will be selecting the user value manually
    if (user.level !== "service_center_user") {
      const { form } = this.state;
      const newForm = { ...form };
      newForm.user = user.id;
      this.setState(state => ({ ...state, form: newForm }));
    }
    // Load list of part types for dropdown menu
    await fetchPartTypes();
    // Load list of bicycle makes for dropdown menu
    fetchBikeMakes();
  }

  componentWillUnmount() {
    const {
      resetPartTypeAttributes,
      resetPartModelAttributes,
      resetDiagnoses
    } = this.props;
    resetPartTypeAttributes();
    resetPartModelAttributes();
    resetDiagnoses();
  }

  setPondRef(ref) {
    this.state.pond = ref;
    this.pond = ref;
  }

  onSubmit = async () => {
    const { createTicket, hideModal, t } = this.props;
    const { form } = this.state;
    const newForm = { ...form };
    newForm.part_type = newForm.part_type.toString();
    if (newForm.date_purchased) {
      newForm.date_purchased = parseISO(newForm.date_purchased.toISOString());
    }

    const fileData = this.pond.getFiles().map(file => {
      return `data:image/png;base64,${file.getFileEncodeBase64String()}`;
    });
    this.setState(state => ({ ...state, isSubmitting: true }));

    let submitErrorMsg = "";
    let hasSubmitError = false;
    await createTicket({
      form: newForm,
      images: fileData
    })
      .catch(errorStatus => {
        if (errorStatus !== 401) {
          submitErrorMsg = t(`create_ticket.error.${errorStatus}`);
          hasSubmitError = true;
        }
      })
      .finally(() => {
        const {
          status: { CREATE_TICKET }
        } = this.props;
        if (CREATE_TICKET === "success") {
          hasSubmitError = false;
        }
      });
    this.setState(state => ({
      ...state,
      isSubmitting: false,
      hasSubmitError,
      submitErrorMsg
    }));
    if (hasSubmitError === false) {
      hideModal();
    }
  };

  // This function responds to a service-center level user manually selecting a dealership name from the dropdown menu,
  // updating the form state and fetching dealership details based on the user selection
  // Additionally, it updates the location field in the form state with the location of the selected dealership
  onChangeDealer = async e => {
    const {
      target: { name, value }
    } = e;
    const { form } = this.state;
    const newForm = { ...form };
    newForm[name] = value;
    const { fetchDealershipDetails } = this.props;
    const data = await fetchDealershipDetails({ id: value });
    // if a 'Default' location exists, automatically set the location field in the form state to the 'Default' location
    const defaultLocation =
      data.locations.find(l => l.name === "Default") ?? false;
    if (defaultLocation) {
      newForm.dealer_location = defaultLocation.id;
    }
    this.setState(state => ({ ...state, form: newForm }));
  };

  // setDealerInfo function is called when a dealership-level user opens the form, after the user's dealership details are fetched from the api (via fetchDealershipDetails) and added to the Redux store
  // The function serves the purpose of taking data from the Redux store and updating the form state automatically based on the user's dealership information
  setDealerInfo = (id, location) => {
    const { form } = this.state;
    const newForm = { ...form };
    newForm.dealer_location = location;
    newForm.dealer_name = id;
    this.setState(state => ({ ...state, form: newForm }));
  };

  onChange = async e => {
    const {
      target: { name, value }
    } = e;
    const { form } = this.state;
    const newForm = { ...form };
    newForm[name] = value;
    this.setState(state => ({ ...state, form: newForm }));
  };

  // When user selects a part type from the dropdown menu, this function updates form state and populates the part model dropdown menu with part models that correspond to the selected part type
  onChangePartType = async e => {
    const {
      target: { value: partTypeId }
    } = e;
    const { form } = this.state;
    const newForm = { ...form };
    const {
      part: { types, diagnoses },
      fetchPartTypeDiagnoses,
      fetchPartTypeAttributes,
      resetPartModelAttributes,
      resetDiagnoses
    } = this.props;
    // If attrs & diagnoses aren't empty, start from scratch in redux store
    if (diagnoses.length > 0) {
      resetDiagnoses();
    }

    // Clear part model selection
    newForm.part_model = "";
    newForm.diagnosis = "";
    newForm.part_type = parseInt(partTypeId, 10);
    let newModels = [];
    types.forEach(type => {
      if (type.id === parseInt(partTypeId, 10)) {
        newModels = type.models;
      }
    });
    this.setState(state => ({ ...state, form: newForm, models: newModels }));

    // Get the standard/default list of part types that will be associated with this part type
    await fetchPartTypeAttributes({ typeId: partTypeId });
    const {
      part: { typeAttributes, modelAttributes }
    } = this.props;
    const allAttributes = { ...typeAttributes, ...modelAttributes };
    Object.keys(allAttributes).forEach(key => {
      form.attributes[key] = "";
    });
    resetPartModelAttributes();
    fetchPartTypeDiagnoses({ partTypeId });
  };

  /**
   * onChangePartModel
   * @param e (event)
   *
   * This function updates form state based on part model selected by user, triggers api calls to fetch all PartModelAttributes & Diagnoses associated with the selection,
   * which results in rendering the dropdown menus for each PartModelAttribute type
   */
  onChangePartModel = async e => {
    const { value } = e.target;
    const {
      part: {
        typeAttributes,
        diagnoses: prevDiagnoses,
        modelAttributes: prevModelAttrs
      },
      fetchPartModelAttributes,
      fetchModelDiagnoses
    } = this.props;
    const { form } = this.state;
    const newForm = { ...form };
    const prevAllAttrs = { ...typeAttributes, ...prevModelAttrs };
    newForm.part_model = value;
    this.setState(state => ({ ...state, form: newForm }));

    // Save the whole attribute object of any currently selected attributes
    const selectedAttrs = {};
    Object.keys(prevAllAttrs).forEach(key => {
      if (newForm.attributes[key]) {
        const filteredOptions = prevAllAttrs[key].filter(
          opt => opt.id.toString() === newForm.attributes[key]
        );
        const targetOption = filteredOptions[0];
        selectedAttrs[key] = targetOption;
      }
    });

    // Get All the PartModelAttributes associated with this new Model No.
    await fetchPartModelAttributes({ modelId: value });
    const {
      part: { modelAttributes }
    } = this.props;

    // Create empty state values for each newly acquired PartModelAttribute
    const allAttrs = { ...typeAttributes, ...modelAttributes };
    Object.keys(allAttrs).forEach(key => {
      // If there is a current selection for this attribute...
      if (selectedAttrs[key]) {
        const optionVals = allAttrs[key].map(option => option.value);
        // If the "value" [read: human readable label] for the current selection
        // for this attributes matches any of the values in the new PartModel's options
        // for this attribute...
        if (optionVals.includes(selectedAttrs[key].value)) {
          allAttrs[key].forEach(option => {
            // ...find the option with the matching "value" [read: label]
            if (option.value === selectedAttrs[key].value) {
              // ...and set state for the current attribute to equal the id of the new
              // PartModel's option with a matching "value" [read: label]
              newForm.attributes[key] = option.id;
            }
          });
        } else {
          newForm.attributes[key] = "";
        }
      } else {
        // If the form state attributes object doesn't have a key for this attribute,
        // set the value to an empty string to start
        newForm.attributes[key] = "";
      }
    });
    this.setState(state => ({ ...state, form: newForm }));

    // Now, do all the same for the diagnosis...
    if (newForm.diagnosis) {
      const filteredDiagnoses = prevDiagnoses.filter(
        option => option.id.toString() === newForm.diagnosis
      );
      const selectedDiagnosis = filteredDiagnoses[0];

      // Get Diagnoses for this Model No.
      await fetchModelDiagnoses({ modelId: value });
      const {
        part: { diagnoses }
      } = this.props;
      const optionVals = diagnoses.map(option => option.value);
      if (optionVals.includes(selectedDiagnosis.value)) {
        diagnoses.forEach(diagnosis => {
          if (diagnosis.value === selectedDiagnosis.value) {
            this.setState(state => ({
              ...state,
              form: { ...newForm, diagnosis: diagnosis.id }
            }));
          }
        });
      } else {
        this.setState(state => ({
          ...state,
          form: { ...newForm, diagnosis: "" }
        }));
      }
    } else {
      this.setState(state => ({
        ...state,
        form: { ...newForm, diagnosis: "" }
      }));
      await fetchModelDiagnoses({ modelId: value });
    }
  };

  onChangeModelAttr = e => {
    const {
      target: { name, value }
    } = e;
    const { form } = this.state;
    const newForm = { ...form };
    newForm.attributes[name] = value;
    this.setState(state => ({ ...state, form: newForm }));
  };

  // Not currently implemented
  // handleSearchByPartSerial = async () => {
  //   const serialNumberInput = document.getElementById('part-serial');
  //   const serial = serialNumberInput.value;
  //   const { fetchPartBySerialNumber, t } = this.props;
  //   this.setState(() => ({ isSearching: true }));
  //   await fetchPartBySerialNumber(serial).catch(errorStatus => {
  //     const error = t(`create_ticket.search_by_serial.${errorStatus}`);
  //     this.setState(() => ({ searchErrorMsg: error }));
  //   });
  //   const {
  //     status: { FETCH_PART_BY_SERIAL }
  //   } = this.props;
  //   if (FETCH_PART_BY_SERIAL !== 'success') {
  //     this.setState(() => ({ hasSearchError: true, isSearching: false }));
  //   }
  // };

  handleDatepickerChange = date => {
    const { form } = this.state;
    const newForm = { ...form };
    newForm.date_purchased = date;
    this.setState(state => ({ ...state, form: newForm }));
  };

  handleShowImage = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  handleUpdateFiles(fileItems) {
    // Set currently active file objects to this.state
    this.setState(state => ({
      ...state,
      form: { ...state.form, files: fileItems.map(fileItem => fileItem.file) }
    }));
  }

  render() {
    const {
      form,
      submitErrorMsg,
      hasSubmitError,
      isSubmitting,
      models,
      isOpen
    } = this.state;

    const {
      t,
      part: { types: partTypes, typeAttributes, modelAttributes, diagnoses },
      bike: { makes: bikeMakes },
      status: {
        FETCH_BIKE_MAKES,
        FETCH_PART_TYPES,
        FETCH_PART_MODEL_ATTRIBUTES,
        FETCH_PART_TYPE_ATTRIBUTES,
        FETCH_MODEL_DIAGNOSES,
        FETCH_PART_TYPE_DIAGNOSES
      }
    } = this.props;

    const bikeMakeElm = document.getElementById("bike-make");
    const currentlySelectedBikeMake =
      bikeMakeElm &&
      bikeMakeElm.options &&
      bikeMakeElm.options[bikeMakeElm.selectedIndex] &&
      bikeMakeElm.options[bikeMakeElm.selectedIndex].text;

    let allAttributes = {};
    if (!form.part_model) {
      allAttributes = typeAttributes;
    } else {
      allAttributes = { ...typeAttributes, ...modelAttributes };
    }

    const genericRequiredRule = {
      rule: "required",
      messages: {
        error: t("form.required_feedback"),
        success: t("form.required")
      }
    };

    return (
      <Form
        onSubmitFunc={() => this.onSubmit()}
        validation={{
          dealer_name: [genericRequiredRule],
          user: [genericRequiredRule],
          dealer_location: [genericRequiredRule],
          bike_make_other: [genericRequiredRule],
          part_type: [genericRequiredRule],
          part_model: [genericRequiredRule],
          diagnosis: [genericRequiredRule]
        }}
        render={status => (
          <Fragment>
            {/* General Dealer/User Information */}
            <DealerFields
              form={form}
              validation={status}
              onChangeFunc={this.onChange}
              onChangeDealerFunc={this.onChangeDealer}
              setDealerInfoFunc={this.setDealerInfo}
            />
            <Section>
              <Row>
                <Col md={3}>
                  <Heading size={5}>{t("create_ticket.heading.part")}</Heading>
                </Col>
                <Col md={9}>
                  <Fragment>
                    {/* Part Serial Number Selector */}
                    <Row>
                      <Col md={6}>
                        <SsgInput
                          label={t("create_ticket.label.part_serial")}
                          type="text"
                          name="part_serial"
                          value={form.part_serial}
                          onChangeFunc={this.onChange}
                          status="muted"
                          infoFunc={this.handleShowImage}
                          infoLabel={t("create_ticket.helper_text.find_serial")}
                          // helperLink={
                          //   <A href="#" onClick={this.handleShowImage}>
                          //     {t('create_ticket.helper_text.find_serial')}
                          //   </A>
                          // }
                          helperText={t(
                            "create_ticket.helper_text.part_serial"
                          )}
                          inputId="part-serial"
                          width="100%"
                        />
                        {isOpen && (
                          <PopOver
                            isOpen={isOpen}
                            toggleFunc={this.handleShowImage}
                          >
                            <img src={forkSerial} alt="fork crown" />
                          </PopOver>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      {/* Part Type Selector */}
                      <Col md={6}>
                        <SsgInput
                          label={t("create_ticket.label.part_type")}
                          inputId="part-type"
                          name="part_type"
                          onChangeFunc={this.onChangePartType}
                          type="select"
                          value={form.part_type}
                          status={status.part_type.status}
                          feedbackMsg={status.part_type.feedback}
                          width="100%"
                          isRequired
                          isDisabled={
                            !partTypes ||
                            partTypes.length < 1 ||
                            FETCH_PART_TYPES === "loading"
                          }
                        >
                          {FETCH_PART_TYPES === "loading" ? (
                            <Option disabled value="">
                              {t("form.placeholders.loading")}
                            </Option>
                          ) : (
                            <Fragment>
                              <Option disabled value="">
                                {t("create_ticket.placeholder.part_type")}
                              </Option>
                              {partTypes &&
                                partTypes.length &&
                                partTypes.map(partType => (
                                  <Option key={partType.id} value={partType.id}>
                                    {partType.name}
                                  </Option>
                                ))}
                            </Fragment>
                          )}
                        </SsgInput>
                      </Col>

                      {/* Part Model Selector */}
                      <Col md={6}>
                        {models && models.length > 0 && (
                          <SsgInput
                            label={t("create_ticket.label.part_model")}
                            inputId="part-model"
                            name="part_model"
                            onChangeFunc={this.onChangePartModel}
                            type="select"
                            value={form.part_model}
                            status={status.part_model.status}
                            feedbackMsg=""
                            width="100%"
                            isRequired
                            isDisabled={
                              FETCH_PART_MODEL_ATTRIBUTES === "loading" ||
                              !models ||
                              models.length === 0
                            }
                          >
                            <Fragment>
                              <Option disabled value="">
                                {t("create_ticket.placeholder.part_model")}
                              </Option>
                              {models &&
                                models.map(model => (
                                  <Option key={model.id} value={model.id}>
                                    {model.name}{" "}
                                    {FETCH_PART_MODEL_ATTRIBUTES === "loading"
                                      ? t("form.placeholders.loading")
                                      : ""}
                                  </Option>
                                ))}
                            </Fragment>
                          </SsgInput>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {Object.entries(allAttributes).filter(
                          // eslint-disable-next-line no-unused-vars
                          ([key, value]) => key !== "diagnosis"
                        ).length > 0 &&
                          FETCH_PART_TYPE_ATTRIBUTES === "success" && (
                            <Heading size={6}>
                              {t("create_ticket.subheading.part_details")}
                            </Heading>
                          )}
                        {FETCH_PART_TYPE_ATTRIBUTES === "loading" && (
                          <Throbber isDark isSmall />
                        )}
                      </Col>
                    </Row>
                    {/* Part Attributes Selectors */}
                    <Row>
                      {Object.entries(allAttributes).length > 0 &&
                        Object.entries(allAttributes).map(
                          ([key, value]) =>
                            key !== "diagnosis" && (
                              <Col key={key} md={6}>
                                <SsgInput
                                  type="select"
                                  label={t(`create_ticket.label.${key}`)}
                                  inputId={key}
                                  name={key}
                                  value={form.attributes[key]}
                                  defaultValue=""
                                  onChangeFunc={this.onChangeModelAttr}
                                  width="100%"
                                  status="muted"
                                  isDisabled={
                                    FETCH_PART_TYPE_ATTRIBUTES === "loading" ||
                                    FETCH_PART_MODEL_ATTRIBUTES === "loading"
                                  }
                                >
                                  <Option disabled value="">
                                    {t(`create_ticket.placeholder.${key}`)}
                                  </Option>
                                  {value.map(option => (
                                    <Option key={option.id} value={option.id}>
                                      {option.value}
                                    </Option>
                                  ))}
                                </SsgInput>
                              </Col>
                            )
                        )}
                    </Row>
                    {/* Part Diagnoses Selector */}
                    <Row>
                      <Col md={6}>
                        <DiagnosesWrapper>
                          <Heading size={6}>
                            {t("create_ticket.subheading.part_diagnosis")}
                          </Heading>
                          <SsgInput
                            label="Diagnosis"
                            inputId="diagnosis"
                            name="diagnosis"
                            onChangeFunc={this.onChange}
                            type="select"
                            value={form.diagnosis}
                            status={status.diagnosis.status}
                            feedbackMsg={status.diagnosis.feedback}
                            width="100%"
                            isRequired
                            isDisabled={
                              !diagnoses ||
                              diagnoses.length === 0 ||
                              FETCH_PART_TYPE_ATTRIBUTES === "loading" ||
                              FETCH_PART_MODEL_ATTRIBUTES === "loading" ||
                              FETCH_MODEL_DIAGNOSES === "loading" ||
                              FETCH_PART_TYPE_DIAGNOSES === "loading"
                            }
                          >
                            <Fragment>
                              <Option disabled value="">
                                {FETCH_PART_TYPE_ATTRIBUTES === "loading" ||
                                FETCH_PART_MODEL_ATTRIBUTES === "loading" ||
                                FETCH_MODEL_DIAGNOSES === "loading" ||
                                FETCH_PART_TYPE_DIAGNOSES === "loading"
                                  ? t("form.placeholders.loading")
                                  : t("create_ticket.placeholder.diagnosis")}
                              </Option>
                              {diagnoses &&
                                diagnoses.length > 0 &&
                                diagnoses.map(diagnosis => (
                                  <Option
                                    key={diagnosis.id}
                                    value={diagnosis.id}
                                  >
                                    {diagnosis.value}
                                  </Option>
                                ))}
                            </Fragment>
                          </SsgInput>
                        </DiagnosesWrapper>
                      </Col>
                    </Row>
                  </Fragment>
                </Col>
              </Row>
            </Section>
            <HR />
            {/* Basic Bike Information */}
            <Section>
              <Row>
                <Col md={3}>
                  <Heading size={5}>{t("create_ticket.heading.bike")}</Heading>
                </Col>
                <Col md={9}>
                  <Row>
                    <Col md={6}>
                      <SsgInput
                        label={t("create_ticket.label.bike_serial")}
                        type="text"
                        name="bike_serial"
                        value={form.bike_serial}
                        onChangeFunc={this.onChange}
                        status="muted"
                        helperText={t("create_ticket.helper_text.bike_serial")}
                        inputId="bike-serial"
                        width="100%"
                      />
                    </Col>
                    <Col md={6}>
                      <SsgInput
                        label={t("create_ticket.label.bike_make")}
                        inputId="bike-make"
                        onChangeFunc={this.onChange}
                        type="select"
                        value={form.bike_make}
                        status="muted"
                        helperText={t("create_ticket.helper_text.bike_make")}
                        name="bike_make"
                        width="100%"
                        isDisabled={
                          !bikeMakes ||
                          bikeMakes.length < 1 ||
                          FETCH_BIKE_MAKES === "loading"
                        }
                      >
                        {FETCH_BIKE_MAKES === "loading" ? (
                          <Option disabled value="">
                            {t("form.placeholders.loading")}
                          </Option>
                        ) : (
                          <Fragment>
                            <Option disabled value="">
                              {t("create_ticket.placeholder.bike_make")}
                            </Option>
                            {bikeMakes.map(make => (
                              <Option key={make.id} value={make.id}>
                                {make.name}
                              </Option>
                            ))}
                          </Fragment>
                        )}
                      </SsgInput>
                      {currentlySelectedBikeMake &&
                        currentlySelectedBikeMake.toLowerCase() === "other" && (
                          <SsgInput
                            type="text"
                            inputId="bike-make-other"
                            name="bike_make_other"
                            value={form.bike_make_other}
                            hideLabel
                            onChangeFunc={this.onChange}
                            placeholder={t(
                              "create_ticket.label.bike_make_other"
                            )}
                            label={t(
                              "create_ticket.placeholder.bike_make_other"
                            )}
                            status={status.bike_make_other.status}
                            isRequired
                            width="100%"
                          />
                        )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Section>
            <HR />

            {/* Additional Information */}
            <Section>
              <Row>
                <Col md={3}>
                  <Heading size={5}>{t("create_ticket.heading.misc")}</Heading>
                </Col>
                <Col md={9}>
                  <Row>
                    <Col md={6}>
                      <Datepicker
                        label={t("create_ticket.label.purchase_date")}
                        name="date_purchased"
                        inputId="date-purchased"
                        onChangeFunc={this.handleDatepickerChange}
                        value={form.date_purchased}
                        status="muted"
                        helperText={t(
                          "create_ticket.helper_text.purchase_date"
                        )}
                        width="100%"
                      />
                    </Col>
                  </Row>
                  <SsgInput
                    label={t("create_ticket.label.notes")}
                    inputId="notes"
                    name="notes"
                    type="textarea"
                    value={form.notes}
                    onChangeFunc={this.onChange}
                    status="muted"
                    placeholder={t("create_ticket.placeholder.notes")}
                    width="100%"
                  />
                  <FileUploader
                    setPond={ref => this.setPondRef(ref)}
                    onUpdateFiles={fileItems =>
                      this.handleUpdateFiles(fileItems)
                    }
                    files={form.files}
                    allowMultiple
                    maxFiles={3}
                    label={t("create_ticket.label.file_upload")}
                    helperText={t("create_ticket.helper_text.file_upload")}
                  />
                </Col>
              </Row>
            </Section>
            <HR />
            <Alert
              alertIsOpen={hasSubmitError}
              color="danger"
              onCloseFunc={() => {
                this.setState(() => ({ hasSubmitError: false }));
              }}
            >
              {submitErrorMsg}
            </Alert>
            <ButtonWrapper>
              <Button
                className="mt-4 mb-2"
                color="primary"
                type="submit"
                isFetching={isSubmitting}
                width="min-content"
              >
                {t("create_ticket.buttons.submit_ticket")}
              </Button>
            </ButtonWrapper>
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  dealership: state.dealership,
  status: state.status,
  part: state.part,
  bike: state.bike
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  fetchDealershipDetails: data => dispatch(fetchDealershipDetails(data)),
  fetchBikeMakes: () => dispatch(fetchBikeMakes()),
  fetchPartTypes: () => dispatch(fetchPartTypes()),
  fetchPartModelAttributes: data => dispatch(fetchPartModelAttributes(data)),
  fetchPartTypeAttributes: data => dispatch(fetchPartTypeAttributes(data)),
  fetchModelDiagnoses: data => dispatch(fetchModelDiagnoses(data)),
  fetchDealershipsAll: data => dispatch(fetchDealershipsAll(data)),
  fetchPartTypeDiagnoses: data => dispatch(fetchPartTypeDiagnoses(data)),
  resetDiagnoses: () => dispatch(resetDiagnoses()),
  resetPartTypeAttributes: () => dispatch(resetPartTypeAttributes()),
  resetPartModelAttributes: () => dispatch(resetPartModelAttributes()),
  // TODO: Replace with functional action creator!
  fetchPartBySerialNumber: number => dispatch(fetchPartBySerialNumber(number)),
  createTicket: data => dispatch(createTicket(data))
});

CreateTicket.propTypes = {
  // mapState
  user: PropTypes.shape({
    id: PropTypes.number,
    level: PropTypes.string,
    dealership: PropTypes.shape({
      id: PropTypes.number
    })
  }).isRequired,
  dealership: PropTypes.shape({
    users: PropTypes.arrayOf(PropTypes.shape()),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    ),
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number
      })
    ),
    dealership: PropTypes.shape({})
  }).isRequired,
  status: PropTypes.shape({
    CREATE_TICKET: PropTypes.string.isRequired,
    FETCH_DEALERSHIP_DETAIL: PropTypes.string.isRequired,
    FETCH_BIKE_MAKES: PropTypes.string.isRequired,
    FETCH_PART_TYPES: PropTypes.string.isRequired,
    FETCH_PART_BY_SERIAL: PropTypes.string.isRequired,
    FETCH_PART_MODEL_ATTRIBUTES: PropTypes.string.isRequired,
    FETCH_MODEL_DIAGNOSES: PropTypes.string.isRequired,
    FETCH_PART_TYPE_DIAGNOSES: PropTypes.string.isRequired,
    FETCH_PART_TYPE_ATTRIBUTES: PropTypes.string.isRequired
  }).isRequired,
  part: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.shape({})),
    typeAttributes: PropTypes.shape(),
    modelAttributes: PropTypes.shape({}),
    part: PropTypes.shape({
      id: PropTypes.string
    }),
    diagnoses: PropTypes.arrayOf(PropTypes.shape())
  }).isRequired,
  bike: PropTypes.shape({
    makes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    )
  }).isRequired,
  // mapDispatch
  hideModal: PropTypes.func.isRequired,
  fetchDealershipDetails: PropTypes.func.isRequired,
  // fetchPartBySerialNumber: PropTypes.func.isRequired,
  fetchPartTypes: PropTypes.func.isRequired,
  fetchPartModelAttributes: PropTypes.func.isRequired,
  fetchBikeMakes: PropTypes.func.isRequired,
  fetchModelDiagnoses: PropTypes.func.isRequired,
  fetchPartTypeDiagnoses: PropTypes.func.isRequired,
  fetchPartTypeAttributes: PropTypes.func.isRequired,
  resetPartTypeAttributes: PropTypes.func.isRequired,
  resetPartModelAttributes: PropTypes.func.isRequired,
  resetDiagnoses: PropTypes.func.isRequired,
  createTicket: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

// Styled Components
const Section = styled.div`
  display: ${props => (props.isHidden ? "none" : "block")};
  padding: ${space[4]}rem 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: ${space[1] * 3}rem;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${space[5]}rem;
  button: {
    margin-bottom: 0;
  }
`;

const DiagnosesWrapper = styled.div`
  margin-top: ${space[1] * 3}rem;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateTicket));
