export default {
  en: {
    misc: {
      months: {
        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        may: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        oct: 'October',
        nov: 'November',
        dev: 'December'
      }
    }
  },
  de: {
    misc: {
      months: {
        jan: 'Januar',
        feb: 'Februar',
        mar: 'Marsch',
        apr: 'April',
        may: 'Dürfen',
        jun: 'Juni',
        jul: 'Juli',
        aug: 'August',
        sep: 'September',
        oct: 'Oktober',
        nov: 'November',
        dev: 'Dezember'
      }
    }
  },
  jp: {
    misc: {
      months: {
        jan: '一月',
        feb: '2月',
        mar: '行進',
        apr: '4月',
        may: '5月',
        jun: '六月',
        jul: '7月',
        aug: '8月',
        sep: '9月',
        oct: '10月',
        nov: '11月',
        dev: '12月'
      }
    }
  },
  it: {
    misc: {
      months: {
        jan: 'Gennaio',
        feb: 'febbraio',
        mar: 'Marzo',
        apr: 'aprile',
        may: 'Maggio',
        jun: 'Giugno',
        jul: 'Luglio',
        aug: 'agosto',
        sep: 'settembre',
        oct: 'ottobre',
        nov: 'novembre',
        dev: 'Dicembre'
      }
    }
  }
};
