import {
  FETCH_USERS_SUCCESS,
  FETCH_SCOPES_SUCCESS,
  SEARCH_USERS_SUCCESS,
  FETCH_USERS_BY_SCOPE_LOADING,
  FETCH_USERS_BY_SCOPE_SUCCESS,
  ADD_USERS_FILTER,
  REMOVE_USERS_FILTER
} from "Actions/users/types";

const initialState = {
  list: [],
  scopes: [],
  filters: ["active", "pending"]
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS: {
      return {
        ...state,
        list: [...action.payload.data],
        pagination: { ...action.payload.pagination }
      };
    }
    case SEARCH_USERS_SUCCESS: {
      return {
        ...state,
        list: [...action.payload.data],
        pagination: { ...action.payload.pagination }
      };
    }
    case FETCH_SCOPES_SUCCESS: {
      return {
        ...state,
        scopes: action.payload
      };
    }
    case FETCH_USERS_BY_SCOPE_LOADING: {
      let { list } = state;
      if (action.payload.shouldOverwriteResults) {
        list = [];
      }
      return {
        ...state,
        list
      };
    }
    case FETCH_USERS_BY_SCOPE_SUCCESS: {
      return {
        ...state,
        list: [...action.payload.data],
        pagination: { ...action.payload.pagination }
      };
    }
    case ADD_USERS_FILTER: {
      if (!state.filters.includes(action.payload)) {
        return {
          ...state,
          filters: [...state.filters, action.payload]
        };
      }

      return state;
    }
    case REMOVE_USERS_FILTER: {
      return {
        ...state,
        filters: state.filters.filter(
          filterItem => filterItem !== action.payload
        )
      };
    }
    default:
      return state;
  }
};

export default usersReducer;
