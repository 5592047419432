import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AppContainer } from "react-hot-loader";
import "./i18n";
import { makeTheme } from "bootstrap-styled/lib/theme";
import BootstrapProvider from "@bootstrap-styled/provider/lib/BootstrapProvider";
import bootstrapTheme from "Theme/bootstrap";
import store, { history } from "./store";
import App from "./containers/App";
import { VERSION } from "./config";
import ErrorBoundary from "./containers/ErrorBoundary";

const ssgTheme = makeTheme(bootstrapTheme);

window.SRS_VERSION = VERSION;

window.getSRSVersionURL = function(hash) {
  return `https://github.com/isolary/srs/commit/${hash}`;
};

const render = App => {
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <AppContainer>
          <BootstrapProvider theme={ssgTheme}>
            <Provider store={store}>
              <App history={history} />
            </Provider>
          </BootstrapProvider>
        </AppContainer>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

render(App);

if (module.hot) {
  module.hot.accept(
    ["./containers", "./reducers", "./actions", "./components"],
    () => {
      render(App);
    }
  );
}
