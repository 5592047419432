import {
  LOGIN_SUCCESS,
  LOCAL_LOGIN,
  LOGOUT,
  UPDATE_USER_SUCCESS,
  ACCEPT_INVITE_SUCCESS,
  DECLINE_INVITE_SUCCESS
} from 'Actions/user/types';
import { api, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE } from 'Actions/api';
import i18n from '../i18n';

const initialState = {
  token: '',
  isLoggedIn: false,
  isInitialized: false,
  lang: 'en',
  name: ''
};

const logout = () => {
  api.defaults.headers.Authorization = ``;
  localStorage.removeItem('user');
};

const login = user => {
  const { lang, token } = user;
  i18n.changeLanguage(lang);
  localStorage.setItem('user', JSON.stringify(user));
  api.defaults.headers.Authorization = `Bearer ${token}`;
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      login(action.payload);
      return {
        ...state,
        ...action.payload,
        isInitialized: true,
        isLoggedIn: true
      };
    }
    case LOCAL_LOGIN: {
      return {
        ...state,
        ...action.payload,
        isInitialized: true,
        isLoggedIn: true
      };
    }
    case LOGOUT: {
      logout();
      return {
        ...state
      };
    }
    case REFRESH_TOKEN_SUCCESS: {
      login({
        ...state,
        token: action.payload.token
      });
      return {
        ...state,
        token: action.payload.token
      };
    }
    case REFRESH_TOKEN_FAILURE: {
      logout();
      return {
        ...state
      };
    }
    case UPDATE_USER_SUCCESS: {
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        ...action.payload
      };
    }
    case ACCEPT_INVITE_SUCCESS: {
      const newState = {
        ...state,
        level: action.payload.level,
        dealership: {
          ...state.dealership,
          status: action.payload.userStatus,
          inviteToken: action.payload.inviteToken
        }
      };
      localStorage.setItem('user', JSON.stringify(newState));
      return newState;
    }
    case DECLINE_INVITE_SUCCESS: {
      const newState = {
        ...state,
        dealership: null
      };
      localStorage.setItem('user', JSON.stringify(newState));
      return newState;
    }
    default:
      return state;
  }
};

export default authReducer;
