import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormGroup from '@bootstrap-styled/v4/lib/Form/FormGroup';
import Container from '@bootstrap-styled/v4/lib/Container';
import Option from '@bootstrap-styled/v4/lib/Option';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Heading,
  Paragraph,
  Form,
  Input as SsgInput,
  Button,
  Alert,
  Checkbox,
  RadioGroup,
  Radio
} from 'Components';
import { finishAccountSetup } from 'Actions/user';
import { colors, space } from 'Theme';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  user: state.user,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  finishAccountSetupData: form => dispatch(finishAccountSetup(form))
});

class AccountSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      hasError: false,
      error: '',
      form: {
        email_verify_token: '',
        email: '',
        addresstype: 'individual',
        isrequestingdealer: 'false',
        firstname: '',
        lastname: '',
        country: 'United States',
        language: 'en',
        addressone: '',
        addresstwo: '',
        city: '',
        state: '',
        zip: '',
        dealername: '',
        locationnote: '',
        companycountry: '',
        companyaddressone: '',
        companyaddresstwo: '',
        companycity: '',
        companystate: '',
        companypostalcode: '',
        password: '',
        passwordconfirmation: '',
        newsletter: false,
        terms: false
      },
      isSent: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { state } = this;
    // const { location } = this.props;

    if (window.location.search) {
      const params = queryString.parse(window.location.search);
      this.setState(() => ({
        form: {
          ...state.form,
          email: decodeURI(params.email),
          email_verify_token: params.token
        }
      }));
    }
  }

  handleChange(event) {
    const { form } = this.state;
    const { value, checked } = event.target;
    let newValue;
    if (event.target.type === 'checkbox') {
      newValue = checked;
    } else {
      newValue = value;
    }
    form[event.target.name] = newValue;
    this.setState(() => ({
      form
    }));
  }

  async handleSubmit() {
    const { form } = this.state;
    const { finishAccountSetupData } = this.props;

    this.setState(() => ({
      isFetching: true
    }));

    await finishAccountSetupData(form).catch(errorStatus => {
      const { t } = this.props;
      const error = t(`account_setup.error.${errorStatus}`);
      this.setState(() => ({ error }));
    });

    let isSent = false;
    let hasError = false;
    const {
      status: { FINISH_ACCOUNT_SETUP }
    } = this.props;
    if (FINISH_ACCOUNT_SETUP === 'success') {
      isSent = true;
    } else {
      hasError = true;
    }
    this.setState(() => ({
      isFetching: false,
      isSent,
      hasError
    }));
  }

  render() {
    const { form, isFetching, isSent, hasError, error } = this.state;
    const { t } = this.props;
    if (isSent) return <Redirect to="/" />;
    const genericRequiredRule = {
      rule: 'required',
      messages: { error: t('form.required_feedback'), success: t('required') }
    };
    const emailRule = {
      rule: 'email',
      messages: {
        error: t('form.invalid.email_address'),
        success: ''
      }
    };
    const passwordRule = {
      rule: 'password',
      messages: {
        error: t('form.invalid.password_criteria'),
        success: ''
      }
    };
    return (
      <div className="App">
        <Container>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <Form
                onSubmitFunc={this.handleSubmit}
                validation={{
                  firstname: [genericRequiredRule],
                  lastname: [genericRequiredRule],
                  email: [genericRequiredRule, emailRule],
                  country: [genericRequiredRule],
                  language: [genericRequiredRule],
                  addressone: [genericRequiredRule],
                  city: [genericRequiredRule],
                  state: [genericRequiredRule],
                  zip: [genericRequiredRule],
                  dealername: [genericRequiredRule],
                  locationnote: [genericRequiredRule],
                  companyaddressone: [genericRequiredRule],
                  companycity: [genericRequiredRule],
                  companystate: [genericRequiredRule],
                  companypostalcode: [genericRequiredRule],
                  companycountry: [genericRequiredRule],
                  password: [genericRequiredRule, passwordRule],
                  passwordconfirmation: [
                    genericRequiredRule,
                    {
                      rule: 'matches',
                      fieldToMatch: 'password',
                      messages: {
                        error: t('form.invalid.passwords_match'),
                        success: t('form.valid.passwords_match')
                      }
                    }
                  ],
                  terms: [genericRequiredRule],
                  // newsletter: [],
                  isrequestingdealer: [genericRequiredRule]
                }}
                render={status => (
                  <Fragment>
                    <FormGroup>
                      <Heading size={1}>
                        {t('account_setup.heading_main')}
                      </Heading>
                      <Paragraph>{t('account_setup.heading_sub')}</Paragraph>
                    </FormGroup>
                    <RadioGroup
                      label={t('account_setup.account_type_text')}
                      status={status.isrequestingdealer.status}
                      feedbackMsg={status.isrequestingdealer.feedback}
                    >
                      <Radio
                        label={t('account_setup.account_type_regular')}
                        inputId="account-type-regular"
                        name="isrequestingdealer"
                        onChangeFunc={this.handleChange}
                        value={false}
                        defaultChecked
                      />
                      <Radio
                        label={t('account_setup.account_type_dealer')}
                        inputId="account-type-dealer"
                        name="isrequestingdealer"
                        onChangeFunc={this.handleChange}
                        defaultChecked={false}
                        value
                      />
                    </RadioGroup>
                    <FormGroup>
                      <SsgInput
                        label={t('form.labels.first_name')}
                        type="text"
                        name="firstname"
                        value={form.firstname}
                        onChangeFunc={this.handleChange}
                        status={status.firstname.status}
                        feedbackMsg={status.firstname.feedback}
                        inputId="first-name"
                        placeholder="John"
                        isRequired
                        width="100%"
                      />
                    </FormGroup>
                    <FormGroup>
                      <SsgInput
                        label={t('form.labels.last_name')}
                        type="text"
                        name="lastname"
                        value={form.lastname}
                        onChangeFunc={this.handleChange}
                        status={status.lastname.status}
                        feedbackMsg={status.lastname.feedback}
                        inputId="last-name"
                        placeholder="Smith"
                        isRequired
                        width="100%"
                      />
                    </FormGroup>
                    <FormGroup>
                      <SsgInput
                        label={t('form.labels.street_address')}
                        type="text"
                        name="addressone"
                        value={form.addressone}
                        onChangeFunc={this.handleChange}
                        status={status.addressone.status}
                        feedbackMsg={status.addressone.feedback}
                        inputId="address-one"
                        placeholder="123 SE Sesame St."
                        isRequired
                        width="100%"
                      />
                    </FormGroup>
                    <FormGroup>
                      <SsgInput
                        label={t('form.labels.address_two')}
                        type="text"
                        name="addresstwo"
                        value={form.addresstwo}
                        onChangeFunc={this.handleChange}
                        inputId="address-two"
                        placeholder="Apt. 203"
                        width="100%"
                        status="muted"
                      />
                    </FormGroup>
                    <FormGroup>
                      <SsgInput
                        label={t('form.labels.city')}
                        type="text"
                        name="city"
                        value={form.city}
                        onChangeFunc={this.handleChange}
                        status={status.city.status}
                        feedbackMsg={status.city.feedback}
                        inputId="city"
                        placeholder="Bridgetown"
                        isRequired
                        width="100%"
                      />
                    </FormGroup>
                    <FormGroup>
                      <SsgInput
                        label={t('form.labels.state')}
                        type="text"
                        name="state"
                        value={form.state}
                        onChangeFunc={this.handleChange}
                        status={status.state.status}
                        feedbackMsg={status.state.feedback}
                        inputId="state"
                        placeholder="Oregon"
                        isRequired
                        width="100%"
                      />
                    </FormGroup>
                    <FormGroup>
                      <SsgInput
                        label={t('form.labels.zip')}
                        type="text"
                        name="zip"
                        value={form.zip}
                        onChangeFunc={this.handleChange}
                        status={status.zip.status}
                        feedbackMsg={status.zip.feedback}
                        inputId="zip"
                        placeholder="97217-8173"
                        isRequired
                        width="100%"
                      />
                    </FormGroup>
                    <FormGroup>
                      <SsgInput
                        label={t('form.labels.country')}
                        inputId="country"
                        onChangeFunc={this.handleChange}
                        type="select"
                        value={form.country}
                        status={status.country.status}
                        feedbackMsg={status.country.feedback}
                        isRequired
                        name="country"
                        width="100%"
                        isDisabled
                      >
                        <Option value="United States">United States</Option>
                      </SsgInput>
                    </FormGroup>
                    <FormGroup>
                      <SsgInput
                        label={t('form.labels.preferred_language')}
                        inputId="language"
                        onChangeFunc={this.handleChange}
                        type="select"
                        value={form.language}
                        status={status.language.status}
                        feedbackMsg={status.language.feedback}
                        isRequired
                        name="language"
                        width="100%"
                      >
                        <Option value="en">{t('languages.en')}</Option>
                        <Option value="de">{t('languages.de')}</Option>
                        <Option value="es">{t('languages.es')}</Option>
                        <Option value="fr">{t('languages.fr')}</Option>
                        <Option value="it">{t('languages.it')}</Option>
                        <Option value="jp">{t('languages.jp')}</Option>
                        <Option value="nl">{t('languages.nl')}</Option>
                        <Option value="zh">{t('languages.zh')}</Option>
                      </SsgInput>
                    </FormGroup>
                    {form.isrequestingdealer === 'true' && (
                      <ExtendedForm>
                        <FormGroup>
                          <SsgInput
                            label={t('form.labels.company')}
                            type="text"
                            name="dealername"
                            value={form.dealername}
                            onChangeFunc={this.handleChange}
                            status={status.dealername.status}
                            feedbackMsg={status.dealername.feedback}
                            inputId="dealer-name"
                            placeholder="Abe's Bikes"
                            isRequired
                            width="100%"
                          />
                        </FormGroup>
                        <FormGroup>
                          <SsgInput
                            label={t('form.labels.company_street_address')}
                            type="text"
                            name="companyaddressone"
                            value={form.companyaddressone}
                            onChangeFunc={this.handleChange}
                            status={status.companyaddressone.status}
                            feedbackMsg={status.companyaddressone.feedback}
                            inputId="company-address-one"
                            placeholder="324 NE Klickitat Street"
                            isRequired
                            width="100%"
                          />
                        </FormGroup>
                        <FormGroup>
                          <SsgInput
                            label={t('form.labels.company_address_two')}
                            type="text"
                            name="companyaddresstwo"
                            value={form.companyaddresstwo}
                            onChangeFunc={this.handleChange}
                            inputId="company-address-two"
                            placeholder="Ste. 203"
                            width="100%"
                            status="muted"
                          />
                        </FormGroup>
                        <FormGroup>
                          <SsgInput
                            label={t('form.labels.company_city')}
                            type="text"
                            name="companycity"
                            value={form.companycity}
                            onChangeFunc={this.handleChange}
                            status={status.companycity.status}
                            feedbackMsg={status.companycity.feedback}
                            inputId="company-city"
                            placeholder="Bridgetown"
                            isRequired
                            width="100%"
                          />
                        </FormGroup>
                        <FormGroup>
                          <SsgInput
                            label="Company State/Province"
                            type="text"
                            name="companystate"
                            value={form.companystate}
                            onChangeFunc={this.handleChange}
                            status={status.companystate.status}
                            feedbackMsg={status.companystate.feedback}
                            inputId="company-state"
                            placeholder="Oregon"
                            isRequired
                            width="100%"
                          />
                        </FormGroup>
                        <FormGroup>
                          <SsgInput
                            label={t('form.labels.company_zip')}
                            type="text"
                            name="companypostalcode"
                            value={form.companypostalcode}
                            onChangeFunc={this.handleChange}
                            status={status.companypostalcode.status}
                            feedbackMsg={status.companypostalcode.feedback}
                            inputId="company-postal-code"
                            placeholder="97217-8173"
                            isRequired
                            width="100%"
                          />
                        </FormGroup>
                        <FormGroup>
                          <SsgInput
                            inputId="company-country"
                            label={t('form.labels.company_country')}
                            name="companycountry"
                            value={form.companycountry}
                            onChangeFunc={this.handleChange}
                            status={status.companycountry.status}
                            feedbackMsg={status.companycountry.feedback}
                            type="select"
                            width="100%"
                            isDisabled
                          >
                            <Option value="United States">United States</Option>
                          </SsgInput>
                        </FormGroup>
                      </ExtendedForm>
                    )}
                    <FormGroup>
                      <SsgInput
                        label={t('form.labels.password')}
                        type="password"
                        name="password"
                        value={form.password}
                        onChangeFunc={this.handleChange}
                        status={status.password.status}
                        feedbackMsg={status.password.feedback}
                        inputId="password"
                        placeholder="Password"
                        isRequired
                        width="100%"
                        helperText={t('form.helper_text.password')}
                      />
                    </FormGroup>
                    <FormGroup>
                      <SsgInput
                        label={t('form.labels.password_confirm')}
                        type="password"
                        name="passwordconfirmation"
                        value={form.passwordconfirmation}
                        onChangeFunc={this.handleChange}
                        status={status.passwordconfirmation.status}
                        feedbackMsg={status.passwordconfirmation.feedback}
                        inputId="password-confirmation"
                        placeholder="Password Confirmation"
                        isRequired
                        width="100%"
                      />
                    </FormGroup>
                    {/* <FormGroup>
                      <Checkbox
                        label={t('form.labels.newsletter')}
                        inputId="newsletter"
                        name="newsletter"
                        onChangeFunc={this.handleChange}
                        value={form.newsletter}
                        status={status.newsletter.status}
                        feedbackMsg={status.newsletter.feedback}
                      />
                    </FormGroup> */}
                    <FormGroup>
                      <div style={{ display: 'flex' }}>
                        <Checkbox
                          label={t('form.labels.terms1')}
                          inputId="terms-conditions"
                          name="terms"
                          onChangeFunc={this.handleChange}
                          value={form.terms}
                          status={status.terms.status}
                          feedbackMsg={status.terms.feedback}
                        />
                        &nbsp;
                        <a href="https://www.srsuntour.com/footer/c-2019-sr-suntour-all-rights-reserved/terms-of-use/" target="_blank"><small>{t('form.labels.terms2')}</small></a>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Alert
                        alertIsOpen={hasError}
                        color="danger"
                        onCloseFunc={() => {
                          this.setState(() => ({ hasError: false }));
                        }}
                      >
                        {error}
                      </Alert>
                      <Button
                        className="w-100 mt-4 mb-2"
                        color="primary"
                        type="submit"
                        isFetching={isFetching}
                        isDisabled={!form.terms}
                      >
                        {t('account_setup.buttons.submit')}
                      </Button>
                    </FormGroup>
                  </Fragment>
                )}
              />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

AccountSetup.propTypes = {
  finishAccountSetupData: PropTypes.func.isRequired,
  status: PropTypes.shape({
    FINISH_ACCOUNT_SETUP: PropTypes.string.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};

const ExtendedForm = styled(FormGroup)`
  background-color: ${colors.gray.lighter};
  padding: ${space[1]}rem;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AccountSetup));
