import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Container from '@bootstrap-styled/v4/lib/Container';
import Row from '@bootstrap-styled/v4/lib/Row';
import Col from '@bootstrap-styled/v4/lib/Col';
import Option from '@bootstrap-styled/v4/lib/Option';
import { Chart } from 'react-google-charts';
import { withTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  Datepicker,
  Heading,
  Input as SsgInput,
  Label,
  Link,
  Paragraph,
  Scrim,
  Throbber
} from 'Components';
import { space, colors, typography, breakpoints } from 'Theme';
import { fetchTickets } from 'Actions/ticket';
import { fetchReportIndex, runReport } from 'Actions/report';

const { fonts, fontSizes, fontSizesMobile } = typography;

const chartOptions = {
  legend: { position: 'none' },
  colors: [colors.primary.base],
  chartArea: {
    width: '90%'
  },
  hAxis: {
    textStyle: {
      fontName: fonts.sansSerif
    }
  },
  vAxis: {
    minValue: 0,
    textStyle: {
      fontName: fonts.sansSerif
    }
  },
  animation: {
    startup: true,
    easing: 'linear',
    duration: 375
  }
};

class ReportingDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      runStartDate: '',
      runEndDate: '',
      reportIdToRun: 1,
      exportType: 'csv',
      isRunningReport: false,
      hasReportDownload: false,
      hasReportError: false,
      isFetching: true,
      reportLink: '',
      reportType: 'csv',
      reportList: [],
      ticketsLast12Months: [],
      usersLast12Months: [],
      totalUsers: 0,
      totalDealers: 0,
      ticket_nums: {
        open: 0,
        closed: 0,
        total: 0
      }
    };
  }

  async componentDidMount() {
    const { fetchReportIndex } = this.props;
    await fetchReportIndex()
      .then(res => {
        this.setState(state => ({
          ...state,
          reportList: res.reportList,
          ticketsLast12Months: res.ticketsLast12Months,
          dealersLast12Months: res.dealersLast12Months,
          usersLast12Months: res.usersLast12Months,
          totalUsers: res.totalUsers,
          totalDealers: res.totalDealers,
          ticket_nums: {
            ...state.ticket_nums,
            total: res.totalTickets,
            open: res.openTickets,
            closed: res.closedTickets
          }
        }));
      })
      .catch(() => {
        this.setState(() => ({ hasReportError: true }));
      });
    this.setState(() => ({
      isFetching: false
    }));
  }

  onChangeReport(e) {
    const {
      target: { value }
    } = e;
    this.setState(() => ({
      reportIdToRun: value
    }));
  }

  onChangeType(e) {
    const {
      target: { value }
    } = e;
    this.setState(() => ({
      exportType: value
    }));
  }

  handleEndDateChange(date) {
    this.setState(() => ({
      runEndDate: date
    }));
  }

  handleStartDateChange(date) {
    this.setState(() => ({
      runStartDate: date
    }));
  }

  async runReport() {
    const { runReport } = this.props;
    const { runStartDate, runEndDate, reportIdToRun, exportType } = this.state;

    this.setState(() => ({
      hasReportDownload: false,
      hasReportError: false,
      isRunningReport: true
    }));

    await runReport({
      id: reportIdToRun,
      startDate: runStartDate,
      endDate: runEndDate,
      report: reportIdToRun,
      type: exportType
    })
      .then(res => {
        this.setState(() => ({
          isRunningReport: false,
          hasReportDownload: true,
          hasReportError: false,
          reportLink: res.link,
          reportType: res.type
        }));
      })
      .catch(() => {
        this.setState(() => ({
          isRunningReport: false,
          hasReportError: true
        }));
      });
  }

  render() {
    const {
      runStartDate,
      runEndDate,
      isRunningReport,
      reportIdToRun,
      exportType,
      reportList,
      reportLink,
      reportType,
      hasReportDownload,
      hasReportError,
      ticketsLast12Months,
      dealersLast12Months,
      usersLast12Months,
      ticket_nums: { total: totalTix, open: openTix, closed: closedTix },
      totalDealers,
      totalUsers,
      isFetching
    } = this.state;
    const { t } = this.props;

    return (
      <>
        <Scrim isShown={isFetching} />
        <Container>
          <Alert
            alertIsOpen={hasReportDownload}
            color="info"
            onCloseFunc={() => {
              this.setState(() => ({ hasReportDownload: false }));
            }}
          >
            <Link href={reportLink} external>
              {t('reporting_dashboard.text.download_button') + " (" + reportType.toUpperCase() + ")"}
            </Link>
          </Alert>
          <Alert
            alertIsOpen={hasReportError}
            color="danger"
            onCloseFunc={() => {
              this.setState(() => ({ hasReportError: false }));
            }}
          >
            {t('reporting_dashboard.error.general')}
          </Alert>
          <Row>
            <Col md={2}>
              <Datepicker
                label={t('form.labels.start_date')}
                name="start_date"
                inputId="date-start"
                onChangeFunc={date => this.handleStartDateChange(date)}
                value={runStartDate}
                status="muted"
                helperText=""
                width="100%"
              />
            </Col>
            <Col md={2}>
              <Datepicker
                label={t('form.labels.end_date')}
                name="end_date"
                inputId="date-end"
                onChangeFunc={date => this.handleEndDateChange(date)}
                value={runEndDate}
                status="muted"
                helperText=""
                width="100%"
              />
            </Col>
            <Col md={6}>
              <ReportSelect
                label={t('reporting_dashboard.text.select_report')}
                type="select"
                inputId="report-id-to-run"
                name="reportIdToRun"
                value={reportIdToRun}
                defaultValue=""
                onChangeFunc={report => this.onChangeReport(report)}
                width="100%"
                status="muted"
              >
                {reportList.map(report => (
                  <Option key={report.id} value={report.id}>
                    {report.name}
                  </Option>
                ))}
              </ReportSelect>
            </Col>
            <Col md={2}>
              <ButtonWrapper>
                <TypeSelect
                  label={t('reporting_dashboard.text.select_type')}
                  type="select"
                  inputId="report-export-type"
                  name="export_type"
                  value={exportType}
                  onChangeFunc={type => this.onChangeType(type)}
                  status="muted"
                >
                  <Option
                    key="type-csv"
                    value="csv"
                  >
                    CSV
                  </Option>
                  <Option
                    key="type-xlsx"
                    value="xlsx"
                  >
                    XLSX
                  </Option>
                </TypeSelect>
                <RunButton
                  onClickFunc={() => this.runReport()}
                  isFetching={isRunningReport}
                >
                  Run
                </RunButton>
              </ButtonWrapper>
            </Col>
          </Row>
          {isFetching ? (
            <ThrobberWrapper>
              <Throbber isFullPage />
            </ThrobberWrapper>
          ) : (
            <>
              <Row>
                <Col lg={6}>
                  <ChartWrapper>
                    <ChartTitle size={3}>
                      {t('reporting_dashboard.titles.tix_last_12')}
                    </ChartTitle>
                    <Totals>
                      <Chart
                        height={window.innerWidth > 512 ? '300px' : '200px'}
                        chartType="ColumnChart"
                        loader={<Throbber />}
                        data={[
                          ['', t('reporting_dashboard.labels.tix')],
                          ...ticketsLast12Months
                        ]}
                        options={chartOptions}
                        // For tests
                        rootProps={{ 'data-testid': '1' }}
                      />
                    </Totals>
                  </ChartWrapper>
                </Col>
                <Col lg={6}>
                  <ChartWrapper>
                    <ChartTitle size={3}>
                      {t('reporting_dashboard.titles.dealers_last_12')}
                    </ChartTitle>
                    <Totals>
                      <Chart
                        width="100%"
                        height="300px"
                        chartType="ColumnChart"
                        loader={<Throbber />}
                        data={[
                          ['', t('reporting_dashboard.labels.dealers')],
                          ...dealersLast12Months
                        ]}
                        options={chartOptions}
                        rootProps={{ 'data-testid': '1' }}
                      />
                    </Totals>
                  </ChartWrapper>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <ChartWrapper>
                    <ChartTitle size={3}>
                      {t('reporting_dashboard.titles.users_last_12')}
                    </ChartTitle>
                    <Totals>
                      <Chart
                        width="100%"
                        height="300px"
                        chartType="ColumnChart"
                        loader={<Throbber />}
                        data={[
                          ['', t('reporting_dashboard.labels.users')],
                          ...usersLast12Months
                        ]}
                        options={chartOptions}
                        rootProps={{ 'data-testid': '1' }}
                      />
                    </Totals>
                  </ChartWrapper>
                </Col>
                <Col md={6}>
                  <ChartWrapper>
                    <ChartTitle size={3}>
                      {t('reporting_dashboard.titles.totals')}
                    </ChartTitle>
                    <Totals>
                      <Row>
                        <Col md={6}>
                          <Label htmlFor="users">
                            {t('reporting_dashboard.labels.users')}
                          </Label>
                          <TotalStat id="users">
                            {Intl.NumberFormat('en-US').format(totalUsers)}
                          </TotalStat>
                        </Col>
                        <Col md={6}>
                          <Label htmlFor="dealers">
                            {t('reporting_dashboard.labels.dealers')}
                          </Label>
                          <TotalStat id="dealers">
                            {Intl.NumberFormat('en-US').format(totalDealers)}
                          </TotalStat>
                        </Col>
                        <Col md={6}>
                          <Label htmlFor="tix">
                            {t('reporting_dashboard.labels.tix')}
                          </Label>
                          <TotalStat id="tix">
                            {Intl.NumberFormat('en-US').format(totalTix)}
                          </TotalStat>
                        </Col>
                        <Col md={6}>
                          <Label htmlFor="open-tix">
                            {t('reporting_dashboard.labels.tix_open')}
                          </Label>
                          <TotalStat id="open-tix">
                            {Intl.NumberFormat('en-US').format(openTix)}
                          </TotalStat>
                        </Col>
                        <Col md={6}>
                          <Label htmlFor="closed-tix">
                            {t('reporting_dashboard.labels.tix_closed')}
                          </Label>
                          <TotalStat id="closed-tix">
                            {Intl.NumberFormat('en-US').format(closedTix)}
                          </TotalStat>
                        </Col>
                      </Row>
                    </Totals>
                  </ChartWrapper>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  ticket: state.ticket,
  modal: state.modal,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  fetchReportIndex: data => dispatch(fetchReportIndex(data)),
  fetchTickets: data => dispatch(fetchTickets(data)),
  runReport: data => dispatch(runReport(data))
});

ReportingDashboard.propTypes = {
  fetchReportIndex: PropTypes.func.isRequired,
  runReport: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape())
  }).isRequired
};

const ButtonWrapper = styled.div`
  > button {
    max-width: 84px;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const ThrobberWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ChartWrapper = styled.div`
  background-color: #ffffff;
  padding: 1.5rem;
  margin-bottom: 1rem;
`;

const ChartTitle = styled(Heading)`
  margin-bottom: 1.25rem;
`;

const ReportSelect = styled(SsgInput)`
  margin-right: 0.5rem;
  width: 100%;
`;
const TypeSelect = styled(SsgInput)`
  margin-right: 0.5rem;
  width: 100%;
`;

const RunButton = styled(Button)`
  display: flex;
  align-items: flex-end;
  margin-bottom: 0;
`;

const Totals = styled.div`
  height: 300px;
  width: 100%;
`;

const TotalStat = styled(Paragraph)`
  font-family: ${fonts.sansSerif};
  font-size: ${fontSizesMobile[4]}rem;
  font-weight: bold;
  font-style: oblique;
  margin-top: 0;
  line-height: 1;
  margin-bottom: ${space[2]}rem;
  @media screen and (min-width: ${breakpoints.sm}) {
    font-size: ${fontSizes[5]}rem;
  }
`;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ReportingDashboard))
);
