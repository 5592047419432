import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge, DataTable, Heading, Link } from 'Components';
import { formatDate, setBadgeColorByStatus } from 'Components/Utils';

const DealerTickets = ({ tickets }) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: t('table.claim'),
      selector: 'uuid',
      cell: row => <Link href={`/ticket/${row.id}`}>{row.id}</Link>,
      minWidth: 'initial',
      maxWidth: '120px'
    },
    {
      name: t('table.status'),
      selector: 'status',
      maxWidth: '140px',
      cell: row => (
        <Badge
          label={t(`data.status.${row.status.toLowerCase()}`)}
          pill
          color={setBadgeColorByStatus(row.status.toLowerCase())}
          inTable
        />
      )
    },
    {
      name: t('table.diagnosis'),
      grow: 2,
      selector: row => {
        if (row.diagnosis) {
          if (row.diagnosis.part_model_attribute) {
            return row.diagnosis.part_model_attribute.value;
          }
          if (row.diagnosis.part_type_attribute) {
            return row.diagnosis.part_type_attribute.value;
          }
        }
        return '';
      }
    },
    {
      name: t('table.customer'),
      selector: row => (row.customer ? row.customer.name : '')
    },
    {
      name: t('table.submitted'),
      selector: 'created_at',
      maxWidth: '90px',
      compact: true,
      cell: row => formatDate(row.created_at, true)
    },
    {
      name: t('table.last_updated'),
      selector: 'updated_at',
      maxWidth: '170px',
      cell: row => formatDate(row.updated_at)
    }
  ];

  return (
    <Fragment>
      <Heading size={6}>{t('dealership.heading_tickets')}</Heading>
      <DataTable
        title=""
        data={tickets}
        columns={columns}
        pagination={false}
        noHeader
        defaultSortField="updated_at"
        defaultSortAsc={false}
        highlightOnHover
      />
    </Fragment>
  );
};

DealerTickets.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default DealerTickets;
