import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Modal } from "Components";
import GlobalStyle from "Theme/global";
import configRoutes from "../../routes";
import "./App.scss";

const App = ({ history, modal, user }) => (
  <ConnectedRouter history={history}>
    <Modal isOpen={modal.isOpen} />
    <GlobalStyle />
    {configRoutes(user.level)}
  </ConnectedRouter>
);

App.propTypes = {
  history: PropTypes.shape().isRequired,
  modal: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired
  }).isRequired,
  user: PropTypes.shape().isRequired
};

const mapStateToProps = state => ({
  modal: state.modal,
  user: state.user
});

export default connect(mapStateToProps)(App);
