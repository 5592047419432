import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading, HR } from 'Components';
import { withTranslation } from 'react-i18next';
import { space } from 'Theme';
import {
  fetchDealershipsAll,
  fetchDealershipDetails
} from 'Actions/dealership';

import Row from '@bootstrap-styled/v4/lib/Row';
import Col from '@bootstrap-styled/v4/lib/Col';

import DealerName from './DealerNameInput';
import DealerInput from './DealerInput';

class DealerFields extends Component {
  componentDidMount() {
    const {
      setDealerInfoFunc,
      fetchDealershipsAll,
      fetchDealershipDetails,
      user
    } = this.props;

    const loadDealerList = async () => {
      await fetchDealershipsAll();
    };

    const loadDealerDetails = async data => {
      await fetchDealershipDetails(data);
      const { dealership } = this.props;
      setDealerInfoFunc(data.id, dealership.locations[0].id);
    };

    if (user.level === 'service_center_user') {
      loadDealerList();
    } else if (user.level !== 'consumer') {
      loadDealerDetails({ id: user.dealership.id });
    }
  }

  render() {
    const {
      form,
      validation,
      onChangeFunc,
      onChangeDealerFunc,
      user,
      dealership,
      status,
      t
    } = this.props;
    if (user.level !== 'consumer') {
      return (
        <Fragment>
          <Section isHidden={user.level !== 'service_center_user'}>
            <Row>
              <Col md={3}>
                <Heading size={5}>{t('create_ticket.heading.general')}</Heading>
              </Col>
              <Col md={9}>
                <Row>
                  <Col md={6}>
                    <DealerName
                      value={form.dealer_name}
                      status={validation.dealer_name.status}
                      userLevel={user.level}
                      dealerList={dealership.list}
                      onChangeDealerFunc={onChangeDealerFunc}
                      onChangeFunc={onChangeFunc}
                    />
                  </Col>
                  <Col md={6}>
                    <DealerInput
                      value={form.user}
                      status={validation.user.status}
                      name="user"
                      inputId="dealer-user"
                      userLevel={user.level}
                      dealerName={form.dealer_name}
                      list={dealership.users}
                      fetchStatus={status.FETCH_DEALERSHIP_DETAIL}
                      onChangeFunc={onChangeFunc}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <DealerInput
                      value={form.dealer_location}
                      status={validation.dealer_location.status}
                      name="dealer_location"
                      inputId="dealer-location"
                      userLevel={user.level}
                      dealerName={form.dealer_name}
                      list={dealership.locations}
                      fetchStatus={status.FETCH_DEALERSHIP_DETAIL}
                      onChangeFunc={onChangeFunc}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Section>
          {user.level === 'service_center_user' && <HR />}
        </Fragment>
      );
    }
    return null;
  }
}

DealerFields.propTypes = {
  // Inherited Props
  form: PropTypes.shape({
    user: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    dealer_name: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    dealer_location: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired
  }).isRequired,
  validation: PropTypes.shape({
    user: PropTypes.shape({
      status: PropTypes.string.isRequired
    }),
    dealer_name: PropTypes.shape({
      status: PropTypes.string.isRequired
    }).isRequired,
    dealer_location: PropTypes.shape({
      status: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  onChangeFunc: PropTypes.func.isRequired,
  onChangeDealerFunc: PropTypes.func.isRequired,
  setDealerInfoFunc: PropTypes.func.isRequired,

  // Redux-mapped Props
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    level: PropTypes.string,
    dealership: PropTypes.shape({
      id: PropTypes.number
    })
  }).isRequired,
  dealership: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape()),
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    )
  }).isRequired,
  status: PropTypes.shape({
    FETCH_DEALERSHIP_DETAIL: PropTypes.string
  }).isRequired,
  fetchDealershipsAll: PropTypes.func.isRequired,
  fetchDealershipDetails: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  dealership: state.dealership,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  fetchDealershipDetails: data => dispatch(fetchDealershipDetails(data)),
  fetchDealershipsAll: data => dispatch(fetchDealershipsAll(data))
});

// Styled Components
const Section = styled.div`
  display: ${props => (props.isHidden ? 'none' : 'block')};
  padding: ${space[4]}rem 0;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DealerFields));
