import React, { useState, useEffect } from "react";
import { Button, Input } from "Components";
import styled from "styled-components";
import Option from "@bootstrap-styled/v4/lib/Option";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createPart } from "Actions/part";
import { hideModal } from "Actions/modal";
import { titleCase } from "Components/Utils";

const AddPartModelAttribute = (props) => {
  const {
    data: { name: partModelName, id: partModelID },
    attributes = [],
  } = props;
  const [isLoading, setLoading] = useState(false);
  const [partModelAttrValue, setPartModelAttrValue] = useState("");
  const [selectedAttr, setSelectedAttr] = useState();
  const DEFAULT_ATTRIBUTE = "wheel_size";
  useEffect(() => {
    // Set the default attribute to diagnosis
    const defaultAttribute = props.attributes.find(
      (attr) => attr.name === DEFAULT_ATTRIBUTE
    );
    setSelectedAttr(defaultAttribute.id);
  }, []);

  const handleChangeAttribute = (e) => {
    setSelectedAttr(e.currentTarget.value);
  };

  const handleCreatePartModelAttribute = () => {
    props
      .createPart({
        partModelId: partModelID,
        value: partModelAttrValue,
        attributeId: selectedAttr,
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
        props.hideModal();
      });
  };

  return (
    <form>
      <Input
        label="Part Model"
        inputId="partModel"
        value={partModelName}
        isDisabled
        name="partModel"
        width="100%"
      />
      <Input
        label="Attribute"
        inputId="attribute"
        onChangeFunc={handleChangeAttribute}
        type="select"
        value={selectedAttr}
        isRequired
        name="attribute"
        width="100%"
      >
        {attributes.map((attribute) => (
          <Option key={attribute.id} value={attribute.id}>
            {titleCase(attribute.name)}
          </Option>
        ))}
      </Input>
      <PartNameInput
        value={partModelAttrValue}
        onChangeFunc={(e) => setPartModelAttrValue(e.currentTarget.value)}
        label="Part Model Attribute Value"
        placeholder="700c"
      />
      <Button onClickFunc={handleCreatePartModelAttribute}>
        Create Part Model Attribute
      </Button>
    </form>
  );
};

const PartNameInput = styled(Input)`
  width: 100%;
  margin-bottom: 1rem;
`;

const mapStateToProps = (state) => ({
  attributes: state.part.attributes,
});

const mapDispatchToProps = (dispatch) => ({
  createPart: (partData) =>
    dispatch(createPart("part-model-attribute", partData)),
  hideModal: (data) => dispatch(hideModal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddPartModelAttribute));
