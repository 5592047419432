import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Badge, DataTable, Heading } from "Components";
import { useTranslation } from "react-i18next";
import {
  formatDate,
  formatPhoneNumber,
  setBadgeColorByStatus
} from "Components/Utils";
import { showModal } from "Actions/modal";
import { connect } from "react-redux";
import TechKebab from "./TechKebab";
import styled from "styled-components";
import { colors, space } from "Theme";
import { archiveUser } from "Actions/user";

const ServiceCenterUsers = ({ techs, archiveUser, unarchiveUser }) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: t("table.id"),
      selector: "id",
      cell: row => row.id
    },
    {
      name: t("table.name"),
      selector: "name",
      cell: row => row.name
    },
    {
      name: t("table.email"),
      selector: "email",
      grow: 2,
      cell: row => row.email ?? ""
    },
    {
      name: t("table.last_activity"),
      selector: "updated_at",
      cell: row => (row.updated_at ? formatDate(row.updated_at) : "")
    },
    {
      name: t("table.status"),
      selector: "status",
      cell: row => (
        <Badge
          label={t(`data.badge.${row.status.toLowerCase()}`)}
          pill
          color={setBadgeColorByStatus(row.status.toLowerCase())}
          inTable
        />
      ),
      compact: true
    },
    {
      name: "Actions",
      selector: "actions",
      cell: row => <TechKebab onClickArchive={() => archiveUser(row.id)} />
    }
  ];

  return (
    <Fragment>
      <Heading size={6}>{t("service_center.heading_users")}</Heading>
      <StyledDataTable
        title=""
        data={techs}
        columns={columns}
        pagination={false}
        noHeader
        overflowY
        defaultSortField="name"
      />
    </Fragment>
  );
};

const StyledDataTable = styled(DataTable)`
  padding-bottom: 10rem;
  padding-top: 0;
  .rdt_TableHeadRow {
    background-color: ${colors.secondary.lighter};
  }
  .rdt_ExpanderRow {
    border-left: 4px solid ${colors.primary.base};
  }
  .rdt_Pagination {
    display: none;
  }
  > div > div:first-child {
    top: ${space[0]}rem;
  }
`;

ServiceCenterUsers.propTypes = {
  techs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      phone: PropTypes.string,
      updated_at: PropTypes.string
    })
  ).isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  showModal: data => dispatch(showModal(data)),
  archiveUser: userID => dispatch(archiveUser({ id: userID }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCenterUsers);
