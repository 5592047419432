import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'Theme';

const PlusIcon = ({ handle, onClickFunc, color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    handle={handle}
    onClick={onClickFunc}
  >
    <g
      id="icon/fi-plus"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        transform="translate(3.000000, 3.000000)"
        fill={color}
        fillRule="nonzero"
        id="Shape"
      >
        <path d="M16.9751287,6.34954455 L11.2331881,6.34954455 L11.2331881,0.609029703 C11.2330572,0.371245889 11.0403976,0.178479951 10.8026139,0.178217822 L6.78083168,0.178217822 C6.54299365,0.178217822 6.35025739,0.371191638 6.35025743,0.609029703 L6.3500198,6.3500198 L0.609267327,6.34978218 C0.380910891,6.34978218 0.178693069,6.552 0.178455182,6.78059406 L0.178455182,10.8023762 C0.178455182,10.9166515 0.223685,11.0262793 0.304512174,11.1070619 C0.385339349,11.1878445 0.494992117,11.23314 0.609267327,11.2329505 L6.35025743,11.2329505 L6.35025743,16.9751287 C6.35038852,17.2130052 6.5431928,17.4059406 6.78106931,17.4059406 L10.8023762,17.4059406 C10.9166347,17.4059406 11.0262133,17.3605516 11.1070062,17.2797587 C11.1877991,17.1989658 11.2331881,17.0893871 11.2331881,16.9751287 L11.2331881,11.2331881 L16.9748911,11.2329505 C17.0892075,11.2331401 17.1988964,11.187812 17.2797305,11.106978 C17.3605645,11.026144 17.4059406,10.9164551 17.405703,10.8021386 L17.4059406,6.78035644 C17.4059406,6.66609802 17.3605516,6.55651933 17.2797587,6.47572643 C17.1989658,6.39493353 17.0893871,6.34954455 16.9751287,6.34954455 L16.9751287,6.34954455 Z" />
      </g>
    </g>
  </svg>
);

PlusIcon.propTypes = {
  handle: PropTypes.string,
  onClickFunc: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string
};

PlusIcon.defaultProps = {
  handle: '',
  onClickFunc: () => {},
  color: colors.gray.darkest,
  size: '16px'
};

export default PlusIcon;
