import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AvatarIcon } from 'Assets/images/icons';
import { space, colors } from 'Theme';

const Avatar = ({ userImg, altText, isLarge }) =>
  userImg ? (
    <StyledAvatar src={userImg} alt={altText} isLarge={isLarge} />
  ) : (
    <AvatarWrapper>
      <AvatarIcon size="40px" />
    </AvatarWrapper>
  );

Avatar.propTypes = {
  userImg: PropTypes.string,
  altText: PropTypes.string,
  isLarge: PropTypes.bool
};

Avatar.defaultProps = {
  userImg: '',
  altText: '',
  isLarge: false
};

const StyledAvatar = styled.img`
  border-radius: 50%;
  width: ${props => (props.isLarge ? space[3] * 4 : space[1] * 5)}rem;
  height: ${props => (props.isLarge ? space[3] * 4 : space[1] * 5)}rem;
  box-shadow: inset 0px 0px 2px 0px ${colors.gray.darkest};
  border: ${props => (props.isLarge ? colors.gray.base : '')} solid 1px;
`;

const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${space[1] * 5}rem;
  height: ${space[1] * 5}rem;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: inset 0px 0px 2px 0px ${colors.gray.darkest};
`;

export default Avatar;
