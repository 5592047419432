export default {
  primary: {
    lightest: '#91C8EE',
    lighter: '#4EA7E4',
    base: '#1E82C8',
    darker: '#145785',
    darkest: '#0A2B43'
  },
  secondary: {
    lightest: '#767476',
    lighter: '#504E50',
    base: '#292829',
    darker: '#020202',
    darkest: '#000000'
  },
  black: {
    base: '#000000'
  },
  white: {
    base: '#ffffff',
    alt: '#fefefe'
  },
  gray: {
    lightest: '#F7F7F7',
    lighter: '#E6E6E6',
    base: '#CACACA',
    dark_1: '#504e50',
    darker: '#8A8A8A',
    darkest: '#0A0A0A'
  },
  green: {
    lighter: '#7BE7A3',
    base: '#3ADB76',
    darker: '#1FA953'
  },
  red: {
    lighter: '#DC8274',
    base: '#CC4B37',
    darker: '#913425'
  },
  yellow: {
    lighter: '#FFC64D',
    base: '#FFAE00',
    darker: '#B37A00'
  },
  product: {
    red: '#EC1D24',
    orange: '#F4802A',
    yellow: '#FFCA05',
    navy: '#00658F',
    lightblue: '#00B8D5',
    green: '#8CC63F',
    brown: '#98715B',
    gray: '#828F96',
    pink: '#e83e8c',
    purple: '#6f42c1'
  },
  transparent: {
    base: 'rgba(255, 255, 255, 0.001)',
    gray: 'rgba(10, 10, 10, 0.5)'
  }
};
