import React from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import BSFormGroup from '@bootstrap-styled/v4/lib/Form/FormGroup';
import BSFormFeedback from '@bootstrap-styled/v4/lib/Form/FormFeedback';
import BSFormText from '@bootstrap-styled/v4/lib/Form/FormText';
import { colors, space, typography, breakpoints } from 'Theme';
import { useTranslation } from 'react-i18next';
import Label from '../Forms/Label';

const SSGDatepicker = ({
  status,
  label,
  feedbackMsg,
  isRequired,
  helperText,
  name,
  inputId,
  onChangeFunc,
  value,
  width
}) => {
  const feedbackMsgBackup =
    !feedbackMsg && isRequired === true ? 'Required' : '';
  const bootstrapStatus = status === 'muted' ? '' : status;
  const { t } = useTranslation();
  return (
    <DatepickerWrapper width={width}>
      <BSFormGroup status={bootstrapStatus}>
        <Label htmlFor={inputId}>{label}</Label>
        <DatePicker
          id={inputId}
          placeholderText={t('form.placeholders.datepicker')}
          selected={value}
          onChange={onChangeFunc}
          name={name}
          maxDate={new Date()}
        />
        {helperText && <BSFormText color="muted">{helperText}</BSFormText>}
        <BSFormFeedback>{feedbackMsg || feedbackMsgBackup}</BSFormFeedback>
      </BSFormGroup>
    </DatepickerWrapper>
  );
};

SSGDatepicker.propTypes = {
  inputId: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['success', 'danger', 'warning', 'muted']),
  feedbackMsg: PropTypes.string,
  onChangeFunc: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  width: PropTypes.string
};

SSGDatepicker.defaultProps = {
  inputId: '',
  label: '',
  isRequired: false,
  helperText: '',
  status: '',
  feedbackMsg: '',
  value: new Date(),
  width: '100%'
};

const { fonts, fontSizes } = typography;

const DatepickerWrapper = styled.div`
  margin-bottom: ${space[2]}rem;
  font-family: ${fonts.sansSerif};
  width: 100%;
  .form-group {
    width: inherit;
    label {
      display: block;
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
    .react-datepicker-popper {
      z-index: 5;
    }
    input {
      width: 100%;
      font-size: ${fontSizes[1]}rem;
      line-height: 1.5;
      text-align: left;
      background-color: ${props =>
        props.isDisabled ? colors.gray.lightest : colors.white.base};
      background-image: none;
      border-radius: 0;
      border: 1px solid ${colors.gray.base};
      padding: ${space[1]}rem;
      color: ${colors.gray.darkest};
      &::placeholder {
        font-size: ${fontSizes[1]}rem;
        letter-spacing: initial;
        color: ${props =>
          props.isDisabled ? colors.gray.darker : colors.gray.base};
      }
      &:focus,
      &:hover {
        &:not(:disabled) {
          color: ${colors.gray.darkest};
          border-color: ${colors.gray.darker};
          &.has-danger,
          &.form-control-danger {
            border-color: ${colors.red.base};
          }
          &.has-success,
          &.form-control-success {
            border-color: ${colors.green.base};
          }
        }
      }
      &:not(:disabled):focus {
        outline: 2px solid ${colors.primary.lightest};
      }
      &:not(:disabled):active {
        border-color: ${colors.primary.base};
      }
      &.has-danger:hover {
        border-color: ${colors.red.base};
      }
      @media screen and (min-width: ${breakpoints.lg}) {
        width: ${props => props.width};
      }
    }
  }
  .form-text,
  .form-control-feedback {
    font-size: 0.75rem;
    color: ${colors.gray.darker};
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    width: ${props => (props.width ? props.width : 'max-content')};
  }
`;

export default SSGDatepicker;
