import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typography, colors } from 'Theme';

const Label = ({ invisible, htmlFor, children, hide }) => (
  <StyledLabel invisible={invisible} htmlFor={htmlFor} hidden={hide}>
    {children}
  </StyledLabel>
);

Label.propTypes = {
  invisible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  hide: PropTypes.bool
};

Label.defaultProps = {
  invisible: false,
  hide: false
};

const { fonts, fontSizes } = typography;

const StyledLabel = styled.label`
  font-family: ${fonts.sansSerif};
  font-size: ${fontSizes[0]}rem;
  line-height: 1.8;
  text-align: left;
  color: ${colors.black.base};
  visibility: ${props => (props.invisible ? 'hidden' : 'visible')};
`;

export default Label;
