export default {
  en: {
    languages: {
      en: 'English',
      es: 'Spanish',
      de: 'German',
      fr: 'French',
      it: 'Italian',
      jp: 'Japanese',
      nl: 'Dutch',
      zh: 'Chinese'
    }
  },
  esMX: {
    languages: {
      en: 'Ingles'
    }
  },
  de: {
    languages: {
      en: 'Englisch',
      es: 'Spanisch',
      de: 'Deutsch',
      fr: 'Französisch',
      it: 'Italienisch',
      jp: 'japanisch',
      nl: 'Niederländisch',
      zh: 'Chinesisch'
    }
  },
  jp: {
    languages: {
      en: '英語',
      es: 'スペイン語',
      de: 'ドイツ人',
      fr: 'フランス語',
      it: 'イタリアの',
      jp: '日本',
      nl: 'オランダの',
      zh: '中国語'
    }
  },
  it: {
    languages: {
      en: 'inglese',
      es: 'Spagnolo',
      de: 'Tedesco',
      fr: 'francese',
      it: 'Italiano',
      jp: 'giapponese',
      nl: 'Olandese',
      zh: 'Cinese'
    }
  }
};
