export default {
  en: {
    dealer_list: {
      error: {
        403: 'You do not have the necessary permissions to access this content.',
        500: 'A technical issue prevented us from loading the list of dealerships. Our staff has been notified of the error'
      }
    }
  },
  de: {
    dealer_list: {
      error: {
        403: 'Sie haben nicht die erforderlichen Berechtigungen, um auf diesen Inhalt zuzugreifen.',
        500: 'Ein technisches Problem hat uns daran gehindert, die Händlerliste zu laden. Unsere Mitarbeiter wurden über den Fehler informiert'
      }
    }
  },
  jp: {
    dealer_list: {
      error: {
        403: 'このコンテンツにアクセスするために必要な権限がありません。',
        500: '技術的な問題により、ディーラーのリストをロードできませんでした。エラーが発生したことがスタッフに通知されました'
      }
    }
  },
  it: {
    dealer_list: {
      error: {
        403: 'Non hai le autorizzazioni necessarie per accedere a questo contenuto.',
        500: "Un problema tecnico ci ha impedito di caricare l'elenco dei concessionari. Il nostro personale è stato informato dell'errore"
      }
    }
  }
};
