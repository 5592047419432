import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import ResourcePopOver from "./ResourcePopOver";
import styled from "styled-components";
import PartTable from "./PartTable";
import { Button, Input } from "Components";
import { connect } from "react-redux";
import { updatePart, deletePart } from "Actions/part";
import { showModal } from "Actions/modal";
import * as modalTypes from "Components/Modal/types";
import { titleCase } from "Components/Utils";

const PartModelAttributesTable = (props) => {
  const [
    isEditingPartModelAttributeID,
    setEditingPartModelAttributeID,
  ] = useState(null);
  const [
    editedPartModelAttributeValue,
    setEditedPartModelAttributeValue,
  ] = useState("");
  const { data, t } = props;
  const modelColumns = [
    {
      id: "id",
      name: t("table.id"),
      selector: "id",
      grow: 1,
      cell: (row) => row.id,
    },
    {
      id: "value",
      name: t("table.value"),
      selector: "value",
      grow: 2,
      cell: (row) =>
        isEditingPartModelAttributeID === row.id ? (
          <StyledInput
            type="text"
            value={editedPartModelAttributeValue}
            onChangeFunc={(e) => {
              setEditedPartModelAttributeValue(e.currentTarget.value);
            }}
          />
        ) : (
          row.value
        ),
    },
    {
      id: "attribute",
      name: t("table.attribute_type"),
      selector: "attribute_id",
      cell: (row) => {
        // Find the attribute type that corresponds to the part type attribute id
        const attribute = props.attributes.find(
          (attribute) => attribute.id === row.attribute_id
        );
        const attributeName = attribute ? attribute.name : "unknown";
        return titleCase(attributeName);
      },
    },
    {
      id: "active",
      name: t("table.status"),
      selector: "active",
      grow: 1,
      cell: (row) =>
        row.active ? t("data.status.active") : t("data.status.archived"),
    },
    {
      id: "actions",
      name: "Actions",
      selector: "actions",
      compact: true,
      cell: (row) =>
        isEditingPartModelAttributeID === row.id ? (
          <SaveButton
            onClickFunc={() =>
              handleSavePartModelAttribute(
                isEditingPartModelAttributeID,
                editedPartModelAttributeValue
              )
            }
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            Save
          </SaveButton>
        ) : (
          <ResourcePopOver
            onClickEdit={handleEditPartModelAttribute}
            onClickDelete={handleDeletePartModelAttribute}
            onClickUnarchive={handleUnarchivePartModelAttribute}
            resourceID={row.id}
          />
        ),
    },
  ];

  const handleEditPartModelAttribute = (id) => {
    const attribute = data.attributes.find((part) => part.id === id);
    setEditedPartModelAttributeValue(attribute.value);
    setEditingPartModelAttributeID(id);
  };

  const handleDeletePartModelAttribute = (id) => {
    props
      .deletePart({ id })
      .catch((err) =>
        console.error(`Could not delete part model attribute\n${err}`)
      )
      .finally(() => {
        window.location.reload();
      });
  };

  const handleUnarchivePartModelAttribute = (id) => {
    const partModelAttribute = data.attributes.find((part) => part.id === id);
    props
      .savePart({ ...partModelAttribute, id, setActive: true })
      .catch((err) => console.error(`Could not unarchive part model\n${err}`))
      .finally(() => {
        window.location.reload();
      });
  };

  const handleSavePartModelAttribute = (id, updatedValue) => {
    // First, find the part type attribute that we are editing
    const partModelAttribute = data.attributes.find((part) => part.id === id);
    // If the value is the same, do nothing
    if (partModelAttribute.value === updatedValue) {
      setEditingPartModelAttributeID(null);
      return;
    }
    // Next, create a new object with the updated value
    // We only allow editing the value of the part type attribute
    const updatedModelAttribute = {
      ...partModelAttribute,
      value: updatedValue,
    };
    // Finally, dispatch the action to update the part type attribute
    props
      .savePart(updatedModelAttribute)
      .then(() => {
        // TODO: Update the part type attribute in the redux store
      })
      .catch((err) => {
        console.error("Could not update part type model attribute\n", err);
      })
      .finally(() => {
        // Reset the editing part type attribute ID
        setEditingPartModelAttributeID(null);
        window.location.reload();
      });
  };

  const addPartModelAttribute = () => {
    const { showModal } = props;
    const modalData = {
      type: modalTypes.FORM_ADD_PART_MODEL_ATTRIBUTE,
      title: t("modal.title.add_part_model_attribute", {
        partTypeName: data.name,
      }),
      data,
    };
    showModal(modalData);
  };

  const partModelAttributesSorted = data.attributes.toSorted(
    (a, b) => b.display_order - a.display_order
  );
  return (
    <StyledContainer>
      <PartTable
        button={{
          text: "Add Part Model Attribute",
          onClick: addPartModelAttribute,
        }}
        data={partModelAttributesSorted}
        columns={modelColumns}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: 2rem;
  margin-left: 2rem;
  flex-direction: column;
  display: flex;
`;

const SaveButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyledInput = styled(Input)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const mapStateToProps = (state) => ({
  attributes: state.part.attributes,
});

const mapDispatchToProps = (dispatch) => ({
  savePart: (partAttributeData) =>
    dispatch(updatePart("part-model-attribute", partAttributeData)),
  deletePart: (partID) => dispatch(deletePart("part-model-attribute", partID)),
  showModal: (data) => dispatch(showModal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PartModelAttributesTable));
