import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { localLogin } from 'Actions/user';
import { api } from 'Actions/api';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from '@bootstrap-styled/v4';
import { PlusIcon as CloseIcon } from 'Assets/images/icons';
import { Sidebar, Header } from 'Containers/Layout';
import { space, colors, breakpoints, headerHeight, sidebarWidth } from 'Theme';
import { withTranslation } from 'react-i18next';

class AuthenticatedPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarHidden: window.innerWidth <= 1200,
      activeSidebarNavItem: 'tickets'
    };
    this.handleSidebarNavChange = this.handleSidebarNavChange.bind(this);
    this.attemptLogin = this.attemptLogin.bind(this);
  }

  componentDidMount() {
    this.attemptLogin();
    this.handleSidebarNavChange();
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname }
    } = this.props;
    const prevPathname = prevProps.location.pathname;
    if (prevPathname !== pathname) {
      this.handleSidebarNavChange(prevPathname);
    }
  }

  handleSidebarNavChange = prevPathname => {
    const {
      location: { pathname }
    } = this.props;

    if (pathname.includes('dealership')) {
      if (
        prevPathname &&
        (prevPathname.includes('dealership') ||
          (pathname.includes('dealerships') &&
            !prevPathname.includes('dealership') &&
            !prevPathname.includes('service-center')))
      ) {
        // If the current url includes the string 'dealership' [read: began as '/dealership/'],
        // and the referring url...
        // ...included 'dealership'[read: began as '/dealership/'], OR
        // ...included NEITHER 'dealerships' NOR 'service-center' [read: did NOT begin as '/dealerships/' or '/service-center/'],
        // then leave the sidebar alone
        return;
      }
      // Otherwise [read: if the referring url did NOT begin as '/dealership/'],
      // collapse the service-center submenu (if it exists), and
      // expand the 'dealership' submenu (if it exists)
      let submenu = document.getElementById('submenu-service-center');
      if (submenu) this.collapseSection(submenu);
      submenu = document.getElementById('submenu-dealership');
      if (submenu) this.expandSection(submenu);
    } else if (pathname.includes('service-center')) {
      if (prevPathname && prevPathname.includes('service-center')) {
        // If the current url includes the string 'service-center' [read: begins as '/service-center/'], and
        // the referring url also included 'service-center'
        // then leave the sidebar alone
        return;
      }
      // Otherwise, [read: if current url begins as '/service-center', but referring url did NOT begin that way],
      // collapse the dealership submenu (if it exists), and
      // expand the service-center submenu (if it exists)
      let submenu = document.getElementById('submenu-dealership');
      if (submenu) this.collapseSection(submenu);
      submenu = document.getElementById('submenu-service-center');
      if (submenu) this.expandSection(submenu);
    } else {
      const serviceCtrSubmenu = document.getElementById(
        'submenu-service-center'
      );
      const dealerSubmenu = document.getElementById('submenu-dealership');
      const submenus = [serviceCtrSubmenu, dealerSubmenu];
      /* eslint-disable-next-line consistent-return */
      submenus.forEach(submenu => {
        // If the current url includes NEITHER 'dealership' NOR 'service-center',
        // then get both potentially present submenu elements, and
        // if the submenu is open...
        if (submenu && submenu.getAttribute('data-collapsed') !== 'true') {
          if (
            prevPathname &&
            !prevPathname.includes('tickets') &&
            !prevPathname.includes('account') &&
            !prevPathname.includes('dealership') &&
            !prevPathname.includes('dealerships')
          ) {
            // ...and the referring url did not include any of the above strings [read: was /login, a page refresh, or a remote link],
            // then collapse the submenu with no animation
            this.collapseSection(submenu, 'none');
          }
          if (
            prevPathname &&
            (prevPathname.includes('dealership') ||
              prevPathname.includes('service-center'))
          ) {
            // ...and the referring url did include either 'dealership' or 'service-center' [read: user navigated from a page with an open submenu to a page whose nav item has NO submenu],
            // then collapse the open submenu with animation
            return this.collapseSection(submenu);
          }
          // ...and the referring url has a sidebar nav item with no submenu (just like the current url),
          // then collapse sidebar menus with no animation (as if on load, they are collapsed)
          return this.collapseSection(submenu, 'none');
        }
      });
    }
  };

  collapseSection = (element, transition) => {
    const el = element;
    const sectionHt = el.scrollHeight;
    const elTransition = el.style.transition;
    requestAnimationFrame(() => {
      el.style.marginTop = `${0}px`;
      if (transition) {
        el.style.transition = transition;
      } else {
        el.style.transition = elTransition;
      }
      el.style.opacity = 1;
      el.style.pointerEvents = 'auto';

      requestAnimationFrame(() => {
        el.style.marginTop = `-${sectionHt}px`;
        el.style.opacity = 0;
        el.style.pointerEvents = 'none';
      });
    });
    el.setAttribute('data-collapsed', 'true');
  };

  expandSection = element => {
    const el = element;
    const sectionHt = el.scrollHeight;
    el.style.transition = `margin-top 0.3s ease, opacity 1s`;

    requestAnimationFrame(() => {
      el.style.marginTop = `-${sectionHt}px`;
      el.style.opacity = 0;
      el.style.pointerEvents = 'none';

      requestAnimationFrame(() => {
        el.style.marginTop = `${0}px`;
        el.style.opacity = 1;
        el.style.pointerEvents = 'auto';
      });
    });

    const removeListener = elem => {
      elem.removeEventListener('transitionend', removeListener);
    };

    el.addEventListener('transitionend', () => removeListener(el));

    // mark the section as "currently not collapsed"
    el.setAttribute('data-collapsed', 'false');
  };

  attemptLogin() {
    const { localLoginData } = this.props;
    const userJSON = localStorage.getItem('user');
    if (userJSON !== null) {
      const userFromStorage = JSON.parse(userJSON);
      localLoginData(userFromStorage);
      api.defaults.headers.Authorization = `Bearer ${userFromStorage.token}`;
    }
  }

  render() {
    const { isSidebarHidden, activeSidebarNavItem } = this.state;
    const { children, user, modal } = this.props;
    // Return early with guard clauses if the user can't see the content.
    if (!user.isInitialized) return <span />;
    if (user.isInitialized && !user.isLoggedIn) return <Redirect to="/login" />;

    // Otherwise display the application
    return (
      <Layout isModalOpen={modal.isOpen}>
        <Sidebar
          isHidden={isSidebarHidden}
          userLevel={user.level}
          userDealerId={user.dealership && user.dealership.id}
          activeNavItem={activeSidebarNavItem}
          handleNavEventFunc={() =>
            this.setState(() => ({ isSidebarHidden: true }))
          }
        />
        <Content isSidebarHidden={isSidebarHidden}>
          <MobileScrim
            isSidebarHidden={isSidebarHidden}
            onClick={() => this.setState(() => ({ isSidebarHidden: true }))}
          />
          <CloseIconWrapper
            isSidebarHidden={isSidebarHidden}
            onClick={() => this.setState(() => ({ isSidebarHidden: true }))}
          >
            <CloseIcon color={colors.white.base} size="24px" />
          </CloseIconWrapper>

          <Header
            isLoggedIn={user.isLoggedIn}
            isSidebarHidden={isSidebarHidden}
            toggleSidebarFunc={() =>
              this.setState(() => ({ isSidebarHidden: !isSidebarHidden }))
            }
          />
          <Page isSidebarHidden={isSidebarHidden}>
            <Container fluid>{children}</Container>
          </Page>
        </Content>
      </Layout>
    );
  }
}

AuthenticatedPage.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
    isInitialized: PropTypes.bool,
    level: PropTypes.string,
    dealership: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  modal: PropTypes.shape({
    isOpen: PropTypes.bool
  }).isRequired,
  localLoginData: PropTypes.func.isRequired
};

AuthenticatedPage.defaultProps = {
  user: {}
};

const Layout = styled.div`
  position: ${props => (props.isModalOpen ? 'fixed' : 'relative')};
`;

const Content = styled.div`
  position: relative;
  margin-left: 0;
  width: 100%;
  overflow-y: ${props =>
    (props.isSidebarHidden && window.innerWidth <= 1200) ||
    window.innerWidth > 1200
      ? 'initial'
      : 'hidden'};
  transition: all 0.3s ease-in-out;
  @media screen and (min-width: ${breakpoints.lg}) {
    margin-left: ${sidebarWidth}px;
    width: calc(100% - ${sidebarWidth}px);
    position: static;
  }
`;

const MobileScrim = styled.button`
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.secondary.base};
  opacity: ${props => (props.isSidebarHidden ? 0 : 0.75)};
  z-index: ${props => (props.isSidebarHidden ? -1 : 14)};
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;
  border: 0;
  @media screen and (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const CloseIconWrapper = styled.button`
  appearance: none;
  position: fixed;
  top: 26px;
  right: 30px;
  padding: 0;
  background: none;
  border: none;
  opacity: ${props => (props.isSidebarHidden ? 0 : 1)};
  z-index: ${props => (props.isSidebarHidden ? -1 : 15)};
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;
  svg {
    transform: rotate(45deg);
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const Page = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: ${space[2]}rem 0;
  background-color: ${colors.gray.lightest};
  min-height: 100vh;
  @media screen and (min-width: ${breakpoints.lg}) {
    min-height: calc(100vh - ${headerHeight}px);
    padding: ${space[2]}rem ${space[3]}rem;
  }
`;

const mapStateToProps = state => ({
  user: state.user,
  modal: state.modal
});

const mapDispatchToProps = dispatch => ({
  push: route => dispatch(push(route)),
  localLoginData: data => dispatch(localLogin(data))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(AuthenticatedPage))
);
