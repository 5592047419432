/* Utility Imports */
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, breakpoints, headerHeight, space } from 'Theme';
import { logout } from 'Actions/user';
import { withTranslation } from 'react-i18next';

/* Component Imports */
import { Container, DropdownItem } from '@bootstrap-styled/v4';
import { Avatar, Dropdown, Heading, Link } from 'Components';

/* Asset Imports */
import { ReactComponent as MenuIcon } from 'Assets/images/icons/srs-list.svg';
import Logo from 'Assets/images/Logo';

const mapStateToProps = state => ({
  user: state.user,
  dealership: state.dealership.dealership
});

const mapDispatchToProps = dispatch => ({
  logoutUserFunc: () => dispatch(logout())
});

const Header = ({
  user: { isLoggedIn, profile_photo_url: profilePhoto },
  dealership,
  isSidebarHidden,
  logoutUserFunc,
  toggleSidebarFunc,
  t
}) => {
  const name = dealership ? dealership.name : '';
  let headerCrumbs = t('header.title.account');
  if (isLoggedIn) {
    const { pathname } = window.location;
    if (pathname.includes('dealership')) {
      headerCrumbs =
        pathname.includes('dealership/') && name
          ? [t('header.title.dealerships'), name].join(' / ')
          : t('header.title.dealerships');
    }
    if (pathname.includes('service-center')) {
      headerCrumbs =
        pathname.includes('service-center/') && name
          ? [t('header.title.service_centers'), name].join(' / ')
          : t('header.title.service_centers');
    }
    if (pathname.includes('ticket')) {
      const { ticket: ticketId } = useParams();
      headerCrumbs =
        pathname.includes('ticket/') && ticketId
          ? [t('header.title.tickets'), ticketId].join(' / #')
          : t('header.title.tickets');
    }
    if (pathname.includes('reports')) {
      headerCrumbs = t('header.title.reports');
    }
    if (pathname.includes('users')) {
      headerCrumbs = t('header.title.users');
    }
    return (
      <StyledHeader isSidebarHidden={isSidebarHidden}>
        <Container fluid>
          <HorizontalNavWrapper>
            <PageTitle size={4} color="white">
              {headerCrumbs}
            </PageTitle>
            <SidebarToggle onClick={() => toggleSidebarFunc()}>
              <MenuIcon />
            </SidebarToggle>
            <LogoWrapper>
              <Logo isLight />
            </LogoWrapper>
            <StyledDropdown toggle={<Avatar userImg={profilePhoto} />}>
              <DropdownItem tag="a" href="/account">
                {t('header.your_account')}
              </DropdownItem>
              <DropdownItem
                tag="div"
                onClick={() => {
                  logoutUserFunc();
                }}
              >
                {t('header.sign_out')}
              </DropdownItem>
            </StyledDropdown>
          </HorizontalNavWrapper>
        </Container>
      </StyledHeader>
    );
  }
  return (
    <header className="row py-3">
      <div className="col-md-8 offset-md-2 text-center">
        <span className="d-block w-100 text-center my-5">
          <Link to="/">
            <Logo />
          </Link>
        </span>
      </div>
    </header>
  );
};

Header.propTypes = {
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired,
    profile_photo_url: PropTypes.string.isRequired
  }).isRequired,
  dealership: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  t: PropTypes.func.isRequired,
  isSidebarHidden: PropTypes.bool.isRequired,
  logoutUserFunc: PropTypes.func,
  toggleSidebarFunc: PropTypes.func.isRequired
};

Header.defaultProps = {
  logoutUserFunc: () => {}
};

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  height: ${headerHeight}px;
  background-color: ${colors.black.base};
  z-index: 10;
  transition: all 0.3s ease-in-out;
  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 0 ${space[3]}rem;
  }
`;

const HorizontalNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  text-align: right;
  width: 100%;
  height: ${headerHeight}px;
`;

const PageTitle = styled(Heading)`
  display: none;
  margin: 0;
  @media screen and (min-width: ${breakpoints.lg}) {
    display: block;
  }
`;

const SidebarToggle = styled.button`
  appearance: none;
  width: min-content;
  padding: 0;
  border: none;
  background-color: ${colors.black.base};
  cursor: pointer;
  @media screen and (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  > svg {
    width: 140px;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: ${space[1] * 5}rem;
  height: ${space[1] * 5}rem;
  .dropdown-menu {
    top: 110%;
  }
  .dropdown-item {
    text-decoration: none;
    &:last-child {
      border-top: 1px solid ${colors.gray.lighter};
      color: ${colors.red.base};
    }
  }
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Header));
