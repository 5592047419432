import { api, retryApiCall } from '../api';
import {
  SAVE_PARTS_ORDER_LOADING,
  SAVE_PARTS_ORDER_ERROR,
  SAVE_PARTS_ORDER_SUCCESS,
  SAVE_PARTS_ORDER_RESET
} from './types';

export const orderCallTypes = {};
const apiCall = retryApiCall.use(orderCallTypes);

export const savePartsOrderLoading = data => ({
  type: SAVE_PARTS_ORDER_LOADING,
  payload: data
});

export const savePartsOrderFailure = data => ({
  type: SAVE_PARTS_ORDER_ERROR,
  payload: data
});

export const savePartsOrderSuccess = data => ({
  type: SAVE_PARTS_ORDER_SUCCESS,
  payload: data
});

export const savePartsOrderReset = () => ({
  type: SAVE_PARTS_ORDER_RESET
});

export const savePartsOrder = data => dispatch => {
  dispatch(apiCall(savePartsOrderLoading, savePartsOrder, data));
  return api
    .put(`/claim/${data.claim_id}/order`, {
      ...data
    })
    .then(res => {
      dispatch(savePartsOrderSuccess(res.data));
      return res.data;
    })
    .catch(err => {
      if (err !== 401) {
        dispatch(savePartsOrderFailure(err));
      }
      return Promise.reject(err);
    });
};
