import moment from "moment";
import { parsePhoneNumber, ParseError } from "libphonenumber-js";

export const stripHtmlTags = string => string.replace(/(<([^>]+)>)/gi, "");

export const formatAddress = string => {
  const words = string.split(" ");
  const formattedWords = [];
  words.forEach(word => {
    if (
      word.length === 2 &&
      (word.charAt(1) === "E" || word.charAt(1) === "W")
    ) {
      formattedWords.push(word);
    } else {
      const letters = word.split("");
      const newLetters = [];
      for (let i = 0; i < letters.length; i += 1) {
        if (i === 0) {
          newLetters.push(letters[i].toUpperCase());
        } else {
          newLetters.push(letters[i].toLowerCase());
        }
      }
      formattedWords.push(newLetters.join(""));
    }
  });
  return formattedWords.join(" ");
};

export const formatPhoneNumber = (number, countryCode = "US") => {
  // Allow only digits, remove all other characters.
  const numeric = number.replace(/[^\d]/g, "");
  try {
    const phoneNumber = parsePhoneNumber(number, countryCode);
    return phoneNumber.formatInternational();
  } catch (error) {
    if (error instanceof ParseError) {
      return numeric;
    }
    return "";
  }
};

export const titleCase = string => {
  const newString = string
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return newString.join(" ");
};

export const formatDate = (date, noTime = false) => {
  const crossBrowserDate = date.replace(" ", "T");
  let dateObj = new Date(date);
  if (moment(dateObj).isValid() === false) {
    dateObj = new Date(crossBrowserDate);
  }
  if (noTime) {
    return moment(dateObj).format("MM/DD/YYYY");
  }
  return moment(dateObj).format("MM/DD/YYYY h:mm A");
};

export const setBadgeColorByStatus = status => {
  let color;
  switch (status.toLowerCase()) {
    case "new":
      color = "success";
      break;
    case "updated":
      color = "info";
      break;
    case "active":
      color = "success";
      break;
    case "pending":
      color = "warning";
      break;
    case "pending_response":
      color = "warning";
      break;
    case "pending_return":
      color = "warning";
      break;
    case "received":
      color = "info";
      break;
    case "shipped":
      color = "info";
      break;
    case "closed":
      color = "default";
      break;
    case "backordered":
      color = "danger";
      break;
    case "service_center_user":
      color = "info";
      break;
    case "invited":
      color = "default";
      break;
    default:
      color = "default";
  }
  return color;
};

export const formatCentsToDollars = number => {
  if (number === null || number === undefined) {
    return "";
  }

  return number.toLocaleString("en-US", {
    useGrouping: false,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
};
