import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors } from 'Theme';

const Throbber = ({ isDark, isSmall, isFullPage, className, inTable }) => (
  <ThrobberWrapper
    className={className}
    isDark={isDark}
    isSmall={isSmall}
    isFullPage={isFullPage}
    inTable={inTable}
  >
    <div className="spinner-border">
      <span className="sr-only">Loading...</span>
    </div>
  </ThrobberWrapper>
);

Throbber.propTypes = {
  isDark: PropTypes.bool,
  isSmall: PropTypes.bool,
  isFullPage: PropTypes.bool,
  inTable: PropTypes.bool
};

Throbber.defaultProps = {
  isDark: false,
  isSmall: false,
  isFullPage: false,
  inTable: false
};

const ThrobberWrapper = styled.div`
  ${props =>
    props.isFullPage &&
    css`
      position: absolute;
      top: ${props => (props.inTable ? '50%' : '0')};
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    `}
  @-webkit-keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .spinner-border {
    display: inline-block;
    width: ${props => (props.isSmall ? '1rem' : '2rem')};
    height: ${props => (props.isSmall ? '1rem' : '2rem')};
    vertical-align: text-bottom;
    border-style: solid;
    border-width: ${props => (props.isSmall ? '0.2em' : '0.25em')};
    border-color: ${props =>
      props.isDark ? colors.black.base : colors.white.base};
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
    z-index: 5;
  }
`;

export default Throbber;
