import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import BSButton from '@bootstrap-styled/v4/lib/Button';
import { makeTheme } from 'bootstrap-styled/lib/theme';
import { bootstrapTheme, typography, space, colors } from 'Theme';
import Throbber from '../Throbber';

const Button = ({
  children,
  color,
  outline,
  isDisabled,
  onClickFunc,
  theme,
  type,
  isFetching,
  width,
  icon,
  skinny,
  style,
  className,
  throbberIsDark
}) => (
  <ButtonWrapper
    className={className}
    style={style}
    width={width}
    hasIcon={icon}
    skinny={skinny}
  >
    <BSButton
      color={color}
      outline={outline}
      disabled={isDisabled}
      onClick={onClickFunc}
      theme={theme}
      type={type}
    >
      {isFetching ? (
        <Fragment>
          <Throbber className="btn-throbber" isSmall isDark={throbberIsDark} />
          <div style={{ visibility: 'hidden' }} className="btn-text">
            {children}
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="btn-text">{children}</div>
          {icon}
        </Fragment>
      )}
    </BSButton>
  </ButtonWrapper>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    'success',
    'info',
    'danger',
    'warning',
    'primary',
    'secondary',
    'link',
    'white'
  ]),
  outline: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClickFunc: PropTypes.func,
  type: PropTypes.string,
  theme: PropTypes.shape(),
  width: PropTypes.string,
  isFetching: PropTypes.bool,
  icon: PropTypes.node,
  skinny: PropTypes.bool,
  style: PropTypes.shape(),
  throbberIsDark: PropTypes.bool
};

Button.defaultProps = {
  color: 'primary',
  outline: false,
  isDisabled: false,
  onClickFunc: () => {},
  type: 'button',
  theme: makeTheme(bootstrapTheme),
  width: 'inherit',
  isFetching: false,
  icon: null,
  skinny: false,
  style: {},
  throbberIsDark: false
};

const ButtonWrapper = styled.div`
  ${props =>
    props.hasIcon &&
    css`
      display: inline-block;
      svg {
        transform: scale(0.65);
      }
      > .btn {
        justify-content: space-between;
      }
    `}
  position: relative;
  width: ${props => props.width};
  font-family: ${typography.fonts.sansSerif};
  border: none;
  margin-bottom: ${space[2]}rem;
  background-color: ${colors.transparent.base};
  > .btn {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-stretch: condensed;
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 1;
    padding: ${props => (props.skinny ? '0.4375rem' : '0.8125rem')};
    border-radius: 0;
    transition: all 0.2s ease-in-out;
    &.disabled {
      opacity 0.25;
    }
    &.btn-white {
      background-color: ${colors.gray.lightest};
      color: ${colors.primary.base};
    }
    &.btn-outline-white {
      background-color: ${colors.transparent.base};
      color: ${colors.white.base};
      border-color: ${colors.white.base};
    }
    &:focus  {
      &.btn-primary,
      &.btn-secondary,
      &.btn-outline-secondary,
      &.btn-outline-white,
      &.btn-link {
        box-shadow: 0 0 0 2px ${colors.primary.lightest};
      }
    }
    &:hover {
      &.btn-primary {
        border-color: ${colors.primary.darker};
        background-color: ${colors.primary.darker};
      }
      &.btn-secondary,
      &.btn-outline-white,
      &.btn-link {
        border-color: ${colors.secondary.darker};
        background-color: ${colors.secondary.darker};
        text-decoration: none;
        color: ${colors.white.base};
      }
    }
    &:active {
      &.btn-primary {
        border-color: ${colors.primary.darkest};
        background-color: ${colors.primary.darkest};
      }
      &.btn-secondary,
      &.btn-outline-secondary,
      &.btn-outline-white {
        border-color: ${colors.secondary.darkest};
        background-color: ${colors.secondary.darkest};
      }
    }
    .btn-throbber {
      position: absolute;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default Button;
