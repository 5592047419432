import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@bootstrap-styled/v4/lib/Form/FormGroup';
import queryString from 'query-string';
import { connect } from 'react-redux';
import {
  Heading,
  Button,
  Input as TextInput,
  Form,
  Alert,
  Link
} from 'Components';
import { login } from 'Actions/user';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  user: state.user,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  loginData: (userData, referrer) => dispatch(login(userData, referrer))
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      hasError: false,
      autoLoggedOut: false,
      form: {
        email: '',
        password: ''
      },
      errorStatus: 200,
      error: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {
      status: { SESSION_TIMEOUT },
      user: { isLoggedIn }
    } = this.props;

    if (isLoggedIn) {
      if (SESSION_TIMEOUT === 'true') {
        this.setState(() => ({ autoLoggedOut: true }));
      }
    }
  }

  handleChange(event) {
    const { form } = this.state;
    form[event.target.name] = event.target.value;
    this.setState(() => ({
      form
    }));
  }

  async handleSubmit() {
    const { loginData } = this.props;
    const { form } = this.state;
    const params = queryString.parse(window.location.search);
    const { referrer } = params;

    this.setState(() => ({
      hasError: false,
      isFetching: true
    }));

    await loginData(form, referrer).catch(errorStatus => {
      const { t } = this.props;
      const error = t(`sign_in.error.${errorStatus}`);
      this.setState(() => ({ error }));
      this.setState(() => ({ errorStatus }));
    });

    let hasError = false;
    const {
      status: { LOGIN }
    } = this.props;
    if (LOGIN === 'failure') {
      hasError = true;
    }
    this.setState(() => ({
      isFetching: false,
      hasError
    }));
  }

  render() {
    const { form, isFetching, hasError, error, autoLoggedOut, errorStatus } = this.state;
    const { t } = this.props;

    return (
      <Fragment>
        {/* Sign-In Form */}
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <Form
              onSubmitFunc={this.handleSubmit}
              validation={{
                email: [
                  {
                    rule: 'required',
                    messages: {
                      error: t('form.required_feedback'),
                      success: ' '
                    }
                  }
                ],
                password: [
                  {
                    rule: 'required',
                    messages: {
                      error: t('form.required_feedback'),
                      success: ' '
                    }
                  }
                ]
              }}
              ignoreRequiredOnBlur
              render={status => (
                <Fragment>
                  <FormGroup>
                    <Heading size={1}>{t('sign_in.heading_main')}</Heading>
                    <Heading size={6}>{t('sign_in.heading_sub')}</Heading>
                  </FormGroup>
                  <FormGroup>
                    <div>
                      <p>
                        {t('sign_in.notice_reset_password1')}
                        <Link
                          href="/forgotpassword"
                        >
                          {t('sign_in.notice_reset_password2')}
                        </Link>
                        {t('sign_in.notice_reset_password3')}
                      </p>
                    </div>
                    <TextInput
                      label={t('sign_in.form.email_label')}
                      inputId="user-email"
                      type="text"
                      name="email"
                      value={form.email}
                      onChangeFunc={this.handleChange}
                      status={status.email.status}
                      feedbackMsg={status.email.feedback}
                      width="100%"
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextInput
                      label={t('sign_in.form.password_label')}
                      inputId="user-password"
                      type="password"
                      name="password"
                      value={form.password}
                      status={status.password.status}
                      feedbackMsg={status.password.feedback}
                      onChangeFunc={this.handleChange}
                      width="100%"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Alert
                      alertIsOpen={hasError}
                      color={ errorStatus == 419?"warning":"danger" }
                      onCloseFunc={() => {
                        this.setState(() => ({ hasError: false }));
                      }}
                    >
                      {error}
                    </Alert>
                    <Alert
                      alertIsOpen={autoLoggedOut}
                      color="warning"
                      onCloseFunc={() => {
                        this.setState(() => ({ autoLoggedOut: false }));
                      }}
                    >
                      {t('sign_in.session_timeout')}
                    </Alert>
                    <Button
                      className="w-100 mt-4 mb-2"
                      color="primary"
                      type="submit"
                      isFetching={isFetching}
                    >
                      {t('sign_in.buttons.sign_in')}
                    </Button>
                    <Link
                      href="/signup"
                      isButton
                      buttonStyle="secondary"
                      width="100%"
                    >
                      {t('sign_in.buttons.create_account')}
                    </Link>
                  </FormGroup>
                </Fragment>
              )}
            />
          </div>
        </div>
        {/* Forgot Password? */}
        <div className="row mt-5">
          <div className="col-md-8 offset-md-2  text-center">
            <Link href="/forgotpassword">
              {t('sign_in.forgot_password_text')}
            </Link>
          </div>
        </div>
      </Fragment>
    );
  }
}

Login.propTypes = {
  loginData: PropTypes.func.isRequired,
  status: PropTypes.shape({
    LOGIN: PropTypes.string.isRequired,
    SESSION_TIMEOUT: PropTypes.string.isRequired
  }).isRequired,
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Login));
