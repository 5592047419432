import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@bootstrap-styled/v4/lib/Form/FormGroup';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
  Heading,
  Paragraph,
  Form,
  Input as TextInput,
  Button,
  Link,
  Alert
} from 'Components';
import { resetPassword } from 'Actions/user';
import { withTranslation, useTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  user: state.user,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  resetPasswordData: data => dispatch(resetPassword(data))
});

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      hasError: false,
      hasSuccess: false,
      form: {
        email: '',
        password_reset_token: '',
        password: '',
        passwordconfirmation: ''
      },
      error: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { state } = this;
    // const { location } = this.props;

    if (window.location.search) {
      const params = queryString.parse(window.location.search);
      this.setState(() => ({
        form: {
          ...state.form,
          email: params.email,
          password_reset_token: params.token
        }
      }));
    }
  }

  handleChange(event) {
    const { form } = this.state;
    form[event.target.name] = event.target.value;
    this.setState(() => ({
      form
    }));
  }

  async handleSubmit() {
    const { form } = this.state;
    const { resetPasswordData } = this.props;

    this.setState(() => ({
      isFetching: true
    }));

    await resetPasswordData(form).catch(errorStatus => {
      const { t } = this.props;
      const error = t(`password_reset.error.${errorStatus}`);
      this.setState(() => ({ error }));
    });

    let hasError = false;
    let hasSuccess = false;
    const {
      status: { RESET_PASSWORD }
    } = this.props;
    if (RESET_PASSWORD === 'success') {
      hasSuccess = true;
    } else {
      hasError = true;
    }
    this.setState(() => ({
      isFetching: false,
      hasError,
      hasSuccess
    }));
  }

  render() {
    const { isFetching, hasSuccess, form, hasError, error } = this.state;
    const { t } = this.props;

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {hasSuccess ? (
              <UpdateSuccessMessage />
            ) : (
              <Form
                onSubmitFunc={this.handleSubmit}
                validation={{
                  password: [
                    {
                      rule: 'required',
                      messages: {
                        error: t('form.required_feedback'),
                        success: ''
                      }
                    }
                  ],
                  passwordconfirmation: [
                    {
                      rule: 'required',
                      messages: {
                        error: t('form.required_feedback'),
                        success: ''
                      }
                    },
                    {
                      rule: 'matches',
                      fieldToMatch: 'password',
                      messages: {
                        error: t('form.password_match_feedback'),
                        success: t('form.valid.passwords_match')
                      }
                    }
                  ]
                }}
                render={status => (
                  <Fragment>
                    <FormGroup>
                      <Heading size={1}>
                        {t('password_reset.heading_main')}
                      </Heading>
                      <Heading size={6}>
                        {t('password_reset.heading_sub')}
                      </Heading>
                    </FormGroup>
                    <FormGroup>
                      <TextInput
                        label={t('form.labels.password')}
                        type="password"
                        name="password"
                        value={form.password}
                        onChangeFunc={this.handleChange}
                        status={status.password.status}
                        feedbackMsg={status.password.feedback}
                        inputId="password"
                        placeholder={t('form.labels.password')}
                        isRequired
                        width="100%"
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextInput
                        label={t('form.labels.password_confirm')}
                        type="password"
                        name="passwordconfirmation"
                        value={form.passwordconfirmation}
                        onChangeFunc={this.handleChange}
                        status={status.passwordconfirmation.status}
                        feedbackMsg={status.passwordconfirmation.feedback}
                        inputId="password-confirmation"
                        placeholder={t('form.labels.password_confirm')}
                        isRequired
                        width="100%"
                      />
                    </FormGroup>
                    <Button
                      className="w-100 mt-4 mb-2"
                      color="primary"
                      type="submit"
                      isFetching={isFetching}
                    >
                      {t('password_reset.buttons.submit')}
                    </Button>
                    {hasError && (
                      <Alert
                        alertIsOpen
                        color="danger"
                        onCloseFunc={() =>
                          this.setState(() => ({ hasError: false }))
                        }
                      >
                        {error}
                      </Alert>
                    )}
                  </Fragment>
                )}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

PasswordReset.propTypes = {
  resetPasswordData: PropTypes.func.isRequired,
  status: PropTypes.shape({
    RESET_PASSWORD: PropTypes.string.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};

const UpdateSuccessMessage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Heading size={1}>{t('password_reset.success.updated_main')}</Heading>
      <Paragraph>{t('password_reset.success.updated_sub')}</Paragraph>
      <Link href="/" width="100%">
        {t('password_reset.buttons.sign_in')}
      </Link>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PasswordReset));
