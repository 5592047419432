import React from "react";
import PropTypes from "prop-types";
import { colors } from "Theme";

const ServiceCentersIcon = ({ color, size, style }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path style={{
        strokeWidth: 0,
        fill: "#000"
      }} d="M21.6 11.25h-6.16v10.02h6.16V11.25Zm-1.85 6.84h-2.47v-1.73h2.47v1.73Zm0-3.7h-2.47v-1.73h2.47v1.73ZM6.09 16.53c0-.17.12-.29.29-.29h4.53c.17 0 .29.12.29.29v4.75h3.67V2.86H2.42v18.41h3.67v-4.75ZM9.88 5.26h2.47v1.73H9.88V5.26Zm0 3.69h2.47v1.73H9.88V8.95Zm0 3.72h2.47v1.73H9.88v-1.73ZM4.94 5.26h2.47v1.73H4.94V5.26Zm0 3.69h2.47v1.73H4.94V8.95Zm0 3.72h2.47v1.73H4.94v-1.73Z" />
      <path style={{
        strokeWidth: 0,
        fill: "none"
      }} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

ServiceCentersIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object
};

ServiceCentersIcon.defaultProps = {
  color: colors.gray.darkest,
  size: "24px",
  style: {}
};

export default ServiceCentersIcon;
