import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@bootstrap-styled/v4/lib/Form/FormGroup';
import { connect } from 'react-redux';
import {
  Heading,
  Paragraph,
  Form,
  Input as TextInput,
  Button,
  Link,
  Alert
} from 'Components';
import { sendPasswordReset } from 'Actions/user';
import { useTranslation, withTranslation } from 'react-i18next';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      hasResetEmailSent: false,
      form: {
        email: ''
      },
      error: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { form } = this.state;
    form[event.target.name] = event.target.value;
    this.setState(() => ({ form }));
  }

  async handleSubmit() {
    const { form } = this.state;
    const { sendPasswordResetData } = this.props;

    this.setState(() => ({
      isFetching: true
    }));

    await sendPasswordResetData(form).catch(errorStatus => {
      const { t } = this.props;
      const error = t(`forgot_password.error.${errorStatus}`);
      this.setState(() => ({ error }));
    });

    const {
      status: { SEND_PASSWORD_RESET }
    } = this.props;
    let hasResetEmailSent = false;
    let hasError = false;
    if (SEND_PASSWORD_RESET === 'success') {
      hasResetEmailSent = true;
    } else {
      hasError = true;
    }
    this.setState(() => ({
      isFetching: false,
      hasError,
      hasResetEmailSent
    }));
  }

  sendResetEmail() {
    this.setState(() => ({
      hasResetEmailSent: true
    }));
  }

  render() {
    const { hasResetEmailSent, isFetching, form, hasError, error } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        {/* Sign-In Form */}
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {// Conditionally display the reset password form or success notification once email is sent.
            !hasResetEmailSent ? (
              <Form
                onSubmitFunc={this.handleSubmit}
                validation={{
                  email: [
                    {
                      rule: 'required',
                      messages: {
                        error: t('form.required_feedback'),
                        success: ''
                      }
                    }
                  ]
                }}
                ignoreRequiredOnBlur
                render={status => (
                  <Fragment>
                    <FormGroup>
                      <Heading size={1}>
                        {t('forgot_password.heading_main')}
                      </Heading>
                      <Heading size={6}>
                        {t('forgot_password.heading_sub')}
                      </Heading>
                    </FormGroup>
                    <FormGroup>
                      <TextInput
                        label="Email"
                        inputId="user-password"
                        name="email"
                        width="100%"
                        value={form.email}
                        status={status.email.status}
                        feedbackMsg={status.email.feedback}
                        onChangeFunc={this.handleChange}
                        ignoreRequiredOnBlur
                      />
                    </FormGroup>
                    <Button
                      className="w-100 mt-4 mb-2"
                      color="primary"
                      type="submit"
                      isFetching={isFetching}
                    >
                      {t('forgot_password.buttons.submit')}
                    </Button>
                    {hasError && (
                      <Alert
                        alertIsOpen
                        color="danger"
                        onCloseFunc={() =>
                          this.setState(() => ({ hasError: false }))
                        }
                      >
                        {error}
                      </Alert>
                    )}
                    <Link href="/" width="100%">
                      {t('forgot_password.buttons.sign_in')}
                    </Link>
                  </Fragment>
                )}
              />
            ) : (
              <EmailSentMessage emailAddress={form.email} />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

ForgotPassword.propTypes = {
  sendPasswordResetData: PropTypes.func.isRequired,
  status: PropTypes.shape({
    SEND_PASSWORD_RESET: PropTypes.string.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};

const EmailSentMessage = ({ emailAddress }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Heading size={1}>
        {t('forgot_password.success.email_sent_heading')}
      </Heading>
      <Paragraph>
        {t('forgot_password.success.email_sent_msg', { address: emailAddress })}
      </Paragraph>
      <Link href="/" width="100%">
        {t('forgot_password.buttons.sign_in')}
      </Link>
    </div>
  );
};

EmailSentMessage.propTypes = {
  emailAddress: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  sendPasswordResetData: formData => dispatch(sendPasswordReset(formData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ForgotPassword));
