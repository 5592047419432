import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { hideModal } from "Actions/modal";
import { connect } from "react-redux";
import { space } from "Theme";
import { inviteServiceCenterTech } from "Actions/serviceCenter";
import {
  Heading,
  Paragraph,
  Form,
  Input as SsgInput,
  Button,
  Alert
} from "Components";
import { FormGroup } from "@bootstrap-styled/v4";
import { withTranslation } from "react-i18next";

const CreateServiceCenterTechForm = props => {
  const [form, setForm] = useState({
    email: ""
  });
  const [isFetching, setFetching] = useState(false);
  const [hasError, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { t } = props;
  const handleUpdateEmail = event => {
    const { value } = event.currentTarget;
    setForm(prevState => ({ ...prevState, email: value }));
  };

  const genericRequiredRule = {
    rule: "required",
    messages: { error: t("form.required_feedback"), success: t("required") }
  };

  const onSubmit = () => {
    const {
      inviteServiceCenterTech,
      hideModal,
      data: { serviceCenterId, onInvite }
    } = props;
    // Start the throbber
    setFetching(true);
    // Send the invite, then hide the throbber
    inviteServiceCenterTech({
      serviceCenterId,
      email: form.email
    })
      .then(() => {
        // If successful, hide the modal
        hideModal();
        // Run the clean up function
        onInvite();
      })
      .catch(err => {
        console.error(err);
        // If failed, show the error
        setErrorMsg(err);
      })
      .finally(() => {
        // Always setFetching to false when completed
        setFetching(false);
      });
  };

  return (
    <FormContainer>
      <Form
        onSubmitFunc={onSubmit}
        ignoreRequiredOnBlur
        validation={{
          email: [
            genericRequiredRule,
            {
              rule: "email",
              messages: {
                error: t("form.invalid.email_address"),
                success: t("form.valid.email_address")
              }
            }
          ]
        }}
        render={status => (
          <Fragment>
            <Heading size={4}>
              {t("create_service_center_tech.heading_main")}
            </Heading>
            <Paragraph>{t("create_service_center_tech.heading_sub")}</Paragraph>
            <FormGroup>
              <SsgInput
                label={t("form.labels.email")}
                inputId="email"
                name="email"
                value={form.email}
                onChangeFunc={handleUpdateEmail}
                status={status.email.status}
                feedbackMsg={status.email.feedback}
                placeholder={t("form.labels.email")}
                isRequired
                width="100%"
              />
            </FormGroup>
            <Button
              className="w-100 mt-4 mb-2"
              color="primary"
              type="submit"
              isFetching={isFetching}
            >
              {t("create_service_center_tech.submit_button")}
            </Button>
            <Alert
              alertIsOpen={hasError}
              color="danger"
              onCloseFunc={() => setError(false)}
            >
              {errorMsg}
            </Alert>
          </Fragment>
        )}
      />
    </FormContainer>
  );
};

CreateServiceCenterTechForm.propTypes = {
  data: PropTypes.shape({
    serviceCenterId: PropTypes.string.isRequired,
    onInvite: PropTypes.func.isRequired
  }).isRequired
};

const FormContainer = styled.div`
  padding: ${space[3]}rem;
  max-width: 480px;
  margin: auto;
`;

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  inviteServiceCenterTech: (serviceCenterId, formData) =>
    dispatch(inviteServiceCenterTech(serviceCenterId, formData)),
  hideModal: data => dispatch(hideModal(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateServiceCenterTechForm));
