import React, { useState } from "react";
import { Button, Input } from "Components";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createPart } from "Actions/part";
import { hideModal } from "Actions/modal";

const AddPartType = (props) => {
  const [partName, setPartName] = useState("");
  const [isLoading, setLoading] = useState(false);
  const handleCreatePart = () => {
    setLoading(true);
    props
      .createPart({ name: partName })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
        props.hideModal();
      });
  };
  return (
    <form>
      <PartNameInput
        value={partName}
        onChangeFunc={(e) => setPartName(e.currentTarget.value)}
        label="Part Name"
        placeholder="Handlebars"
      />
      <Button onClickFunc={handleCreatePart} isFetching={isLoading}>
        Create Part Type
      </Button>
    </form>
  );
};

const PartNameInput = styled(Input)`
  width: 100%;
  margin-bottom: 1rem;
`;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createPart: (partData) => dispatch(createPart("part-type", partData)),
  hideModal: (data) => dispatch(hideModal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddPartType));
