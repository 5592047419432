export default {
  en: {
    create_dealer_user: {
      heading_main: 'Invite a user',
      heading_sub:
        'Send an invite to join your organization. Recipient will receive a link to accept the invitation using their existing account.',
      submit_button: 'Send Invite',
      error: {
        500: 'A technical issue prevented us sending the invitation. Our staff has been notified of the error.',
        404: 'The email address you provided does not match any existing users. You can only invite users who already have an account.',
        403: 'This email address is already associated with a dealership. No invitation will be sent.',
        401: 'Your user account is not authorized to send an invitation.'
      }
    }
  },
  de: {
    create_dealer_user: {
      heading_main: 'Laden Sie einen Benutzer ein',
      heading_sub:
        'Senden Sie eine Einladung zum Beitritt zu Ihrer Organisation. Der Empfänger erhält einen Link, um die Einladung mit seinem bestehenden Konto anzunehmen.',
      submit_button: 'Send Invite',
      error: {
        500: 'Ein technisches Problem verhinderte das Senden der Einladung. Unsere Mitarbeiter wurden über den Fehler informiert.',
        404: 'Die von Ihnen angegebene E-Mail-Adresse stimmt mit keinem vorhandenen Benutzer überein. Sie können nur Benutzer einladen, die bereits ein Konto haben.',
        403: 'Diese E-Mail-Adresse ist bereits mit einem Händler verknüpft. Es wird keine Einladung verschickt.',
        401: 'Ihr Benutzerkonto ist nicht berechtigt, eine Einladung zu versenden.'
      }
    }
  },
  jp: {
    create_dealer_user: {
      heading_main: 'ユーザーを招待する',
      heading_sub:
        '組織に参加するための招待状を送信します。受信者は、既存のアカウントを使用して招待を受け入れるためのリンクを受け取ります。',
      submit_button: '招待を送る',
      error: {
        500: '技術的な問題により、招待状を送信できませんでした。エラーが発生したことがスタッフに通知されました。',
        404: '指定したメールアドレスが既存のユーザーと一致しません。すでにアカウントを持っているユーザーのみを招待できます。',
        403: 'このメールアドレスはすでに販売店に関連付けられています。招待状は送信されません。',
        401: 'ユーザーアカウントに招待状の送信が許可されていません。'
      }
    }
  },
  fr: {
    create_dealer_user: {
      heading_main: 'Inviter un utilisateur',
      heading_sub:
        "Envoyez une invitation à rejoindre votre organisation. Le destinataire recevra un lien pour accepter l'invitation en utilisant son compte existant.",
      submit_button: 'Envoyer une invitation',
      error: {
        500: "Un problème technique nous a empêché d'envoyer l'invitation. Notre personnel a été informé de l'erreur.",
        404: "L'adresse e-mail que vous avez fournie ne correspond à aucun utilisateur existant. Vous ne pouvez inviter que des utilisateurs qui ont déjà un compte.",
        403: 'Cette adresse e-mail est déjà associée à un concessionnaire. Aucune convocation ne sera envoyée.',
        401: "Votre compte utilisateur n'est pas autorisé à envoyer une invitation."
      }
    }
  },
  it: {
    create_dealer_user: {
      heading_main: 'Invita un utente',
      heading_sub:
        "Invia un invito per entrare a far parte della tua organizzazione. Il destinatario riceverà un link per accettare l'invito utilizzando il proprio account esistente.",
      submit_button: 'Invia un invito',
      error: {
        500: "Un problema tecnico ci ha impedito di inviare l'invito. Il nostro personale è stato informato dell'errore.",
        404: "L'indirizzo email che hai fornito non corrisponde a nessun utente esistente. Puoi invitare solo utenti che hanno già un account.",
        403: 'Questo indirizzo email è già associato a una concessionaria. Nessun invito verrà inviato.',
        401: 'Il tuo account utente non è autorizzato a inviare un invito.'
      }
    }
  }
};
