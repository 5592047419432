import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { hideModal } from 'Actions/modal';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { updateDealerUser } from 'Actions/dealership';
import styled from 'styled-components';
import { space, colors, breakpoints } from 'Theme';
import { titleCase } from 'Components/Utils';
import Row from '@bootstrap-styled/v4/lib/Row';
import Col from '@bootstrap-styled/v4/lib/Col';
import Option from '@bootstrap-styled/v4/lib/Option';
import { Heading, Form, Input as SsgInput, Alert, Button } from 'Components';

const mapStateToProps = state => ({
  dealership: state.dealership
});

const mapDispatchToProps = dispatch => ({
  updateDealerUser: (id, data) => dispatch(updateDealerUser(id, data)),
  hideModal: data => dispatch(hideModal(data))
});

class UpdateDealerUserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdating: false,
      form: {
        status: '',
        is_admin: ''
      },
      hasError: false,
      error: ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const {
      data: {
        dealer_user: { status, is_admin: isAdmin }
      }
    } = this.props;

    const { form } = this.state;

    return this.setState(() => ({
      form: {
        ...form,
        status,
        is_admin: isAdmin
      }
    }));
  }

  onSubmit = async () => {
    const {
      updateDealerUser,
      hideModal,
      data,
      dealership: {
        dealership: { id: dealerId }
      },
      t
    } = this.props;
    const { form } = this.state;

    this.setState(() => ({
      isUpdating: true
    }));

    let hasError = false;
    let error = '';

    await updateDealerUser({
      dealerId,
      userId: data.id,
      ...form
    }).catch(err => {
      hasError = true;
      error = t(`dealership.error.${err}`);
    });

    this.setState(() => ({
      hasError,
      error,
      isUpdating: false
    }));

    if (!hasError) {
      hideModal();
    }
  };

  handleChange(event) {
    const { form } = this.state;
    const { value, checked } = event.target;
    let newValue;
    if (event.target.type === 'checkbox') {
      newValue = checked;
    } else {
      newValue = value;
    }
    form[event.target.name] = newValue;
    this.setState(() => ({ form }));
  }

  render() {
    const { isUpdating, hasError, error, form } = this.state;
    const { t } = this.props;

    const levels = [1, 0];
    const scopes = ['active', 'inactive'];

    const genericRequiredRule = {
      rule: 'required',
      messages: {
        error: t('form.required_feedback'),
        success: ' '
      }
    };

    return (
      <Form
        onSubmitFunc={() => this.onSubmit()}
        validation={{
          status: [genericRequiredRule],
          is_admin: [genericRequiredRule]
        }}
        render={status => (
          <Fragment>
            <Alert
              alertIsOpen={hasError}
              color="danger"
              onCloseFunc={() => {
                this.setState(() => ({ hasError: false }));
              }}
            >
              {error}
            </Alert>
            <Section>
              <Row>
                <Col md={4}>
                  <Heading size={6}>
                    {t('dealership_users.account_info')}
                  </Heading>
                </Col>
                <Col md={8}>
                  <Row>
                    <Col md={6}>
                      <SsgInput
                        label={t('form.labels.status')}
                        type="select"
                        name="status"
                        value={form.status}
                        status={status.status.status}
                        onChangeFunc={this.handleChange}
                        inputId="user-status"
                        width="100%"
                      >
                        {scopes.map(scope => (
                          <Option key={scope.id} value={scope}>
                            {titleCase(scope)}
                          </Option>
                        ))}
                      </SsgInput>
                    </Col>
                    <Col md={6}>
                      <SsgInput
                        label={t('form.labels.level')}
                        type="select"
                        name="is_admin"
                        value={form.is_admin}
                        status={status.is_admin.status}
                        onChangeFunc={this.handleChange}
                        helperText={t('dealership_users.helper_text.level')}
                        inputId="user-level"
                        width="100%"
                      >
                        {levels.map(level => (
                          <Option key={level.id} value={level}>
                            {level === 0 ? 'Employee' : 'Admin'}
                          </Option>
                        ))}
                      </SsgInput>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Section>
            <ButtonWrapper>
              <Button
                color="primary"
                type="submit"
                isFetching={isUpdating}
                width="max-content"
              >
                {t('dealership_users.buttons.update')}
              </Button>
            </ButtonWrapper>
          </Fragment>
        )}
      />
    );
  }
}

UpdateDealerUserForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    dealer_user: PropTypes.shape({
      is_admin: PropTypes.string,
      status: PropTypes.string
    })
  }),
  dealership: PropTypes.shape({
    dealership: PropTypes.shape({
      id: PropTypes.number
    })
  }),
  updateDealerUser: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

UpdateDealerUserForm.defaultProps = {
  data: {},
  dealership: {
    dealership: {}
  }
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${space[1] * 3}rem;
`;

const Section = styled.section`
  padding-top: ${space[1] * 3}rem;
  padding-bottom: ${space[2] * 2}rem;
  margin-bottom: ${space[1]}rem;
  border-bottom: 2px solid ${colors.gray.base};
  @media screen and (min-width: ${breakpoints.md}) {
    padding-top: ${space[1] * 7}rem;
    padding-bottom: ${space[4]}rem;
  }
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UpdateDealerUserForm));
