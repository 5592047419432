export default {
  en: {
    reporting_dashboard: {
      text: {
        download_button: 'Download report',
        by_service_center: 'Tickets By Service Center',
        by_status: 'Tickets By Status',

        by_make: 'Tickets By Bike Make',
        select_report: 'Select Report',
        select_type: 'Select Type'
      },
      titles: {
        tix_last_12: 'New Tickets',
        dealers_last_12: 'New Dealerships',
        users_last_12: 'New Users',
        totals: 'Totals'
      },
      labels: {
        months: 'Months',
        tix: 'Tickets',
        tix_closed: 'Closed Tickets',
        tix_open: 'Open Tickets',
        users: 'Users',
        dealers: 'Dealerships'
      },
      error: {
        general: 'An error occurred while fetching report data.'
      }
    }
  },
  de: {
    reporting_dashboard: {
      text: {
        download_button: 'Bericht herunterladen (CSV)',
        by_service_center: 'Tickets von Service Center',
        by_status: 'Tickets nach Statu',

        by_make: 'Tickets nach Fahrradhersteller',
        select_report: 'Wählen Sie Bericht aus'
      },
      titles: {
        tix_last_12: 'Neue Eintrittskarten',
        dealers_last_12: 'Neue Händler',
        users_last_12: 'Neue Nutzer',
        totals: 'Summen'
      },
      labels: {
        months: 'Monate',
        tix: 'Eintrittskarten',
        tix_closed: 'Geschlossene Karten',
        tix_open: 'Offene Eintrittskarten',
        users: 'Benutzer',
        dealers: 'Händler'
      },
      error: {
        general: 'Beim Abrufen der Berichtsdaten ist ein Fehler aufgetreten'
      }
    }
  },
  jp: {
    reporting_dashboard: {
      text: {
        download_button: 'レポートのダウンロード（CSV）',
        by_service_center: 'サービスセンター別のチケット',
        by_status: 'ステータス別のチケット',

        by_make: 'バイクメーカーによるチケット',
        select_report: 'レポートを選択'
      },
      titles: {
        tix_last_12: '新しいチケット',
        dealers_last_12: '新しいディーラー',
        users_last_12: '新しい利用者',
        totals: '合計'
      },
      labels: {
        months: '月',
        tix: '切符売場',
        tix_closed: 'クローズドチケット',
        tix_open: 'オープンチケット',
        users: 'ユーザー',
        dealers: 'ディーラー'
      },
      error: {
        general: 'レポートデータの取得中にエラーが発生しました'
      }
    }
  },
  it: {
    reporting_dashboard: {
      text: {
        download_button: 'Scarica il rapporto (CSV)',
        by_service_center: 'Biglietti dal centro servizi',
        by_status: 'Biglietti per stato',

        by_make: 'Biglietti per bici Marca',
        select_report: 'Seleziona Segnala'
      },
      titles: {
        tix_last_12: 'Nuovi biglietti',
        dealers_last_12: 'Nuove Concessionarie',
        users_last_12: 'Nuovi utenti',
        totals: 'Totali'
      },
      labels: {
        months: 'Mesi',
        tix: 'Biglietti',
        tix_closed: 'Biglietti chiusi',
        tix_open: 'Biglietti aperti',
        users: 'Utenti',
        dealers: 'Concessionari'
      },
      error: {
        general:
          'Si è verificato un errore durante il recupero dei dati del rapporto'
      }
    }
  }
};
