export default {
  en: {
    service_center: {
      heading_main: "{{ service_center_name }}",
      heading_sub:
        "Send an invite to for a service center technician to join your service center. The recipient will receive an emailed link to accept the invitation and create an account. The recipient must not have an existing account at SR SUNTOUR Warranty.",
      heading_info: "General Information",
      heading_users: "Technicians",
      heading_invites: "Invitations",
      invite_user: "Tech invited successfully",
      submit_button: "Send Invite",
      error: {
        500: "A technical issue prevented us loading the list of service centers. Our staff has been notified of the error",
        403: "This email is already associated with an account at SR SUNTOUR Warranty. The recipient must not have an existing account.",
        404: "The specified service center and/or location could not be found",
        200: "Service Center updated successfully",
      },
      buttons: {
        add: "Add",
        update: "Update Service Center",
        add_user: "Invite Technician",
        view_centers: "Back to Service Center",
      },
    },
  },
};
