// import { rmUnit, detectUnit } from '@bootstrap-styled/utils/lib/unitUtils';
import { fonts, fontSizes } from './typography';
import colors from './colors';
import space from './space';

// const detectedUnit = detectUnit('1rem');
// Utilities not currently in use:
// - Color - https://github.com/Qix-/color
//

export default {
  // Basic Colors
  $white: colors.white.base,
  $black: colors.black.base,
  $red: colors.red.base,
  $orange: colors.product.orange,
  $yellow: colors.yellow.base,
  $green: colors.green.base,
  $blue: colors.primary.base,
  $teal: colors.product.lightblue,
  $pink: colors.product.pink,
  $purple: colors.product.purple,

  // Grayscale (aka Secondary)
  '$gray-dark': colors.secondary.darker,
  $gray: colors.secondary.base,
  '$gray-light': colors.secondary.lighter,
  '$gray-lighter': colors.secondary.lightest,
  '$gray-lightest': colors.gray.lightest,

  // Semantic Color Scheme
  '$brand-primary': colors.primary.base,
  '$brand-success': colors.green.base,
  '$brand-info': colors.product.lightblue,
  '$brand-warning': colors.yellow.base,
  '$brand-danger': colors.red.base,
  // '$brand-inverse': '', // TBD

  // Optional Theme Features -- Enable/Disable
  '$enable-rounded': true,
  '$enable-shadows': false,
  '$enable-graidents': false,
  '$enable-transitions': true,
  '$enable-hover-media-query': false,
  '$enable-grid-classes': true,
  '$enable-print-styles': true,

  // Spacing -- Default
  // '$spacer': '1rem',
  // '$spacer-x': '$spacer',
  // '$spacer-y': '$spacer',

  /** The following code is used by Bootstrap-Styled to calculate/manipulate spacing variables in theme */

  // detectedUnit = detectUnit(v['$spacer']);
  // v['$spacer-halved'] = ((rmUnit(v['$spacer'], detectedUnit) / 2) + detectedUnit);
  // v['$spacers'] = {
  //   0: {
  //     x: 0,
  //     y: 0,
  //   },
  //   1: {
  //     x: (rmUnit(v['$spacer-x']) * 0.25) + detectedUnit,
  //     y: (rmUnit(v['$spacer-y']) * 0.25) + detectedUnit,
  //   },
  //   2: {
  //     x: (rmUnit(v['$spacer-x']) * 0.5) + detectedUnit,
  //     y: (rmUnit(v['$spacer-y']) * 0.5) + detectedUnit,
  //   },
  //   3: {
  //     x: v['$spacer-x'],
  //     y: v['$spacer-y'],
  //   },
  //   4: {
  //     x: (rmUnit(v['$spacer-x']) * 1.5) + detectedUnit,
  //     y: (rmUnit(v['$spacer-y']) * 1.5) + detectedUnit,
  //   },
  //   5: {
  //     x: (rmUnit(v['$spacer-x']) * 3) + detectedUnit,
  //     y: (rmUnit(v['$spacer-y']) * 3) + detectedUnit,
  //   },
  // };

  //   detectedUnit = detectUnit(v['$spacer']);
  //   v['$headings-margin-bottom'] = (rmUnit(v['$spacer'], detectedUnit) / 2) + detectedUnit;

  // Border -- Default
  '$border-width': '1px',

  // Utility Sizes
  $sizes: {
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%'
  },

  // Grid
  '$grid-columns': '12',
  '$grid-gutter-width': '30px',

  // Max Width Utility Sizes
  '$container-max-widths': {
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1140px'
  },

  /** Typography */

  // Font-Family
  '$font-family-sans-serif': fonts.sansSerif,
  '$font-family-monospace': fonts.monospace,
  '$font-family-base': fonts.sansSerif,

  // Font Sizes
  '$font-size-base': '1rem',
  '$font-size-lg': '1.25rem',
  '$font-size-sm': '.875rem',
  '$font-size-xs': '.75rem',

  // Font-Weight
  '$font-weight-normal': 'normal',
  '$font-weight-bold': 'bold',
  '$font-weight-base': '$font-weight-normal',

  // Line Height
  '$line-height-base': '1.5',

  // Headings - Size
  '$font-size-h1': `${fontSizes[6]}rem`,
  '$font-size-h2': `${fontSizes[5]}rem`,
  '$font-size-h3': `${fontSizes[4]}rem`,
  '$font-size-h4': `${fontSizes[3]}rem`,
  '$font-size-h5': `${fontSizes[2]}rem`,
  '$font-size-h6': `${fontSizes[1]}rem`,

  // Headings Etc.
  '$headings-font-family': 'inherit',
  '$headings-font-weight': '500',
  '$headings-line-height': '1.1',
  '$headings-color': 'inherit',
  '$headings-margin-bottom': '',

  // Display Headings - Size
  '$display1-size': '6rem',
  '$display2-size': '5.5rem',
  '$display3-size': '4.5rem',
  '$display4-size': '3.5rem',

  // Displayed Headings - Weight
  '$display1-weight': '300',
  '$display2-weight': '300',
  '$display3-weight': '300',
  '$display4-weight': '300',

  '$display-line-height': 'headings-line-height',

  // Lead
  '$lead-font-size': '1.25rem',
  '$lead-font-weight': '300',

  // Misc.
  '$small-font-size': '80%',
  '$text-muted': colors.secondary.lighter,
  '$mark-padding': '.2em',
  '$mark-bg': '$state-warning-bg',
  '$dt-font-weight': '$font-weight-bold',
  '$list-inline-padding': '5px',

  /** Padding, Border-Radii, Etc. */
  '$line-height-lg': '1.6',
  '$line-height-sm': '1.3',

  '$border-radius': '0',
  '$border-radius-lg': '0',
  '$border-radius-sm': '0',

  '$component-active-color': '$white',
  '$component-active-bg': '$brand-primary',

  '$caret-width': '.3em',

  // Links
  '$link-color': '',
  '$link-decoration': 'none',
  '$link-hover-color': '',
  '$link-hover-decoration': 'underline',

  // Content Misc.
  '$code-font-size': '90%',
  '$code-padding-x': '.4rem',
  '$code-padding-y': '.2rem',
  '$code-color': '#bd4147',
  '$code-bg': '$gray-lightest',

  '$kbd-color': '$white',
  '$kbd-bg': '$gray-dark',
  // '$kbd-box-shadow': `inset 0 -.1rem 0 ${Color(v['$black']).alpha(0.25).toString()}`,
  '$nested-kbd-font-weight': '$font-weight-bold',

  '$pre-color': '$gray-dark',
  '$pre-scrollable-max-height': '340px',

  /** ** Image Thumbnails */
  '$thumbnail-padding': '.25rem',
  '$thumbnail-bg': '$body-bg',
  '$thumbnail-border-width': '$border-width',
  '$thumbnail-border-color': '#ddd',
  '$thumbnail-border-radius': '$border-radius',
  // '$thumbnail-box-shadow': `0 1px 2px ${Color(v['$black']).alpha(0.75).toString()}`,
  '$thumbnail-transition': `all .2s ease-in-out`,

  // Figures
  '$figure-caption-font-size': '90%',
  '$figure-caption-color': '$gray-light',

  // Tables
  '$table-cell-padding': '.75rem',
  '$table-sm-cell-padding': '.3rem',
  '$table-bg': 'transparent',
  // '$table-bg-accent': Color(v['$black']).alpha(0.05).toString(),
  // '$table-bg-hover': Color(v['$black']).alpha(0.075).toString(),
  // '$table-bg-active': '$table-bg-hover',
  '$table-inverse-bg': '$gray-dark',
  // '$table-inverse-bg-accent': Color(v['$white']).alpha(0.05).toString(),
  // '$table-inverse-bg-hover': Color(v['$white']).alpha(0.075).toString(),
  // '$table-inverse-border': Color(v['$gray-dark']).lighten(0.075).toString(),
  '$table-inverse-color': '$body-bg',
  '$table-head-bg': '$gray-lighter',
  '$table-head-color': '$gray',
  '$table-border-width': '$border-width',
  '$table-border-color': '$gray-lighter',

  // Z-Index Values -- Default
  // Warning: Avoid customizing these defaults
  '$zindex-dropdown-backdrop': '990',
  '$zindex-dropdown': '1000',
  '$zindex-fixed': '1030',
  '$zindex-sticky': '1030',
  '$zindex-modal-backdrop': '2040',
  '$zindex-modal': '2050',
  '$zindex-popover': '1060',
  '$zindex-tooltip': '1070',

  /** Inputs */
  '$btn-padding-x': '0.4375rem',
  '$btn-padding-y': '0.4375rem',
  '$btn-line-height': '1',
  '$btn-font-weight': 'bold',
  // '$btn-transition': string,
  '$btn-box-shadow': 'none',
  '$btn-block-spacing-y': '0rem',
  '$btn-primary-color': colors.white.base,
  '$btn-primary-bg': colors.primary.base,
  '$btn-primary-border': colors.primary.base,
  '$btn-secondary-color': colors.white.base,
  '$btn-secondary-bg': colors.secondary.base,
  '$btn-secondary-border': colors.secondary.base,
  '$btn-info-color': colors.white.base,
  '$btn-info-bg': colors.product.lightblue,
  '$btn-info-border': colors.product.lightblue,
  '$btn-success-color': colors.white.base,
  '$btn-success-bg': colors.green.base,
  '$btn-success-border': colors.green.base,
  '$btn-warning-color': colors.white.base,
  '$btn-warning-bg': colors.yellow.base,
  '$btn-warning-border': colors.yellow.base,
  '$btn-danger-color': colors.white.base,
  '$btn-danger-bg': colors.red.base,
  '$btn-danger-border': colors.red.base,
  '$btn-link-disabled-color': colors.secondary.lighter,
  '$btn-padding-x-lg': '0.4375rem',
  '$btn-padding-y-lg': '0.4375rem',
  '$btn-padding-x-sm': '0.4375rem',
  '$btn-padding-y-sm': '0.4375rem',
  '$btn-border-radius': 0,
  '$btn-border-radius-lg': 0,
  '$btn-border-radius-sm': 0,
  '$input-btn-border-width': '1px',
  '$cursor-disabled': 'not-allowed',

  '$input-padding-x': '.75rem',
  '$input-padding-y': '.5rem',
  '$input-line-height': '1.25',
  '$input-bg': colors.white.base,
  '$input-bg-disabled': colors.gray.lightest,

  '$input-color': colors.gray.darkest,
  '$input-border-color': colors.gray.base,
  '$input-box-shadow': 'none',

  '$input-border-radius': 0,
  '$input-border-radius-lg': 0,
  '$input-border-radius-sm': 0,

  '$input-bg-focus': colors.white.base,
  '$input-border-focus': colors.gray.darker,
  '$input-box-shadow-focus': `0 0 8px rgba(${colors.product.lightblue}, .6)`,
  '$input-color-focus': colors.gray.darkest,

  '$input-color-placeholder': colors.secondary.lighter,

  '$input-padding-x-sm': '.5rem',
  '$input-padding-y-sm': '.5rem',
  '$input-padding-x-lg': '.5rem',
  '$input-padding-y-lg': '.5rem',

  '$input-height': '2rem',
  '$input-height-sm': `${0.875 * 1.3 + 1}rem`,
  '$input-height-lg': `${1.25 * 1.6 + 1}rem`,
  '$input-transition': `border-color ease-in-out .15s, box-shadow ease-in-out .15s`,
  '$label-margin-bottom': '.5rem',

  // Alert-related Variables
  '$alert-padding-x': `${space[2]}rem`,
  '$alert-padding-y': `${space[1]}rem`,
  '$alert-border-radius': 0,
  '$alert-link-font-weight': '400',
  '$alert-border-width': 0,
  '$alert-success-bg': colors.green.lighter,
  '$alert-success-text': colors.black.base,
  '$alert-success-border': colors.green.lighter,
  '$alert-info-bg': colors.primary.lightest,
  '$alert-info-text': colors.black.base,
  '$alert-info-border': colors.primary.lightest,
  '$alert-warning-bg': colors.yellow.lighter,
  '$alert-warning-text': colors.black.base,
  '$alert-warning-border': colors.yellow.lighter,
  '$alert-danger-bg': colors.red.lighter,
  '$alert-danger-text': colors.black.base,
  '$alert-danger-border': colors.red.lighter,

  // Badge-related Variables
  '$badge-default-bg': colors.gray.lighter,
  '$badge-primary-bg': colors.primary.base,
  '$badge-success-bg': colors.green.lighter,
  '$badge-info-bg': colors.primary.lightest,
  '$badge-warning-bg': colors.yellow.lighter,
  '$badge-danger-bg': colors.red.lighter,
  '$badge-color': colors.black.base,
  // '$badge-link-hover-color': string,
  '$badge-font-size': `0.75rem`,
  // '$badge-font-weight': '400',
  // '$badge-padding-x': string,
  // '$badge-padding-y': string,
  // '$badge-pill-padding-x': string,
  '$badge-pill-border-radius': '14px'
  // '$link-hover-decoration': string,
};
