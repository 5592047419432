export default {
  en: {
    forgot_password: {
      heading_main: 'Reset Password',
      heading_sub: 'Reset the password for your SR SUNTOUR Warranty account',
      success: {
        email_sent_heading: 'Reset Email Sent',
        email_sent_msg:
          "If there's an SR SUNTOUR account associated with {{ address }}, you will receive an email with instructions for how to reset your password",
        email_sent_msg_alt:
          'You will receive an email with instructions for how to reset your password'
      },
      error: {
        404: "We don't have an account matching that email address in our system."
      },
      buttons: {
        submit: 'Submit',
        sign_in: 'Back to Sign In'
      }
    }
  },
  de: {
    forgot_password: {
      heading_main: 'Passwort zurücksetzen',
      heading_sub:
        'Setzen Sie das Passwort für Ihr SR SUNTOUR Garantiekonto zurück',
      success: {
        email_sent_heading: 'E-Mail gesendet zurücksetzen',
        email_sent_msg:
          'Wenn mit {{ address }} ein SR SUNTOUR-Konto verknüpft ist, erhalten Sie eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts',
        email_sent_msg_alt:
          'Sie erhalten eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts'
      },
      error: {
        404: 'Wir haben in unserem System kein Konto, das mit dieser E-Mail-Adresse übereinstimmt.'
      },
      buttons: {
        submit: 'einreichen',
        sign_in: 'Zurück zu Anmelden'
      }
    }
  },
  jp: {
    forgot_password: {
      heading_main: 'パスワードを再設定する',
      heading_sub: 'SRSUNTOUR保証アカウントのパスワードをリセットします',
      success: {
        email_sent_heading: '送信された電子メールをリセット',
        email_sent_msg:
          '{{address}}に関連付けられたSRSUNTOURアカウントがある場合は、パスワードをリセットする方法が記載されたメールが届きます',
        email_sent_msg_alt:
          'パスワードをリセットする方法が記載されたメールが届きます'
      },
      error: {
        404: 'システムにそのメールアドレスと一致するアカウントがありません。'
      },
      buttons: {
        submit: '送信',
        sign_in: 'サインインに戻る'
      }
    }
  },
  it: {
    forgot_password: {
      heading_main: 'Resetta la password',
      heading_sub:
        'Reimposta la password per il tuo account di garanzia SR SUNTOUR',
      success: {
        email_sent_heading: 'Reimposta e-mail inviata',
        email_sent_msg:
          "Se è presente un account SR SUNTOUR associato a {{ address }}, riceverai un'email con le istruzioni su come reimpostare la password",
        email_sent_msg_alt:
          "Riceverai un'e-mail con le istruzioni su come reimpostare la password"
      },
      error: {
        404: "Non abbiamo un account che corrisponda a quell'indirizzo email nel nostro sistema."
      },
      buttons: {
        submit: 'Invia',
        sign_in: 'Torna a Accedi'
      }
    }
  }
};
