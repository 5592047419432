/* eslint-disable prettier/prettier */
export const CREATE_USER = "CREATE_USER";
export const LOCAL_LOGIN = "LOCAL_LOGIN";
export const LOGOUT = "LOGOUT";

export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const SEND_PASSWORD_RESET_LOADING = "SEND_PASSWORD_RESET_LOADING";
export const SEND_PASSWORD_RESET_FAILURE = "SEND_PASSWORD_RESET_FAILURE";
export const SEND_PASSWORD_RESET_SUCCESS = "SEND_PASSWORD_RESET_SUCCESS";

export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const SIGN_UP_LOADING = "SIGN_UP_LOADING";
export const RESEND_VERIFYCODE_LOADING = "RESEND_VERIFYCODE_LOADING";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";
export const RESEND_VERIFYCODE_FAILURE = "RESEND_VERIFYCODE_FAILURE";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const RESEND_VERIFYCODE_SUCCESS = "RESEND_VERIFYCODE_SUCCESS";

export const FINISH_ACCOUNT_SETUP_LOADING = "FINISH_ACCOUNT_SETUP_LOADING";
export const FINISH_ACCOUNT_SETUP_FAILURE = "FINISH_ACCOUNT_SETUP_FAILURE";
export const FINISH_ACCOUNT_SETUP_SUCCESS = "FINISH_ACCOUNT_SETUP_SUCCESS";

export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const ACCEPT_INVITE_LOADING = "ACCEPT_INVITE_LOADING";
export const ACCEPT_INVITE_SUCCESS = "ACCEPT_INVITE_SUCCESS";
export const ACCEPT_INVITE_ERROR = "ACCEPT_INVITE_ERROR";
export const ACCEPT_INVITE_RESET = "ACCEPT_INVITE_RESET";

export const DECLINE_INVITE_LOADING = "DECLINE_INVITE_LOADING";
export const DECLINE_INVITE_SUCCESS = "DECLINE_INVITE_SUCCESS";
export const DECLINE_INVITE_ERROR = "DECLINE_INVITE_ERROR";

export const SESSION_TIMEOUT_SUCCESS = "SESSION_TIMEOUT_SUCCESS";

export const ARCHIVE_USER_LOADING = "ARCHIVE_USER_LOADING";
export const ARCHIVE_USER_SUCCESS = "ARCHIVE_USER_SUCCESS";
export const ARCHIVE_USER_FAILURE = "ARCHIVE_USER_FAILURE";
