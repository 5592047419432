import {
  FETCH_BIKE_MAKES_LOADING,
  FETCH_BIKE_MAKES_ERROR,
  FETCH_BIKE_MAKES_SUCCESS
} from 'Actions/bike/types';
import {
  FETCH_DEALERSHIPS_LOADING,
  FETCH_DEALERSHIPS_ERROR,
  FETCH_DEALERSHIPS_SUCCESS,
  FETCH_DEALERSHIPS_BY_SCOPE_LOADING,
  FETCH_DEALERSHIPS_BY_SCOPE_ERROR,
  FETCH_DEALERSHIPS_BY_SCOPE_SUCCESS,
  FETCH_SCOPES_LOADING as FETCH_DEALER_SCOPES_LOADING,
  FETCH_SCOPES_ERROR as FETCH_DEALER_SCOPES_ERROR,
  FETCH_SCOPES_SUCCESS as FETCH_DEALER_SCOPES_SUCCESS,
  FETCH_DEALERSHIPS_ALL_LOADING,
  FETCH_DEALERSHIPS_ALL_ERROR,
  FETCH_DEALERSHIPS_ALL_SUCCESS,
  FETCH_DEALERSHIP_DETAIL_LOADING,
  FETCH_DEALERSHIP_DETAIL_ERROR,
  FETCH_DEALERSHIP_DETAIL_SUCCESS,
  CREATE_DEALER_LOCATION_LOADING,
  CREATE_DEALER_LOCATION_ERROR,
  CREATE_DEALER_LOCATION_SUCCESS,
  UPDATE_DEALERSHIP_LOADING,
  UPDATE_DEALERSHIP_ERROR,
  UPDATE_DEALERSHIP_SUCCESS,
  INVITE_USER_LOADING,
  INVITE_USER_ERROR,
  INVITE_USER_SUCCESS,
  INVITE_USER_RESET,
  SEARCH_DEALERS_LOADING,
  SEARCH_DEALERS_SUCCESS,
  SEARCH_DEALERS_ERROR,
  SEARCH_DEALERS_RESET
} from 'Actions/dealership/types';
import {
  SAVE_PARTS_ORDER_LOADING,
  SAVE_PARTS_ORDER_ERROR,
  SAVE_PARTS_ORDER_SUCCESS,
  SAVE_PARTS_ORDER_RESET
} from 'Actions/order/types';
import {
  FETCH_PART_BY_SERIAL_LOADING,
  FETCH_PART_BY_SERIAL_ERROR,
  FETCH_PART_BY_SERIAL_SUCCESS,
  FETCH_PART_TYPES_LOADING,
  FETCH_PART_TYPES_ERROR,
  FETCH_PART_TYPES_SUCCESS,
  FETCH_PART_MODEL_ATTRIBUTES_LOADING,
  FETCH_PART_MODEL_ATTRIBUTES_ERROR,
  FETCH_PART_MODEL_ATTRIBUTES_SUCCESS,
  FETCH_PART_TYPE_ATTRIBUTES_LOADING,
  FETCH_PART_TYPE_ATTRIBUTES_ERROR,
  FETCH_PART_TYPE_ATTRIBUTES_SUCCESS,
  FETCH_MODEL_DIAGNOSES_LOADING,
  FETCH_MODEL_DIAGNOSES_ERROR,
  FETCH_MODEL_DIAGNOSES_SUCCESS,
  FETCH_PART_TYPE_DIAGNOSES_LOADING,
  FETCH_PART_TYPE_DIAGNOSES_ERROR,
  FETCH_PART_TYPE_DIAGNOSES_SUCCESS
} from 'Actions/part/types';
import {
  CREATE_TICKET,
  CREATE_TICKET_ERROR,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_RESET,
  FETCH_TICKETS_LOADING,
  FETCH_TICKETS_ERROR,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKETS_BY_SCOPE_LOADING,
  FETCH_TICKETS_BY_SCOPE_ERROR,
  FETCH_TICKETS_BY_SCOPE_SUCCESS,
  FETCH_TICKET_DETAILS_LOADING,
  FETCH_TICKET_DETAILS_SUCCESS,
  FETCH_TICKET_DETAILS_ERROR,
  FETCH_SCOPES_LOADING as FETCH_TICKET_SCOPES_LOADING,
  FETCH_SCOPES_ERROR as FETCH_TICKET_SCOPES_ERROR,
  FETCH_SCOPES_SUCCESS as FETCH_TICKET_SCOPES_SUCCESS,
  UPDATE_TICKET_DETAILS_LOADING,
  UPDATE_TICKET_DETAILS_ERROR,
  UPDATE_TICKET_DETAILS_SUCCESS,
  RESET_UPDATE_TICKET_DETAILS,
  SEARCH_TICKETS_LOADING,
  SEARCH_TICKETS_SUCCESS,
  SEARCH_TICKETS_ERROR
} from 'Actions/ticket/types';
import {
  LOGIN_LOADING,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  SEND_PASSWORD_RESET_LOADING,
  SEND_PASSWORD_RESET_FAILURE,
  SEND_PASSWORD_RESET_SUCCESS,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  SIGN_UP_LOADING,
  SIGN_UP_FAILURE,
  SIGN_UP_SUCCESS,
  RESEND_VERIFYCODE_FAILURE, 
  RESEND_VERIFYCODE_LOADING, 
  RESEND_VERIFYCODE_SUCCESS,
  FINISH_ACCOUNT_SETUP_LOADING,
  FINISH_ACCOUNT_SETUP_FAILURE,
  FINISH_ACCOUNT_SETUP_SUCCESS,
  ACCEPT_INVITE_LOADING,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_ERROR,
  ACCEPT_INVITE_RESET,
  SESSION_TIMEOUT_SUCCESS,
} from 'Actions/user/types';
import {
  FETCH_USERS_LOADING,
  FETCH_USERS_ERROR,
  FETCH_USERS_SUCCESS,
  FETCH_SCOPES_LOADING as FETCH_USERS_SCOPES_LOADING,
  FETCH_SCOPES_ERROR as FETCH_USERS_SCOPES_ERROR,
  FETCH_SCOPES_SUCCESS as FETCH_USERS_SCOPES_SUCCESS,
  SEARCH_USERS_LOADING,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_ERROR,
  FETCH_USERS_BY_SCOPE_LOADING,
  FETCH_USERS_BY_SCOPE_SUCCESS,
  FETCH_USERS_BY_SCOPE_ERROR
} from 'Actions/users/types';

const initialState = {
  LOGIN: 'none',
  SEND_PASSWORD_RESET: 'none',
  RESET_PASSWORD: 'none',
  SIGN_UP: 'none',
  RESEND_VERIFYCODE: 'none',
  CREATE_TICKET: 'none',
  FETCH_TICKET_SCOPES: 'none',
  FETCH_TICKETS: 'none',
  FETCH_TICKETS_BY_SCOPE: 'none',
  FETCH_TICKET_DETAILS: 'none',
  FETCH_DEALERSHIPS_ALL: 'none',
  FETCH_DEALERSHIP_DETAIL: 'none',
  FINISH_ACCOUNT_SETUP: 'none',
  FETCH_PART_BY_SERIAL: 'none',
  FETCH_PART_MODEL_ATTRIBUTES: 'none',
  FETCH_BIKE_MAKES: 'none',
  FETCH_MODEL_DIAGNOSES: 'none',
  FETCH_PART_TYPE_DIAGNOSES: 'none',
  FETCH_DEALER_SCOPES: 'none',
  FETCH_DEALERSHIPS: 'none',
  FETCH_DEALERSHIPS_BY_SCOPE: 'none',
  CREATE_DEALER_LOCATION: 'none',
  UPDATE_DEALERSHIP: 'none',
  UPDATE_TICKET_DETAILS: 'none',
  INVITE_USER: 'none',
  ACCEPT_INVITE: 'none',
  SAVE_PARTS_ORDER: 'none',
  SESSION_TIMEOUT: 'none',
  SEARCH_DEALERS: 'none',
  SEARCH_TICKETS: 'none',
  FETCH_USERS: 'none',
  FETCH_USERS_SCOPES: 'none',
  SEARCH_USERS: 'none',
  FETCH_USERS_BY_SCOPE: 'none',
};

const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    /** BIKE ACTIONS */
    case FETCH_BIKE_MAKES_LOADING: {
      return {
        ...state,
        FETCH_BIKE_MAKES: 'loading'
      };
    }
    case FETCH_BIKE_MAKES_ERROR: {
      return {
        ...state,
        FETCH_BIKE_MAKES: 'failure'
      };
    }
    case FETCH_BIKE_MAKES_SUCCESS: {
      return {
        ...state,
        FETCH_BIKE_MAKES: 'success'
      };
    }

    /** DEALER ACTIONS */
    case SEARCH_DEALERS_LOADING: {
      return {
        ...state,
        SEARCH_DEALERS: 'loading'
      };
    }
    case SEARCH_DEALERS_ERROR: {
      return {
        ...state,
        SEARCH_DEALERS: 'failure'
      };
    }
    case SEARCH_DEALERS_SUCCESS: {
      return {
        ...state,
        SEARCH_DEALERS: 'success'
      };
    }
    case SEARCH_DEALERS_RESET: {
      return {
        ...state,
        SEARCH_DEALERS: 'none'
      };
    }
    case FETCH_DEALERSHIPS_ALL_LOADING: {
      return {
        ...state,
        FETCH_DEALERSHIPS_ALL: 'loading'
      };
    }
    case FETCH_DEALERSHIPS_ALL_ERROR: {
      return {
        ...state,
        FETCH_DEALERSHIPS_ALL: 'failure'
      };
    }
    case FETCH_DEALERSHIPS_ALL_SUCCESS: {
      return {
        ...state,
        FETCH_DEALERSHIPS_ALL: 'success'
      };
    }
    case FETCH_DEALERSHIP_DETAIL_LOADING: {
      return {
        ...state,
        FETCH_DEALERSHIP_DETAIL: 'loading'
      };
    }
    case FETCH_DEALERSHIP_DETAIL_ERROR: {
      return {
        ...state,
        FETCH_DEALERSHIP_DETAIL: 'failure'
      };
    }
    case FETCH_DEALERSHIP_DETAIL_SUCCESS: {
      return {
        ...state,
        FETCH_DEALERSHIP_DETAIL: 'success'
      };
    }
    case FETCH_DEALER_SCOPES_LOADING: {
      return {
        ...state,
        FETCH_DEALER_SCOPES: 'loading'
      };
    }
    case FETCH_DEALER_SCOPES_ERROR: {
      return {
        ...state,
        FETCH_DEALER_SCOPES: 'failure'
      };
    }
    case FETCH_DEALER_SCOPES_SUCCESS: {
      return {
        ...state,
        FETCH_DEALER_SCOPES: 'success'
      };
    }
    case FETCH_DEALERSHIPS_LOADING: {
      return {
        ...state,
        FETCH_DEALERSHIPS: 'loading'
      };
    }
    case FETCH_DEALERSHIPS_ERROR: {
      return {
        ...state,
        FETCH_DEALERSHIPS: 'failure'
      };
    }
    case FETCH_DEALERSHIPS_SUCCESS: {
      return {
        ...state,
        FETCH_DEALERSHIPS: 'success'
      };
    }
    case FETCH_DEALERSHIPS_BY_SCOPE_LOADING: {
      return {
        ...state,
        FETCH_DEALERSHIPS_BY_SCOPE: 'loading'
      };
    }
    case FETCH_DEALERSHIPS_BY_SCOPE_ERROR: {
      return {
        ...state,
        FETCH_DEALERSHIPS_BY_SCOPE: 'failure'
      };
    }
    case FETCH_DEALERSHIPS_BY_SCOPE_SUCCESS: {
      return {
        ...state,
        FETCH_DEALERSHIPS_BY_SCOPE: 'success'
      };
    }
    case CREATE_DEALER_LOCATION_LOADING: {
      return {
        ...state,
        CREATE_DEALER_LOCATION: 'loading'
      };
    }
    case CREATE_DEALER_LOCATION_ERROR: {
      return {
        ...state,
        CREATE_DEALER_LOCATION: 'failure'
      };
    }
    case CREATE_DEALER_LOCATION_SUCCESS: {
      return {
        ...state,
        CREATE_DEALER_LOCATION: 'success'
      };
    }
    case UPDATE_DEALERSHIP_LOADING: {
      return {
        ...state,
        UPDATE_DEALERSHIP: 'loading'
      };
    }
    case UPDATE_DEALERSHIP_ERROR: {
      return {
        ...state,
        UPDATE_DEALERSHIP: 'failure'
      };
    }
    case UPDATE_DEALERSHIP_SUCCESS: {
      return {
        ...state,
        UPDATE_DEALERSHIP: 'success'
      };
    }
    case INVITE_USER_LOADING: {
      return {
        ...state,
        INVITE_USER: 'loading'
      };
    }
    case INVITE_USER_ERROR: {
      return {
        ...state,
        INVITE_USER: 'failure'
      };
    }
    case INVITE_USER_SUCCESS: {
      return {
        ...state,
        INVITE_USER: 'success'
      };
    }
    case INVITE_USER_RESET: {
      return {
        ...state,
        INVITE_USER: 'none'
      };
    }

    /** ORDER ACTIONS */
    case SAVE_PARTS_ORDER_LOADING: {
      return {
        ...state,
        SAVE_PARTS_ORDER: 'loading'
      };
    }
    case SAVE_PARTS_ORDER_ERROR: {
      return {
        ...state,
        SAVE_PARTS_ORDER: 'failure'
      };
    }
    case SAVE_PARTS_ORDER_SUCCESS: {
      return {
        ...state,
        SAVE_PARTS_ORDER: 'success'
      };
    }
    case SAVE_PARTS_ORDER_RESET: {
      return {
        ...state,
        SAVE_PARTS_ORDER: 'none'
      };
    }

    /** PART ACTIONS */
    case FETCH_PART_BY_SERIAL_LOADING: {
      return {
        ...state,
        FETCH_PART_BY_SERIAL: 'loading'
      };
    }
    case FETCH_PART_BY_SERIAL_ERROR: {
      return {
        ...state,
        FETCH_PART_BY_SERIAL: 'failure'
      };
    }
    case FETCH_PART_BY_SERIAL_SUCCESS: {
      return {
        ...state,
        FETCH_PART_BY_SERIAL: 'success'
      };
    }
    case FETCH_PART_TYPES_LOADING: {
      return {
        ...state,
        FETCH_PART_TYPES: 'loading'
      };
    }
    case FETCH_PART_TYPES_ERROR: {
      return {
        ...state,
        FETCH_PART_TYPES: 'failure'
      };
    }
    case FETCH_PART_TYPES_SUCCESS: {
      return {
        ...state,
        FETCH_PART_TYPES: 'success'
      };
    }
    case FETCH_PART_MODEL_ATTRIBUTES_LOADING: {
      return {
        ...state,
        FETCH_PART_MODEL_ATTRIBUTES: 'loading'
      };
    }
    case FETCH_PART_MODEL_ATTRIBUTES_ERROR: {
      return {
        ...state,
        FETCH_PART_MODEL_ATTRIBUTES: 'failure'
      };
    }
    case FETCH_PART_MODEL_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        FETCH_PART_MODEL_ATTRIBUTES: 'success'
      };
    }
    case FETCH_PART_TYPE_ATTRIBUTES_LOADING: {
      return {
        ...state,
        FETCH_PART_TYPE_ATTRIBUTES: 'loading'
      };
    }
    case FETCH_PART_TYPE_ATTRIBUTES_ERROR: {
      return {
        ...state,
        FETCH_PART_TYPE_ATTRIBUTES: 'failure'
      };
    }
    case FETCH_PART_TYPE_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        FETCH_PART_TYPE_ATTRIBUTES: 'success'
      };
    }
    case FETCH_MODEL_DIAGNOSES_LOADING: {
      return {
        ...state,
        FETCH_MODEL_DIAGNOSES: 'loading'
      };
    }
    case FETCH_MODEL_DIAGNOSES_ERROR: {
      return {
        ...state,
        FETCH_MODEL_DIAGNOSES: 'failure'
      };
    }
    case FETCH_MODEL_DIAGNOSES_SUCCESS: {
      return {
        ...state,
        FETCH_MODEL_DIAGNOSES: 'success'
      };
    }
    case FETCH_PART_TYPE_DIAGNOSES_LOADING: {
      return {
        ...state,
        FETCH_PART_TYPE_DIAGNOSES: 'loading'
      };
    }
    case FETCH_PART_TYPE_DIAGNOSES_ERROR: {
      return {
        ...state,
        FETCH_PART_TYPE_DIAGNOSES: 'failure'
      };
    }
    case FETCH_PART_TYPE_DIAGNOSES_SUCCESS: {
      return {
        ...state,
        FETCH_PART_TYPE_DIAGNOSES: 'success'
      };
    }

    /** TICKET ACTIONS */
    case CREATE_TICKET: {
      return {
        ...state,
        CREATE_TICKET: 'loading'
      };
    }
    case CREATE_TICKET_ERROR: {
      return {
        ...state,
        CREATE_TICKET: 'failure'
      };
    }
    case CREATE_TICKET_SUCCESS: {
      return {
        ...state,
        CREATE_TICKET: 'success'
      };
    }
    case CREATE_TICKET_RESET: {
      return {
        ...state,
        CREATE_TICKET: 'none'
      };
    }
    case FETCH_TICKET_SCOPES_LOADING: {
      return {
        ...state,
        FETCH_TICKET_SCOPES: 'loading'
      };
    }
    case FETCH_TICKET_SCOPES_ERROR: {
      return {
        ...state,
        FETCH_TICKET_SCOPES: 'failure'
      };
    }
    case FETCH_TICKET_SCOPES_SUCCESS: {
      return {
        ...state,
        FETCH_TICKET_SCOPES: 'success'
      };
    }
    case FETCH_TICKETS_LOADING: {
      return {
        ...state,
        FETCH_TICKETS: 'loading'
      };
    }
    case FETCH_TICKETS_ERROR: {
      return {
        ...state,
        FETCH_TICKETS: 'failure'
      };
    }
    case FETCH_TICKETS_SUCCESS: {
      return {
        ...state,
        FETCH_TICKETS: 'success'
      };
    }
    case SEARCH_TICKETS_LOADING: {
      return {
        ...state,
        SEARCH_TICKETS: 'loading'
      };
    }
    case SEARCH_TICKETS_ERROR: {
      return {
        ...state,
        SEARCH_TICKETS: 'failure'
      };
    }
    case SEARCH_TICKETS_SUCCESS: {
      return {
        ...state,
        SEARCH_TICKETS: 'success'
      };
    }
    case FETCH_TICKET_DETAILS_LOADING: {
      return {
        ...state,
        FETCH_TICKET_DETAILS: 'loading'
      };
    }
    case FETCH_TICKET_DETAILS_ERROR: {
      return {
        ...state,
        FETCH_TICKET_DETAILS: 'failure'
      };
    }
    case FETCH_TICKET_DETAILS_SUCCESS: {
      return {
        ...state,
        FETCH_TICKET_DETAILS: 'success'
      };
    }
    case FETCH_TICKETS_BY_SCOPE_LOADING: {
      return {
        ...state,
        FETCH_TICKETS_BY_SCOPE: 'loading'
      };
    }
    case FETCH_TICKETS_BY_SCOPE_ERROR: {
      return {
        ...state,
        FETCH_TICKETS_BY_SCOPE: 'failure'
      };
    }
    case FETCH_TICKETS_BY_SCOPE_SUCCESS: {
      return {
        ...state,
        FETCH_TICKETS_BY_SCOPE: 'success'
      };
    }
    case RESET_UPDATE_TICKET_DETAILS: {
      return {
        ...state,
        UPDATE_TICKET_DETAILS: 'none'
      };
    }

    /** USER ACTIONS */
    case LOGIN_LOADING: {
      return {
        ...state,
        LOGIN: 'loading'
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        LOGIN: 'failure'
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        LOGIN: 'success',
        SESSION_TIMEOUT: 'false'
      };
    }
    case SEND_PASSWORD_RESET_LOADING: {
      return {
        ...state,
        SEND_PASSWORD_RESET: 'loading'
      };
    }
    case SEND_PASSWORD_RESET_FAILURE: {
      return {
        ...state,
        SEND_PASSWORD_RESET: 'failure'
      };
    }
    case SEND_PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        SEND_PASSWORD_RESET: 'success'
      };
    }
    case RESET_PASSWORD_LOADING: {
      return {
        ...state,
        RESET_PASSWORD: 'loading'
      };
    }
    case RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        RESET_PASSWORD: 'failure'
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        RESET_PASSWORD: 'success'
      };
    }
    case SIGN_UP_LOADING: {
      return {
        ...state,
        SIGN_UP: 'loading'
      };
    }
    case SIGN_UP_FAILURE: {
      return {
        ...state,
        SIGN_UP: 'failure'
      };
    }
    case SIGN_UP_SUCCESS: {
      return {
        ...state,
        SIGN_UP: 'success'
      };
    }
    case RESEND_VERIFYCODE_LOADING: {
      return {
        ...state,
        RESEND_VERIFYCODE: 'loading'
      };
    }
    case RESEND_VERIFYCODE_FAILURE: {
      return {
        ...state,
        RESEND_VERIFYCODE: 'failure'
      };
    }
    case RESEND_VERIFYCODE_SUCCESS: {
      return {
        ...state,
        RESEND_VERIFYCODE: 'success'
      };
    }
    case FINISH_ACCOUNT_SETUP_LOADING: {
      return {
        ...state,
        FINISH_ACCOUNT_SETUP: 'loading'
      };
    }
    case FINISH_ACCOUNT_SETUP_FAILURE: {
      return {
        ...state,
        FINISH_ACCOUNT_SETUP: 'failure'
      };
    }
    case FINISH_ACCOUNT_SETUP_SUCCESS: {
      return {
        ...state,
        FINISH_ACCOUNT_SETUP: 'success'
      };
    }
    case UPDATE_TICKET_DETAILS_LOADING: {
      return {
        ...state,
        UPDATE_TICKET_DETAILS: 'loading'
      };
    }
    case UPDATE_TICKET_DETAILS_ERROR: {
      return {
        ...state,
        UPDATE_TICKET_DETAILS: 'failure'
      };
    }
    case UPDATE_TICKET_DETAILS_SUCCESS: {
      return {
        ...state,
        UPDATE_TICKET_DETAILS: 'success'
      };
    }
    case ACCEPT_INVITE_LOADING: {
      return {
        ...state,
        ACCEPT_INVITE: 'loading'
      };
    }
    case ACCEPT_INVITE_ERROR: {
      return {
        ...state,
        ACCEPT_INVITE: 'failure'
      };
    }
    case ACCEPT_INVITE_SUCCESS: {
      return {
        ...state,
        ACCEPT_INVITE: 'success'
      };
    }
    case ACCEPT_INVITE_RESET: {
      return {
        ...state,
        ACCEPT_INVITE: 'none'
      };
    }
    case SESSION_TIMEOUT_SUCCESS: {
      return {
        ...state,
        SESSION_TIMEOUT: 'true'
      };
    }
    case FETCH_USERS_LOADING: {
      return {
        ...state,
        FETCH_USERS: 'loading'
      };
    }
    case FETCH_USERS_ERROR: {
      return {
        ...state,
        FETCH_USERS: 'failure'
      };
    }
    case FETCH_USERS_SUCCESS: {
      return {
        ...state,
        FETCH_USERS: 'success'
      };
    }

    case FETCH_USERS_SCOPES_LOADING: {
      return {
        ...state,
        FETCH_USERS_SCOPES: 'loading'
      };
    }
    case FETCH_USERS_SCOPES_ERROR: {
      return {
        ...state,
        FETCH_USERS_SCOPES: 'failure'
      };
    }
    case FETCH_USERS_SCOPES_SUCCESS: {
      return {
        ...state,
        FETCH_USERS_SCOPES: 'success'
      };
    }
    case SEARCH_USERS_LOADING: {
      return {
        ...state,
        SEARCH_USERS: 'loading'
      };
    }
    case SEARCH_USERS_ERROR: {
      return {
        ...state,
        SEARCH_USERS: 'failure'
      };
    }
    case SEARCH_USERS_SUCCESS: {
      return {
        ...state,
        SEARCH_USERS: 'success'
      };
    }
    case FETCH_USERS_BY_SCOPE_LOADING: {
      return {
        ...state,
        FETCH_USERS_BY_SCOPE: 'loading'
      };
    }
    case FETCH_USERS_BY_SCOPE_ERROR: {
      return {
        ...state,
        FETCH_USERS_BY_SCOPE: 'failure'
      };
    }
    case FETCH_USERS_BY_SCOPE_SUCCESS: {
      return {
        ...state,
        FETCH_USERS_BY_SCOPE: 'success'
      };
    }

    default: {
      return state;
    }
  }
};

export default statusReducer;
