import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { colors, space, typography } from 'Theme';

const TabbedContent = ({ children }) => {
  const numChildren = children.length;
  return (
    <TabbedContentWrapper>
      <Tabs defaultIndex={0}>
        {/* Since all content must have a tab, split children in half and make the tabs the first half */}
        <StyledTabList>
          {React.Children.map(children, (child, i) =>
            i < numChildren / 2 ? <StyledTab> {child} </StyledTab> : null
          )}
        </StyledTabList>
        {/* And the actual content the second half */}
        {React.Children.map(children, (child, i) => {
          return i >= numChildren / 2 ? (
            <StyledPanel> {child} </StyledPanel>
          ) : null;
        })}
      </Tabs>
    </TabbedContentWrapper>
  );
};

const { fontSizes } = typography;
const TabbedContentWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const StyledTabList = styled(TabList)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 40px;
  box-shadow: 0px -2px 0px 0px ${colors.gray.base} inset;
  list-style-type: none;
  li h6 {
    margin-right: 0;
  }
`;
StyledTabList.tabsRole = 'TabList';

const StyledTab = styled(Tab)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${space[2]}rem;
  height: 100%;
  border-radius: 0;
  background-color: ${colors.gray.base};
  > *:first-child {
    font-style: normal;
    font-size: ${fontSizes[0]}rem;
    margin-right: 0;
  }
  &:hover {
    cursor: pointer;
  }
  &.react-tabs__tab--selected {
    border: 2px solid ${colors.gray.base};
    border-bottom: none;
    background-color: ${colors.gray.lightest};
  }
`;
StyledTab.tabsRole = 'Tab';

const StyledPanel = styled(TabPanel)`
  &.react-tabs__tab-panel--selected {
    padding: ${space[3]}rem 0;
  }
`;
StyledPanel.tabsRole = 'TabPanel';

TabbedContent.propTypes = {
  children: PropTypes.node.isRequired
};

TabbedContent.defaultProps = {};

export default TabbedContent;
