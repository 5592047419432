export default {
  en: {
    footer: {
      copyright: 'Copyright',
      all_rights: 'All Rights Reserved',
      privacy: 'Privacy'
    }
  },
  de: {
    footer: {
      copyright: 'Urheberrechte',
      all_rights: 'Alle Rechte vorbehalten',
      privacy: 'Privatsphäre'
    }
  },
  jp: {
    footer: {
      copyright: '著作権',
      all_rights: '全著作権所有',
      privacy: 'プライバシー'
    }
  },
  it: {
    footer: {
      copyright: "Diritto d'autore",
      all_rights: 'Tutti i diritti riservati',
      privacy: 'Privacy'
    }
  }
};
