import { api, retryApiCall } from '../api';
import {
  FETCH_REPORT_INDEX_LOADING,
  FETCH_REPORT_INDEX_SUCCESS,
  FETCH_REPORT_INDEX_ERROR,
  RUN_REPORT_LOADING,
  RUN_REPORT_SUCCESS,
  RUN_REPORT_ERROR
} from './types';

export const ticketCallTypes = {};
const apiCall = retryApiCall.use(ticketCallTypes);

// Fetch All Tickets
export const fetchReportIndexLoading = data => ({
  type: FETCH_REPORT_INDEX_LOADING,
  payload: data
});

export const fetchReportIndexFailure = data => ({
  type: FETCH_REPORT_INDEX_ERROR,
  payload: data
});

export const fetchReportIndexSuccess = data => ({
  type: FETCH_REPORT_INDEX_SUCCESS,
  payload: data
});

export const fetchReportIndex = data => dispatch => {
  dispatch(apiCall(fetchReportIndexLoading, fetchReportIndex, data));
  const endpoint = `/reports`;
  return api
    .get(endpoint, {
      ...data
    })
    .then(res => {
      dispatch(fetchReportIndexSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(fetchReportIndexFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

export const runReportLoading = data => ({
  type: RUN_REPORT_LOADING,
  payload: data
});

export const runReportFailure = data => ({
  type: RUN_REPORT_ERROR,
  payload: data
});

export const runReportSuccess = data => ({
  type: RUN_REPORT_SUCCESS,
  payload: data
});

export const runReport = data => dispatch => {
  dispatch(apiCall(runReportLoading, runReport, data));
  return api
    .post(`/export/claims`, {
      ...data
    })
    .then(res => {
      dispatch(runReportSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(runReportFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};
