import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link as NavLink } from 'react-router-dom';
import BootstrapLink from '@bootstrap-styled/v4/lib/A';
import { makeTheme } from 'bootstrap-styled/lib/theme';
import { bootstrapTheme, typography, colors } from 'Theme';

const Link = ({
  href,
  theme,
  children,
  isButton,
  buttonStyle,
  external,
  width,
  onClickFunc,
  className
}) => {
  if (external) {
    return (
      <LinkWrapper
        className={className}
        isButton={isButton}
        buttonStyle={buttonStyle}
        width={width}
      >
        <BootstrapLink href={href} theme={theme} onClick={onClickFunc}>
          {children}
        </BootstrapLink>
      </LinkWrapper>
    );
  }
  return (
    <LinkWrapper
      className={className}
      isButton={isButton}
      buttonStyle={buttonStyle}
      width={width}
    >
      <NavLink to={href} onClick={onClickFunc}>
        {children}
      </NavLink>
    </LinkWrapper>
  );
};

Link.propTypes = {
  theme: PropTypes.shape(),
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  external: PropTypes.bool,
  isButton: PropTypes.bool,
  buttonStyle: PropTypes.oneOf([
    'primary',
    'secondary',
    'link',
    'outline-secondary',
    'outline-white'
  ]),
  width: PropTypes.string,
  onClickFunc: PropTypes.func
};

Link.defaultProps = {
  theme: makeTheme(bootstrapTheme),
  href: '',
  external: false,
  isButton: false,
  buttonStyle: 'primary',
  width: 'initial',
  onClickFunc: () => {}
};

const { fonts, fontSizes } = typography;

const LinkWrapper = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width};
  font-family: ${fonts.sansSerif};
  font-size: ${fontSizes[1]}rem;
  font-stretch: condensed;
  font-weight: normal;
  line-height: 1.5;
  > a {
    text-decoration: none;
    color: ${colors.primary.base};
    &:hover {
      color: ${colors.primary.darker};
      text-decoration: none;
    }
    &:active {
      color: ${colors.primary.darkest};
      text-decoration: none;
    }
  }
  ${props =>
    props.color &&
    css`
      > a {
        color: ${props.color};
      }
    `}
  ${props =>
    props.isButton &&
    css`
      > a {
        width: inherit;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        font-size: 0.9rem;
        line-height: 1;
        padding: 0.8125rem;
        transition: all 0.2s ease-in-out;
        border: 1px solid;
        &:focus {
          box-shadow: 0 0 0 2px ${colors.primary.lightest};
        }
        ${props.buttonStyle === 'primary' &&
          css`
            color: ${colors.white.base};
            background-color: ${colors.primary.base};
            border-color: ${colors.primary.base};
            &:hover {
              background-color: ${colors.primary.darker};
              border-color: ${colors.primary.darker};
            }
            &:active {
              background-color: ${colors.primary.darkest};
              border-color: ${colors.primary.darkest};
            }
          `}
        ${props.buttonStyle === 'secondary' &&
          css`
            color: ${colors.white.base};
            background-color: ${colors.secondary.base};
            border-color: ${colors.secondary.base};
          `}
        &:hover {
          ${(props.buttonStyle === 'secondary' ||
            props.buttonStyle === 'outline-white' ||
            props.buttonStyle === 'link') &&
            css`
              border-color: ${colors.secondary.darker};
              background-color: ${colors.secondary.darker};
              text-decoration: none;
              color: ${colors.white.base};
            `}
        }
        &:active {
          ${(props.buttonStyle === 'secondary' ||
            props.buttonStyle === 'outline-secondary' ||
            props.buttonStyle === 'outline-white') &&
            css`
              border-color: ${colors.secondary.darkest};
              background-color: ${colors.secondary.darkest};
            `}
        }
      }
    `};
`;

export default Link;
