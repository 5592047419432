import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { dumpParts, updatePart, deletePart } from "Actions/part";
import PartTable from "./PartTable";
import styled from "styled-components";
import PartTypeAttributeTable from "./PartTypeAttributeTable";
import PartModelTable from "./PartModelTable";
import ResourcePopOver from "./ResourcePopOver";
import { Button, Input } from "Components";
import * as modalTypes from "Components/Modal/types";
import { showModal } from "Actions/modal";

const PartsManager = (props) => {
  const [loadingParts, setLoadingParts] = useState(true);
  const [attributes, setAttributes] = useState([]);
  const [partTypes, setPartTypes] = useState([]);
  // If set to null, no part is being edited, otherwise it is the part ID being edited
  const [isEditingPartID, setEditingPartID] = useState(null);
  const [editedPartName, setEditedPartName] = useState("");
  const { t } = props;
  // This hits the dumpParts endpoint when the component mounts
  useEffect(() => {
    props
      .dumpParts()
      .then((data) => {
        const partTypesSorted = data.partTypes.toSorted(
          (a, b) => b.display_order - a.display_order
        );
        const attributesSorted = data.attributes.toSorted(
          (a, b) => b.display_order - a.display_order
        );
        setAttributes(attributesSorted);
        setPartTypes(partTypesSorted);
      })
      .finally(() => setLoadingParts(false));
    () => {
      if (attributes) {
        setAttributes([]);
      }
    };
  }, []);
  const handleEditPart = (partID) => {
    // First set the part name to the current part name
    const part = partTypes.find((part) => part.id === partID);
    setEditedPartName(part.name);
    // Then set the part ID to the part ID being edited, enabling the input field
    setEditingPartID(partID);
  };

  const handleSavePart = (partID, updatedName) => {
    // First find the part being edited
    const part = partTypes.find((part) => part.id === partID);
    // If the part name is the same as the updated name, do nothing
    if (part.name === updatedName) {
      setEditingPartID(null);
      return;
    }
    // Otherwise, update the part name
    const updatedPart = {
      ...part,
      name: updatedName,
    };
    // Dispatch the updatePart action
    props
      .savePart(updatedPart)
      .then(() => {
        // Update the partTypes array with the updated part
        const updatedPartTypes = partTypes.map((part) =>
          part.id === partID ? updatedPart : part
        );
        setPartTypes(updatedPartTypes);
      })
      .catch((err) => {
        console.error("Could not update part\n", err);
      })
      .finally(() => {
        // Reset the editing part ID
        setEditingPartID(null);
        window.location.reload();
      });
  };

  const handleDeletePart = (partID) => {
    // Dispatch the deletePart action
    props
      .deletePart({ id: partID })
      .catch((err) => {
        console.error("Could not delete part\n", err);
      })
      .finally(() => {
        window.location.reload();
      });
  };

  const handleUnarchivePart = (partID) => {
    const fullPart = partTypes.find((part) => part.id === partID);
    // Dispatch an updatePart action with the active field set to true
    props
      .savePart({ id: partID, ...fullPart, setActive: true })
      .then(() => {
        // Update the partTypes array with the updated part
        const updatedPartTypes = partTypes.map((part) =>
          part.id === partID ? { ...part, active: 1 } : part
        );
        setPartTypes(updatedPartTypes);
      })
      .catch((err) => {
        console.error("Could not unarchive part\n", err);
      })
      .finally(() => {
        window.location.reload();
      });
  };

  const addPartModal = () => {
    const { showModal } = props;
    const modalData = {
      type: modalTypes.FORM_ADD_PART_TYPE,
      title: t("modal.title.add_part_type"),
    };
    showModal(modalData);
  };

  const partTypeColumns = [
    {
      id: "id",
      name: t("table.id"),
      selector: "id",
      grow: 1,
      cell: (row) => row.id,
    },
    {
      id: "name",
      name: t("table.name"),
      selector: "name",
      grow: 2,
      cell: (row) =>
        isEditingPartID === row.id ? (
          <StyledInput
            type="text"
            value={editedPartName}
            onChangeFunc={(e) => setEditedPartName(e.currentTarget.value)}
          />
        ) : (
          row.name
        ),
    },
    {
      id: "active",
      name: t("table.status"),
      selector: "active",
      grow: 1,
      cell: (row) =>
        row.active ? t("data.status.active") : t("data.status.archived"),
    },
    {
      id: "actions",
      name: "Actions",
      selector: "actions",
      compact: true,
      cell: (row) =>
        isEditingPartID === row.id ? (
          <SaveButton
            onClickFunc={() => handleSavePart(isEditingPartID, editedPartName)}
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            Save
          </SaveButton>
        ) : (
          <ResourcePopOver
            onClickEdit={handleEditPart}
            onClickDelete={handleDeletePart}
            onClickUnarchive={handleUnarchivePart}
            resourceID={row.id}
          />
        ),
    },
  ];

  const ExpandedPartTypes = ({ data }) => {
    return (
      <Fragment>
        <PartTypeAttributeTable data={data} />
        <PartModelTable data={data} />
      </Fragment>
    );
  };

  return (
    <StyledContainer>
      <PartTable
        data={partTypes}
        columns={partTypeColumns}
        expandedComponent={ExpandedPartTypes}
        button={{
          text: "Add Part Type",
          onClick: addPartModal,
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  .rdt_Pagination {
    display: none;
  }
`;

const SaveButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyledInput = styled(Input)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const mapStateToProps = (state) => ({
  attributes: state.part.attributes,
});

const mapDispatchToProps = (dispatch) => ({
  dumpParts: () => dispatch(dumpParts()),
  savePart: (partData) => dispatch(updatePart("part-type", partData)),
  deletePart: (partID) => dispatch(deletePart("part-type", partID)),
  showModal: (data) => dispatch(showModal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PartsManager));
