import { SHOW_MODAL, HIDE_MODAL } from 'Actions/modal/types';

const initialState = {
  isOpen: false
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL: {
      return {
        ...state,
        isOpen: true,
        contentType: action.payload.type,
        title: action.payload.title,
        data: action.payload.data
      };
    }
    case HIDE_MODAL: {
      return {
        ...state,
        isOpen: false
      };
    }
    default:
      return {
        ...state
      };
  }
};

export default modalReducer;
