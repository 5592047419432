import React from 'react';
import PropTypes from 'prop-types';
import BootstrapHr from '@bootstrap-styled/v4/lib/Hr';
import styled from 'styled-components';
import { colors } from 'Theme';

const HR = ({ color }) => <StyledHR color={color} />;

HR.propTypes = {
  color: PropTypes.oneOf([
    'gray',
    'primary',
    'secondary',
    'black',
    'green',
    'red',
    'yellow'
  ])
};

HR.defaultProps = {
  color: 'gray'
};

const getColor = colorName => {
  return colors[colorName].base;
};

const StyledHR = styled(BootstrapHr)`
  margin-top: 0;
  margin-bottom: 0;
  border-color: ${props => getColor(props.color)};
`;

export default HR;
