export default {
  en: {
    create_ticket: {
      heading: {
        general: 'General Information',
        bike: 'Bike Information',
        part: 'Part Information',
        misc: 'Additional Information'
      },
      subheading: {
        part_details: 'Part Details',
        part_diagnosis: 'Part Diagnosis'
      },
      label: {
        dealer_name: 'Dealer Name',
        user: 'User',
        dealer_location: 'Location',
        bike_serial: 'Bike Serial Number',
        bike_make: 'Bike Make',
        bike_make_other: 'Bike Make (Other)',
        part_serial: 'Part Serial Number',
        part_type: 'Part Type',
        part_model: 'Model',
        diagnosis: 'Diagnosis',
        notes: 'Additional notes/message',
        purchase_date: 'Purchase Date',
        file_upload: 'File Upload',
        brake_type: 'Brake Type',
        chainring_size: 'Chainring Size',
        feature: 'Feature',
        color: 'Color',
        crank_arm_length: 'Crank Arm Length',
        shock_size: 'Shock Size',
        size: 'Size',
        wheel_size: 'Wheel Size',
        type_of_part: 'Type of Part'
      },
      placeholder: {
        dealer_name: 'Select Dealer Name...',
        user: 'Select User...',
        dealer_location: 'Select Location...',
        bike_make: 'Select Bike Make...',
        bike_make_other: 'Bianchi',
        part_type: 'Select Part Type...',
        part_model: 'Select Part Model...',
        diagnosis: 'Select a Diagnosis...',
        brake_type: 'Select a Brake Type...',
        chainring_size: 'Select a Chainring Size...',
        feature: 'Select a Feature...',
        color: 'Select a Color...',
        crank_arm_length: 'Select Crank Arm Length...',
        shock_size: 'Select Shock Size...',
        size: 'Select a Part Size...',
        wheel_size: 'Select Wheel Size...',
        type_of_part: 'Select the Type of Part...',
        notes: 'Add your notes/message here...',
        purchase_date: 'mm/dd/yyyy'
      },
      helper_text: {
        dealer_name: 'Dealership submitting the claim',
        user: 'User submitting the claim',
        dealer_location: 'The main location for your dealership',
        bike_serial: 'Serial number of the bicycle',
        bike_make: 'Brand/make of the bicycle',
        part_serial: 'Serial number for the specific part',
        find_serial: "What's this?",
        purchase_date: 'Original purchase date of part/bike',
        file_upload:
          'Upload up to three images. Images must be smaller than 10MB, and in JPG or PNG format.'
      },
      buttons: {
        lookup: 'Look Up',
        submit_ticket: 'Submit Ticket',
        update_ticket: 'Update Ticket',
        cancel: 'Cancel'
      },
      choose_manually: 'Choose Part Manually',
      success: 'Your ticket was created successfully',
      error: {
        400: 'There was an issue with the information you submitted. Please review your ticket details before trying again',
        404: 'No matching part/model found in our records',
        405: 'Method not allowed',
        500: 'A technical issue prevented us from creating your ticket. Our staff has been notified of the error'
      }
    }
  },
  de: {
    create_ticket: {
      heading: {
        general: 'Allgemeine Informationen',
        bike: 'Fahrradinformationen',
        part: 'Teileinformationen',
        misc: 'zusätzliche Information'
      },
      subheading: {
        part_details: 'Teiledetails',
        part_diagnosis: 'Teilediagnose'
      },
      label: {
        dealer_name: 'Name des Händlers',
        user: 'Benutzer',
        dealer_location: 'Standort',
        bike_serial: 'Seriennummer des Fahrrads',
        bike_make: 'Fahrradmarke',
        bike_make_other: 'Fahrradmarke (Andere)',
        part_serial: 'Seriennummer des Teils',
        part_type: 'Teil typ',
        part_model: 'Modell',
        diagnosis: 'Diagnose',
        notes: 'Zusätzliche Hinweise/Nachricht',
        purchase_date: 'Kaufdatum',
        file_upload: 'Datei-Upload',
        brake_type: 'Bremstyp',
        chainring_size: 'Kettenblattgröße',
        feature: 'Feature',
        color: 'Farbe',
        crank_arm_length: 'Kurbelarmlänge',
        shock_size: 'Schockgröße',
        size: 'Größe',
        wheel_size: 'Radgröße',
        type_of_part: 'Art des Teils'
      },
      placeholder: {
        dealer_name: 'Händlername auswählen...',
        user: 'Wähle den Benutzer..',
        dealer_location: 'Ort auswählen...',
        bike_make: 'Fahrradmarke auswählen...',
        bike_make_other: 'Bianchi',
        part_type: 'Teiletyp auswählen...',
        part_model: 'Teilemodell auswählen...',
        diagnosis: 'Diagnose auswählen...',
        brake_type: 'Wählen Sie einen Bremstyp...',
        chainring_size: 'Wählen Sie eine Kettenblattgröße...',
        feature: 'Wählen Sie eine Funktion aus...',
        color: 'Wähle eine Farbe...',
        crank_arm_length: 'Kurbelarmlänge auswählen...',
        shock_size: 'Stoßdämpfergröße auswählen...',
        size: 'Wählen Sie eine Teilegröße...',
        wheel_size: 'Laufradgröße auswählen...',
        type_of_part: 'Wählen Sie die Art des Teils...',
        notes: 'Fügen Sie hier Ihre Notizen/Nachricht hinzu...',
        purchase_date: 'mm/dd/yyyy'
      },
      helper_text: {
        dealer_name: 'Händler, der den Anspruch einreicht',
        user: 'Benutzer, der den Anspruch einreicht',
        dealer_location: 'Der Hauptstandort für Ihr Autohaus',
        bike_serial: 'Seriennummer des Fahrrads',
        bike_make: 'Marke/Marke des Fahrrads',
        part_serial: 'Seriennummer für das spezifische Teil',
        find_serial: 'Was ist das?',
        purchase_date: 'Ursprüngliches Kaufdatum des Teils/Fahrrads',
        file_upload:
          'Laden Sie bis zu drei Bilder hoch. Bilder müssen kleiner als 5 MB und im JPG- oder PNG-Format sein.'
      },
      buttons: {
        lookup: 'Nachschlagen',
        submit_ticket: 'Ticket übermitteln',
        update_ticket: 'Ticket aktualisieren',
        cancel: 'Stornieren'
      },
      choose_manually: 'Wählen Sie Teil manuell',
      success: 'Ihr Ticket wurde erfolgreich erstellt',
      error: {
        400: 'Es gab ein Problem mit den von Ihnen übermittelten Informationen. Bitte überprüfen Sie Ihre Ticketdetails, bevor Sie es erneut versuchen',
        404: 'Kein passendes Teil/Modell in unseren Unterlagen gefunden',
        405: 'Methode nicht erlaubt',
        500: 'Ein technisches Problem hat uns daran gehindert, Ihr Ticket zu erstellen. Unsere Mitarbeiter wurden über den Fehler informiert'
      }
    }
  },
  jp: {
    create_ticket: {
      heading: {
        general: '一般情報',
        bike: '自転車情報',
        part: '部品情報',
        misc: '追加情報'
      },
      subheading: {
        part_details: '部品の詳細',
        part_diagnosis: '部品診断'
      },
      label: {
        dealer_name: '販売業者名',
        user: 'ユーザー',
        dealer_location: '位置',
        bike_serial: '自転車のシリアル番号',
        bike_make: 'バイクメイク',
        bike_make_other: 'バイクメーカー（その他）',
        part_serial: '部品のシリアル番号',
        part_type: 'パーツタイプ',
        part_model: 'モデル',
        diagnosis: '診断',
        notes: '追加のメモ/メッセージ',
        purchase_date: '購入日',
        file_upload: 'ファイルのアップロード',
        brake_type: 'ブレーキタイプ',
        chainring_size: 'チェーンリングサイズ',
        feature: '特徴',
        color: '色',
        crank_arm_length: 'クランクアームの長さ',
        shock_size: 'ショックサイズ',
        size: 'サイズ',
        wheel_size: 'ホイールサイズ',
        type_of_part: '部品の種類'
      },
      placeholder: {
        dealer_name: 'ディーラー名を選択...',
        user: 'ユーザーを選択...',
        dealer_location: '場所を選択...',
        bike_make: 'バイクメーカーを選択...',
        bike_make_other: 'ビアンキ',
        part_type: 'パーツタイプを選択...',
        part_model: 'パーツモデルを選択...',
        diagnosis: '診断を選択してください...',
        brake_type: 'ブレーキタイプを選択してください...',
        chainring_size: 'チェーンリングのサイズを選択してください...',
        feature: '機能を選択...',
        color: '色を選択...',
        crank_arm_length: 'クランクアームの長さを選択...',
        shock_size: 'ショックサイズを選択...',
        size: 'パーツサイズを選択...',
        wheel_size: 'ホイールサイズを選択...',
        type_of_part: '部品の種類を選択してください...',
        notes: 'ここにメモ/メッセージを追加してください...',
        purchase_date: 'mm/dd/yyyy'
      },
      helper_text: {
        dealer_name: '請求を提出するディーラー',
        user: '申し立てを送信するユーザー',
        dealer_location: 'ディーラーの主な場所',
        bike_serial: '自転車のシリアル番号',
        bike_make: '自転車のブランド/メーカー',
        part_serial: '特定の部品のシリアル番号',
        find_serial: 'これは何ですか？',
        purchase_date: 'パーツ/バイクの最初の購入日',
        file_upload:
          '最大3つの画像をアップロードします。画像は10MB未満で、JPGまたはPNG形式である必要があります。'
      },
      buttons: {
        lookup: '見上げる',
        submit_ticket: 'チケットを提出する',
        update_ticket: 'チケットの更新',
        cancel: 'キャンセル'
      },
      choose_manually: 'パーツを手動で選択',
      success: 'チケットは正常に作成されました',
      error: {
        400: '送信した情報に問題がありました。再試行する前に、チケットの詳細を確認してください',
        404: 'レコードに一致するパーツ/モデルが見つかりません',
        405: '方法は許可されていません',
        500: '技術的な問題により、チケットを作成できませんでした。エラーが発生したことがスタッフに通知されました'
      }
    }
  },
  fr: {
    create_ticket: {
      heading: {
        general: 'Informations générales',
        bike: 'Informations sur le vélo',
        part: 'Informations sur la pièce',
        misc: 'Informations Complémentaires'
      },
      subheading: {
        part_details: 'Détails de la pièce',
        part_diagnosis: 'Diagnostic des pièces'
      },
      label: {
        dealer_name: 'Nom du revendeur',
        user: 'Utilisateur',
        dealer_location: 'Lieu',
        bike_serial: 'Numéro de série du vélo',
        bike_make: 'Marque de vélo',
        bike_make_other: 'Marque de vélo (Autre)',
        part_serial: 'Numéro de série de la pièce',
        part_type: 'Type de pièce',
        part_model: 'Modèle',
        diagnosis: 'Diagnostic',
        notes: 'Remarques/messages supplémentaires',
        purchase_date: "date d'achat",
        file_upload: 'Téléchargement de fichiers',
        brake_type: 'Type de frein',
        chainring_size: 'Taille du plateau',
        feature: 'Caractéristique',
        color: 'Couleur',
        crank_arm_length: 'Longueur du bras de manivelle',
        shock_size: 'Taille de choc',
        size: 'Taille',
        wheel_size: 'Taille de roue',
        type_of_part: 'Type de pièce'
      },
      placeholder: {
        dealer_name: 'Sélectionnez le nom du revendeur...',
        user: 'Sélectionnez Utilisateur...',
        dealer_location: "Sélectionnez l'emplacement...",
        bike_make: 'Sélectionnez la marque du vélo...',
        bike_make_other: 'Bianchi',
        part_type: 'Sélectionnez le type de pièce...',
        part_model: 'Sélectionnez le modèle de pièce...',
        diagnosis: 'Sélectionnez un diagnostic...',
        brake_type: 'Sélectionnez un type de frein...',
        chainring_size: 'Sélectionnez une taille de plateau...',
        feature: 'Sélectionnez une fonctionnalité...',
        color: 'Sélectionnez une couleur...',
        crank_arm_length: 'Sélectionnez la longueur du bras de manivelle...',
        shock_size: "Sélectionnez la taille de l'amortisseur...",
        size: 'Sélectionnez une taille de pièce...',
        wheel_size: 'Sélectionnez la taille de la roue...',
        type_of_part: 'Sélectionnez le type de pièce...',
        notes: 'Ajoutez vos notes/messages ici...',
        purchase_date: 'mm/dd/yyyy'
      },
      helper_text: {
        dealer_name: 'Concessionnaire soumettant la réclamation',
        user: 'Utilisateur soumettant la réclamation',
        dealer_location: "L'emplacement principal de votre concession",
        bike_serial: 'Numéro de série du vélo',
        bike_make: 'Marque/fabrication du vélo',
        part_serial: 'Numéro de série de la pièce spécifique',
        find_serial: "Qu'est-ce que c'est ça?",
        purchase_date: "Date d'achat originale de la pièce/vélo",
        file_upload:
          "Téléchargez jusqu'à trois images. Les images doivent être inférieures à 5 Mo et au format JPG ou PNG."
      },
      buttons: {
        lookup: 'Chercher',
        submit_ticket: 'Envoyer le ticket',
        update_ticket: 'Mettre à jour le billet',
        cancel: 'Annuler'
      },
      choose_manually: 'Choisissez la pièce manuellement',
      success: 'Votre billet a été créé avec succès',
      error: {
        400: 'Il y a eu un problème avec les informations que vous avez soumises. Veuillez vérifier les détails de votre billet avant de réessayer',
        404: 'Aucune pièce/modèle correspondant trouvé dans nos dossiers',
        405: 'Méthode Non Autorisée',
        500: "Un problème technique nous a empêché de créer votre ticket. Notre personnel a été informé de l'erreur"
      }
    }
  },
  it: {
    create_ticket: {
      heading: {
        general: 'Informazioni generali',
        bike: 'Informazioni sulla bici',
        part: 'Informazioni sulla parte',
        misc: 'Informazioni aggiuntive'
      },
      subheading: {
        part_details: 'Dettagli della parte',
        part_diagnosis: 'Diagnosi delle parti'
      },
      label: {
        dealer_name: 'Nome del rivenditore',
        user: 'Utente',
        dealer_location: 'Posizione',
        bike_serial: 'Numero di serie della bici',
        bike_make: 'Marca bici',
        bike_make_other: 'Marca bici (Altro)',
        part_serial: 'Numero di serie della parte',
        part_type: 'Tipo di parte',
        part_model: 'Modello',
        diagnosis: 'Diagnosi',
        notes: 'Note/messaggio aggiuntivi',
        purchase_date: 'Data di acquisto',
        file_upload: 'Upload di file',
        brake_type: 'Tipo di freno',
        chainring_size: 'Dimensioni della corona',
        feature: 'Caratteristica',
        color: 'Colore',
        crank_arm_length: 'Lunghezza della pedivella',
        shock_size: 'Dimensione shock',
        size: 'Misurare',
        wheel_size: 'Dimensioni della ruota',
        type_of_part: 'Tipo di parte'
      },
      placeholder: {
        dealer_name: 'Seleziona il nome del rivenditore...',
        user: 'Seleziona utente...',
        dealer_location: 'Seleziona posizione...',
        bike_make: 'Seleziona Marca bici...',
        bike_make_other: 'Bianchi',
        part_type: 'Seleziona il tipo di parte...',
        part_model: 'Seleziona il modello della parte...',
        diagnosis: 'Seleziona una diagnosi...',
        brake_type: 'Seleziona un tipo di freno...',
        chainring_size: 'Seleziona una misura della corona...',
        feature: 'Seleziona una caratteristica...',
        color: 'Seleziona un colore...',
        crank_arm_length: 'Seleziona Lunghezza pedivella...',
        shock_size: "Seleziona la dimensione dell'ammortizzatore...",
        size: 'Seleziona una dimensione della parte...',
        wheel_size: 'Seleziona la dimensione della ruota...',
        type_of_part: 'Seleziona il tipo di parte...',
        notes: 'Aggiungi le tue note/messaggio qui...',
        purchase_date: 'mm/dd/yyyy'
      },
      helper_text: {
        dealer_name: 'Concessionaria che presenta il reclamo',
        user: 'Utente che presenta il reclamo',
        dealer_location: 'La sede principale per la tua concessionaria',
        bike_serial: 'Numero di serie della bicicletta',
        bike_make: 'Marca/marca della bicicletta',
        part_serial: 'Numero di serie per la parte specifica',
        find_serial: "Che cos'è questo?",
        purchase_date: 'Data di acquisto originale della parte/bicicletta',
        file_upload:
          'Carica fino a tre immagini. Le immagini devono essere inferiori a 5 MB e in formato JPG o PNG.'
      },
      buttons: {
        lookup: 'Cercare',
        submit_ticket: 'Presentare il biglietto',
        update_ticket: 'Aggiorna biglietto',
        cancel: 'Annulla'
      },
      choose_manually: 'Scegli la parte manualmente',
      success: 'Il tuo biglietto è stato creato con successo',
      error: {
        400: 'Si è verificato un problema con le informazioni che hai inviato. Si prega di rivedere i dettagli del biglietto prima di riprovare',
        404: 'Nessun pezzo/modello corrispondente trovato nei nostri archivi',
        405: 'Operazione non permessa',
        500: "Un problema tecnico ci ha impedito di creare il tuo biglietto. Il nostro personale è stato informato dell'errore"
      }
    }
  }
};
