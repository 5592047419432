import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import BootstrapInput from '@bootstrap-styled/v4/lib/Input';
import BSFormGroup from '@bootstrap-styled/v4/lib/Form/FormGroup';
import BSFormFeedback from '@bootstrap-styled/v4/lib/Form/FormFeedback';
import BSFormText from '@bootstrap-styled/v4/lib/Form/FormText';
import BSInputGroup from '@bootstrap-styled/v4/lib/InputGroup';

import { makeTheme } from 'bootstrap-styled/lib/theme';
import { typography, colors, space, bootstrapTheme, breakpoints } from 'Theme';
import { CaretIcon } from 'Assets/images/icons';
import Label from '../Label';
import Button from '../../Button';

const Input = ({
  status,
  onChangeFunc,
  onKeyUpFunc,
  feedbackMsg,
  placeholder,
  isRequired,
  altRequired,
  isDisabled,
  helperText,
  infoFunc,
  infoLabel,
  label,
  name,
  hideLabel,
  inputId,
  type,
  theme,
  width,
  value,
  children,
  button,
  buttonAction,
  className,
  pattern
}) => {
  const feedbackMsgBackup =
    !feedbackMsg && isRequired === true ? 'Required' : '';
  const bootstrapStatus = status === 'muted' ? '' : status;

  const BSInput = statusStr =>
    statusStr === 'muted' ? (
      <BootstrapInput
        id={inputId}
        type={type}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChangeFunc}
        onKeyUp={onKeyUpFunc}
        theme={theme}
        disabled={isDisabled}
        pattern={pattern || null}
        required={altRequired}
      >
        {children}
      </BootstrapInput>
    ) : (
      <BootstrapInput
        id={inputId}
        type={type}
        state={statusStr}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChangeFunc}
        onKeyUp={onKeyUpFunc}
        theme={theme}
        disabled={isDisabled}
        pattern={pattern || null}
        required={altRequired}
      >
        {children}
      </BootstrapInput>
    );
  return (
    <InputWrapper
      className={className}
      width={name === 'countryCode' ? '115px' : width}
      isCheckbox={type === 'checkbox'}
      isPassword={type === 'password'}
      name={name}
    >
      <BSFormGroup color={bootstrapStatus}>
        <LabelWrapper>
          <Label htmlFor={inputId} hide={hideLabel}>
            {label}
          </Label>
          {infoFunc && infoLabel && (
            <LinkWrapper>
              <InfoButton type="button" onClick={() => infoFunc()}>
                {infoLabel}
              </InfoButton>
            </LinkWrapper>
          )}
        </LabelWrapper>
        {type !== 'select' ? (
          <BSInputGroup>
            {BSInput(status)}
            {button && (
              <Button
                // TODO: Implement Part Serial Search; temporarily disabled until search is functional
                isDisabled
                style={{ marginBottom: 0, lineHeight: 1.5 }}
                width="max-content"
                onClick={() => buttonAction()}
              >
                {button}
              </Button>
            )}
          </BSInputGroup>
        ) : (
          <SelectWrapper>
            {BSInput(status)}
            <CaretIcon />
          </SelectWrapper>
        )}
        {helperText && <BSFormText color="muted">{helperText}</BSFormText>}
        <BSFormFeedback>{feedbackMsg || feedbackMsgBackup}</BSFormFeedback>
      </BSFormGroup>
    </InputWrapper>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'text',
    'email',
    'password',
    'select',
    'textarea',
    'number',
    'tel',
    'search'
  ]),
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  altRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  infoFunc: PropTypes.func,
  infoLabel: PropTypes.string,
  helperText: PropTypes.string,
  hideLabel: PropTypes.bool,
  theme: PropTypes.shape(),
  width: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['success', 'danger', 'warning', 'muted']),
  feedbackMsg: PropTypes.string,
  onChangeFunc: PropTypes.func,
  onKeyUpFunc: PropTypes.func,
  children: PropTypes.node,
  button: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonAction: PropTypes.func,
  pattern: PropTypes.string
};

Input.defaultProps = {
  placeholder: '',
  isRequired: false,
  altRequired: false,
  isDisabled: false,
  type: 'text',
  helperText: '',
  infoFunc: () => {},
  infoLabel: '',
  hideLabel: false,
  theme: makeTheme(bootstrapTheme),
  width: 'max-content',
  status: '',
  feedbackMsg: '',
  onChangeFunc: () => {},
  onKeyUpFunc: () => {},
  children: null,
  button: '',
  buttonAction: () => {},
  pattern: ''
};

const { fonts, fontSizes } = typography;

const InputWrapper = styled.div`
  ${props => css`
    ${props.className} {
      margin-left: ${props.isCheckbox ? `1.25rem` : `0`};
      margin-bottom: ${space[2]}rem;
      margin-right: ${props.name === 'countryCode' ? '0.5rem' : '0'}
      font-family: ${fonts.sansSerif};
      width: ${props.width || 'max-content'};
      .input-group {
        z-index: 0;
      }
      input.form-control, textarea.form-control {
        width: 100%;
        font-size: ${fontSizes[1]}rem;
        line-height: 1.5;
        text-align: left;
        background-color: ${
          props.isDisabled ? colors.gray.lightest : colors.white.base
        };
        background-image: none;
        border-radius: 0;
        border: 1px solid ${colors.gray.base};
        padding: ${space[1]}rem;
        color: ${colors.gray.darkest};
        ${props.isPassword &&
          css`
            letter-spacing: 2px;
          `}
        &::placeholder {
          font-size: ${fontSizes[1]}rem;
          letter-spacing: initial;
          color: ${props.isDisabled ? colors.gray.darker : colors.gray.base};
        }
        &:focus,
        &:hover {
          &:not(:disabled) {
            color: ${colors.gray.darkest};
            border-color: ${colors.gray.darker};
            &.has-danger,
            &.form-control-danger{
              border-color: ${colors.red.base};
            }
            &.has-success,
            &.form-control-success {
              border-color: ${colors.green.base}
            }
          }
        }
        &:not(:disabled):focus {
          outline: 2px solid ${colors.primary.lightest};
        }
        &:not(:disabled):active {
          border-color: ${colors.primary.base};
        }
        &.has-danger:hover {
          border-color: ${colors.red.base};
        }
        @media screen and (min-width: ${breakpoints.lg}) {
          width: ${props.width};
        }
      }
      textarea {
        height: 120px;
        width: 100%;
      }
      ${props.className} .has-success .form-control-success,
      ${props.className} .has-danger .form-control-danger {
        background-image: none;
      }
      .form-text {
        display: inline-flex;
      }
      .form-control-feedback {
        font-size: 0.75rem;
        color: ${colors.gray.darker};
      }
      .input-group-btn button {
        font-family: ${fonts.sansSerif};
        font-weight: bold;
        font-stretch: condensed;
        text-transform: uppercase;
      }
      @media screen and (min-width: ${breakpoints.lg}) {
        width: ${props => (props.width ? props.width : 'max-content')};
      }
    }
  `}
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${colors.white.base};
  background-image: none;
  border-radius: 0;
  border-width: 0;
  width: 100%;
  min-width: 115px;
  z-index: 0;
  > svg {
    position: absolute;
    right: ${space[1]}rem;
    pointer-events: none;
  }
  > select.form-control {
    border-radius: 0;
    display: inline-block;
    appearance: none;
    width: 100%;
    padding: ${space[1]}rem;
    padding-right: 2.5rem;
    margin: 0;
    border: 1px solid ${colors.gray.base};
    font-family: ${fonts.sansSerif};
    font-size: ${fontSizes[1]}rem;
    line-height: 1.5;
    text-align: left;
    &:disabled {
      color: ${colors.gray.darker};
    }
    option {
      color: ${colors.gray.darkest};
      &:disabled {
        color: ${colors.gray.darker};
      }
    }
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LinkWrapper = styled(BSFormText)`
  button {
    border: none;
    background: none;
    color: #1e82c8;
    cursor: pointer;
    font-family: ${fonts.sansSerif};
    font-size: 0.75rem;
  }
`;

const InfoButton = styled.button`
  padding: 0;
`;

export default Input;
