import React, { useState } from "react";
import { Button, Input } from "Components";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createPart } from "Actions/part";
import { hideModal } from "Actions/modal";

const AddPartModel = (props) => {
  const [partModelName, setPartModelName] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { name: partTypeName, id: partTypeID } = props.data;
  const handleCreatePartModel = () => {
    setLoading(true);
    const partTypeAttributeData = {
      partTypeId: partTypeID,
      name: partModelName,
    };
    props.createPart(partTypeAttributeData).finally(() => {
      setLoading(false);
      props.hideModal();
    });
  };
  return (
    <form>
      <Input
        label="Part Type"
        inputId="part_type"
        value={partTypeName}
        isDisabled
        name="part_type"
        width="100%"
      />
      <PartNameInput
        value={partModelName}
        onChangeFunc={(e) => setPartModelName(e.currentTarget.value)}
        label="Part Model Name"
        placeholder="NVX"
      />
      <Button onClickFunc={handleCreatePartModel}>Create Part Model</Button>
    </form>
  );
};

const PartNameInput = styled(Input)`
  width: 100%;
  margin-bottom: 1rem;
`;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createPart: (partData) => dispatch(createPart("part-model", partData)),
  hideModal: (data) => dispatch(hideModal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddPartModel));
