import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Heading,
  Alert,
  Input as SsgInput,
  PageHeader,
  Button,
  Scrim,
  Throbber
} from "Components";
import { titleCase } from "Components/Utils";
import Row from "@bootstrap-styled/v4/lib/Row";
import Col from "@bootstrap-styled/v4/lib/Col";
import Option from "@bootstrap-styled/v4/lib/Option";
import styled from "styled-components";
import { space, colors, headerHeight, breakpoints } from "Theme";
import { showModal } from "Actions/modal";
import { withTranslation } from "react-i18next";
import * as modalTypes from "Components/Modal/types";
import { fetchUser, fetchUsersScopes } from "Actions/users";

const User = props => {
  const {
    match: {
      params: { userId }
    },
    t,
    history,
    modal,
    showModal,
    fetchUser,
    fetchScopes
  } = props;
  const [user, setUser] = useState({});
  const [scopes, setScopes] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);

  // Fetch user data when component mounts and when the modal is closed
  useEffect(() => {
    // Execute both promises
    Promise.allSettled([fetchUser({ id: userId }), fetchScopes()])
      .then(results => {
        const userResult = results[0];
        const scopesResult = results[1];
        if (userResult.status === "fulfilled") {
          setUser(userResult.value);
        } else {
          setAuthError({ message: "Unauthorized access" });
        }
        if (scopesResult.status === "fulfilled") {
          setScopes(scopesResult.value);
        }
      })
      .catch(err => setAuthError(err))
      .finally(() => setLoading(false));
  }, [modal.isOpen]);

  // Open modal to confirm archiving user
  const archiveUser = () => {
    const modalData = {
      type: modalTypes.CONFIRM_ARCHIVE_USER,
      title: `Archive user ${user.name}`,
      data: {
        ...user
      }
    };
    showModal(modalData);
  };

  return isLoading ? (
    <Fragment>
      <Scrim isShown={isLoading} />
      <StyledThrobber isFullPage />
    </Fragment>
  ) : authError !== null ? (
    <Alert alertIsOpen={authError} color="danger" noClose={authError}>
      Unauthorized access
    </Alert>
  ) : (
    <Fragment>
      <PageHeader
        backButtonUrl="/users"
        backButtonText="Back to Users"
        history={history}
      />
      <AlertWrapper />
      <UserHeader>
        <Heading size={4}>{user.name}</Heading>
      </UserHeader>
      <Section>
        <Row>
          <Col md={4}>
            <Heading size={6}>{t("user.heading_info")}</Heading>
          </Col>
          <Col md={8}>
            <Row>
              <Col md={6}>
                <SsgInput
                  label={t("form.labels.user_name")}
                  name="name"
                  value={user.name}
                  inputId="name"
                  isDisabled
                  width="100%"
                />
              </Col>
              <Col md={6}>
                <SsgInput
                  label={t("form.labels.user_email")}
                  name="email"
                  value={user.email}
                  inputId="email"
                  isDisabled
                  width="100%"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <SsgInput
                  label={t("form.labels.status")}
                  type="select"
                  name="status"
                  value={user.status}
                  onChangeFunc={() => {}}
                  inputId="user-status"
                  width="100%"
                  isDisabled
                >
                  {scopes.map(scope => (
                    <Option key={`scope-${scope}`} value={scope}>
                      {titleCase(scope)}
                    </Option>
                  ))}
                </SsgInput>
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>
      {user.status !== "archived" && (
        <ButtonWrapper>
          <Button color="primary" onClickFunc={archiveUser} width="max-content">
            {t("user.buttons.archive")}
          </Button>
        </ButtonWrapper>
      )}
    </Fragment>
  );
};

const AlertWrapper = styled.div`
  margin-top: calc(${headerHeight}px + ${space[1]}rem);
  margin-bottom: ${space[3]}rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${space[1] * 3}rem;
`;

const UserHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Section = styled.section`
  padding-top: ${space[1] * 3}rem;
  padding-bottom: ${space[2] * 2}rem;
  margin-bottom: ${space[1]}rem;
  border-bottom: 2px solid ${colors.gray.base};
  @media screen and (min-width: ${breakpoints.md}) {
    padding-top: ${space[1] * 7}rem;
    padding-bottom: ${space[4]}rem;
  }
`;

const StyledThrobber = styled(Throbber)`
  top: 2rem;
`;

const mapStateToProps = state => ({
  modal: state.modal
});

const mapDispatchToProps = dispatch => ({
  fetchUser: data => dispatch(fetchUser(data)),
  fetchScopes: () => dispatch(fetchUsersScopes()),
  showModal: data => dispatch(showModal(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(User))
);
