export default {
  en: {
    accept_invite_dealer_user: {
      heading_main: `You are invited to join {{ dealer_name }}'s account`,
      heading_sub: `Joining will enable you to use the SR SUNTOUR warranty application on behalf of {{ dealer_name }}.`,
      button: 'Join',
      success: `You are now a member of {{ dealer_name }}`,
      error: {
        500: 'A technical issue prevented us updating this invitation from the dealer. Our staff has been notified of the error',
        401: 'This user is not authorized to accept this invitation',
        404: 'The specified relationship could not be found'
      }
    },
    decline_invite_dealer_user: {
      button: 'Decline'
    }
  },
  de: {
    accept_invite_dealer_user: {
      heading_main: `Sie werden eingeladen, dem Konto von {{ dealer_name }} beizutreten`,
      heading_sub: `Der Beitritt ermöglicht es Ihnen, den SR SUNTOUR-Garantieantrag im Namen von {{ dealer_name }} zu nutzen.`,
      button: 'Verbinden',
      success: `Sie sind jetzt Mitglied bei {{dealer_name}}`,
      error: {
        500: 'Aufgrund eines technischen Problems konnten wir diese Einladung des Händlers nicht aktualisieren. Unsere Mitarbeiter wurden über den Fehler informiert',
        401: 'Dieser Benutzer ist nicht berechtigt, diese Einladung anzunehmen',
        404: 'Die angegebene Beziehung konnte nicht gefunden werden'
      }
    },
    decline_invite_dealer_user: {
      button: 'Ablehnen'
    }
  },
  jp: {
    accept_invite_dealer_user: {
      heading_main: `{{dealer_name}}のアカウントに招待されています`,
      heading_sub: `参加すると、{{dealer_name}}に代わってSRSUNTOUR保証アプリケーションを使用できるようになります。`,
      button: '加入',
      success: `これで、{{dealer_name}}のメンバーになりました`,
      error: {
        500: '技術的な問題により、ディーラーからのこの招待状を更新できませんでした。エラーが発生したことがスタッフに通知されました',
        401: 'このユーザーは、この招待を受け入れる権限がありません',
        404: '指定された関係が見つかりませんでした'
      }
    },
    decline_invite_dealer_user: {
      button: '却下'
    }
  },
  it: {
    accept_invite_dealer_user: {
      heading_main: `Sei invitato a iscriverti all'account di {{ dealer_name }}`,
      heading_sub: `Sei invitato a iscriverti all'account di {{ dealer_name }}`,
      button: 'Giuntura',
      success: `Ora sei un membro di {{ dealer_name }}`,
      error: {
        500: "Un problema tecnico ci ha impedito di aggiornare questo invito dal rivenditore. Il nostro personale è stato informato dell'errore",
        401: 'Questo utente non è autorizzato ad accettare questo invito',
        404: 'Impossibile trovare la relazione specificata'
      }
    },
    decline_invite_dealer_user: {
      button: 'Declino'
    }
  }
};
