import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Option from "@bootstrap-styled/v4/lib/Option";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchUsers,
  fetchUsersScopes,
  fetchUsersByScope,
  searchUsers,
  addFilter,
  removeFilter
} from "Actions/users";
import {
  Alert,
  Badge,
  Input,
  DataTable,
  Scrim,
  Search,
  Link
} from "Components";
import {
  formatPhoneNumber,
  formatDate,
  titleCase,
  setBadgeColorByStatus
} from "Components/Utils";
import { withTranslation } from "react-i18next";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      pageNumber: 1,
      perPage: 25,
      hasError: false,
      error: "",
      paginationResetDefaultPage: false,
      searchKeyword: "",
      isSearching: false
    };
  }

  async componentDidMount() {
    const { pageNumber } = this.state;
    const { fetchUsersScopes } = this.props;
    await fetchUsersScopes().catch(err => Promise.reject(err));
    await this.fetchData(pageNumber).catch(err => Promise.reject(err));
  }

  handlePageChange = async page => {
    const { perPage, searchKeyword } = this.state;
    const {
      searchUsersData,
      users: { filters }
    } = this.props;
    if (searchKeyword) {
      const queryData = {
        search: searchKeyword,
        paginationPerPage: perPage,
        page
      };
      if (filters.length > 0) {
        queryData.scope = filters.join("+");
      }
      searchUsersData(queryData).catch(() => {
        // TODO: Catch errors
      });
    } else {
      this.fetchData(page, false);
    }
  };

  handleRowsPerPageChange = (currRowsPer, pageNumber) => {
    this.setState(
      state => ({ ...state, perPage: currRowsPer, pageNumber }),
      () => {
        this.fetchData(pageNumber);
      }
    );
  };

  fetchData = async (pageNumber = null, shouldOverWriteResults = false) => {
    const {
      fetchUsers,
      fetchUsersByScope,
      history,
      t,
      users: { filters }
    } = this.props;
    const { perPage, searchKeyword } = this.state;

    const pageData = {};
    if (pageNumber) {
      pageData.page = pageNumber;
    }
    pageData.paginationPerPage = perPage;

    if (!filters.length) {
      if (searchKeyword) {
        pageData.search = searchKeyword;
      }
      this.setState(() => ({
        isFetching: true
      }));
      await fetchUsers(pageData).catch(errorStatus => {
        if (errorStatus === 403) {
          return history.push("/tickets");
        }
        const error = t(`user_list.error.${errorStatus}`);
        return this.setState(() => ({ error, hasError: true }));
      });
    } else {
      this.setState(() => ({
        isFetching: true
      }));
      await fetchUsersByScope({
        ...pageData,
        scope: filters.join("+"),
        shouldOverWriteResults
      }).catch(errorStatus => {
        if (errorStatus === 403) {
          return history.push("/tickets");
        }
        const error = t(`user_list.error.${errorStatus}`);
        return this.setState(() => ({ error, hasError: true }));
      });
    }

    this.setState(() => ({
      isFetching: false
    }));
  };

  handleSearchChange = e => {
    const {
      target: { value }
    } = e;
    this.setState(state => ({ ...state, searchKeyword: value }));
  };

  onSearch = () => {
    const {
      searchKeyword,
      perPage: paginationPerPage,
      pageNumber: page
    } = this.state;
    const { searchUsersData } = this.props;

    this.setState(state => ({
      ...state,
      isSearching: true
    }));

    const queryData = {
      search: encodeURIComponent(searchKeyword),
      paginationPerPage,
      page
    };
    searchUsersData(queryData)
      .catch(() => console.error("Error searching users"))
      .then(() => this.setState(state => ({ ...state, isSearching: false })));
  };

  addFilter = async event => {
    const { value } = event.target;
    const { addFilter } = this.props;
    await addFilter(value);

    this.fetchData(1, true);
  };

  removeFilter = async event => {
    const scopeToRemove = event.currentTarget.getAttribute("handle");
    const { removeFilter } = this.props;

    await removeFilter(scopeToRemove);

    this.fetchData(null, true);
  };

  render() {
    const {
      isFetching,
      isSearching,
      searchKeyword,
      perPage,
      paginationResetDefaultPage,
      hasError,
      error
    } = this.state;
    const { users, t, history } = this.props;
    const { list, pagination, scopes, filters } = users;

    const columns = [
      {
        id: "name",
        name: t("table.name"),
        selector: "name",
        cell: row =>
          row.name ? <Link href={`/user/${row.id}`}>{row.name}</Link> : ""
      },
      {
        id: "dealership",
        name: t("table.dealership"),
        selector: "dealership",
        cell: row => (row.dealer.length > 0 ? row.dealer[0].name : "")
      },
      {
        id: "phone",
        name: t("table.phone"),
        selector: "phone",
        cell: row => (row.phone ? formatPhoneNumber(row.phone) : ""),
        grow: 1
      },
      {
        id: "last_activity",
        name: t("table.last_activity"),
        selector: "updated_at",
        cell: row => formatDate(row.updated_at)
      },
      {
        id: "status",
        name: t("table.status"),
        selector: row =>
          row.dealer_user && row.status !== "archived"
            ? "dealer_user.status"
            : "status",
        compact: true,
        cell: row =>
          row.dealer_user && row.status !== "archived" ? (
            <Badge
              label={t(`data.status.${row.dealer_user.status.toLowerCase()}`)}
              pill
              color={setBadgeColorByStatus(
                row.dealer_user.status.toLowerCase()
              )}
              inTable
            />
          ) : (
            <Badge
              label={t(`data.status.${row.status.toLowerCase()}`)}
              pill
              color={setBadgeColorByStatus(row.status.toLowerCase())}
              inTable
            />
          )
      }
    ];

    return (
      <Fragment>
        <Scrim isShown={isFetching || isSearching} />
        <InputsWrapper>
          <Input
            label={t("form.labels.filters")}
            onChangeFunc={this.addFilter}
            hideLabel
            status="success"
            type="select"
            value=" "
            inputId="filters"
            name="filters"
            width="max-content"
          >
            <Option value=" ">{t("form.filter")}</Option>
            {scopes &&
              scopes.map(scope => (
                <Option key={scope} value={scope}>
                  {titleCase(t(`data.status.${scope.toLowerCase()}`))}
                </Option>
              ))}
          </Input>
          <Search
            value={searchKeyword}
            label={t("form.labels.search")}
            status="muted"
            placeholder={t("form.placeholders.search")}
            onSearchFunc={this.onSearch}
            onChangeFunc={e => this.handleSearchChange(e)}
          />
        </InputsWrapper>
        {filters &&
          filters.map(filter => (
            <Badge
              key={filter}
              label={t(`data.status.${filter}`)}
              handle={filter.toLowerCase()}
              pill
              color={setBadgeColorByStatus(filter.toLowerCase())}
              isRemovable
              onRemove={e => this.removeFilter(e)}
            />
          ))}
        <Alert
          alertIsOpen={hasError}
          color="warning"
          onCloseFunc={() => this.setState(() => ({ hasError: false }))}
        >
          {error}
        </Alert>
        <DataTable
          title=""
          data={list || []}
          columns={columns}
          noHeader
          pagination
          paginationPerPage={perPage}
          paginationServer
          paginationTotalRows={pagination ? pagination.total : 0}
          progressPending={isFetching}
          paginationResetDefaultPage={paginationResetDefaultPage}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          onRowClicked={param => {
            history.push(`/user/${param.id}`);
          }}
          pointerOnHover
          highlightOnHover
        />
      </Fragment>
    );
  }
}

UserList.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  fetchUsersScopes: PropTypes.func.isRequired,
  fetchUsersByScope: PropTypes.func.isRequired,
  searchUsersData: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  users: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    pagination: PropTypes.object,
    scopes: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  users: state.users
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: data => dispatch(fetchUsers(data)),
  fetchUsersScopes: () => dispatch(fetchUsersScopes()),
  fetchUsersByScope: data => dispatch(fetchUsersByScope(data)),
  searchUsersData: data => dispatch(searchUsers(data)),
  addFilter: data => dispatch(addFilter(data)),
  removeFilter: data => dispatch(removeFilter(data))
});

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  input[type="search"] {
    border-left: 0;
  }
`;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserList))
);
