import React from 'react';
import PropTypes from 'prop-types';
import H1 from '@bootstrap-styled/v4/lib/H1';
import H2 from '@bootstrap-styled/v4/lib/H2';
import H3 from '@bootstrap-styled/v4/lib/H3';
import H4 from '@bootstrap-styled/v4/lib/H4';
import H5 from '@bootstrap-styled/v4/lib/H5';
import H6 from '@bootstrap-styled/v4/lib/H6';
import styled from 'styled-components';
import { makeTheme } from 'bootstrap-styled/lib/theme';
import { bootstrapTheme, typography, breakpoints } from 'Theme';

const SRSHeading = ({ className, size, children, color, lead, theme }) => {
  let heading;
  switch (size) {
    case 1:
      heading = (
        <StyledH1 className={className} color={color} lead={lead} theme={theme}>
          {children}
        </StyledH1>
      );
      break;
    case 2:
      heading = (
        <StyledH2 className={className} color={color} lead={lead} theme={theme}>
          {children}
        </StyledH2>
      );
      break;
    case 3:
      heading = (
        <StyledH3 className={className} color={color} lead={lead} theme={theme}>
          {children}
        </StyledH3>
      );
      break;
    case 4:
      heading = (
        <StyledH4 className={className} color={color} lead={lead} theme={theme}>
          {children}
        </StyledH4>
      );
      break;
    case 5:
      heading = (
        <StyledH5 className={className} color={color} lead={lead} theme={theme}>
          {children}
        </StyledH5>
      );
      break;
    case 6:
      heading = (
        <StyledH6 className={className} color={color} lead={lead} theme={theme}>
          {children}
        </StyledH6>
      );
      break;
    default:
      heading = (
        <StyledH1 className={className} color={color} lead={lead} theme={theme}>
          {children}
        </StyledH1>
      );
      break;
  }
  return heading;
};

SRSHeading.propTypes = {
  size: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    'white',
    'muted',
    'gray-dark',
    'primary',
    'success',
    'info',
    'warning',
    'danger'
  ]),
  lead: PropTypes.bool,
  theme: PropTypes.shape()
};

SRSHeading.defaultProps = {
  lead: false,
  theme: makeTheme(bootstrapTheme)
};

const { fonts, fontSizes, fontSizesMobile } = typography;

const StyledH1 = styled(H1)`
  font-size: ${fontSizesMobile[5]}rem;
  @media screen and (min-width: ${breakpoints.sm}) {
    font-size: ${fontSizes[6]}rem;
  }
`;

const StyledH2 = styled(H2)`
  font-size: ${fontSizesMobile[4]}rem;
  @media screen and (min-width: ${breakpoints.sm}) {
    font-size: ${fontSizes[5]}rem;
  }
`;

const StyledH3 = styled(H3)`
  font-size: ${fontSizesMobile[3]}rem;
  @media screen and (min-width: ${breakpoints.sm}) {
    font-size: ${fontSizes[4]}rem;
  }
`;

const StyledH4 = styled(H4)`
  font-size: ${fontSizesMobile[2]}rem;
  @media screen and (min-width: ${breakpoints.sm}) {
    font-size: ${fontSizes[3]}rem;
  }
`;

const StyledH5 = styled(H5)`
  font-size: ${fontSizesMobile[1]}rem;
  @media screen and (min-width: ${breakpoints.sm}) {
    font-size: ${fontSizes[2]}rem;
  }
`;

const StyledH6 = styled(H6)`
  font-size: ${fontSizesMobile[0]}rem;
  @media screen and (min-width: ${breakpoints.sm}) {
    font-size: ${fontSizes[1]}rem;
  }
`;

const Heading = styled(SRSHeading)`
  font-family: ${fonts.sansSerif};
  font-style: oblique;
  font-stretch: condensed;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
`;

export default Heading;
