export default {
  en: {
    create_dealer_loc: {
      heading_main: 'Add a location',
      heading_sub: 'Add an additional location for your business',
      submit_button: 'Add Location'
    }
  },
  de: {
    create_dealer_loc: {
      heading_main: 'Füge einen Ort hinzu',
      heading_sub:
        'Fügen Sie einen zusätzlichen Standort für Ihr Unternehmen hinzu',
      submit_button: 'Ort hinzufügen'
    }
  },
  jp: {
    create_dealer_loc: {
      heading_main: '位置を追加する',
      heading_sub: 'あなたのビジネスのための追加の場所を追加します',
      submit_button: '場所を追加'
    }
  },
  fr: {
    create_dealer_loc: {
      heading_main: 'Ajouter un emplacement',
      heading_sub:
        'Ajouter un emplacement supplémentaire pour votre entreprise',
      submit_button: 'Ajouter un emplacement'
    }
  },
  it: {
    create_dealer_loc: {
      heading_main: 'Aggiungi un luogo',
      heading_sub: 'Aggiungi una sede aggiuntiva per la tua attività',
      submit_button: 'Aggiungi luogo'
    }
  }
};
