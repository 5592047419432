import React from 'react';
import PropTypes from 'prop-types';

const AvatarIcon = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    aria-labelledby="user-avatar user-avatar-description"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="user-avatar">User Avatar</title>
    <desc id="user-avatar-description">
      A gray and black image representing a person in lieu of a custom user
      avatar
    </desc>
    <defs>
      <circle id="a" cx="24" cy="24" r="24" />
      <path
        d="M16 54.7H.4V33.9C5.6 32 9 30.4 10.8 28.7 13.4 26 5.6 26 5.6 13 5.6 4.3 9 0 16 0c7 0 10.4 4.3 10.4 13 0 13-7.8 13-5.2 15.7 1.7 1.7 5.2 3.4 10.4 5.2V56L16 54.7z"
        id="c"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-1346-16H94v1024h-1440z" />
      <g>
        <use fill="#DFE1E5" xlinkHref="#a" />
        <g mask="url(#b)" fill="#DFE1E5">
          <path d="M0 0h48v48H0z" />
        </g>
        <g mask="url(#b)">
          <g transform="translate(8 8)">
            <mask id="d" fill="#fff">
              <use xlinkHref="#c" />
            </mask>
            <use fill="#A5ADBB" xlinkHref="#c" />
            <g mask="url(#d)">
              <path d="M-8-8h48v48H-8z" fill="#A5ADBB" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

AvatarIcon.propTypes = {
  size: PropTypes.string
};

AvatarIcon.defaultProps = {
  size: '48px'
};

export default AvatarIcon;
