export default {
  en: {
    create_service_center_tech: {
      heading_main: "Invite a technician",
      heading_sub:
        "Send an invite to for a service center technician to join your service center. Recipient will receive a link to accept the invitation and create an account",
      submit_button: "Send Invite",
      error: {
        500: "A technical issue prevented us sending the invitation. Our staff has been notified of the error.",
        401: "Your user account is not authorized to send an invitation."
      }
    }
  }
};
