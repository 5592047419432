import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'Theme';

const TicketsIcon = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="icon/fi-page-filled"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        transform="translate(5.000000, 2.000000)"
        fill={color}
        fillRule="nonzero"
        id="Combined-Shape"
      >
        <path d="M0.411089178,6.98851485 C0.302020384,6.98851485 0.197422233,6.9451712 0.120321236,6.86802564 C0.0432202391,6.79088007 -2.19824159e-13,6.68625689 -2.19824159e-13,6.57718812 L-2.19824159e-13,6.16633663 L6.1663367,-1.70530257e-13 L6.57742581,-1.70530257e-13 C6.80437125,0.000131182358 6.9882773,0.184143629 6.9882773,0.411089109 L6.9882773,6.57742574 C6.9882773,6.80459406 6.80459413,6.98851485 6.57742581,6.98851485 L0.411089178,6.98851485 Z M13.9770298,-6.57252031e-14 C14.204068,-6.57252031e-14 14.3881189,0.184050863 14.3881189,0.411089109 L14.3881189,20.1433663 C14.3881189,20.3704046 14.204068,20.5544554 13.9770298,20.5544554 L0.411089178,20.5544554 C0.183920861,20.5544554 6.87765729e-08,20.3705347 6.87765729e-08,20.1433663 L6.87765729e-08,9.76372277 L0.00570303907,9.76372277 C0.0342178906,9.56411881 0.203643633,9.41037624 0.411089178,9.41037624 L8.94106938,9.41037624 L8.94106938,9.41013861 L8.94320799,9.41037624 C9.17024624,9.41037624 9.3542971,9.22632537 9.3542971,8.99928713 L9.35382185,8.99382178 L9.3542971,8.99382178 L9.3542971,0.308435644 L9.36879215,0.308435644 C9.41465353,0.131405941 9.57386145,-6.57252031e-14 9.76538621,-6.57252031e-14 L13.9770298,-6.57252031e-14 Z" />
      </g>
    </g>
  </svg>
);

TicketsIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};

TicketsIcon.defaultProps = {
  color: colors.gray.darkest,
  size: '24px'
};

export default TicketsIcon;
