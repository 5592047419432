import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'Components';
import { useTranslation } from 'react-i18next';
import Option from '@bootstrap-styled/v4/lib/Option';

const DealerInput = ({
  value,
  status,
  name,
  inputId,
  userLevel,
  dealerName,
  fetchStatus,
  list,
  onChangeFunc
}) => {
  const { t } = useTranslation();

  if (userLevel === 'service_center_user') {
    return (
      dealerName && (
        <Input
          type="select"
          label={t(`create_ticket.label.${name}`)}
          name={name}
          value={value}
          onChangeFunc={e => onChangeFunc(e)}
          status={status}
          helperText={t(`create_ticket.helper_text.${name}`)}
          inputId={inputId}
          width="100%"
          isRequired
          isDisabled={!list || !list.length || fetchStatus === 'loading'}
        >
          {fetchStatus === 'loading' ? (
            <Option disabled value="">
              {t('form.placeholders.loading')}
            </Option>
          ) : (
            <Option disabled value="">
              {t(`create_ticket.placeholder.${name}`)}
            </Option>
          )}
          {list &&
            list.length &&
            list.map(item => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
        </Input>
      )
    );
  }

  return (
    <Input
      type="text"
      label={t(`create_ticket.label.${name}`)}
      name={name}
      value={value}
      inputId={inputId}
      onChangeFunc={onChangeFunc}
      status={status}
      helperText={t(`create_ticket.helper_text.${name}`)}
      placeholder={t(`form.placeholders.company.${name}`)}
      width="100%"
      isRequired
    />
  );
};

DealerInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  dealerName: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  fetchStatus: PropTypes.string.isRequired,
  userLevel: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  onChangeFunc: PropTypes.func.isRequired
};

export default DealerInput;
