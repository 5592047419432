import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import FormGroup from '@bootstrap-styled/v4/lib/Form/FormGroup';
import FormText from '@bootstrap-styled/v4/lib/Form/FormText';
import Label from '../Forms/Label';

registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      setPond,
      files,
      allowMultiple,
      maxFiles,
      onUpdateFiles,
      label,
      helperText
    } = this.props;
    return (
      <FormGroup>
        <Label htmlFor="filepond" hidden={!label}>
          {label}
        </Label>
        <FilePond
          ref={ref => setPond(ref)}
          files={files}
          allowMultiple={allowMultiple}
          maxFiles={maxFiles}
          checkValidity
          dropValidation
          maxFileSize="10MB"
          labelMaxFileSizeExceeded="File Size exceeded 10MB. Please try again."
          onupdatefiles={fileItems => onUpdateFiles(fileItems)}
          acceptedFileTypes={['image/png', 'image/jpg', 'image/jpeg']}
          fileValidateTypeDetectType={(source, type) =>
            new Promise(resolve => {
              // do custom type detection here and return with promise
              resolve(type);
            })
          }
        />
        <FormText color="muted">{helperText}</FormText>
      </FormGroup>
    );
  }
}

FileUploader.propTypes = {
  setPond: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  allowMultiple: PropTypes.bool.isRequired,
  maxFiles: PropTypes.number.isRequired,
  onUpdateFiles: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string
};

FileUploader.defaultProps = {
  helperText: '',
  label: ''
};

export default FileUploader;
