import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { updatePart, fetchAttributes, deletePart } from "Actions/part";
import ResourcePopOver from "./ResourcePopOver";
import styled from "styled-components";
import PartTable from "./PartTable";
import { Button, Input } from "Components";
import { showModal } from "Actions/modal";
import * as modalTypes from "Components/Modal/types";
import { titleCase } from "Components/Utils";

const PartTypeAttributeTable = (props) => {
  const [
    isEditingPartTypeAttributeID,
    setEditingPartTypeAttributeID,
  ] = useState(null);
  const [
    editedPartTypeAttributeName,
    setEditedPartTypeAttributeName,
  ] = useState("");
  // This use effect initializes the attributes data, which we use across various tables
  // This effect intentionally only runs when the attributes array is empty
  useEffect(() => {
    if (props.attributes.length === 0) {
      props.fetchAttributes().catch((err) => console.error(err));
    }
  }, []);
  const { data, t } = props;
  const attributeColumns = [
    {
      id: "id",
      name: t("table.id"),
      selector: "id",
      grow: 1,
      cell: (row) => row.id,
    },
    {
      id: "value",
      name: t("table.value"),
      selector: "value",
      grow: 2,
      cell: (row) =>
        isEditingPartTypeAttributeID === row.id ? (
          <StyledInput
            type="text"
            value={editedPartTypeAttributeName}
            onChangeFunc={(e) => {
              setEditedPartTypeAttributeName(e.currentTarget.value);
            }}
          />
        ) : (
          row.value
        ),
    },
    {
      id: "attribute",
      name: t("table.attribute_type"),
      selector: "attribute_id",
      cell: (row) => {
        // Find the attribute type that corresponds to the part type attribute id
        const attribute = props.attributes.find(
          (attribute) => attribute.id === row.attribute_id
        );
        const attributeName = attribute ? attribute.name : "unknown";
        return titleCase(attributeName);
      },
    },
    {
      id: "active",
      name: t("table.status"),
      selector: "active",
      grow: 1,
      cell: (row) =>
        row.active ? t("data.status.active") : t("data.status.archived"),
    },
    {
      id: "actions",
      name: "Actions",
      selector: "actions",
      compact: true,
      cell: (row) =>
        isEditingPartTypeAttributeID === row.id ? (
          <SaveButton
            onClickFunc={() =>
              handleSavePartTypeAttribute(
                isEditingPartTypeAttributeID,
                editedPartTypeAttributeName
              )
            }
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            Save
          </SaveButton>
        ) : (
          <ResourcePopOver
            onClickEdit={handleEditPartTypeAttribute}
            onClickDelete={handleDeletePartTypeAttribute}
            onClickUnarchive={handleUnarchivePartTypeAttribute}
            resourceID={row.id}
          />
        ),
    },
  ];

  const handleEditPartTypeAttribute = (id) => {
    const part = data.attributes.find((part) => part.id === id);
    setEditedPartTypeAttributeName(part.value);
    setEditingPartTypeAttributeID(id);
  };

  const handleDeletePartTypeAttribute = (id) => {
    props
      .deletePart({ id })
      .catch((err) =>
        console.error("Could not delete part type attribute\n", err)
      )
      .finally(() => {
        window.location.reload();
      });
  };

  const handleUnarchivePartTypeAttribute = (id) => {
    props
      .savePart({ id, setActive: true })
      .catch((err) =>
        console.error("Could not unarchive part type attribute\n", err)
      )
      .finally(() => {
        window.location.reload();
      });
  };

  const handleSavePartTypeAttribute = (id, value) => {
    // First, find the part type attribute that we are editing
    const partTypeAttribute = data.attributes.find((part) => part.id === id);
    // If the value is the same, do nothing
    if (partTypeAttribute.value === value) {
      setEditingPartTypeAttributeID(null);
      return;
    }
    // Next, create a new object with the updated value
    // We only allow editing the value of the part type attribute
    const updatedPartTypeAttribute = {
      ...partTypeAttribute,
      value: value,
    };
    // Finally, dispatch the action to update the part type attribute
    props
      .savePart(updatedPartTypeAttribute)
      .then(() => {
        // TODO: Update the part type attribute in the redux store
      })
      .catch((err) => {
        console.error("Could not update part type attribute\n", err);
      })
      .finally(() => {
        // Reset the editing part type attribute ID
        setEditingPartTypeAttributeID(null);
        window.location.reload();
      });
  };

  // Make sure to weave the actions column into the expandedColumns array
  const partTypeAttributes = data.attributes.map((attribute) => {
    return {
      ...attribute,
      actions: <ResourcePopOver />,
    };
  });

  const addPartTypeAttributeModal = () => {
    const { showModal } = props;
    const modalData = {
      type: modalTypes.FORM_ADD_PART_TYPE_ATTRIBUTE,
      title: t("modal.title.add_part_type_attribute", {
        partTypeName: data.name,
      }),
      data,
    };
    showModal(modalData);
  };

  return (
    <StyledContainer>
      <PartTable
        data={partTypeAttributes}
        columns={attributeColumns}
        button={{
          text: "Add Part Type Attribute",
          onClick: addPartTypeAttributeModal,
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: 2rem;
  margin-left: 2rem;
  flex-direction: column;
  display: flex;
`;

const SaveButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyledInput = styled(Input)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const mapStateToProps = (state) => ({
  attributes: state.part.attributes,
});

const mapDispatchToProps = (dispatch) => ({
  savePart: (partAttributeData) =>
    dispatch(updatePart("part-type-attribute", partAttributeData)),
  deletePart: (partID) => dispatch(deletePart("part-type-attribute", partID)),
  showModal: (data) => dispatch(showModal(data)),
  fetchAttributes: () => dispatch(fetchAttributes()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PartTypeAttributeTable));
