export default {
  en: {
    ticket: {
      labels: {
        bike_make: "Bike Make",
        bike_serial_number: "Bike Serial Number",
        part_type_label: "Part Type",
        part_serial_number: "Part Serial Number",
        part_model: "Part Model",
        part_attributes: "Attributes",
        date_purchased: "Date Purchased",
        diagnosis: "Diagnosis",
        message_text: "Message Text",
        files: "File Upload",
        technician: "Technician",
        hidden: "Hidden",
        order: {
          item_number: "Item Number",
          category: "Category",
          price_per: "Price ea.",
          quantity: "Quantity",
          item_total: "Item Total",
          ticket: "Ticket Number",
          shipping_price: "Shipping Price",
          tracking_number: "Tracking Number",
          ra_number: "RA Number",
          invoice_number: "Invoice Number",
          order_total: "Grand Total"
        }
      },
      headings: {
        message: "Message",
        parts_and_shipping: "Parts & Shipping",
        parts_and_shipping_2: "Parts and Shipping",
        parts: "Parts",
        shipping: "Shipping",
        claim: "Claim Details",
        customer: "Customer",
        dealership: "Dealership",
        tickets: "Tickets"
      },
      dropdown: {
        edit: "Edit Ticket",
        select_status: "Select a Status"
      },
      dropdown_message: {
        select_status: "Select a Status",
        edit: "Edit",
        hide: "Hide",
        show: "Show"
      },
      success: {
        updated: "Your ticket was updated successfully"
      },
      buttons: {
        send_message: "Send Message",
        send_message_update_status: "Send Message & Update Status",
        update: "Update",
        contact_us: "Contact Us",
        save: "Save Order",
        add_item: "Add Item",
        view_tickets: "Back to Tickets"
      },
      placeholders: {
        message_text: "Add your response here..."
      },
      helper_text: {
        files: "Accepted file formats: .PNG, .JPG"
      },
      alt: {
        part_image: "View of Part(s) in Question",
        user_portrait: "User Portrait",
        status_new: "Opened by {{customer}} {{timestamp}}",
        status_update: "Updated: Status Set To {{status}} {{timestamp}}"
      },
      error: {
        403: "You do not have the necessary permissions to access this content.",
        500: "A technical issue prevented us from creating your ticket. Our staff has been notified of the error",
        order: {
          405: "Some of the information required was either missing or incorrectly formatted. Please double check your submission and try again.",
          404: "The ticket for which you are trying to add a parts order was not found",
          201: "Parts order saved successfully"
        }
      }
    }
  },
  de: {
    ticket: {
      labels: {
        bike_make: "Fahrradmarke",
        bike_serial_number: "Seriennummer des Fahrrads",
        part_type_label: "Teil typ",
        part_serial_number: "Seriennummer des Teils",
        part_model: "Teilemodell",
        part_attributes: "Attributes",
        date_purchased: "Kaufdatum",
        diagnosis: "Diagnose",
        message_text: "Nachrichtentext",
        files: "Datei-Upload",
        technician: "Techniker",
        order: {
          item_number: "Artikelnummer",
          category: "Kategorie",
          price_per: "Preis pro Stück",
          quantity: "Menge",
          item_total: "Artikel Gesamt",
          ticket: "Ticketnummer",
          shipping_price: "Versandkosten",
          tracking_number: "Auftragsnummer, Frachtnummer, Sendungscode",
          ra_number: "RA-Nummer",
          invoice_number: "Rechnungsnummer",
          order_total: "Gesamtsumme"
        }
      },
      headings: {
        message: "Nachricht",
        parts_and_shipping: "Teile & Versand",
        parts_and_shipping_2: "Teile und Versand",
        parts: "Teile",
        shipping: "Versand",
        claim: "Anspruchsdetails",
        customer: "Kunde",
        dealership: "Händler",
        tickets: "Eintrittskarten"
      },
      dropdown: {
        edit: "Ticket bearbeiten",
        select_status: "Wählen Sie einen Status aus"
      },
      success: {
        updated: "Ihr Ticket wurde erfolgreich aktualisiert"
      },
      buttons: {
        send_message: "Nachricht senden",
        send_message_update_status: "Nachricht senden und Status aktualisieren",
        update: "Aktualisieren",
        contact_us: "Kontaktiere uns",
        save: "Bestellung speichern",
        add_item: "Artikel hinzufügen",
        view_tickets: "Zurück zu Tickets"
      },
      placeholders: {
        message_text: "Fügen Sie hier Ihre Antwort hinzu..."
      },
      helper_text: {
        files: "Akzeptierte Dateiformate: .PNG, .JPG"
      },
      alt: {
        part_image: "Ansicht der betreffenden Teile",
        user_portrait: "Benutzerportrait",
        status_new: "Geöffnet von {{customer}} {{timestamp}}",
        status_update:
          "Aktualisiert: Status auf {{status}} {{timestamp}} gesetzt"
      },
      error: {
        403: "Sie haben nicht die erforderlichen Berechtigungen, um auf diesen Inhalt zuzugreifen.",
        500: "Ein technisches Problem hat uns daran gehindert, Ihr Ticket zu erstellen. Unsere Mitarbeiter wurden über den Fehler informiert",
        order: {
          405: "Einige der erforderlichen Informationen fehlten oder waren falsch formatiert. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.",
          404: "Das Ticket, für das Sie versuchen, eine Teilebestellung hinzuzufügen, wurde nicht gefunden",
          201: "Ersatzteilbestellung erfolgreich gespeichert"
        }
      }
    }
  },
  jp: {
    ticket: {
      labels: {
        bike_make: "バイクメイク",
        bike_serial_number: "自転車のシリアル番号",
        part_type_label: "パーツタイプ",
        part_serial_number: "部品のシリアル番号",
        part_model: "パーツモデル",
        part_attributes: "属性",
        date_purchased: "購入日",
        diagnosis: "診断",
        message_text: "メッセージテキスト",
        files: "ファイルのアップロード",
        technician: "技術者",
        order: {
          item_number: "商品番号",
          category: "カテゴリー",
          price_per: "価格ea。",
          quantity: "量",
          item_total: "アイテム合計",
          ticket: "チケット番号",
          shipping_price: "配送料",
          tracking_number: "追跡番号",
          ra_number: "RA番号",
          invoice_number: "請求書番号",
          order_total: "総計"
        }
      },
      headings: {
        message: "メッセージ",
        parts_and_shipping: "部品と配送",
        parts_and_shipping_2: "部品と配送",
        parts: "部品",
        shipping: "運送",
        claim: "クレームの詳細",
        customer: "お客様",
        dealership: "ディーラー",
        tickets: "切符売場"
      },
      dropdown: {
        edit: "チケットの編集",
        select_status: "ステータスを選択"
      },
      success: {
        updated: "チケットが正常に更新されました"
      },
      buttons: {
        send_message: "メッセージを送る",
        send_message_update_status: "メッセージの送信とステータスの更新",
        update: "アップデート",
        contact_us: "お問い合わせ",
        save: "注文を保存",
        add_item: "アイテムを追加",
        view_tickets: "チケットに戻る"
      },
      placeholders: {
        message_text: "ここにあなたの応答を追加してください..."
      },
      helper_text: {
        files: "使用可能なファイル形式：.PNG、.JPG"
      },
      alt: {
        part_image: "問題の部品の表示",
        user_portrait: "ユーザーポートレート",
        status_new: "{{customer}}{{timestamp}}によって開かれました",
        status_update: "更新：ステータスを{{status}}{{timestamp}}に設定"
      },
      error: {
        403: "このコンテンツにアクセスするために必要な権限がありません。",
        500: "技術的な問題により、チケットを作成できませんでした。エラーが発生したことがスタッフに通知されました",
        order: {
          405: "必要な情報の一部が欠落しているか、正しくフォーマットされていませんでした。提出物を再確認して、再試行してください。",
          404: "パーツオーダーを追加しようとしているチケットが見つかりませんでした",
          201: "部品の注文が正常に保存されました"
        }
      }
    }
  },
  it: {
    ticket: {
      labels: {
        bike_make: "Marca bici",
        bike_serial_number: "Numero di serie della bici",
        part_type_label: "Tipo di parte",
        part_serial_number: "Numero di serie della parte",
        part_model: "Modello in parte",
        part_attributes: "Attributi",
        date_purchased: "Data di acquisto",
        diagnosis: "Diagnosi",
        message_text: "Messaggio di testo",
        files: "Upload di file",
        technician: "Tecnico",
        order: {
          item_number: "Codice articolo",
          category: "Categoria",
          price_per: "Prezzo es.",
          quantity: "Quantità",
          item_total: "Totale articolo",
          ticket: "Numero del biglietto",
          shipping_price: "Prezzo di spedizione",
          tracking_number: "Numero di identificazione",
          ra_number: "Numero RA",
          invoice_number: "Numero di fattura",
          order_total: "Somma totale"
        }
      },
      headings: {
        message: "Messaggio",
        parts_and_shipping: "Ricambi e spedizione",
        parts_and_shipping_2: "Ricambi e spedizione",
        parts: "Parti",
        shipping: "Spedizione",
        claim: "Dettagli del reclamo",
        customer: "Cliente",
        dealership: "Concessionario",
        tickets: "Biglietti"
      },
      dropdown: {
        edit: "Modifica biglietto",
        select_status: "Seleziona uno stato"
      },
      success: {
        updated: "Il tuo biglietto è stato aggiornato correttamente"
      },
      buttons: {
        send_message: "Invia messaggio",
        send_message_update_status: "Invia messaggio e aggiorna lo stato",
        update: "Aggiornare",
        contact_us: "Contattaci",
        save: "Salva ordine",
        add_item: "Aggiungi articolo",
        view_tickets: "Torna a Biglietti"
      },
      placeholders: {
        message_text: "Aggiungi qui la tua risposta..."
      },
      helper_text: {
        files: "Formati di file accettati: .PNG, .JPG"
      },
      alt: {
        part_image: "Vista della parte(i) in questione",
        user_portrait: "Ritratto dell'utente",
        status_new: "Aperto da {{cliente}} {{timestamp}}",
        status_update: "Aggiornato: stato impostato su {{status}} {{timestamp}}"
      },
      error: {
        403: "Non hai le autorizzazioni necessarie per accedere a questo contenuto.",
        500: "Un problema tecnico ci ha impedito di creare il tuo biglietto. Il nostro personale è stato informato dell'errore",
        order: {
          405: "Alcune delle informazioni richieste erano mancanti o formattate in modo errato. Si prega di ricontrollare l'invio e riprovare.",
          404: "Il biglietto per il quale stai tentando di aggiungere un ordine di parti non è stato trovato",
          201: "Ordine delle parti salvato correttamente"
        }
      }
    }
  }
};
