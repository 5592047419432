import React from "react";
import { JsonEditor } from "json-edit-react";

const Editor = ({ data }) => {
  return (
    <JsonEditor
      data={data}
      collapse={2}
      onUpdate={({ newData }) => {
        // Do something with the new data, e.g. update jsonData
      }}
    />
  );
};

export default Editor;
