export default {
  en: {
    dealership: {
      heading_main: '{{ dealer_name }}',
      heading_sub:
        'Send an invite to join your organization. Recipient will receive a link to accept the invitation or create an account.',
      heading_info: 'General Information',
      heading_locations: 'Locations',
      heading_users: 'Users',
      heading_tickets: 'Recent Tickets',
      invite_user: 'User invited successfully',
      submit_button: 'Send Invite',
      error: {
        500: 'A technical issue prevented us loading the list of dealerships. Our staff has been notified of the error',
        403: 'User not authorized',
        404: 'The specified dealership and/or location could not be found',
        200: 'Dealership updated successfully'
      },
      buttons: {
        add: 'Add',
        update: 'Update Dealership',
        add_user: 'Invite User',
        add_location: 'Add Location',
        view_dealers: 'Back to Dealerships'
      }
    }
  },
  de: {
    dealership: {
      heading_main: '{{ dealer_name }}',
      heading_sub:
        'Senden Sie eine Einladung zum Beitritt zu Ihrer Organisation. Der Empfänger erhält einen Link, um die Einladung anzunehmen oder ein Konto zu erstellen.',
      heading_info: 'Allgemeine Informationen',
      heading_locations: 'Standorte',
      heading_users: 'Benutzer',
      heading_tickets: 'Aktuelle Tickets',
      invite_user: 'Benutzer erfolgreich eingeladen',
      submit_button: 'Sende Einladung',
      error: {
        500: 'Ein technisches Problem verhinderte das Laden der Händlerliste. Unsere Mitarbeiter wurden über den Fehler informiert',
        403: 'Benutzer nicht berechtigt',
        404: 'Der angegebene Händler und/oder Standort konnte nicht gefunden werden',
        200: 'Händler erfolgreich aktualisiert'
      },
      buttons: {
        add: 'Addieren',
        update: 'Händler aktualisieren',
        add_user: 'Benutzer einladen',
        add_location: 'Ort hinzufügen',
        view_dealers: 'Zurück zu den Händlern'
      }
    }
  },
  jp: {
    dealership: {
      heading_main: '{{ dealer_name }}',
      heading_sub:
        '組織に参加するための招待状を送信します。受信者は、招待を受け入れるか、アカウントを作成するためのリンクを受け取ります。',
      heading_info: '一般情報',
      heading_locations: '場所',
      heading_users: 'ユーザー',
      heading_tickets: '最近のチケット',
      invite_user: 'ユーザーは正常に招待されました',
      submit_button: '招待を送る',
      error: {
        500: '技術的な問題により、ディーラーのリストをロードできませんでした。エラーが発生したことがスタッフに通知されました',
        403: 'ユーザーが許可されていません',
        404: '指定された販売店および/または場所が見つかりませんでした',
        200: 'ディーラーが正常に更新されました'
      },
      buttons: {
        add: '追加',
        update: 'ディーラーの更新',
        add_user: 'ユーザーを招待',
        add_location: '場所を追加',
        view_dealers: 'ディーラーに戻る'
      }
    }
  },
  it: {
    dealership: {
      heading_main: '{{ dealer_name }}',
      heading_sub:
        "Invia un invito per entrare a far parte della tua organizzazione. Il destinatario riceverà un link per accettare l'invito o creare un account.",
      heading_info: 'Informazioni generali',
      heading_locations: 'Posizioni',
      heading_users: 'Utenti',
      heading_tickets: 'Biglietti recenti',
      invite_user: 'Utente invitato con successo',
      submit_button: 'Invia un invito',
      error: {
        500: "Un problema tecnico ci ha impedito di caricare l'elenco dei concessionari. Il nostro personale è stato informato dell'errore",
        403: 'Utente non autorizzato',
        404: "Impossibile trovare il concessionario e/o l'ubicazione specificati",
        200: 'Concessionaria aggiornata con successo'
      },
      buttons: {
        add: 'Aggiungere',
        update: 'Aggiorna Concessionaria',
        add_user: "Invita l'utente",
        add_location: 'Aggiungi luogo',
        view_dealers: 'Torna a Concessionarie'
      }
    }
  }
};
