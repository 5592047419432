import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import ResourcePopOver from "./ResourcePopOver";
import styled from "styled-components";
import PartTable from "./PartTable";
import PartModelAttributesTable from "./PartModelAttributesTable";
import { Button, Input } from "Components";
import { connect } from "react-redux";
import { updatePart, deletePart } from "Actions/part";
import { showModal } from "Actions/modal";
import * as modalTypes from "Components/Modal/types";

const PartModelTable = (props) => {
  const [isEditingPartModelID, setEditingPartModelID] = useState(null);
  const [editedPartModelName, setEditedPartModelName] = useState("");
  const { data, t } = props;
  const modelColumns = [
    {
      id: "id",
      name: t("table.id"),
      selector: "id",
      grow: 1,
      cell: (row) => row.id,
    },
    {
      id: "name",
      name: t("table.name"),
      selector: "name",
      grow: 2,
      cell: (row) =>
        isEditingPartModelID === row.id ? (
          <StyledInput
            type="text"
            value={editedPartModelName}
            onChangeFunc={(e) => setEditedPartModelName(e.currentTarget.value)}
          />
        ) : (
          row.name
        ),
    },
    {
      id: "active",
      name: t("table.status"),
      selector: "active",
      grow: 1,
      cell: (row) =>
        row.active ? t("data.status.active") : t("data.status.archived"),
    },
    {
      id: "actions",
      name: "Actions",
      selector: "actions",
      compact: true,
      cell: (row) =>
        isEditingPartModelID === row.id ? (
          <SaveButton
            onClickFunc={() =>
              handleSavePartModel(isEditingPartModelID, editedPartModelName)
            }
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            Save
          </SaveButton>
        ) : (
          <ResourcePopOver
            onClickEdit={handleEditPartModel}
            onClickDelete={handleDeletePartModel}
            onClickUnarchive={handleUnarchivePartModel}
            resourceID={row.id}
          />
        ),
    },
  ];

  const addPartModelModal = () => {
    const { showModal } = props;
    const modalData = {
      type: modalTypes.FORM_ADD_PART_MODEL,
      title: t("modal.title.add_part_model", {
        partTypeName: data.name,
      }),
      data,
    };
    showModal(modalData);
  };

  const ExpandedModels = ({ data }) => {
    return <PartModelAttributesTable data={data} />;
  };

  const handleEditPartModel = (id) => {
    const part = data.models.find((part) => part.id === id);
    setEditedPartModelName(part.name);
    setEditingPartModelID(id);
  };

  const handleDeletePartModel = (id) => {
    props
      .deletePart({ id })
      .catch((err) => {
        console.error("Could not delete part model\n", err);
      })
      .finally(() => {
        window.location.reload();
      });
  };

  const handleUnarchivePartModel = (id) => {
    const partModel = data.models.find((part) => part.id === id);
    props
      .savePart({ id, ...partModel, setActive: true })
      .catch((err) =>
        console.error("Could not unarchive part type attribute\n", err)
      )
      .finally(() => {
        window.location.reload();
      });
  };

  const handleSavePartModel = (id, name) => {
    // First, find the part type attribute that we are editing
    const partModel = data.models.find((part) => part.id === id);
    // If the value is the same, do nothing
    if (partModel.name === name) {
      setEditingPartTypeAttributeID(null);
      return;
    }
    // Next, create a new object with the updated value
    // We only allow editing the value of the part type attribute
    const updatedPartModel = {
      ...partModel,
      name: name,
    };
    // Finally, dispatch the action to update the part type attribute
    props
      .savePart(updatedPartModel)
      .then(() => {
        // TODO: Update the part type attribute in the redux store
      })
      .catch((err) => {
        console.error("Could not update part type attribute\n", err);
      })
      .finally(() => {
        // Reset the editing part type attribute ID
        setEditingPartModelID(null);
        window.location.reload();
      });
  };

  const sortedPartModels = data.models.toSorted(
    (a, b) => b.display_order - a.display_order
  );

  return (
    <StyledContainer>
      <PartTable
        expandedComponent={ExpandedModels}
        button={{
          text: "Add Part Model",
          onClick: addPartModelModal,
        }}
        data={sortedPartModels}
        columns={modelColumns}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: 2rem;
  margin-left: 2rem;
  flex-direction: column;
  display: flex;
`;

const SaveButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyledInput = styled(Input)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  savePart: (partAttributeData) =>
    dispatch(updatePart("part-model", partAttributeData)),
  deletePart: (partID) => dispatch(deletePart("part-model", partID)),
  showModal: (data) => dispatch(showModal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PartModelTable));
