import { FETCH_BIKE_MAKES_SUCCESS } from 'Actions/bike/types';

const initialState = {
  makes: []
};

const partReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BIKE_MAKES_SUCCESS: {
      return {
        ...state,
        makes: action.payload
      };
    }
    default:
      return state;
  }
};

export default partReducer;
