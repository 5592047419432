import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, bootstrapTheme } from 'Theme';
import { makeTheme } from 'bootstrap-styled/lib/theme';
import BSDropdown from '@bootstrap-styled/v4/lib/Dropdown/index';
import BSDropdownMenu from '@bootstrap-styled/v4/lib/Dropdown/DropdownMenu';
import BSDropdownToggle from '@bootstrap-styled/v4/lib/Dropdown/DropdownToggle';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  render() {
    const { isOpen } = this.state;
    const { toggle, children, theme, right, className, size } = this.props;
    return (
      <StyledDropdown
        className={className}
        size={size}
        isOpen={isOpen}
        toggle={() => this.setState(() => ({ isOpen: !isOpen }))}
        theme={theme}
      >
        <StyledToggle color="muted" tag="div">
          {toggle}
        </StyledToggle>
        <StyledMenu right={right}>{children}</StyledMenu>
      </StyledDropdown>
    );
  }
}

Dropdown.propTypes = {
  toggle: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  right: PropTypes.bool,
  theme: PropTypes.shape(),
  size: PropTypes.oneOf(['sm', 'lg']),
  className: PropTypes.string
};

Dropdown.defaultProps = {
  theme: makeTheme(bootstrapTheme),
  right: true,
  className: '',
  size: 'sm'
};

const StyledDropdown = styled(BSDropdown)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledToggle = styled(BSDropdownToggle)`
  cursor: pointer;
  background: none;
  border: none;
`;

const StyledMenu = styled(BSDropdownMenu)`
  &.dropdown-menu {
    padding: ${space[1]}rem 0;
    margin: 0;
    border: 1px solid black;
    .dropdown-item {
      font-stretch: normal;
      padding: 10px ${space[2]}rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export default Dropdown;
