import React from 'react';
import PropTypes from 'prop-types';

const ReportsIcon = ({ size }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
  >
    <g>
      <g>
        <path
          d="M85.088,80.271c2.715,0,4.912-2.2,4.912-4.917V24.645c0-2.717-2.197-4.917-4.912-4.917H14.912
          c-2.714,0-4.912,2.2-4.912,4.917v50.709c0,2.717,2.197,4.917,4.912,4.917H85.088z M80.175,70.443h-60.35V29.556h60.35V70.443z"
        />
      </g>
      <g>
        <g>
          <path
            d="M41.564,65.897c-0.927,0-1.819-0.37-2.481-1.029l-4.896-4.897l-5.96,4.932c-1.5,1.242-3.708,1.024-4.942-0.466
            c-1.234-1.495-1.026-3.71,0.466-4.941l8.424-6.969c1.394-1.156,3.434-1.06,4.719,0.223l4.072,4.075l8.979-13.989
            c0.69-1.07,1.874-1.688,3.181-1.602c1.272,0.082,2.4,0.846,2.945,2.002l5.299,11.206L71.602,36.45
            c0.96-1.688,3.099-2.266,4.787-1.318c1.683,0.963,2.273,3.102,1.315,4.79L64.133,63.784c-0.646,1.135-1.901,1.82-3.183,1.769
            c-1.31-0.046-2.481-0.821-3.041-2.008l-5.479-11.591L44.516,64.28c-0.573,0.897-1.521,1.48-2.577,1.597
            C41.815,65.887,41.688,65.897,41.564,65.897z"
          />
        </g>
      </g>
    </g>
  </svg>
);

ReportsIcon.propTypes = {
  size: PropTypes.string
};

ReportsIcon.defaultProps = {
  size: '24px'
};

export default ReportsIcon;
