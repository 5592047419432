import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fonts, fontSizes } from 'Theme/typography';

const Paragraph = ({ children, lead, className }) => (
  <P className={className} lead={lead}>
    {children}
  </P>
);

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  lead: PropTypes.bool
};

Paragraph.defaultProps = {
  lead: false
};

const P = styled.p`
  font-family: ${fonts.sansSerif};
  font-stretch: condensed;
  font-size: ${props => (props.lead ? fontSizes[2] : fontSizes[1])}rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

export default Paragraph;
