export const ADD_ACTION = 'ADD_ACTION';
export const addAction = data => ({
  type: ADD_ACTION,
  payload: data
});

const actionLogger = store => next => action => {
  if (action.type !== ADD_ACTION) {
    store.dispatch(addAction(action));
  }
  return next(action);
};
export default actionLogger;
