export default {
  en: {
    service_center_list: {
      error: {
        403: 'You do not have the necessary permissions to access this content.',
        500: 'A technical issue prevented us from loading the list of service centers. Our staff has been notified of the error'
      }
    }
  }
};
