import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { updateUser, sendPasswordReset } from "Actions/user";
import Row from "@bootstrap-styled/v4/lib/Row";
import Col from "@bootstrap-styled/v4/lib/Col";
import Option from "@bootstrap-styled/v4/lib/Option";
import { Alert, Button, Form, Heading, Input, Label, Avatar } from "Components";
import { space, colors } from "Theme";
import { parsePhoneNumber } from "libphonenumber-js";
import countries from "../../components/Utils/countryData";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    const {
      user,
      user: { address },
    } = props;
    this.state = {
      form: {
        user_id: user.id,
        name: user.name || "",
        country: address.country || "",
        address_one: address.address_one || "",
        address_two: address.address_two || "",
        city: address.city || "",
        state: address.state || "",
        zip: address.zip || "",
        countryCode: "US",
        phone: "",
        email: user.email || "",
        lang_preferred: user.lang || "",
        user_level: user.level || "dealer_user",
        service_center: user.service_center.name || "", // Display only
      },
      hasError: false,
      errorMsg: "",
      hasSuccess: false,
      successMsg: "",
      isSubmitting: false,
      isSendingEmail: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const {
      user: { phone },
    } = this.props;
    const { form } = this.state;

    const phoneNumber = parsePhoneNumber(phone);
    const nationalNumber = phoneNumber.formatNational();

    return this.setState(() => ({
      form: {
        ...form,
        phone: nationalNumber,
      },
    }));
  }

  handleChange = (e) => {
    const {
      target,
      target: { name, value, checked },
    } = e;
    const { form } = this.state;
    let newValue;
    if (target.type === "checkbox") {
      newValue = checked;
    } else {
      newValue = value;
    }
    form[name] = newValue;
    this.setState(() => ({ form }));
  };

  handleSubmit = async () => {
    const { form } = this.state;
    const { t, updateUser } = this.props;

    this.setState(() => ({ isSubmitting: true }));
    let hasError = false;
    let errorMsg = "";
    let hasSuccess = false;
    let successMsg = "";

    const newForm = { ...form };
    if (form.phone === "") {
      newForm.phone = "";
    } else {
      const phoneNumber = parsePhoneNumber(form.phone, form.countryCode);
      newForm.phone = phoneNumber.number;
    }

    await updateUser(newForm)
      .then(() => {
        hasSuccess = true;
        successMsg = t("my_acct.success.update");
      })
      .catch((errStatus) => {
        hasError = true;
        errorMsg = t(`my_acct.error.${errStatus}`);
      });
    this.setState(() => ({
      hasError,
      errorMsg,
      hasSuccess,
      successMsg,
      isSubmitting: false,
    }));
  };

  handleResetPassword = async () => {
    const {
      sendPasswordReset,
      user: { email },
      t,
    } = this.props;
    this.setState(() => ({ isSendingEmail: true }));
    let hasError = false;
    let errorMsg = "";
    let hasSuccess = false;
    let successMsg = "";
    const data = { email };
    await sendPasswordReset(data)
      .then(() => {
        hasSuccess = true;
        successMsg = t("forgot_password.success.email_sent_msg_alt");
      })
      .catch((errStatus) => {
        hasError = true;
        errorMsg = t(`forgot_password.error.${errStatus}`);
      });
    this.setState(() => ({
      hasError,
      errorMsg,
      hasSuccess,
      successMsg,
      isSendingEmail: false,
    }));
  };

  render() {
    const { user, t } = this.props;
    const {
      form,
      hasError,
      errorMsg,
      hasSuccess,
      successMsg,
      isSubmitting,
      isSendingEmail,
    } = this.state;

    const genericRequiredRule = {
      rule: "required",
      messages: {
        error: t("form.required_feedback"),
        success: " ",
      },
    };

    return (
      <Fragment>
        <Form
          onSubmitFunc={() => this.handleSubmit()}
          validation={{
            name: [genericRequiredRule],
            email: [genericRequiredRule],
            user_level: [genericRequiredRule],
            phone: [
              {
                rule: "phone",
                messages: {
                  error: t("form.invalid.phone_number"),
                  success: "",
                },
              },
            ],
          }}
          render={(status) => (
            <Fragment>
              <Alert
                alertIsOpen={hasSuccess}
                color="success"
                onCloseFunc={() => this.setState(() => ({ hasSuccess: false }))}
              >
                {successMsg}
              </Alert>
              <Alert
                alertIsOpen={hasError}
                color="danger"
                onCloseFunc={() => this.setState(() => ({ hasError: false }))}
              >
                {errorMsg}
              </Alert>
              <AccountHeader>
                <Heading size={4}>{t("my_acct.headings.settings")}</Heading>
              </AccountHeader>
              <Section>
                <Row>
                  <Col md={4}>
                    <Heading size={6}>
                      {t("my_acct.headings.account_info")}
                    </Heading>
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col md={6}>
                        <Input
                          onChangeFunc={this.handleChange}
                          label={t("form.labels.email")}
                          inputId="user-email"
                          name="email"
                          value={form.email}
                          status={status.email.status}
                          width="100%"
                          isDisabled
                        />
                      </Col>
                      <Col md={6}>
                        <div className="d-flex">
                          <Input
                            label={t("form.labels.country_code")}
                            type="select"
                            name="countryCode"
                            value={form.countryCode}
                            onChangeFunc={this.handleChange}
                            inputId="countryCode"
                            width="100%"
                            isDisabled
                          >
                            {countries &&
                              countries
                                .sort((a, b) => {
                                  return a.countryCode - b.countryCode;
                                })
                                .map((country) => (
                                  <Option
                                    key={country.iso2}
                                    value={country.iso2.toUpperCase()}
                                  >
                                    +{country.countryCode}
                                  </Option>
                                ))}
                          </Input>
                          <Input
                            onChangeFunc={this.handleChange}
                            label={t("form.labels.phone")}
                            inputId="user-phone"
                            name="phone"
                            value={form.phone}
                            status={status.phone.status}
                            feedbackMsg={status.phone.feedback}
                            type="tel"
                            width="100%"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Input
                          onChangeFunc={this.handleChange}
                          label={t("form.labels.lang_pref")}
                          inputId="lang-pref"
                          name="lang_preferred"
                          value={form.lang_preferred}
                          type="select"
                          width="100%"
                          status="muted"
                        >
                          <Option value="en">{t("languages.en")}</Option>
                          <Option value="es">{t("languages.es")}</Option>
                          <Option value="fr">{t("languages.fr")}</Option>
                          <Option value="it">{t("languages.it")}</Option>
                          <Option value="jp">{t("languages.jp")}</Option>
                          <Option value="nl">{t("languages.nl")}</Option>
                          <Option value="zh">{t("languages.zh")}</Option>
                        </Input>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label htmlFor="reset-password">
                          {t("my_acct.labels.security")}
                        </Label>
                        <Button
                          color="secondary"
                          outline
                          onClickFunc={this.handleResetPassword}
                          isFetching={isSendingEmail}
                          width="max-content"
                          helperText={t("my_acct.helper_text.change_password")}
                        >
                          {t("my_acct.buttons.change_password")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Section>
              <Section>
                <Row>
                  <Col md={4}>
                    <Heading size={6}>{t("my_acct.headings.general")}</Heading>
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col md={3}>
                        <AvatarWrapper>
                          <ImageLink
                            href="https://gravatar.com/"
                            target="_blank"
                          >
                            <Avatar userImg={user.profile_photo_url} isLarge />
                          </ImageLink>
                          <p style={{ fontSize: "13px" }}>
                            Generated by{" "}
                            <a
                              href="https://gravatar.com/"
                              target="_blank"
                              style={{ color: colors.black.base }}
                              rel="noopener noreferrer"
                            >
                              Gravatar
                            </a>
                          </p>
                        </AvatarWrapper>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Input
                          onChangeFunc={this.handleChange}
                          label={t("form.labels.name")}
                          inputId="user-name"
                          name="name"
                          value={form.name}
                          status={status.name.status}
                          width="100%"
                        />
                        <Input
                          onChangeFunc={this.handleChange}
                          label={t("form.labels.street_address")}
                          inputId="user-street-address"
                          name="address_one"
                          value={form.address_one}
                          status="muted"
                          width="100%"
                        />
                        <Input
                          onChangeFunc={this.handleChange}
                          label={t("form.labels.address_two")}
                          inputId="user-address-two"
                          name="address_two"
                          value={form.address_two}
                          status="muted"
                          width="100%"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <Input
                          onChangeFunc={this.handleChange}
                          label={t("form.labels.city")}
                          inputId="user-city"
                          name="city"
                          value={form.city}
                          status="muted"
                          width="100%"
                        />
                      </Col>
                      <Col md={4}>
                        <Input
                          onChangeFunc={this.handleChange}
                          label={t("form.labels.state")}
                          inputId="user-state"
                          name="state"
                          value={form.state}
                          status="muted"
                          width="100%"
                        />
                      </Col>
                      <Col md={4}>
                        <Input
                          onChangeFunc={this.handleChange}
                          label={t("form.labels.zip")}
                          inputId="user-zip"
                          name="zip"
                          value={form.zip}
                          status="muted"
                          width="100%"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Input
                          onChangeFunc={this.handleChange}
                          label={t("form.labels.country")}
                          inputId="user-country"
                          type="select"
                          name="country"
                          value={form.country}
                          status="muted"
                          width="100%"
                          isDisabled
                        >
                          <Option value="United States">United States</Option>
                        </Input>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Input
                          onChangeFunc={this.handleChange}
                          label={t("form.labels.service_center_name")}
                          inputId="user-service-center"
                          type="select"
                          name="service_center"
                          value={form.service_center}
                          status="muted"
                          width="100%"
                          isDisabled
                        >
                          <Option value={form.service_center}>
                            {form.service_center}
                          </Option>
                        </Input>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Section>
              <ButtonWrapper className="d-flex w-100 justify-content-end">
                <Button
                  type="submit"
                  width="max-content"
                  isFetching={isSubmitting}
                >
                  {t("my_acct.buttons.update")}
                </Button>
              </ButtonWrapper>
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
}

MyAccount.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    level: PropTypes.string,
    address: PropTypes.shape(),
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    lang_preferred: PropTypes.string,
    profile_photo_url: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  sendPasswordReset: PropTypes.func.isRequired,
};

const AccountHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${space[3]}rem;
`;

const Section = styled.section`
  padding-top: ${space[1] * 7}rem;
  padding-bottom: ${space[4]}rem;
  margin-bottom: ${space[1]}rem;
  border-bottom: 2px solid ${colors.gray.base};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${space[1] * 3}rem;
`;

const AvatarWrapper = styled.div`
  margin-bottom: ${space[3]}rem;
`;

const ImageLink = styled.a`
  > img {
    &:hover {
      border: ${colors.gray.darker} solid 1px;
    }
  }
`;

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data)),
  sendPasswordReset: (data) => dispatch(sendPasswordReset(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MyAccount));
