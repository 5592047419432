import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fetchPartTypes } from 'Actions/part';
import { savePartsOrder, savePartsOrderReset } from 'Actions/order';
import { Alert, Button, Form, Heading, Input, Throbber } from 'Components';
import Row from '@bootstrap-styled/v4/lib/Row';
import Col from '@bootstrap-styled/v4/lib/Col';
import Option from '@bootstrap-styled/v4/lib/Option';

import { space, colors, typography, breakpoints } from 'Theme';
import { formatCentsToDollars } from 'Components/Utils';

class PartsOrder extends Component {
  constructor(props) {
    super(props);
    const { id: claimId, order } = props.ticket.current;
    if (order) {
      const {
        shipping_price: shipPrice,
        tracking_number: trackNum,
        ra_number: raNum,
        invoice_number: invNum,
        total_price: totalPrice,
        parts
      } = order;
      const revisedParts = parts.map(part => {
        const newPart = { ...part };
        newPart.price = formatCentsToDollars(Number(part.price_each));
        newPart.total = formatCentsToDollars(Number(part.order_total));
        newPart.category = part.part_type_id;
        return newPart;
      });
      this.state = {
        form: {
          claim_id: claimId,
          shipping_price: shipPrice
            ? formatCentsToDollars(Number(shipPrice))
            : 0,
          tracking_number: trackNum == null ? '' : trackNum,
          ra_number: raNum == null ? '' : raNum,
          invoice_number: invNum == null ? '' : invNum,
          order_total: totalPrice
            ? parseFloat(formatCentsToDollars(Number(totalPrice)))
            : 0,
          parts: [...revisedParts]
        },
        hasError: false,
        errorMsg: '',
        successMsg: '',
        isSubmitting: false,
        isLoading: true
      };
    } else {
      this.state = {
        form: {
          claim_id: claimId,
          shipping_price: 0,
          tracking_number: '',
          ra_number: '',
          invoice_number: '',
          order_total: 0,
          parts: []
        },
        hasError: false,
        errorMsg: '',
        successMsg: '',
        isSubmitting: false,
        isLoading: true
      };
    }
    this.addPartRow = this.addPartRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.calculateGrandTotal = this.calculateGrandTotal.bind(this);
  }

  async componentDidMount() {
    const { form } = this.state;
    const {
      fetchPartTypes,
      ticket: {
        current: { id }
      },
      part: { types: partTypes }
    } = this.props;
    form.claim_id = id;
    if (partTypes.length === 0) {
      await fetchPartTypes();
    }
    if (form.parts.length === 0) {
      this.addPartRow();
    }
    this.setState(() => ({ form, isLoading: false }));
  }

  async componentWillUnmount() {
    const { savePartsOrderReset } = this.props;
    savePartsOrderReset();
  }

  handleSubmit = async () => {
    const { t, savePartsOrder } = this.props;
    const { form } = this.state;
    const newForm = form;

    this.setState(() => ({ isSubmitting: true }));
    form.parts.forEach(part => {
      const newPart = part;
      newPart.quantity = parseInt(part.quantity, 10);
      return newPart;
    });
    let successMsg = '';
    let errorMsg = '';
    let hasError = false;
    await savePartsOrder(newForm)
      .then(() => {
        successMsg = t(`ticket.error.order.201`);
      })
      .catch(errStatus => {
        if (errStatus !== 401) {
          hasError = true;
          errorMsg = t(`ticket.error.order.${errStatus}`);
        }
      })
      .finally(() => {
        const { status: SAVE_PARTS_ORDER } = this.props;
        if (SAVE_PARTS_ORDER === 'success') {
          hasError = false;
        }
      });
    this.setState(() => ({
      isSubmitting: false,
      hasError,
      errorMsg,
      successMsg
    }));
  };

  handleChange = e => {
    const {
      target: { name, value }
    } = e;
    const { form } = this.state;
    if (name === 'shipping_price') {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(value) === false) {
        form.order_total -= Number(form.shipping_price);
        form.shipping_price = value;
        form.order_total += Number(form.shipping_price);
      }
    } else {
      form[name] = value;
    }
    this.setState(() => ({ form }));
  };

  handleChangeToPart = e => {
    const {
      target,
      target: { name, value }
    } = e;
    const { form } = this.state;
    const idArr = target.id.split('-');
    const i = idArr[idArr.length - 1];
    if (name === `price` || name === `quantity`) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(value) === false) {
        form.parts[i][name] = value;
        let newTotal = 0;
        if (value !== '') {
          const qty = parseInt(form.parts[i].quantity, 10);
          const price = parseFloat(form.parts[i].price);
          newTotal = formatCentsToDollars(qty * price);
        }
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(newTotal) === false) {
          form.parts[i].total = newTotal;
          form.order_total = this.calculateGrandTotal(form);
        }
      }
    } else {
      form.parts[i][name] = value;
    }
    this.setState(() => ({ form }));
  };

  calculateGrandTotal = form => {
    let orderTotal = 0;
    form.parts.forEach(part => {
      orderTotal += parseFloat(part.total, 10);
    });
    orderTotal += parseFloat(form.shipping_price, 10);
    return orderTotal;
  };

  addPartRow = async () => {
    const { form } = this.state;
    const newPart = {};
    newPart.id = Math.random().toString();
    newPart.item_number = '';
    newPart.category = '';
    newPart.price = '0';
    newPart.quantity = 0;
    newPart.total = 0;
    form.parts = [...form.parts, newPart];
    await this.setState(() => ({ form }));
  };

  removeRow = async id => {
    const idArr = id.split('-');
    const i = idArr[idArr.length - 1];
    const { form } = this.state;
    form.parts.splice(i, 1);
    form.order_total = this.calculateGrandTotal(form);
    await this.setState(() => ({ form }));
  };

  render() {
    const {
      t,
      part: { types: partTypes },
      status: { SAVE_PARTS_ORDER },
      savePartsOrderReset
    } = this.props;
    const {
      form,
      isSubmitting,
      isLoading,
      hasError,
      errorMsg,
      successMsg
    } = this.state;

    return isLoading ? (
      <Fragment>
        <Throbber isFullPage isDark />
      </Fragment>
    ) : (
      <Fragment>
        <Form
          onSubmitFunc={this.handleSubmit}
          validation={{}}
          ignoreRequiredOnBlur
          render={() => (
            <Fragment>
              <FormFieldsWrapper>
                <Heading size={5}>{t(`ticket.headings.parts`)}</Heading>
                {form.parts.map((part, index) => (
                  <PartRow
                    key={part.id}
                    part={part}
                    t={t}
                    i={index}
                    partTypes={partTypes}
                    removeRowFunc={this.removeRow}
                    handleChangeFunc={this.handleChangeToPart}
                  />
                ))}
                <Button
                  className="mb-5"
                  outline
                  color="secondary"
                  width="max-content"
                  onClickFunc={this.addPartRow}
                >
                  {t('ticket.buttons.add_item')}
                </Button>
                <Heading size={5}>{t(`ticket.headings.shipping`)}</Heading>
                <StyledRow className="mb-3" noGutters>
                  <Col md={2}>
                    <Input
                      label={t('ticket.labels.order.shipping_price')}
                      inputId="shipping_price"
                      name="shipping_price"
                      value={formatCentsToDollars(form.shipping_price)}
                      onChangeFunc={this.handleChange}
                      width="100%"
                      status="muted"
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      label={t('ticket.labels.order.tracking_number')}
                      inputId="tracking_number"
                      name="tracking_number"
                      value={form.tracking_number}
                      onChangeFunc={this.handleChange}
                      width="100%"
                      status="muted"
                    />
                  </Col>
                </StyledRow>
                <Heading size={5}>{t(`ticket.headings.claim`)}</Heading>
                <StyledRow className="mb-3" noGutters>
                  <Col md={4}>
                    <Input
                      label={t('ticket.labels.order.ra_number')}
                      inputId="ra_number"
                      name="ra_number"
                      value={form.ra_number}
                      onChangeFunc={this.handleChange}
                      width="100%"
                      status="muted"
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      label={t('ticket.labels.order.invoice_number')}
                      inputId="invoice_number"
                      name="invoice_number"
                      value={form.invoice_number}
                      onChangeFunc={this.handleChange}
                      width="100%"
                      status="muted"
                    />
                  </Col>
                </StyledRow>
              </FormFieldsWrapper>
              <Row>
                <Col md={{ size: 3, offset: 9 }}>
                  <Input
                    label={t('ticket.labels.order.order_total')}
                    inputId="order_total"
                    name="order_total"
                    value={formatCentsToDollars(form.order_total)}
                    onChangeFunc={this.handleChange}
                    isDisabled
                    width="100%"
                    status="muted"
                  />
                </Col>
              </Row>
              <Alert
                alertIsOpen={SAVE_PARTS_ORDER === 'success'}
                color="success"
                onCloseFunc={() => savePartsOrderReset()}
              >
                {successMsg}
              </Alert>
              <Alert
                alertIsOpen={hasError}
                color="danger"
                onCloseFunc={() => this.setState(() => ({ hasError: false }))}
              >
                {errorMsg}
              </Alert>
              <div className="float-right">
                {/* Hidden Input for Current Ticket Id */}
                <label hidden htmlFor="claim_id">
                  {t('ticket.labels.order.ticket')}
                </label>
                <input
                  hidden
                  type="text"
                  name="claim_id"
                  value={form.claim_id}
                  onChange={() => {}}
                />
                <Button type="submit" color="primary" isFetching={isSubmitting}>
                  {t('ticket.buttons.save')}
                </Button>
              </div>
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
}

PartsOrder.propTypes = {
  t: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    current: PropTypes.shape({
      id: PropTypes.number,
      order: PropTypes.shape({
        parts: PropTypes.arrayOf(PropTypes.shape({})),
        shipping_price: PropTypes.string,
        ra_number: PropTypes.string,
        invoice_number: PropTypes.string,
        total_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        tracking_number: PropTypes.string
      })
    })
  }).isRequired,
  part: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  status: PropTypes.shape({
    SAVE_PARTS_ORDER: PropTypes.string
  }).isRequired,
  fetchPartTypes: PropTypes.func.isRequired,
  savePartsOrder: PropTypes.func.isRequired,
  savePartsOrderReset: PropTypes.func.isRequired
};

const { fontSizes } = typography;
const FormFieldsWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.gray.base};
  margin-bottom: ${space[1] * 3}rem;
`;

const PartRow = ({
  t,
  part,
  i,
  handleChangeFunc,
  partTypes,
  removeRowFunc
}) => {
  return (
    <StyledRow id={`part-row-${i}`} className="align-items-start" noGutters>
      <Col md={3}>
        <Input
          label={t('ticket.labels.order.item_number')}
          inputId={`item-number-${i}`}
          name="item_number"
          value={part.item_number}
          onChangeFunc={handleChangeFunc}
          width="100%"
          status="muted"
          altRequired
        />
      </Col>
      <Col md={4}>
        <Input
          label={t('ticket.labels.order.category')}
          type="select"
          inputId={`category-${i}`}
          name="category"
          value={part.category}
          width="100%"
          placeholder={t('form.placeholders.select')}
          onChangeFunc={handleChangeFunc}
          status="muted"
          altRequired
        >
          <Option disabled value="">
            {t('form.placeholders.select')}
          </Option>
          {partTypes.map(type => (
            <Option key={type.id} value={type.id}>
              {type.name}
            </Option>
          ))}
        </Input>
      </Col>
      <Col md={2}>
        <Input
          label={t('ticket.labels.order.price_per')}
          inputId={`price-${i}`}
          name="price"
          value={part.price}
          onChangeFunc={handleChangeFunc}
          width="100%"
          status="muted"
          altRequired
        />
      </Col>
      <Col className="h-100" md={1}>
        <Input
          label={t('ticket.labels.order.quantity')}
          inputId={`quantity-${i}`}
          name="quantity"
          value={part.quantity}
          onChangeFunc={handleChangeFunc}
          width="100%"
          status="muted"
          altRequired
        />
      </Col>
      <Col className="h-100" md={2}>
        <Input
          label={t('ticket.labels.order.item_total')}
          inputId={`total-${i}`}
          name="total"
          value={part.total}
          onChangeFunc={handleChangeFunc}
          width="100%"
          isDisabled
          status="muted"
        />
      </Col>
      <CloseButton
        className="close-button"
        color="white"
        style={{
          marginBottom: 0
        }}
        onClickFunc={() => removeRowFunc(`part-row-${i}`)}
      >
        &times;
      </CloseButton>
    </StyledRow>
  );
};

PartRow.propTypes = {
  i: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  part: PropTypes.shape({
    item_number: PropTypes.string,
    category: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    price: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    total: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  partTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  removeRowFunc: PropTypes.func.isRequired,
  handleChangeFunc: PropTypes.func.isRequired
};

const StyledRow = styled(Row)`
  position: relative;
  &.row.no-gutters {
    [class*='col-']:not(:last-child) {
      padding-right: ${space[1]}rem;
    }
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: -${space[2]}rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  width: max-content;
  &.close-button > .btn {
    margin-left: ${space[1]}rem;
    padding: 0;
    .btn-text {
      color: ${colors.gray.darkest};
      font-size: ${fontSizes[3]}rem;
    }
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    top: initial;
    right: ${space[1] * 3}rem;
    bottom: ${space[1] * 3}rem;
    transform: translateY(0);
    z-index: 5;
  }
`;

const mapStateToProps = state => ({
  part: state.part,
  ticket: state.ticket,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  fetchPartTypes: () => dispatch(fetchPartTypes()),
  savePartsOrder: data => dispatch(savePartsOrder(data)),
  savePartsOrderReset: () => dispatch(savePartsOrderReset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PartsOrder));
