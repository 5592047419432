import {
  FETCH_TICKETS_SUCCESS,
  FETCH_SCOPES_SUCCESS,
  FETCH_TICKETS_BY_SCOPE_LOADING,
  FETCH_TICKETS_BY_SCOPE_SUCCESS,
  FETCH_TICKET_DETAILS_SUCCESS,
  CREATE_TICKET_MESSAGE_SUCCESS,
  UPDATE_TICKET_STATUS_SUCCESS,
  CREATE_TICKET_SUCCESS,
  UPDATE_TICKET_DETAILS_SUCCESS,
  SEARCH_TICKETS_SUCCESS,
  ADD_TICKET_FILTER,
  REMOVE_TICKET_FILTER
} from 'Actions/ticket/types';
import { SAVE_PARTS_ORDER_SUCCESS } from 'Actions/order/types';

const initialState = {
  current: {
    bicycle_make: '',
    order: {
      parts: []
    }
  },
  list: [],
  scopes: [],
  filters: []
};

const ticketReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TICKETS_SUCCESS: {
      return {
        ...state,
        list: [...action.payload.data],
        pagination: { ...action.payload.pagination }
      };
    }
    case SEARCH_TICKETS_SUCCESS: {
      return {
        ...state,
        list: [...action.payload.data],
        pagination: { ...action.payload.pagination }
      };
    }
    case FETCH_SCOPES_SUCCESS: {
      return {
        ...state,
        scopes: action.payload
      };
    }
    case FETCH_TICKETS_BY_SCOPE_LOADING: {
      let { list } = state;
      if (action.payload.shouldOverwriteResults) {
        list = [];
      }
      return {
        ...state,
        list
      };
    }
    case FETCH_TICKETS_BY_SCOPE_SUCCESS: {
      return {
        ...state,
        list: [...action.payload.data],
        pagination: { ...action.payload.pagination }
      };
    }
    case FETCH_TICKET_DETAILS_SUCCESS: {
      return {
        ...state,
        current: { ...action.payload.data }
      };
    }
    case CREATE_TICKET_MESSAGE_SUCCESS: {
      return {
        ...state,
        current: {
          ...state.current,
          messages: [
            ...state.current.messages,
            {
              text: action.payload.text,
              from_user: action.payload.from_user,
              created_at: action.payload.created_at,
              images: action.payload.images,
              message_type: 'chat'
            }
          ],
          images: [...state.current.images, ...action.payload.images]
        }
      };
    }
    case CREATE_TICKET_SUCCESS: {
      const newTicketsList = [...state.list];
      newTicketsList.unshift(action.payload.data);
      return {
        ...state,
        list: newTicketsList
      };
    }
    case SAVE_PARTS_ORDER_SUCCESS: {
      return {
        ...state,
        current: {
          ...state.current,
          order: {
            ...state.current.order,
            ...action.payload
          }
        }
      };
    }
    case UPDATE_TICKET_STATUS_SUCCESS: {
      return {
        ...state,
        current: {
          ...state.current,
          status: action.payload.status,
          messages: [
            ...state.current.messages,
            {
              text: action.payload.message.text,
              message_type: 'status_update',
              from_user: action.payload.message.from_user,
              created_at: action.payload.message.created_at
            }
          ]
        }
      };
    }
    case UPDATE_TICKET_DETAILS_SUCCESS: {
      return {
        ...state,
        current: {
          ...state.current,
          ...action.payload,
          parts: {
            ...state.current.parts,
            ...action.payload.parts,
            attributes: [...action.payload.parts.attributes]
          }
        }
      };
    }
    case ADD_TICKET_FILTER: {
      if (!state.filters.includes(action.payload)) {
        return {
          ...state,
          filters: [
            ...state.filters,
            action.payload
          ]
        } 
      }

      return state;
    }
    case REMOVE_TICKET_FILTER: {
      return {
        ...state,
        filters: state.filters.filter(filterItem => filterItem !== action.payload)
      } 
    }
    default:
      return state;
  }
};

export default ticketReducer;
