import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import BSAlert from '@bootstrap-styled/v4/lib/Alert';
import { typography, colors, space, bootstrapTheme } from 'Theme';
import { makeTheme } from 'bootstrap-styled/lib/theme';

class Alert extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      children,
      color,
      theme,
      isCallOut,
      alertIsOpen,
      onCloseFunc,
      noClose
    } = this.props;
    return (
      <AlertWrapper
        isCallOut={isCallOut}
        isOpen={alertIsOpen}
        color={color}
        noClose={noClose}
      >
        <BSAlert
          theme={theme}
          color={color}
          isOpen={alertIsOpen}
          onClick={() => onCloseFunc()}
        >
          {children}
        </BSAlert>
      </AlertWrapper>
    );
  }
}

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    'success',
    'info',
    'warning',
    'danger',
    'gray',
    colors.white.base
  ]),
  theme: PropTypes.shape(),
  isCallOut: PropTypes.bool,
  alertIsOpen: PropTypes.bool,
  noClose: PropTypes.bool,
  onCloseFunc: PropTypes.func
};

Alert.defaultProps = {
  color: colors.gray.lighter,
  theme: makeTheme(bootstrapTheme),
  isCallOut: false,
  alertIsOpen: false,
  noClose: false,
  onCloseFunc: () => {}
};

const AlertWrapper = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  margin-bottom: ${space[2]}rem;
  ${props =>
    props.color === colors.gray.lighter &&
    css`
      background-color: ${colors.gray.lighter};
    `}
  ${props =>
    props.className &&
    css`
      .alert button {
        font-size: ${typography.fontSizes[0]}rem;
        color: ${colors.black.base};
        text-shadow: none;
        opacity: 1;
      }
    `}
  .alert {
    font-family: ${typography.fonts.sansSerif};
    font-stretch: normal;
    font-size: ${typography.fontSizes[1]}rem;
    line-height: 1.6;
    border-radius: 0;
    color: ${colors.black.base};
    margin-bottom: 0;
    > button.close {
      top: 50%;
    }
    a {
      display: block;
      font-family: ${typography.fonts.sansSerif};
      font-stretch: normal;
      text-decoration: underline;
      color: ${colors.black.base};
      &:hover {
        color: ${colors.black.base};
        text-decoration: none;
      }
    }
    button {
      display: ${props => (props.noClose ? 'none' : null)};
      line-height: 0.75;
      font-size: ${typography.fontSizes[0]}rem;
      color: ${colors.black.base};
      text-shadow: none;
      opacity: 1;
    }
    ${props =>
      props.color === colors.gray.lighter &&
      css`
        background-color: ${props.color};
      `}
  }
  ${props =>
    props.isCallOut &&
    css`
      background-color: ${colors.white.base};
      border: 1px solid ${colors.black.base};
      .alert {
        border: none;
        background-color: ${colors.white.base};
      }
    `}
`;

export default Alert;
