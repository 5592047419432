export default {
  en: {
    table: {
      name: "Name",
      value: "Value",
      id: "ID",
      status: "Status",
      address: "Address",
      phone: "Phone Number",
      email: "Email",
      location: "Location",
      last_updated: "Last Updated",
      last_activity: "Last Activity",
      created_at: "Created At",
      invited_at: "Invited At",
      submitted: "Submitted",
      claim: "Claim No.",
      claim_uuid: "Ticket ID",
      dealership: "Dealership",
      country: "Country",
      dropdown: {
        view: "View",
        edit: "Edit"
      },
      customer: "Customer",
      diagnosis: "Diagnosis",
      part_type: "Part Type",
      attribute_type: "Attribute Type"
    },
    data: {
      status: {
        new: "New",
        updated: "Pending SRS Response",
        backordered: "Backordered",
        pending_response: "Pending Customer Response",
        pending_return: "Pending Return",
        received: "Received",
        shipped: "Shipped",
        closed: "Closed",
        active: "Active",
        inactive: "Inactive",
        pending: "Pending",
        archived: "Archived"
      },
      badge: {
        primary: "Primary",
        owner: "Owner",
        service_center_user: "Technician",
        invited: "Pending",
        archived: "Archived",
        active: "Active"
      }
    }
  },
  de: {
    table: {
      name: "Name",
      status: "Status",
      address: "Adresse",
      phone: "Telefonnummer",
      email: "Email",
      location: "Standort",
      last_updated: "Zuletzt aktualisiert",
      last_activity: "Letzte Aktivität",
      submitted: "Eingereicht",
      claim: "Anspruch Nr.",
      dealership: "Händler",
      country: "Land",
      dropdown: {
        view: "Sicht",
        edit: "Bearbeiten"
      },
      customer: "Kunde",
      diagnosis: "Diagnose",
      part_type: "Teil typ"
    },
    data: {
      status: {
        new: "Neu",
        updated: "Aktualisiert",
        backordered: "Nachbestellt",
        pending_response: "Ausstehende Antwort",
        pending_return: "Ausstehende Rückgabe",
        received: "Empfangen",
        shipped: "Versendet",
        closed: "Geschlossen",
        active: "Aktiv",
        inactive: "Inaktiv",
        pending: "Ausstehend"
      },
      badge: {
        primary: "Primär",
        owner: "Eigentümer"
      }
    }
  },
  jp: {
    table: {
      name: "名前",
      status: "スターテス",
      address: "住所",
      phone: "電話番号",
      email: "Eメール",
      location: "位置",
      last_updated: "最終更新",
      last_activity: "最後の活動",
      submitted: "提出済み",
      claim: "クレーム番号",
      dealership: "ディーラー",
      country: "国",
      dropdown: {
        view: "意見",
        edit: "編集"
      },
      customer: "お客様",
      diagnosis: "診断",
      part_type: "パーツタイプ"
    },
    data: {
      status: {
        new: "新しい",
        updated: "更新しました",
        backordered: "入荷待ち",
        pending_response: "保留中の応答",
        pending_return: "保留中の返品",
        received: "受け取った",
        shipped: "発送済み",
        closed: "閉まっている",
        active: "アクティブ",
        inactive: "非活性",
        pending: "保留中"
      },
      badge: {
        primary: "主要な",
        owner: "オーナー"
      }
    }
  },
  fr: {
    table: {
      name: "Nom",
      status: "Statut",
      address: "Adresse",
      phone: "Numéro de téléphone",
      email: "E-mail",
      location: "Lieu",
      last_updated: "Dernière mise à jour",
      last_activity: "Dernière Activité",
      submitted: "Soumis",
      claim: "Numéro de réclamation",
      dealership: "Concession",
      country: "De campagne",
      dropdown: {
        view: "Voir",
        edit: "Éditer"
      },
      customer: "Cliente",
      diagnosis: "Diagnostic",
      part_type: "Type de pièce"
    },
    data: {
      status: {
        new: "Nouvelle",
        updated: "Mise à jour",
        backordered: "En rupture de stock",
        pending_response: "En attente de réponse",
        pending_return: "En attente de retour",
        received: "A reçu",
        shipped: "Expédié",
        closed: "Fermée",
        active: "Active",
        inactive: "Inactive",
        pending: "En attente"
      },
      badge: {
        primary: "Primaire",
        owner: "Propriétaire"
      }
    }
  },
  it: {
    table: {
      name: "Nome",
      status: "Stato",
      address: "Indirizzo",
      phone: "Numero di telefono",
      email: "Email",
      location: "Posizione",
      last_updated: "Ultimo aggiornamento",
      last_activity: "L'ultima attività",
      submitted: "Presentato",
      claim: "Rivendicazione n.",
      dealership: "Concessionario",
      country: "Paese",
      dropdown: {
        view: "Visualizzazione",
        edit: "Modificare"
      },
      customer: "Cliente",
      diagnosis: "Diagnosi",
      part_type: "Tipo di parte"
    },
    data: {
      status: {
        new: "Nuovo",
        updated: "Aggiornato",
        backordered: "In arretrato",
        pending_response: "In attesa di risposta",
        pending_return: "In attesa di restituzione",
        received: "Ricevuto",
        shipped: "Spedito",
        closed: "Chiuso",
        active: "Attivo",
        inactive: "Inattivo",
        pending: "In attesa di"
      },
      badge: {
        primary: "Primario",
        owner: "Proprietario"
      }
    }
  }
};
