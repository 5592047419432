import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Option from "@bootstrap-styled/v4/lib/Option";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchDealerships,
  fetchScopes,
  fetchDealershipsByScope,
  setDealership,
  searchDealers,
  addFilter,
  removeFilter
} from "Actions/dealership";
import {
  Alert,
  Badge,
  DataTable,
  Input,
  Link,
  Scrim,
  Search
} from "Components";
import {
  titleCase,
  setBadgeColorByStatus,
  formatPhoneNumber
} from "Components/Utils";
import { withTranslation } from "react-i18next";

class DealershipList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      searchKeyword: "",
      isSearching: false,
      pageNumber: 1,
      perPage: 25,
      paginationResetDefaultPage: false,
      sort: {
        by: "name",
        order: "asc"
      },
      hasError: false,
      error: ""
    };
  }

  async componentDidMount() {
    const { pageNumber } = this.state;
    const { fetchScopes } = this.props;
    await fetchScopes().catch(err => Promise.reject(err));
    await this.fetchData(pageNumber);
  }

  handlePageChange = async page => {
    const { searchKeyword, perPage, sort } = this.state;
    const {
      searchDealersData,
      dealership: { filters }
    } = this.props;
    if (searchKeyword) {
      const queryData = {
        search: searchKeyword,
        paginationPerPage: perPage,
        page
      };
      if (filters.length > 0) {
        queryData.scope = filters.join("+");
      }
      searchDealersData(queryData).catch(() => {
        // TODO: Catch errors
      });
    } else {
      this.fetchData(page, false, sort);
    }
  };

  handleRowsPerPageChange = (currRowsPer, pageNumber) => {
    this.setState(
      state => ({ ...state, perPage: currRowsPer, pageNumber }),
      () => {
        this.fetchData(pageNumber);
      }
    );
  };

  handleSearchChange = e => {
    const {
      target: { value }
    } = e;
    this.setState(state => ({ ...state, searchKeyword: value }));
  };

  onSearch = () => {
    const {
      searchKeyword,
      perPage: paginationPerPage,
      pageNumber: page,
      sort
    } = this.state;
    const { searchDealersData } = this.props;

    this.setState(state => ({
      ...state,
      isSearching: true
    }));

    const queryData = {
      search: encodeURIComponent(searchKeyword),
      paginationPerPage,
      page,
      sort
    };
    searchDealersData(queryData)
      .catch(() => {
        // TODO: add err catch cb
      })
      .then(() => this.setState(state => ({ ...state, isSearching: false })));
  };

  handleSort = (column, sortDirection) => {
    const sort = {
      by: column.selector,
      order: sortDirection
    };
    this.setState(
      state => ({
        ...state,
        pageNumber: 1,
        sort,
        paginationResetDefaultPage: true
      }),
      () => {
        this.fetchData(1, false, sort);
      }
    );
  };

  fetchData = async (
    pageNumber = null,
    shouldOverWriteResults = false,
    sortConfig = {}
  ) => {
    const {
      fetchDealerships,
      fetchDealershipsByScope,
      history,
      dealership: { filters }
    } = this.props;
    const { perPage, searchKeyword } = this.state;

    const pageData = {};
    if (pageNumber) {
      pageData.page = pageNumber;
    } else {
      pageData.page = 1;
    }
    // Set pagination to 25 rows per page
    pageData.paginationPerPage = perPage;
    if (Object.keys(sortConfig).length > 0) {
      pageData.sort = sortConfig;
    } else {
      const { sort } = this.state;
      pageData.sort = sort;
    }

    // We either fetch and paginate the total list of dealerships, or if the user has filters applied
    // we paginate the filtered results
    const { t } = this.props;
    if (!filters.length) {
      if (searchKeyword) {
        pageData.search = searchKeyword;
      }
      this.setState(() => ({
        isFetching: true
      }));
      await fetchDealerships(pageData).catch(errorStatus => {
        if (errorStatus === 403) {
          return history.push("/tickets");
        }
        const error = t(`dealer_list.error.${errorStatus}`);
        return this.setState(() => ({ error, hasError: true }));
      });
    } else {
      this.setState(() => ({
        isFetching: true,
        searchKeyword: ""
      }));
      await fetchDealershipsByScope({
        ...pageData,
        scope: filters.join("+"),
        shouldOverWriteResults // To support pagination of previously applied filters
      }).catch(errorStatus => {
        if (errorStatus === 403) {
          return history.push("/tickets");
        }
        const error = t(`dealer_list.error.${errorStatus}`);
        return this.setState(() => ({ error, hasError: true }));
      });
    }

    this.setState(() => ({
      isFetching: false
    }));
  };

  addFilter = async event => {
    const { sort } = this.state;
    const { value } = event.target;
    const { addFilter } = this.props;

    await addFilter(value);

    this.fetchData(1, true, sort);
  };

  removeFilter = async event => {
    const scopeToRemove = event.currentTarget.getAttribute("handle");
    const { removeFilter } = this.props;

    await removeFilter(scopeToRemove);

    this.fetchData(null, true);
  };

  render() {
    const {
      isFetching,
      isSearching,
      searchKeyword,
      perPage,
      hasError,
      error,
      paginationResetDefaultPage
    } = this.state;

    const { dealership, setDealership, history, t } = this.props;
    const { list, pagination, scopes, filters } = dealership;
    const columns = [
      {
        id: "name",
        name: t("table.name"),
        selector: "name",
        grow: 2,
        cell: row => <Link href={`/dealership/${row.id}`}>{row.name}</Link>
      },
      {
        id: "status",
        name: t("table.status"),
        selector: "status",
        cell: row => (
          <Badge
            label={t(`data.status.${row.status.toLowerCase()}`)}
            pill
            color={setBadgeColorByStatus(row.status.toLowerCase())}
            inTable
          />
        )
      },
      {
        id: "phone",
        name: t("table.phone"),
        selector: "phone",
        cell: row => (row.phone ? formatPhoneNumber(row.phone) : "")
      },
      {
        id: "address",
        name: t("table.address"),
        selector: "",
        cell: row => {
          const location = row.locations[0] ?? {
            address: {
              address_one: "",
              address_two: "",
              city: "",
              state: "",
              zip: ""
            }
          };
          const street = location.address.address_two
            ? `${location.address.address_one}, ${location.address.address_two}, `
            : `${location.address.address_one}, `;
          return (
            <Fragment>
              {street}
              {location.address.city}, {location.address.state}{" "}
              {location.address.zip}
            </Fragment>
          );
        },
        sortable: false,
        grow: 2
      },
      {
        id: "country",
        name: t("table.country"),
        selector: "locations.0.address.country",
        sortable: false,
        cell: row =>
          row.locations.length > 0 &&
          row.locations[0].address.country !== "undefined"
            ? row.locations[0].address.country
            : ""
      }
    ];

    return (
      <Fragment>
        <Scrim isShown={isFetching || isSearching} />
        <InputsWrapper>
          <Input
            inputId="filters"
            onChangeFunc={this.addFilter}
            type="select"
            label={t("form.labels.filters")}
            hideLabel
            value=" "
            status="success"
            name="filters"
            width="max-content"
          >
            <Option value=" ">{t("form.filter")}</Option>
            {scopes &&
              scopes.map(aScope => (
                <Option key={aScope} value={aScope}>
                  {titleCase(t(`data.status.${aScope.toLowerCase()}`))}
                </Option>
              ))}
          </Input>
          <Search
            value={searchKeyword}
            label={t("form.labels.search")}
            status="muted"
            placeholder={t("form.placeholders.search")}
            onSearchFunc={this.onSearch}
            onChangeFunc={e => this.handleSearchChange(e)}
          />
        </InputsWrapper>

        {filters &&
          filters.map(aFilter => (
            <Badge
              key={aFilter}
              label={aFilter}
              handle={aFilter.toLowerCase()}
              pill
              onRemove={e => this.removeFilter(e)}
              isRemovable
              color={setBadgeColorByStatus(aFilter.toLowerCase())}
            />
          ))}
        <Alert
          alertIsOpen={hasError}
          color="warning"
          onCloseFunc={() => this.setState(() => ({ hasError: false }))}
        >
          {error}
        </Alert>

        <DataTable
          title=""
          data={list || []}
          columns={columns}
          pointerOnHover
          highlightOnHover
          pagination
          noHeader
          defaultSortField="name"
          onRowClicked={param => {
            setDealership({ id: param.id });
            history.push(`/dealership/${param.id}`);
          }}
          onChangePage={this.handlePageChange}
          paginationServer
          paginationResetDefaultPage={paginationResetDefaultPage}
          paginationTotalRows={pagination ? pagination.total : 0}
          paginationPerPage={perPage}
          sortServer
          onSort={this.handleSort}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          progressPending={isFetching || isSearching}
        />
      </Fragment>
    );
  }
}

DealershipList.propTypes = {
  fetchDealerships: PropTypes.func.isRequired,
  fetchScopes: PropTypes.func.isRequired,
  fetchDealershipsByScope: PropTypes.func.isRequired,
  searchDealersData: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.object,
    push: PropTypes.func
  }).isRequired,
  setDealership: PropTypes.func.isRequired,
  dealership: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    pagination: PropTypes.object,
    scopes: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  dealership: state.dealership,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  fetchDealerships: data => dispatch(fetchDealerships(data)),
  fetchScopes: () => dispatch(fetchScopes()),
  fetchDealershipsByScope: data => dispatch(fetchDealershipsByScope(data)),
  setDealership: data => dispatch(setDealership(data)),
  searchDealersData: data => dispatch(searchDealers(data)),
  addFilter: data => dispatch(addFilter(data)),
  removeFilter: data => dispatch(removeFilter(data))
});

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  input[type="search"] {
    border-left: 0;
  }
`;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(DealershipList))
);
