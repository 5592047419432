import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import PropTypes from 'prop-types';
import { space, colors } from 'Theme';
import Logo from 'Assets/images/Logo';
import Footer from '../../Layout/Footer';

const UnauthenticatedPage = ({ children }) => (
  <Layout>
    <Container>
      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <Page>
            <LogoWrapper>
              <NavLink to="/">
                <Logo />
              </NavLink>
            </LogoWrapper>
            <Content>{children}</Content>
            <Footer />
          </Page>
        </Col>
      </Row>
    </Container>
  </Layout>
);

UnauthenticatedPage.propTypes = {
  children: PropTypes.node.isRequired
};

const Layout = styled.div`
  background-color: ${colors.gray.lightest};
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  padding: ${space[4]}rem 0;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  svg {
    width: 220px;
  }
`;

const Content = styled.div`
  width: 100%;
  padding: ${space[4]}rem 0;
`;

export default UnauthenticatedPage;
