export default {
  en: {
    sign_up: {
      heading_main: 'Create Account',
      heading_sub: 'Create an SR SUNTOUR Warranty account',
      region_helper1: 'If your region is not listed, please follow the instructions ',
      region_helper2: 'here',
      region_helper3: ' to submit a service request.',
      success: {
        account_created:
          "An email has been sent to the address you've provided. Please check your email to finish your account setup."
      },
      error: {
        400: 'The email address has already been taken',
        500: 'A technical issue prevented us from sending you an email. Our staff has been notified of the error.'
      },
      buttons: {
        register: 'Get Started',
        sign_in: 'Back to Sign In',
        resend_verifycode: 'Resend Verify Email',
        sent_verifycode: 'Sent Successfully'
      }
    }
  },
  de: {
    sign_up: {
      heading_main: 'Benutzerkonto erstellen',
      heading_sub: 'Erstellen Sie ein SR SUNTOUR Garantiekonto',
      region_helper1: 'Wenn Ihre Region nicht aufgeführt ist, folgen Sie bitte den Anweisungen ',
      region_helper2: 'hier',
      region_helper3: ', um eine Serviceanfrage zu stellen.',
      success: {
        account_created:
          'Eine E-Mail wurde an die von Ihnen angegebene Adresse gesendet. Bitte überprüfen Sie Ihre E-Mails, um die Einrichtung Ihres Kontos abzuschließen.'
      },
      error: {
        400: 'Die E-Mail-Adresse ist bereits vergeben',
        500: 'Ein technisches Problem hat uns daran gehindert, Ihnen eine E-Mail zu senden. Unsere Mitarbeiter wurden über den Fehler informiert.'
      },
      buttons: {
        register: 'Loslegen',
        sign_in: 'Zurück zu Anmelden',
        resend_verifycode: 'Verifizierungscode erneut senden',
        sent_verifycode: 'Erfolgreich gesendet'
      }
    }
  },
  jp: {
    sign_up: {
      heading_main: 'アカウントを作成する',
      heading_sub: 'SRSUNTOUR保証アカウントを作成する',
      region_helper1: 'お住まいの地域がリストにない場合は、',
      region_helper2: 'こちら',
      region_helper3: 'の手順に従ってサービス リクエストを送信してください。',
      success: {
        account_created:
          '指定したアドレスにメールが送信されました。メールをチェックして、アカウントの設定を完了してください。'
      },
      error: {
        400: 'メールアドレスはすでに取得されています',
        500: '技術的な問題により、メールを送信できませんでした。エラーが発生したことがスタッフに通知されました。'
      },
      buttons: {
        register: 'はじめに',
        sign_in: 'サインインに戻る',
        resend_verifycode: '確認メールを再送信',
        sent_verifycode: '正常に送信されました'
      }
    }
  },
  it: {
    sign_up: {
      heading_main: 'Creare un account',
      heading_sub: 'Crea un account di garanzia SR SUNTOUR',
      region_helper1: 'Se la tua regione non è elencata, segui le istruzioni ',
      region_helper2: 'qui',
      region_helper3: ' per inviare una richiesta di assistenza.',
      success: {
        account_created:
          "È stata inviata un'e-mail all'indirizzo che hai fornito. Controlla la tua email per completare la configurazione del tuo account."
      },
      error: {
        400: "L'indirizzo email è già stato preso",
        500: "Un problema tecnico ci ha impedito di inviarti un'e-mail. Il nostro personale è stato informato dell'errore."
      },
      buttons: {
        register: 'Iniziare',
        sign_in: 'Torna a Accedi',
        resend_verifycode: `Invia di nuovo l'e-mail di verifica`,
        sent_verifycode: 'Inviato con successo'
      }
    }
  }
};
