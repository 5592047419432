import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  colors,
  breakpoints,
  space,
  headerHeight,
  sidebarWidth,
  typography,
} from "Theme";
import { NavLink } from "react-router-dom";
import Logo from "Assets/images/Logo";
import {
  TicketsIcon,
  DealersIcon,
  AccountIcon,
  ReportsIcon,
  ServiceCentersIcon,
  UsersIcon,
} from "Assets/images/icons";
import { useTranslation } from "react-i18next";

const isActiveFunc = (match, location, str) => {
  if (location.pathname.includes(str)) {
    return true;
  }
  if (!match) {
    return false;
  }
  return true;
};

const Sidebar = ({ isHidden, userLevel, userDealerId, handleNavEventFunc }) => {
  const { t } = useTranslation();
  const getTrimmedVersion = () => {
    const fullVersion = process.env.VERSION;
    // If there is no version, return "dev"
    if (!fullVersion) return "dev";
    // The version is expected to be a commit hash, and we don't need the whole thing
    if (fullVersion.length > 8) {
      return fullVersion.slice(fullVersion.length - 8, fullVersion.length);
    }
    // But if it isn't at least 8 characters, return the entire version
    return fullVersion;
  };

  const navItems = [
    // TICKETS
    {
      isActive: (match, location) => isActiveFunc(match, location, "ticket"),
      exact: true,
      to: "/tickets",
      activeClassName: "active",
      label: "tickets",
      onClick: () => handleNavEventFunc(),
      icon: <TicketsIcon />,
      navLabel: <NavLabel>{t("sidebar.tickets")}</NavLabel>,
    },
    // SERVICE CENTERS
    {
      isActive: (match, location) =>
        isActiveFunc(match, location, "service_center"),
      exact: true,
      to: "/service-centers",
      activeClassName: "active",
      label: "service_centers",
      onClick: () => handleNavEventFunc(),
      icon: <ServiceCentersIcon />,
      navLabel: <NavLabel>{t("sidebar.service_centers")}</NavLabel>,
    },
    // DEALERSHIPS
    {
      isActive: (match, location) =>
        isActiveFunc(match, location, "dealership"),
      exact: true,
      to: "/dealerships",
      activeClassName: "active",
      label: "dealerships",
      onClick: () => handleNavEventFunc(),
      icon: <DealersIcon />,
      navLabel: <NavLabel>{t("sidebar.dealers")}</NavLabel>,
    },
    // USERS
    {
      isActive: (match, location) => isActiveFunc(match, location, "users"),
      to: "/users",
      activeClassName: "active",
      label: "users",
      onClick: () => handleNavEventFunc(),
      icon: <UsersIcon />,
      navLabel: <NavLabel>{t("sidebar.users")}</NavLabel>,
    },
    // REPORTS
    {
      isActive: (match, location) => isActiveFunc(match, location, "reports"),
      to: "/reports",
      activeClassName: "active",
      label: "reports",
      onClick: () => handleNavEventFunc(),
      icon: <ReportsIcon />,
      navLabel: <NavLabel>{t("sidebar.reports")}</NavLabel>,
    },
    // DEALERSHIP
    {
      isActive: (match, location) =>
        isActiveFunc(match, location, "dealership"),
      to: `/dealership/${userDealerId}`,
      activeClassName: "active",
      label: "account",
      onClick: () => handleNavEventFunc(),
      icon: <DealersIcon />,
      navLabel: <NavLabel>{t("sidebar.dealer")}</NavLabel>,
    },
    // PARTS MANAGER
    {
      isActive: (match, location) =>
        isActiveFunc(match, location, "parts-manager"),
      to: "/parts-manager",
      activeClassName: "active",
      label: "parts_manager",
      onClick: () => handleNavEventFunc(),
      icon: <TicketsIcon />,
      navLabel: <NavLabel>{t("sidebar.parts_manager")}</NavLabel>,
    },
    // ACCOUNT
    {
      isActive: (match, location) => isActiveFunc(match, location, "account"),
      exact: true,
      to: "/account",
      activeClassName: "active",
      label: "account",
      onClick: () => handleNavEventFunc(),
      icon: <AccountIcon />,
      navLabel: <NavLabel>{t("sidebar.acct")}</NavLabel>,
    },
  ];
  const getRoutesByLevel = (level) => {
    // A list of allowed routes the user can access based on their user level
    let allowedRoutes = [];
    // Populate the allowedRoutes array based on the user level
    // The array is based on the `to` prop of the navItems
    switch (level) {
      case "super_admin": {
        allowedRoutes = ["/service-centers", "/parts-manager", "/account"];
        break;
      }
      case "service_center_user": {
        allowedRoutes = [
          "/tickets",
          "/dealerships",
          "/users",
          "/reports",
          "/account",
        ];
        break;
      }
      case "dealer_user": {
        allowedRoutes = ["/tickets", `/dealership/${userDealerId}`, "/account"];
      }
      default: {
        allowedRoutes = ["/tickets", "/account"];
      }
    }
    return allowedRoutes;
  };

  // Render's nav items based on user level, an empty level being the base level
  const renderNavItems = (level = "") => {
    const allowedNavItems = navItems.filter((item) =>
      getRoutesByLevel(level).includes(item.to)
    );
    return allowedNavItems.map((item) => (
      <NavItem {...item}>
        {item.icon}
        {item.navLabel}
      </NavItem>
    ));
  };

  return (
    <SidebarWrapper isHidden={isHidden}>
      <LogoWrapper>
        <NavLink to="/">
          <Logo />
        </NavLink>
      </LogoWrapper>
      <Content>
        {renderNavItems(userLevel)}
        <Version>{getTrimmedVersion()}</Version>
      </Content>
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  userLevel: PropTypes.string.isRequired,
  userDealerId: PropTypes.number,
  handleNavEventFunc: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  userDealerId: 0,
};

const SidebarWrapper = styled.div`
  position: ${(props) => (props.isHidden ? "absolute" : "fixed")};
  top: 0;
  left: ${(props) => (props.isHidden ? `-${sidebarWidth}px` : 0)};
  min-width: ${sidebarWidth}px;
  max-width: ${sidebarWidth}px;
  height: 100vh;
  background-color: ${colors.white.base};
  z-index: 16;
  transition: all 0.3s ease-in-out;
  @media screen and (min-width: ${breakpoints.lg}) {
    position: fixed;
    left: 0;
  }
`;

const LogoWrapper = styled.div`
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${headerHeight}px;
  width: 100%;
  background-color: ${colors.white.base};
  z-index: 5;
  a {
    line-height: 1;
    svg {
      width: 140px;
    }
  }
`;

const Content = styled.div`
  height: calc(100vh - ${headerHeight}px);
  display: flex;
  flex-direction: column;
`;

const NavItem = styled(NavLink)`
  position: relative;
  display: flex;
  padding: ${space[2]}rem;
  color: ${colors.black.base};
  background-color: ${colors.white.base};
  border: 1px solid ${colors.white.base};
  font-stretch: condensed;
  font-style: oblique;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 5;
  transition: all 0.3s ease;
  svg {
    margin-right: ${space[2]}rem;
  }
  &:hover {
    background-color: ${colors.gray.lighter};
    border-right: 1px solid ${colors.white.base};
  }
  &.active {
    color: ${colors.primary.darkest};
    &:not(.toggle) {
      background-color: ${colors.gray.lighter};
      border-right: 1px solid ${colors.white.base};
    }
    svg path {
      fill: ${colors.primary.base};
    }
  }
`;

const NavLabel = styled.span`
  > * {
    width: min-content;
  }
`;

const Version = styled.p`
  margin-top: auto;
  color: ${colors.gray.base};
  font-size: ${typography.fontSizes[0]}rem;
  text-align: center;
  padding: 4px;
`;

export default Sidebar;
