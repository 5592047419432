/* eslint-disable prettier/prettier */
export const FETCH_USERS_LOADING = "FETCH_USERS_LOADING";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USER_LOADING = "FETCH_USER_LOADING";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_SCOPES_LOADING = "FETCH_SCOPES_LOADING";
export const FETCH_SCOPES_ERROR = "FETCH_SCOPES_ERROR";
export const FETCH_SCOPES_SUCCESS = "FETCH_SCOPES_SUCCESS";
export const SEARCH_USERS_LOADING = "SEARCH_USERS_LOADING";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_ERROR = "SEARCH_USERS_ERROR";
export const FETCH_USERS_BY_SCOPE_LOADING = "FETCH_USERS_BY_SCOPE_LOADING";
export const FETCH_USERS_BY_SCOPE_SUCCESS = "FETCH_USERS_BY_SCOPE_SUCCESS";
export const FETCH_USERS_BY_SCOPE_ERROR = "FETCH_USERS_BY_SCOPE_ERROR";
export const ADD_USERS_FILTER = "ADD_USERS_FILTER";
export const REMOVE_USERS_FILTER = "REMOVE_USERS_FILTER";
