import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints, space } from "Theme";
import { formatDate } from "Components/Utils";
import { OverflowIcon } from "Assets/images/icons";
import { DropdownItem } from "@bootstrap-styled/v4";
import { showModal } from "Actions/modal";
import * as modalTypes from "Components/Modal/types";
import Heading from "../Heading";
import Badge from "../Badge";
import Dropdown from "../Dropdown";

const MessageHeader = ({
  name,
  img,
  createdDate,
  isTechnician,
  isServiceCenterUser,
  showModal,
  overrideDropdownOptions = [],
  isHidden = false
}) => {
  const { t } = useTranslation();
  // These are defined here because we need access to the t and onEditTicketModal functions
  const defaultDropdownOptions = [
    {
      text: t(`ticket.dropdown.edit`),
      onClick: onEditTicketModal,
      tag: "a"
    }
  ];
  // If overrideDropdownOptions is passed, use that instead of the default
  const dropdownOptions = overrideDropdownOptions.length
    ? overrideDropdownOptions
    : defaultDropdownOptions;
  const onEditTicketModal = () => {
    if (onEditOverride) {
      onEditOverride();
      return;
    }
    const modalData = {
      type: modalTypes.FORM_UPDATE_TICKET,
      title: t("modal.title.update_ticket")
    };
    showModal(modalData);
  };

  return (
    <MessageHeaderWrapper>
      <ProfilePicture inMessage>
        <img src={img} alt={t(`ticket.alt.user_portrait`)} />
      </ProfilePicture>
      <TextWrapper>
        <StyledHeading size={6}>{name}</StyledHeading>
        <MessageDate>{formatDate(createdDate)}</MessageDate>
        {isTechnician && (
          <MessageBadge
            label={t(`ticket.labels.technician`)}
            pill
            color="info"
            inTable
          />
        )}
        {isHidden && isServiceCenterUser && (
          <MessageBadge
            label={t(`ticket.labels.hidden`)}
            pill
            color="default"
            inTable
          />
        )}
      </TextWrapper>
      {isServiceCenterUser && (
        <StyledDropdown toggle={<OverflowIcon />}>
          {dropdownOptions.map((option, index) => (
            <DropdownItem key={index} tag={option.tag} onClick={option.onClick}>
              {option.text}
            </DropdownItem>
          ))}
        </StyledDropdown>
      )}
    </MessageHeaderWrapper>
  );
};

MessageHeader.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  isTechnician: PropTypes.bool,
  isServiceCenterUser: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  overrideDropdownOptions: PropTypes.array,
  isHidden: PropTypes.bool
};

MessageHeader.defaultProps = {
  isTechnician: false,
  isServiceCenterUser: false
};

const MessageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${space[1]}rem;
  margin-bottom: ${space[1]}rem;
  flex-wrap: wrap;
  .dropdown {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    align-items: center;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledHeading = styled(Heading)`
  width: 100%;
  margin-right: ${space[1]}rem;
  margin-bottom: ${space[1]}rem;
  @media screen and (min-width: ${breakpoints.sm}) {
    width: max-content;
    margin-bottom: 0;
  }
`;

const ProfilePicture = styled.div`
  width: ${space[1] * 4}rem;
  height: ${space[1] * 4}rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: ${space[1]}rem;
  margin-bottom: ${space[1]}rem;
  img {
    width: 100%;
    height: 100%;
  }
`;

const MessageDate = styled.span`
  font-stretch: normal;
`;

const MessageBadge = styled(Badge)`
  margin-right: 0;
  margin-left: ${space[1]}rem;
`;

const StyledDropdown = styled(Dropdown)`
  width: ${space[1] * 5}rem;
  height: ${space[1] * 5}rem;
  .dropdown-menu {
    top: 70%;
  }
  .dropdown-item {
    text-decoration: none;
    font-size: 1rem;
  }
`;

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  showModal: data => dispatch(showModal(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageHeader);
