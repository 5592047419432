import './fonts/_univers.scss';
// Font Size based in relative units (rem)
export const fontSizes = [0.875, 1, 1.25, 1.5625, 1.9375, 2.5, 3];

export const fontSizesMobile = [1, 1.0625, 1.125, 1.1875, 1.25, 1.5];

export const fonts = {
  serif: '',
  sansSerif: '"Univers LT", "Helvetica", sans-serif',
  monospace:
    'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace'
};

export const lineHeights = [];

export default {
  fontSizesMobile,
  fontSizes,
  fonts,
  lineHeights
};
