import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BootstrapInput from '@bootstrap-styled/v4/lib/Input';
import FormGroup from '@bootstrap-styled/v4/lib/Form/FormGroup';
import { makeTheme } from 'bootstrap-styled/lib/theme';
import { space, colors, bootstrapTheme } from 'Theme';
import Label from '../../Label';

const Radio = ({
  defaultChecked,
  onChangeFunc,
  placeholder,
  isDisabled,
  label,
  name,
  inputId,
  theme,
  value
}) => (
  <RadioWrapper check>
    <BootstrapInput
      id={inputId}
      type="radio"
      value={value}
      name={name}
      placeholder={placeholder}
      onChange={onChangeFunc}
      theme={theme}
      disabled={isDisabled}
      defaultChecked={defaultChecked}
    />
    <Label htmlFor={inputId} check>
      {label}
    </Label>
  </RadioWrapper>
);

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  theme: PropTypes.shape(),
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChangeFunc: PropTypes.func,
  defaultChecked: PropTypes.bool
};

Radio.defaultProps = {
  placeholder: '',
  isDisabled: false,
  theme: makeTheme(bootstrapTheme),
  onChangeFunc: () => {},
  defaultChecked: false
};

const RadioWrapper = styled(FormGroup)`
  label {
    font-size: 1rem;
  }
  input {
    margin-right: ${space[1]}rem;
  }
  position: relative;
  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 16px;
    width: 16px;
    + label {
      margin-left: 1.5rem;
      &::before {
        position: absolute;
        top: 6px;
        left: 0;
        content: '';
        height: 16px;
        width: 16px;
        border: 1px solid ${colors.gray.base};
        border-radius: 50%;
      }
      &::after {
        position: absolute;
        top: 10px;
        left: 4px;
        content: '';
        background-color: ${colors.black.base};
        border: 1px solid ${colors.black.base};
        border-radius: 50%;
        height: 8px;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        width: 8px;
      }
    }
    &:checked + label::after {
      opacity: 1;
    }
  }
`;

export default Radio;
