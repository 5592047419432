/* eslint-disable prettier/prettier */
export const FETCH_SERVICE_CENTERS_LOADING = "FETCH_SERVICE_CENTERS_LOADING";
export const FETCH_SERVICE_CENTERS_SUCCESS = "FETCH_SERVICE_CENTERS_SUCCESS";
export const FETCH_SERVICE_CENTERS_ERROR = "FETCH_SERVICE_CENTERS_ERROR";
export const SEARCH_SERVICE_CENTERS_LOADING = "SEARCH_SERVICE_CENTERS_LOADING";
export const SEARCH_SERVICE_CENTERS_ERROR = "SEARCH_SERVICE_CENTERS_ERROR";
export const SEARCH_SERVICE_CENTERS_SUCCESS = "SEARCH_SERVICE_CENTERS_SUCCESS";
export const FETCH_SERVICE_CENTER_LOADING = "FETCH_SERVICE_CENTER_LOADING";
export const FETCH_SERVICE_CENTER_SUCCESS = "FETCH_SERVICE_CENTER_SUCCESS";
export const FETCH_SERVICE_CENTER_ERROR = "FETCH_SERVICE_CENTER_ERROR";
export const INVITE_SERVICE_CENTER_TECH_LOADING =
  "INVITE_SERVICE_CENTER_TECH_LOADING";
export const INVITE_SERVICE_CENTER_TECH_SUCCESS =
  "INVITE_SERVICE_CENTER_TECH_SUCCESS";
export const INVITE_SERVICE_CENTER_TECH_ERROR =
  "INVITE_SERVICE_CENTER_TECH_ERROR";
export const ACCEPT_TECH_INVITE_LOADING = "ACCEPT_TECH_INVITE_LOADING";
export const ACCEPT_TECH_INVITE_ERROR = "ACCEPT_TECH_INVITE_ERROR";
export const ACCEPT_TECH_INVITE_SUCCESS = "ACCEPT_TECH_INVITE_SUCCESS";
export const UPDATE_SERVICE_CENTER_LOADING = "UPDATE_SERVICE_CENTER_LOADING";
export const UPDATE_SERVICE_CENTER_SUCCESS = "UPDATE_SERVICE_CENTER_SUCCESS";
export const UPDATE_SERVICE_CENTER_ERROR = "UPDATE_SERVICE_CENTER_ERROR";
