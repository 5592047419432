export default {
  en: {
    header: {
      sign_out: 'Sign Out',
      title: {
        account: 'Account',
        dealerships: 'Dealerships',
        service_centers: 'Service Centers',
        tickets: 'Tickets',
        reports: 'Reports',
        users: 'Users'
      },
      your_account: 'Your Account',
      avatar_alt: 'User Avatar'
    }
  },
  de: {
    header: {
      sign_out: 'Austragen',
      title: {
        account: 'Konto',
        dealerships: 'Händler',
        tickets: 'Eintrittskarten',
        reports: 'Berichte',
        users: 'Benutzer'
      },
      your_account: 'Ihr Konto',
      avatar_alt: 'Benutzer-Avatar'
    }
  },
  jp: {
    header: {
      sign_out: 'サインアウト',
      title: {
        account: 'アカウント',
        dealerships: 'ディーラー',
        tickets: '切符売場',
        reports: 'レポート',
        users: 'ユーザー'
      },
      your_account: '貴方のアカウント',
      avatar_alt: 'ユーザーアバター'
    }
  },
  it: {
    header: {
      sign_out: 'Disconnessione',
      title: {
        account: 'Account',
        dealerships: 'Concessionari',
        service_centers: 'Centri di servizio',
        tickets: 'Biglietti',
        reports: 'Rapporti',
        users: 'Utenti'
      },
      your_account: 'Il tuo account',
      avatar_alt: 'Avatar utente'
    }
  }
};
