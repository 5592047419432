export default {
  en: {
    account_setup: {
      heading_main: "Finish Setting Up Your Account",
      heading_sub:
        "Complete this form to finish setting up your SR SUNTOUR Warranty account.",
      account_type_text: "Account Type",
      account_type_dealer: "Dealer",
      account_type_regular: "Regular",
      success: {
        account_created:
          "An email has been sent to the address you've provided. Please check your email to finish your account setup."
      },
      error: {
        401: "Verify tokens do not match",
        403: "The submitted email address has not yet been verified",
        404: "The specified user does not exist",
        449: "Dealer name is required",
        500: "A technical issue prevented us from sending you an email. Our staff has been notified of the error."
      },
      buttons: {
        submit: "Create Account"
      }
    }
  },
  de: {
    account_setup: {
      heading_main: "Beenden Sie die Einrichtung Ihres Kontos",
      heading_sub:
        "Füllen Sie dieses Formular aus, um die Einrichtung Ihres SRS-Garantiekontos abzuschließen",
      account_type_text: "Konto Typ",
      account_type_dealer: "Händler",
      account_type_regular: "Regulär",
      success: {
        account_created:
          "Eine E-Mail wurde an die von Ihnen angegebene Adresse gesendet. Bitte überprüfen Sie Ihre E-Mails, um die Einrichtung Ihres Kontos abzuschließen."
      },
      error: {
        401: "Stellen Sie sicher, dass die Token nicht übereinstimmen",
        403: "Die übermittelte E-Mail-Adresse wurde noch nicht verifiziert",
        404: "The specified user does not exist",
        449: "Der angegebene Benutzer existiert nicht",
        500: "Ein technisches Problem hat uns daran gehindert, Ihnen eine E-Mail zu senden. Unsere Mitarbeiter wurden über den Fehler informiert."
      },
      buttons: {
        submit: "Benutzerkonto erstellen"
      }
    }
  },
  jp: {
    account_setup: {
      heading_main: "アカウントの設定を完了する",
      heading_sub:
        "このフォームに記入して、SRS保証アカウントの設定を完了してください",
      account_type_text: "口座の種類",
      account_type_dealer: "ディーラー",
      account_type_regular: "通常",
      success: {
        account_created:
          "指定したアドレスにメールが送信されました。メールをチェックして、アカウントの設定を完了してください。"
      },
      error: {
        401: "トークンが一致しないことを確認します",
        403: "送信されたメールアドレスはまだ確認されていません",
        404: "指定されたユーザーは存在しません",
        449: "ディーラー名が必要です",
        500: "技術的な問題により、メールを送信できませんでした。エラーが発生したことがスタッフに通知されました。"
      },
      buttons: {
        submit: "アカウントを作成する"
      }
    }
  },
  fr: {
    account_setup: {
      heading_main: "Terminer la configuration de votre compte",
      heading_sub:
        "Remplissez ce formulaire pour terminer la configuration de votre compte de garantie SRS",
      account_type_text: "Type de compte",
      account_type_dealer: "Marchand",
      account_type_regular: "Régulier",
      success: {
        account_created:
          "Un e-mail a été envoyé à l'adresse que vous avez fournie. Veuillez vérifier votre e-mail pour terminer la configuration de votre compte."
      },
      error: {
        401: "Vérifier que les jetons ne correspondent pas",
        403: "L'adresse e-mail soumise n'a pas encore été vérifiée",
        404: "L'utilisateur demandé n'existe pas",
        449: "Le nom du revendeur est requis",
        500: "Un problème technique nous a empêché de vous envoyer un e-mail. Notre personnel a été informé de l'erreur."
      },
      buttons: {
        submit: "Créer un compte"
      }
    }
  },
  it: {
    account_setup: {
      heading_main: "Completa la configurazione del tuo account",
      heading_sub:
        "Completa questo modulo per completare la configurazione dell'account di garanzia SRS",
      account_type_text: "Tipo di account",
      account_type_dealer: "Rivenditore",
      account_type_regular: "Regolare",
      success: {
        account_created:
          "È stata inviata un'e-mail all'indirizzo che hai fornito. Controlla la tua email per completare la configurazione del tuo account."
      },
      error: {
        401: "Verifica che i token non corrispondano",
        403: "L'indirizzo email inviato non è stato ancora verificato",
        404: "L'utente specificato non esiste",
        449: "Il nome del rivenditore è obbligatorio",
        500: "Un problema tecnico ci ha impedito di inviarti un'e-mail. Il nostro personale è stato informato dell'errore."
      },
      buttons: {
        submit: "Creare un account"
      }
    }
  }
};
