export default {
  en: {
    sign_in: {
      heading_main: 'Sign In',
      heading_sub: 'Sign in to your SR SUNTOUR Warranty account',
      forgot_password_text: 'Forgot password?',
      session_timeout: 'You have been signed out due to inactivity',
      notice_reset_password1: 'Welcome to the new warranty app. If you had an account in the previous app, you will need to ',
      notice_reset_password2: 'reset your password',
      notice_reset_password3: ' before you can sign in.',
      form: {
        email_label: 'Email',
        password_label: 'Password'
      },
      buttons: {
        sign_in: 'Sign In',
        create_account: 'Create Account'
      },
      error: {
        400: 'These credentials do match our records',
        403: 'The submitted email address has not yet been verified',
        404: "Whoops, we couldn't find that (404).",
        419: "An email has been sent with a link to reset your password. You will only need to do this once.",
        500: 'A technical issue prevented us from signing you into the warrany application. Our staff has been notified of the error.'
      }
    },
    sign_out: {
      heading_main: 'Sign Out'
    }
  },
  'es-MX': {
    sign_in: {
      heading_main: 'Inicie sesión',
      heading_sub: 'Inicie sesión en su cuenta de garantía SR SUNTOUR',
      forgot_password_text: 'Se te olvidó tu contraseña?',
      notice_reset_password1: 'Bienvenido a la nueva aplicación de garantía. Si tenía una cuenta en la aplicación anterior, deberá ',
      notice_reset_password2: 'restablecer su contraseña',
      notice_reset_password3: ' antes de poder iniciar sesión.',
      form: {
        email_label: 'Correo electrónico',
        password_label: 'Contraseña'
      },
      buttons: {
        sign_in: 'Registrarse',
        create_account: 'Crear una cuenta'
      }
    }
  },
  de: {
    sign_in: {
      heading_main: 'Anmelden',
      heading_sub: 'Melden Sie sich bei Ihrem SR SUNTOUR Garantiekonto an',
      forgot_password_text: 'Passwort vergessen?',
      session_timeout: 'Sie wurden aufgrund von Inaktivität abgemeldet',
      notice_reset_password1: 'Willkommen bei der neuen Garantie-App. Wenn Sie in der vorherigen App ein Konto hatten, müssen Sie ',
      notice_reset_password2: 'Ihr Passwort zurücksetzen',
      notice_reset_password3: ', bevor Sie sich anmelden können.',
      form: {
        email_label: 'Email',
        password_label: 'Passwort'
      },
      buttons: {
        sign_in: 'Anmelden',
        create_account: 'Benutzerkonto erstellen'
      },
      error: {
        400: 'Diese Anmeldeinformationen stimmen mit unseren Aufzeichnungen überein',
        403: 'Die übermittelte E-Mail-Adresse wurde noch nicht verifiziert',
        419: "Es wurde eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts gesendet. Sie müssen dies nur einmal tun.",
        500: 'Ein technisches Problem hat uns daran gehindert, Sie beim Garantieantrag anzumelden. Unsere Mitarbeiter wurden über den Fehler informiert.'
      }
    },
    sign_out: {
      heading_main: 'Austragen'
    }
  },
  jp: {
    sign_in: {
      heading_main: 'サインイン',
      heading_sub: 'SRSUNTOUR保証アカウントにサインインします',
      forgot_password_text: 'パスワードをお忘れですか？',
      session_timeout: '非アクティブのためにサインアウトされました',
      notice_reset_password1: '新しい保証アプリへようこそ。 以前のアプリでアカウントを持っていた場合は、サインインする前に',
      notice_reset_password2: 'パスワードをリセット',
      notice_reset_password3: 'する必要があります。',
      form: {
        email_label: 'Eメール',
        password_label: 'パスワード'
      },
      buttons: {
        sign_in: 'サインイン',
        create_account: 'アカウントを作成する'
      },
      error: {
        400: 'これらの資格情報は私たちの記録と一致します',
        403: '送信されたメールアドレスはまだ確認されていません',
        419: "パスワードをリセットするためのリンクが記載された電子メールが送信されました。 これは一度だけ行う必要があります。",
        500: '技術的な問題により、warranyアプリケーションにサインインできませんでした。エラーが発生したことがスタッフに通知されました。'
      }
    },
    sign_out: {
      heading_main: 'サインアウト'
    }
  },
  it: {
    sign_in: {
      heading_main: 'Registrazione',
      heading_sub: 'Accedi al tuo account di garanzia SR SUNTOUR',
      forgot_password_text: 'Ha dimenticato la password?',
      session_timeout: 'Sei stato disconnesso per inattività',
      notice_reset_password1: "Benvenuto nella nuova app di garanzia. Se avevi un account nell'app precedente, dovrai ",
      notice_reset_password2: 'reimpostare la password',
      notice_reset_password3: ' prima di poter accedere.',
      form: {
        email_label: 'Email',
        password_label: "Parola d'ordine"
      },
      buttons: {
        sign_in: 'Registrazione',
        create_account: 'Creare un account'
      },
      error: {
        400: 'Queste credenziali corrispondono ai nostri record',
        403: "L'indirizzo email inviato non è stato ancora verificato",
        419: "È stata inviata un'e-mail con un collegamento per reimpostare la password. Dovrai farlo solo una volta.",
        500: "Un problema tecnico ci ha impedito di iscriverti all'applicazione di garanzia. Il nostro personale è stato informato dell'errore."
      }
    },
    sign_out: {
      heading_main: 'Disconnessione'
    }
  }
};
