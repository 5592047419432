import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'Theme';

const Caret = ({ size, color }) => (
  <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5 8.5l-10 7-10-7"
      stroke={color}
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

Caret.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

Caret.defaultProps = {
  size: '24px',
  color: colors.gray.darkest
};

export default Caret;
