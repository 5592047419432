import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card } from '@bootstrap-styled/v4';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'Components';
import { colors, space, breakpoints } from 'Theme';
import { setBadgeColorByStatus } from 'Components/Utils';
import { ReactComponent as InfoIcon } from 'Assets/images/icons/icon-info.svg';

const StatusUpdate = ({ status, customerName, createdAt }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Card color={setBadgeColorByStatus(status)} width="100%" />
      <div className="content">
        <InfoIcon />{' '}
        <Paragraph size={6}>
          {status === 'new'
            ? t('ticket.alt.status_new', {
                customer: customerName,
                timestamp: createdAt
              })
            : t('ticket.alt.status_update', {
                status: t(`data.status.${status}`),
                timestamp: createdAt
              })}
        </Paragraph>
      </div>
    </Wrapper>
  );
};

StatusUpdate.propTypes = {
  status: PropTypes.string.isRequired,
  customerName: PropTypes.string,
  createdAt: PropTypes.string.isRequired
};

StatusUpdate.defaultProps = {
  customerName: ''
};

const Wrapper = styled.div`
  background-color: ${colors.gray.lighter};
  margin-bottom: ${space[3] + space[1]}rem;
  position: relative;
  left: -${space[1] * 5}rem;
  .content {
    padding: ${space[1] * 0.15}rem;
    padding-left: ${space[1]}rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      flex: 1;
    }
    p {
      flex: 7;
      margin: ${space[1]}rem;
      font-stretch: normal;
    }
    @media screen and (min-width: ${breakpoints.sm}) {
      justify-content: initial;
      svg {
        flex: unset;
      }
      p {
        flex: unset;
      }
    }
  }
`;

export default StatusUpdate;
