import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'Components';
import { useTranslation } from 'react-i18next';
import Option from '@bootstrap-styled/v4/lib/Option';

const DealerName = ({
  value,
  status,
  userLevel,
  dealerList,
  onChangeDealerFunc,
  onChangeFunc
}) => {
  const { t } = useTranslation();

  if (userLevel === 'service_center_user') {
    return (
      <Input
        type="select"
        label={t('create_ticket.label.dealer_name')}
        name="dealer_name"
        value={value}
        onChangeFunc={onChangeDealerFunc}
        status={status}
        helperText={t('create_ticket.helper_text.dealer_name')}
        inputId="dealer-name"
        width="100%"
        isRequired
        isDisabled={!dealerList || !dealerList.length}
      >
        {dealerList && dealerList.length > 0 ? (
          <Fragment>
            <Option disabled value="">
              {t('create_ticket.placeholder.dealer_name')}
            </Option>
            {dealerList.map(dealer => (
              <Option key={dealer.id} value={dealer.id}>
                {dealer.name}
              </Option>
            ))}
          </Fragment>
        ) : (
          <Option disabled value="">
            {t('form.placeholders.loading')}
          </Option>
        )}
      </Input>
    );
  }

  return (
    <Input
      type="text"
      label={t('create_ticket.label.dealer_name')}
      name="dealer_name"
      value={value}
      inputId="dealer-name"
      onChangeFunc={onChangeFunc}
      status={status}
      helperText={t('create_ticket.helper_text.dealer_name')}
      placeholder={t('form.placeholders.company.name')}
      width="100%"
      isRequired
    />
  );
};

DealerName.propTypes = {
  userLevel: PropTypes.string.isRequired,
  dealerList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  status: PropTypes.string.isRequired,
  onChangeDealerFunc: PropTypes.func.isRequired,
  onChangeFunc: PropTypes.func.isRequired
};

export default DealerName;
