import { ADD_ACTION } from '../middleware/actionLogger';

const initialState = {
  list: []
};

const actionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ACTION: {
      const list = [...state.list];
      list.push(action.payload);
      return {
        ...state,
        list
      };
    }
    default:
      return state;
  }
};

export default actionReducer;
