import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Badge, DataTable, Heading } from 'Components';
import { useTranslation } from 'react-i18next';
import {
  formatDate,
  setBadgeColorByStatus,
  formatPhoneNumber
} from 'Components/Utils';

const DealerLocations = ({ locations }) => {
  const { t } = useTranslation();
  const columns = [
    {
      name: t('table.name'),
      selector: 'name',
      cell: row => (
        <div>
          {row.name}{' '}
          {row.is_primary ? (
            <Badge label={t(`data.badge.primary`)} pill color="info" inTable />
          ) : null}
        </div>
      )
    },
    {
      name: t('table.address'),
      selector: 'address.address_one',
      cell: row => {
        const street = row.address.address_two
          ? `${row.address.address_one}, ${row.address.address_two}, `
          : `${row.address.address_one}, `;
        return (
          <Fragment>
            {street}
            {row.address.city}, {row.address.state} {row.address.zip}
          </Fragment>
        );
      },
      grow: 2
    },
    {
      name: t('table.country'),
      selector: 'address.country',
      cell: row =>
        row.address.country !== 'undefined' ? row.address.country : ''
    },
    {
      name: t('table.phone'),
      selector: 'address.phone',
      cell: row =>
        row.address.phone ? formatPhoneNumber(row.address.phone) : ''
    },
    {
      name: t('table.last_activity'),
      selector: 'updated_at',
      cell: row => row.updated_at && formatDate(row.updated_at)
    },
    {
      name: t('table.status'),
      selector: 'status',
      cell: row => (
        <Badge
          label={t(`data.status.${row.status.toLowerCase()}`)}
          pill
          color={setBadgeColorByStatus(row.status.toLowerCase())}
          inTable
        />
      ),
      compact: true
    }
  ];
  return (
    <Fragment>
      <Heading size={6}>{t('dealership.heading_locations')}</Heading>
      <DataTable
        title=""
        data={locations}
        columns={columns}
        pagination={false}
        noHeader
        highlightOnHover={false}
        defaultSortField="name"
      />
    </Fragment>
  );
};

DealerLocations.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default DealerLocations;
