import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { breakpoints, colors, space, typography } from "Theme";
import { formatDate } from "Components/Utils";
import { Button } from "Components";
import MessageHeader from "./MessageHeader";

const parseLocalImagePath = fullPath => {
  return fullPath.replace("/var/www/public", "");
};

const Message = ({
  ticketCreatorId,
  message,
  apiDomainUrl,
  onEdit,
  isEditable = false
}) => {
  const { t } = useTranslation();
  const [messageText, setMessageText] = useState(message.text);
  const [updatedMessage, setUpdatedMessage] = useState(message.text);
  const [isEditing, setIsEditing] = useState(false);
  const [isHidden, setHidden] = useState(message.hidden);
  const [isLoading, setLoading] = useState(false);

  const handleCancelEdit = event => {
    event.preventDefault();
    setIsEditing(false);
  };

  const handleSubmitEdit = event => {
    setMessageText(updatedMessage);
    setLoading(true);
    onEdit({
      message: {
        id: message.id,
        text: updatedMessage
      }
    })
      .then(() => {
        setLoading(false);
        setIsEditing(false);
      })
      .catch(error => {
        setLoading(false);
        console.error(`Error updating message: ${error}`);
      });
  };

  const handleToggleHide = (messageId, hidden) => {
    setLoading(true);
    onEdit({
      message: {
        id: messageId,
        hidden
      }
    })
      .then(() => {
        setLoading(false);
        setHidden(hidden);
      })
      .catch(error => {
        setLoading(false);
        console.error(`Error hiding message: ${error}`);
      });
  };

  return (
    <MessageWrapper>
      <MessageHeader
        name={message.from_user.name}
        img={message.from_user.profile_photo_url}
        createdDate={formatDate(message.created_at)}
        isTechnician={
          ticketCreatorId &&
          message.from_user.id &&
          ticketCreatorId !== message.from_user.id
        }
        isHidden={isHidden}
        isServiceCenterUser={isEditable}
        overrideDropdownOptions={[
          {
            text: t(`ticket.dropdown_message.edit`),
            onClick: () => setIsEditing(true),
            tag: "a"
          },
          {
            text: isHidden
              ? t(`ticket.dropdown_message.show`)
              : t(`ticket.dropdown_message.hide`),
            onClick: () => handleToggleHide(message.id, !isHidden),
            tag: "a"
          }
        ]}
      />
      <MessageBody>
        {message.images &&
          message.images.map(messageImage => {
            const { image } = messageImage;
            return (
              <a
                href={
                  image.storage_type === "s3"
                    ? image.s3_object_path
                    : `${apiDomainUrl}${parseLocalImagePath(
                        image.filesystem_object_path
                      )}`
                }
                target="_blank"
                rel="noopener noreferrer"
                key={messageImage.id}
              >
                <img
                  src={
                    image.storage_type === "s3"
                      ? image.s3_object_path
                      : `${apiDomainUrl}${parseLocalImagePath(
                          image.filesystem_object_path
                        )}`
                  }
                  alt={t(`ticket.alt.part_image`)}
                  width="156"
                />
              </a>
            );
          })}
      </MessageBody>
      <MessageText>
        {isEditing ? (
          <form>
            <textarea
              type="text"
              disabled={!isEditing || isLoading}
              value={updatedMessage}
              rows={5}
              style={{
                width: "100%",
                fontFamily: "'Helvetica',Arial,sans-serif",
                fontSize: 16,
                marginBottom: "1.5rem"
              }}
              onChange={e => setUpdatedMessage(e.currentTarget.value)}
            />
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                gap: 8,
                justifyContent: "flex-end"
              }}
            >
              <Button
                type="button"
                color="secondary"
                disabled={isLoading}
                outline
                onClickFunc={handleCancelEdit}
              >
                Cancel
              </Button>
              <Button
                type="button"
                isFetching={isLoading}
                onClickFunc={handleSubmitEdit}
              >
                Save
              </Button>
            </div>
          </form>
        ) : (
          messageText
        )}
      </MessageText>
    </MessageWrapper>
  );
};

Message.propTypes = {
  ticketCreatorId: PropTypes.number.isRequired,
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    created_at: PropTypes.string,
    from_user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      profile_photo_url: PropTypes.string
    }).isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        storage_type: PropTypes.string,
        s3_object_image_path: PropTypes.string,
        filesystem_object_path: PropTypes.string
      })
    ),
    text: PropTypes.string.isRequired
  }).isRequired,
  apiDomainUrl: PropTypes.string.isRequired,
  onEdit: PropTypes.func
};

const { fonts } = typography;
const MessageWrapper = styled.div`
  margin-bottom: ${space[3] + space[1]}rem;
  background-color: ${colors.white.base};
  padding: ${space[2]}rem;
  position: relative;
  left: -${space[1] * 5}rem;
  &:last-child {
    margin-bottom: 0;
  }
`;
const MessageBody = styled.div`
  padding-left: ${space[1]}rem;
  @media screen and (min-width: ${breakpoints.sm}) {
    padding-left: ${space[1] * 6}rem;
  }
`;
const MessageText = styled.div`
  font-stretch: normal;
  font-family: ${fonts.sansSerif};
  padding: ${space[1]}rem;
  @media screen and (min-width: ${breakpoints.sm}) {
    padding-left: ${space[1] * 6}rem;
  }
`;

export default Message;
