import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'Theme';

const SortIcon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="srs-caret"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M8.88669666,5.31046536 L11.7537445,9.42863557 C12.0693003,9.88189371 11.9576706,10.5051406 11.5044124,10.8206965 C11.3367448,10.9374257 11.1373471,11 10.933048,11 L5.07071383,11 C4.51842908,11 4.07071383,10.5522847 4.07071383,10 C4.07071383,9.78864374 4.13768078,9.58272218 4.26200143,9.41179575 L7.25728779,5.29362554 C7.58214403,4.84698601 8.20756492,4.74826115 8.65420445,5.07311738 C8.74439457,5.13871575 8.82297632,5.21893874 8.88669666,5.31046536 Z"
        id="Combined-Shape"
        fill={color}
      />
    </g>
  </svg>
);

SortIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

SortIcon.defaultProps = {
  size: '16px',
  color: colors.white.base
};

export default SortIcon;
