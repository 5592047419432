export default {
  en: {
    modal: {
      title: {
        create_ticket: "Create Ticket",
        update_ticket: "Edit Ticket",
        add_user: "Invite User",
        add_location: "Add Location",
        add_tech: "Add Technician",
        update_user: "Edit User",
        accept_dealer_invite: "Join Organization",
        add_part_type: "Add Part Type",
        add_part_type_attribute:
          "Add Part Type Attribute for {{ partTypeName }}",
        add_part_model: "Add Part Model",
        add_part_model_attribute: "Add Part Model Attribute",
      },
      close: "Close",
    },
  },
  de: {
    modal: {
      title: {
        create_ticket: "Ticket erstellen",
        update_ticket: "Ticket bearbeiten",
        add_user: "Benutzer einladen",
        add_location: "Ort hinzufügen",
        update_user: "Benutzer bearbeiten",
        accept_dealer_invite: "Organisation beitreten",
      },
      close: "Nah dran",
    },
  },
  jp: {
    modal: {
      title: {
        create_ticket: "チケットを作成する",
        update_ticket: "チケットの編集",
        add_user: "ユーザーを招待",
        add_location: "場所を追加",
        update_user: "ユーザーの編集",
        accept_dealer_invite: "組織に参加する",
      },
      close: "選ぶ",
    },
  },
  it: {
    modal: {
      title: {
        create_ticket: "Crea biglietto",
        update_ticket: "Modifica biglietto",
        add_user: "Invita l'utente",
        add_location: "Aggiungi luogo",
        update_user: "Modifica utente",
        accept_dealer_invite: "Unisciti all'organizzazione",
      },
      close: "Chiudere",
    },
  },
};
