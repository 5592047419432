import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Row from '@bootstrap-styled/v4/lib/Row';
import Col from '@bootstrap-styled/v4/lib/Col';
import Option from '@bootstrap-styled/v4/lib/Option';
import { withTranslation } from 'react-i18next';
import {
  fetchDealershipDetails,
  updateDealer,
  fetchScopes,
  inviteUserReset
} from 'Actions/dealership';
import { showModal } from 'Actions/modal';
import {
  Heading,
  Form,
  Input as SsgInput,
  PageHeader,
  Button,
  Alert,
  Scrim,
  Throbber
} from 'Components';
import * as modalTypes from 'Components/Modal/types';
import { space, colors, breakpoints, headerHeight } from 'Theme';
import { titleCase } from 'Components/Utils';
import { parsePhoneNumber } from 'libphonenumber-js';
import Locations from './Locations';
import Users from './Users';
import Tickets from './Tickets';
import countries from '../../components/Utils/countryData';

class Dealership extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      isUpdating: false,
      form: {
        name: '',
        email: '',
        status: '',
        primary_location: '',
        countryCode: 'US',
        phone: ''
      },
      hasSuccess: false,
      successMsg: '',
      hasError: false,
      authError: false,
      error: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.onAddLocation = this.onAddLocation.bind(this);
    this.onAddUser = this.onAddUser.bind(this);
  }

  async componentDidMount() {
    const {
      fetchScopes,
      fetchDealershipDetails,
      match: {
        params: { dealership }
      },
      t,
      user,
      history
    } = this.props;

    await fetchScopes().catch(errStatus => {
      const error = t(`dealership.error.${errStatus}`);
      this.setState(() => ({ error }));
    });

    const { form } = this.state;
    // If the dealer_user doesn't work at the dealership that submitted this ticket,
    // then get data for the correct dealership. Otherwise, use URL param
    if (user.level === 'dealer_user' && dealership !== user.dealership.id) {
      history.replace(`/dealership/${user.dealership.id}`);
    }
    const data = {
      id: user.level === 'dealer_user' ? user.dealership.id : dealership
    };
    await fetchDealershipDetails(data).catch(errorStatus => {
      const error = t(`dealership.error.${errorStatus}`);
      if (
        user.level === 'service_center_user' &&
        (errorStatus === 403 || errorStatus === 404)
      ) {
        history.push('/dealerships');
      }
      this.setState(() => ({
        authError: true,
        error
      }));
    });

    // After fetching the current details we now have more information about the
    // dealer and need a new handle to it.
    const {
      dealership: {
        dealership: { name, status, email, phone },
        locations
      }
    } = this.props;

    locations.forEach(loc => {
      if (loc.is_primary) {
        form.primary_location = loc.id;
      }
    });

    let nationalNumber = '';
    if (phone) {
      const phoneNumber = parsePhoneNumber(phone, form.countryCode);

      nationalNumber = phoneNumber.formatNational();

      // TODO: When more countries than U.S. are added, use this functionality to look up country abbreviation to select correct country calling code

      // const country = countries.find(
      // 	(country) => country.countryCode == phoneNumber.countryCallingCode
      // );
    }

    return this.setState(() => ({
      isFetching: false,
      form: {
        ...form,
        name,
        status,
        email,
        // TODO: For int'l -- country code from phone number.
        // countryCode: country.iso2.toUpperCase(),
        phone: nationalNumber
      }
    }));
  }

  async onSubmit() {
    const { updateDealer, dealership, t } = this.props;
    const { form } = this.state;

    this.setState(() => ({
      isUpdating: true
    }));

    let hasSuccess = false;
    let successMsg = '';
    let hasError = false;
    let error = '';

    let phoneNumber = '';
    if (form.phone) {
      phoneNumber = parsePhoneNumber(form.phone, form.countryCode);
    }

    const newForm = {
      ...form,
      phone: phoneNumber ? phoneNumber.number : phoneNumber
    };

    await updateDealer({
      id: dealership.dealership.id,
      ...newForm
    })
      .then(() => {
        hasSuccess = true;
        successMsg = t(`dealership.error.200`);
      })
      .catch(err => {
        hasError = true;
        error = t(`dealership.error.${err}`);
      });

    this.setState(() => ({
      hasSuccess,
      successMsg,
      hasError,
      error,
      isUpdating: false
    }));
  }

  onAddLocation() {
    const { showModal, t } = this.props;
    const modalData = {
      type: modalTypes.FORM_CREATE_DEALER_LOCATION,
      title: t('modal.title.add_location')
    };
    showModal(modalData);
  }

  onAddUser() {
    const { showModal, t } = this.props;
    const modalData = {
      type: modalTypes.FORM_CREATE_DEALER_USER,
      title: t('modal.title.add_user')
    };
    showModal(modalData);
  }

  handleChange(event) {
    const { form } = this.state;
    const { value, checked } = event.target;
    let newValue;
    if (event.target.type === 'checkbox') {
      newValue = checked;
    } else {
      newValue = value;
    }
    form[event.target.name] = newValue;
    this.setState(() => ({
      form
    }));
  }

  render() {
    const {
      isFetching,
      isUpdating,
      form,
      error,
      hasError,
      authError,
      successMsg,
      hasSuccess
    } = this.state;
    const {
      user,
      user: { level: userLevel },
      history,
      status: { INVITE_USER },
      inviteUserReset,
      dealership: { dealership, locations, users, scopes, tickets },
      t
    } = this.props;

    const genericRequiredRule = {
      rule: 'required',
      messages: {
        error: t('form.required_feedback'),
        success: ''
      }
    };

    let admin = false;
    if (users) {
      users.forEach(u => {
        if (
          (u.id === user.id && u.dealer_user.is_admin === 1) ||
          user.level === 'service_center_user'
        ) {
          admin = true;
        }
      });
    }

    return isFetching ? (
      <Fragment>
        <Scrim isShown={isFetching} />
        <StyledThrobber isFullPage />
      </Fragment>
    ) : (
      <Form
        onSubmitFunc={() => this.onSubmit()}
        validation={{
          name: [genericRequiredRule],
          status: [genericRequiredRule],
          email: [],
          countryCode: [],
          phone: [
            {
              rule: 'phone',
              messages: {
                error: t('form.invalid.phone_number'),
                success: ''
              }
            }
          ]
        }}
        ignoreRequiredOnBlur
        render={status => (
          <Fragment>
            {user.level === 'service_center_user' && (
              <PageHeader
                backButtonUrl="/dealerships"
                backButtonText={t(`dealership.buttons.view_dealers`)}
                history={history}
              />
            )}
            <AlertWrapper>
              <Alert
                alertIsOpen={hasError || authError}
                color="danger"
                noClose={authError}
                onCloseFunc={() => this.setState(() => ({ hasError: false }))}
              >
                {error}
              </Alert>
              <Alert
                alertIsOpen={hasSuccess}
                color="success"
                onCloseFunc={() => this.setState(() => ({ hasSuccess: false }))}
              >
                {successMsg}
              </Alert>
              <Alert
                alertIsOpen={INVITE_USER === 'success'}
                color="success"
                onCloseFunc={() => inviteUserReset()}
              >
                {t('dealership.invite_user')}
              </Alert>
            </AlertWrapper>
            {!authError && (
              <Fragment>
                <DealerHeader>
                  <Heading size={4}>
                    {t('dealership.heading_main', {
                      dealer_name: `${dealership.name}`
                    })}
                  </Heading>
                </DealerHeader>
                <Section>
                  <Row>
                    <Col md={4}>
                      <Heading size={6}>{t('dealership.heading_info')}</Heading>
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col md={6}>
                          <SsgInput
                            label={t('form.labels.company')}
                            name="name"
                            value={form.name}
                            onChangeFunc={this.handleChange}
                            status={status.name.status}
                            feedbackMsg={status.name.feedback}
                            inputId="company-name"
                            placeholder={form.name}
                            isRequired
                            width="100%"
                          />
                        </Col>
                        <Col md={6}>
                          <div className="d-flex">
                            <SsgInput
                              label={t('form.labels.country_code')}
                              type="select"
                              name="countryCode"
                              value={form.countryCode}
                              onChangeFunc={this.handleChange}
                              inputId="countryCode"
                              minWidth="100px"
                              isDisabled
                            >
                              {countries &&
                                countries
                                  .sort((a, b) => {
                                    return a.countryCode - b.countryCode;
                                  })
                                  .map(country => (
                                    <Option
                                      key={country.iso2}
                                      value={country.iso2.toUpperCase()}
                                    >
                                      +{country.countryCode}
                                    </Option>
                                  ))}
                            </SsgInput>
                            <SsgInput
                              label={t('form.labels.phone')}
                              type="tel"
                              name="phone"
                              value={form.phone}
                              onChangeFunc={this.handleChange}
                              status={status.phone.status}
                              feedbackMsg={status.phone.feedback}
                              inputId="company-phone"
                              width="100%"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <SsgInput
                            label={t('form.labels.company_email')}
                            type="email"
                            name="email"
                            value={form.email}
                            onChangeFunc={this.handleChange}
                            status={status.email.status}
                            feedbackMsg={status.email.feedback}
                            inputId="company-email"
                            placeholder={form.email}
                            width="100%"
                          />
                        </Col>
                        <Col md={6}>
                          <SsgInput
                            label={t('form.labels.status')}
                            type="select"
                            name="status"
                            value={form.status}
                            onChangeFunc={this.handleChange}
                            status={status.status.status}
                            feedbackMsg={status.status.feedback}
                            inputId="company-status"
                            width="100%"
                            isDisabled={userLevel !== 'service_center_user'}
                          >
                            {scopes &&
                              scopes.map(scope => (
                                <Option
                                  key={`scope-${scope}`}
                                  value={scope.toLowerCase()}
                                >
                                  {titleCase(scope)}
                                </Option>
                              ))}
                          </SsgInput>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <SsgInput
                            label={t('form.labels.primary_location')}
                            type="select"
                            name="primary_location"
                            value={form.primary_location}
                            onChangeFunc={this.handleChange}
                            status="muted"
                            inputId="company-primary-location"
                            width="100%"
                          >
                            <Option value="">
                              {t('form.placeholders.select')}
                            </Option>
                            {locations &&
                              locations.map(loc => (
                                <Option key={loc.id} value={loc.id}>
                                  {loc.name}
                                </Option>
                              ))}
                          </SsgInput>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Section>
                <ButtonWrapper>
                  <Button
                    color="primary"
                    type="submit"
                    isFetching={isUpdating}
                    width="max-content"
                  >
                    {t('dealership.buttons.update')}
                  </Button>
                </ButtonWrapper>
                <Section>
                  <Users
                    users={users}
                    ownerId={dealership.owner_id}
                    loggedinUser={user}
                  />
                  {admin && (
                    <ButtonWrapper>
                      <Button
                        color="secondary"
                        outline
                        type="button"
                        onClickFunc={() => this.onAddUser()}
                        width="max-content"
                      >
                        {t('dealership.buttons.add_user')}
                      </Button>
                    </ButtonWrapper>
                  )}
                </Section>
                <Section>
                  <Locations locations={locations} />
                  {admin && (
                    <ButtonWrapper>
                      <Button
                        color="secondary"
                        outline
                        type="button"
                        onClickFunc={() => this.onAddLocation()}
                        width="max-content"
                      >
                        {t('dealership.buttons.add_location')}
                      </Button>
                    </ButtonWrapper>
                  )}
                </Section>
                {userLevel === 'service_center_user' && (
                  <Section>
                    <Tickets tickets={tickets} />
                  </Section>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      />
    );
  }
}

Dealership.propTypes = {
  dealership: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape()),
    pagination: PropTypes.shape(),
    dealership: PropTypes.shape(),
    locations: PropTypes.arrayOf(PropTypes.shape()),
    users: PropTypes.arrayOf(PropTypes.shape()),
    tickets: PropTypes.arrayOf(PropTypes.shape()),
    scopes: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  status: PropTypes.shape({
    INVITE_USER: PropTypes.string
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    level: PropTypes.string,
    isLoggedIn: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    dealership: PropTypes.shape({
      id: PropTypes.number
    }),
    service_center_id: PropTypes.number
  }).isRequired,
  inviteUserReset: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  fetchScopes: PropTypes.func.isRequired,
  fetchDealershipDetails: PropTypes.func.isRequired,
  updateDealer: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      dealership: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${space[1] * 3}rem;
`;

const DealerHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Section = styled.section`
  padding-top: ${space[1] * 3}rem;
  padding-bottom: ${space[2] * 2}rem;
  margin-bottom: ${space[1]}rem;
  border-bottom: 2px solid ${colors.gray.base};
  @media screen and (min-width: ${breakpoints.md}) {
    padding-top: ${space[1] * 7}rem;
    padding-bottom: ${space[4]}rem;
  }
`;

const AlertWrapper = styled.div`
  margin-top: calc(${headerHeight}px + ${space[1]}rem);
  margin-bottom: ${space[3]}rem;
`;

const StyledThrobber = styled(Throbber)`
  top: 2rem;
`;

const mapStateToProps = state => ({
  user: state.user,
  dealership: state.dealership,
  modal: state.modal,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  fetchScopes: data => dispatch(fetchScopes(data)),
  fetchDealershipDetails: data => dispatch(fetchDealershipDetails(data)),
  showModal: data => dispatch(showModal(data)),
  updateDealer: data => dispatch(updateDealer(data)),
  inviteUserReset: () => dispatch(inviteUserReset())
});

/**
 * Expanded Row for Dealership Location
 */
const DealerLocationRowDisplay = ({ data }) => {
  const { name, address } = data;
  const { zip, country, phone } = address;

  return (
    <Row className="px-5 py-3">
      <Col md={6}>
        <p>
          <b> Name:</b> {name}
        </p>
        <p>
          <b>Zip:</b> {zip}
        </p>
      </Col>
      <Col md={6}>
        <p>
          {' '}
          <b>Country:</b> {country}
        </p>
        <p>
          <b>Phone Number:</b> {phone}
        </p>
      </Col>
    </Row>
  );
};

DealerLocationRowDisplay.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.shape({
      zip: PropTypes.string,
      country: PropTypes.string,
      phone: PropTypes.string
    })
  })
};

DealerLocationRowDisplay.defaultProps = {
  data: {}
};

/**
 * Expanded Row Component for Dealership User
 */
const DealerUserRowDisplay = ({ data }) => {
  const { email, phone, notes } = data;
  return (
    <Row className="px-5 py-3">
      <Col md={6}>
        <p>
          <b>Email:</b> {email}
        </p>
        <p>
          <b>Phone:</b> {phone}
        </p>
      </Col>
      <Col md={6}>
        <p>
          <b>Notes:</b> {notes}
        </p>
      </Col>
    </Row>
  );
};

DealerUserRowDisplay.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    notes: PropTypes.string
  })
};

DealerUserRowDisplay.defaultProps = {
  data: {}
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dealership))
);
