/* eslint-disable prettier/prettier */
export const FETCH_DEALERSHIPS_LOADING          = 'FETCH_DEALERSHIPS_LOADING';
export const FETCH_DEALERSHIPS_SUCCESS          = 'FETCH_DEALERSHIPS_SUCCESS';
export const FETCH_DEALERSHIPS_ERROR            = 'FETCH_DEALERSHIPS_ERROR';

export const FETCH_DEALERSHIPS_ALL_LOADING      = 'FETCH_DEALERSHIPS_ALL_LOADING';
export const FETCH_DEALERSHIPS_ALL_SUCCESS      = 'FETCH_DEALERSHIPS_ALL_SUCCESS';
export const FETCH_DEALERSHIPS_ALL_ERROR        = 'FETCH_DEALERSHIPS_ALL_ERROR';

export const FETCH_DEALERSHIP_DETAIL_LOADING    = 'FETCH_DEALERSHIP_DETAIL_LOADING';
export const FETCH_DEALERSHIP_DETAIL_SUCCESS    = 'FETCH_DEALERSHIP_DETAIL_SUCCESS';
export const FETCH_DEALERSHIP_DETAIL_ERROR      = 'FETCH_DEALERSHIP_DETAIL_ERROR';

export const FETCH_DEALERSHIPS_BY_SCOPE_LOADING = 'FETCH_DEALERSHIPS_BY_SCOPE_LOADING';
export const FETCH_DEALERSHIPS_BY_SCOPE_SUCCESS = 'FETCH_DEALERSHIPS_BY_SCOPE_SUCCESS';
export const FETCH_DEALERSHIPS_BY_SCOPE_ERROR   = 'FETCH_DEALERSHIPS_BY_ERROR';

export const FETCH_SCOPES_LOADING               = 'FETCH_SCOPES_LOADING';
export const FETCH_SCOPES_SUCCESS               = 'FETCH_SCOPES_SUCCESS';
export const FETCH_SCOPES_ERROR                 = 'FETCH_SCOPES_ERROR';

export const CREATE_DEALER_LOCATION_LOADING     = 'CREATE_DEALER_LOCATION_LOADING';
export const CREATE_DEALER_LOCATION_SUCCESS     = 'CREATE_DEALER_LOCATION_SUCCESS';
export const CREATE_DEALER_LOCATION_ERROR       = 'CREATE_DEALER_LOCATION_ERROR';

export const SET_DEALER                         = 'SET_DEALER';

export const UPDATE_DEALERSHIP_LOADING          = 'UPDATE_DEALERSHIP_LOADING';
export const UPDATE_DEALERSHIP_SUCCESS          = 'UPDATE_DEALERSHIP_SUCCESS';
export const UPDATE_DEALERSHIP_ERROR            = 'UPDATE_DEALERSHIP_ERROR';

export const INVITE_USER_LOADING                = 'INVITE_USER_LOADING';
export const INVITE_USER_SUCCESS                = 'INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR                  = 'INVITE_USER_ERROR';
export const INVITE_USER_RESET                  = 'INVITE_USER_RESET';

export const SEARCH_DEALERS_LOADING             = 'SEARCH_DEALERS_LOADING';
export const SEARCH_DEALERS_SUCCESS             = 'SEARCH_DEALERS_SUCCESS';
export const SEARCH_DEALERS_ERROR               = 'SEARCH_DEALERS_ERROR';
export const SEARCH_DEALERS_RESET               = 'SEARCH_DEALERS_RESET';         

export const UPDATE_DEALER_USER_LOADING         = 'UPDATE_DEALER_USER_LOADING';
export const UPDATE_DEALER_USER_SUCCESS         = 'UPDATE_DEALER_USER_SUCCESS';
export const UPDATE_DEALER_USER_ERROR           = 'UPDATE_DEALER_USER_ERROR';

export const ADD_DEALER_FILTER              = 'ADD_DEALER_FILTER';
export const REMOVE_DEALER_FILTER           = 'REMOVE_DEALER_FILTER';