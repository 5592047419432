import React, { Component, Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Option from "@bootstrap-styled/v4/lib/Option";
import { connect } from "react-redux";
import { fetchScopes } from "Actions/ticket";
import {
  Button,
  Heading,
  Input as SsgInput,
  FileUploader,
  Form,
  Checkbox
} from "Components";
import { space, colors } from "Theme";
import { withTranslation } from "react-i18next";

class AddMessageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        message: "",
        status: "",
        files: [],
        hidden: false
      },
      isLoading: true,
      hasUpdatedTicketStatus: false,
      isSubmitting: false
    };
    this.sendMessage = this.sendMessage.bind(this);
    this.handleUpdateFiles = this.handleUpdateFiles.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    const {
      fetchTicketScopes,
      ticket: {
        current: { status }
      },
      user
    } = this.props;
    await fetchTicketScopes();
    this.setState(state => ({
      ...state,
      isLoading: false,
      form: { ...state.form, status }
    }));

    if (user.level === "service_center_user") {
      this.onChange({ target: { name: "status", value: "pending_response" } });
    }
  }

  onChange = async e => {
    const { onTicketStatusSelect } = this.props;
    const {
      target: { name, value, checked }
    } = e;

    const { form } = this.state;

    form[name] = value;

    if (name === "status") {
      onTicketStatusSelect(form.status);
      this.setState(state => ({ ...state, hasUpdatedTicketStatus: true }));
    }

    if (name === "hidden") {
      form.hidden = checked;
    }

    this.setState(state => ({ ...state, form }));
  };

  setPondRef(ref) {
    this.state.pond = ref;
    this.pond = ref;
  }

  handleUpdateFiles(fileItems) {
    // Set currently active file objects to this.state
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        files: fileItems.map(fileItem => fileItem.file)
      }
    }));
  }

  async sendMessage() {
    const {
      form,
      form: { message, hidden }
    } = this.state;
    const {
      createTicketMessageFunc,
      ticket: {
        current: { id }
      },
      user,
      onSaveTicketStatus
    } = this.props;

    // Get base64-encoded data for each file uploaded to transmit to the server
    const fileData = this.pond.getFiles().map(file => {
      return `data:image/png;base64,${file.getFileEncodeBase64String()}`;
    });

    const images = this.pond.getFiles().map(file => {
      return {
        image: {
          storage_type: "s3",
          s3_object_path: file.filename
        }
      };
    });

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        message: "",
        files: [],
        hidden: false
      },
      isSubmitting: true,
      initialStatus: form.status
    }));

    // Update the ticket status and send the message
    // If it wasn't empty
    if (user.level === "service_center_user") {
      await onSaveTicketStatus();
    }
    if (message.length || images.length) {
      await createTicketMessageFunc({
        ...form,
        id,
        fileData,
        images,
        hidden,
        user: { ...user }
      });
    }

    this.setState(state => ({
      ...state,
      isSubmitting: false,
      hasUpdatedTicketStatus: false
    }));
  }

  render() {
    const {
      form,
      isSubmitting,
      isLoading,
      hasUpdatedTicketStatus
    } = this.state;
    const {
      t,
      user,
      ticket: { scopes }
    } = this.props;

    return (
      <Form
        onSubmitFunc={async () => this.sendMessage()}
        validation={{
          message: [],
          status: [],
          hidden: false
        }}
        render={formStatus => {
          return (
            <Fragment>
              <FormFieldsWrapper>
                <Heading size={5}>{t(`ticket.headings.message`)}</Heading>
                <SsgInput
                  label={t(`ticket.labels.message_text`)}
                  inputId="message"
                  name="message"
                  value={form.message}
                  onChangeFunc={e => {
                    this.onChange(e);
                  }}
                  type="textarea"
                  status={formStatus.message.status}
                  placeholder={t(`ticket.placeholders.message_text`)}
                  width="100%"
                />
                <FileUploader
                  setPond={ref => this.setPondRef(ref)}
                  onUpdateFiles={this.handleUpdateFiles}
                  files={form.files}
                  allowMultiple
                  maxFiles={3}
                  label={t(`ticket.labels.files`)}
                  helperText={t(`ticket.helper_text.files`)}
                />
              </FormFieldsWrapper>
              <MessageActionsWrapper>
                {user.level === "service_center_user" && (
                  <Fragment>
                    <div className="form-group" style={{ marginRight: 16 }}>
                      <StyledCheckbox
                        type="checkbox"
                        name="hidden"
                        checked={form.hidden}
                        onChange={this.onChange}
                      />
                      <label htmlFor="hidden">Hidden</label>
                    </div>
                    <SsgInput
                      label="Select Status"
                      hideLabel
                      inputId="set-status"
                      type="select"
                      name="status"
                      value={form.status}
                      onChangeFunc={this.onChange}
                      className="mr-2"
                      style={{ marginBottom: 0 }}
                      status={formStatus.status.status}
                      isDisabled={isLoading}
                    >
                      {scopes &&
                        scopes.map(aScope => (
                          <Option key={aScope} value={aScope.toLowerCase()}>
                            {t(`data.status.${aScope.toLowerCase()}`)}
                          </Option>
                        ))}
                    </SsgInput>
                  </Fragment>
                )}
                <div>
                  <Button
                    color="primary"
                    type="submit"
                    isFetching={isSubmitting}
                    width="min-content"
                    style={{ marginBottom: 0 }}
                  >
                    {hasUpdatedTicketStatus
                      ? t(`ticket.buttons.send_message_update_status`)
                      : t(`ticket.buttons.send_message`)}
                  </Button>
                </div>
              </MessageActionsWrapper>
            </Fragment>
          );
        }}
      />
    );
  }
}

AddMessageForm.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  ticket: PropTypes.shape().isRequired,
  fetchTicketScopes: PropTypes.func.isRequired,
  onTicketStatusSelect: PropTypes.func,
  createTicketMessageFunc: PropTypes.func.isRequired,
  onSaveTicketStatus: PropTypes.func
};

AddMessageForm.defaultProps = {
  onTicketStatusSelect: () => {},
  onSaveTicketStatus: () => {}
};

const FormFieldsWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.gray.base};
  margin-bottom: ${space[1] * 3}rem;
`;

const MessageActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  float: right;

  .form-group {
    margin-right: ${space[1]}rem;
    margin-bottom: 0;
  }
`;

const StyledCheckbox = styled.input`
  margin: 0 4px 0 0;
`;

const mapStateToProps = state => ({
  user: state.user,
  ticket: state.ticket,
  modal: state.modal,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  fetchTicketScopes: () => dispatch(fetchScopes())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddMessageForm));
