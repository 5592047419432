import React, { useState, useEffect } from "react";
import { Button, Input } from "Components";
import styled from "styled-components";
import Option from "@bootstrap-styled/v4/lib/Option";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createPart } from "Actions/part";
import { hideModal } from "Actions/modal";
import { titleCase } from "Components/Utils";

const AddPartTypeAttribute = (props) => {
  const { attributes } = props;
  const [isLoading, setLoading] = useState(true);
  const [partAttributeValue, setPartAttributeValue] = useState("");
  const [selectedAttr, setSelectedAttr] = useState();
  const DEFAULT_ATTRIBUTE = "diagnosis";
  useEffect(() => {
    // Set the default attribute to diagnosis
    const defaultAttribute = props.attributes.find(
      (attr) => attr.name === DEFAULT_ATTRIBUTE
    );
    setSelectedAttr(defaultAttribute.id);
  }, []);

  const handleChangeAttribute = (e) => {
    const attribute = attributes.find((attr) => attr.id === e.target.value);
    setSelectedAttr(attribute);
  };

  const { name: partTypeName, id: partTypeID } = props.data;
  const handleCreatePartTypeAttribute = () => {
    setLoading(true);
    const partTypeAttributeData = {
      partTypeId: partTypeID,
      attributeId: selectedAttr,
      value: partAttributeValue,
    };
    props.createPart(partTypeAttributeData).finally(() => {
      setLoading(false);
      props.hideModal();
    });
  };
  return (
    <form>
      <Input
        label="Part Type"
        inputId="part_type"
        value={partTypeName}
        isDisabled
        name="part_type"
        width="100%"
      />
      <Input
        label="Attribute"
        inputId="attribute"
        onChangeFunc={handleChangeAttribute}
        type="select"
        value={selectedAttr}
        isRequired
        name="attribute"
        width="100%"
      >
        {attributes.map((attribute) => (
          <Option key={attribute.id} value={attribute.id}>
            {titleCase(attribute.name)}
          </Option>
        ))}
      </Input>
      <PartNameInput
        value={partAttributeValue}
        onChangeFunc={(e) => setPartAttributeValue(e.currentTarget.value)}
        label="Part Type Attribute Value"
        placeholder="Air Loss"
      />
      <Button onClickFunc={handleCreatePartTypeAttribute}>
        Create Part Type Attribute
      </Button>
    </form>
  );
};

const PartNameInput = styled(Input)`
  width: 100%;
  margin-bottom: 1rem;
`;

const mapStateToProps = (state) => ({
  attributes: state.part.attributes,
});

const mapDispatchToProps = (dispatch) => ({
  createPart: (partData) =>
    dispatch(createPart("part-type-attribute", partData)),
  hideModal: (data) => dispatch(hideModal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddPartTypeAttribute));
