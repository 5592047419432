import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MagnifyingGlassIcon as SearchIcon } from 'Assets/images/icons';
import Input from '../Forms/Input';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoSearchTimerId: 0
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.setAutoSearchTimerId = this.setAutoSearchTimerId.bind(this);
    this.onKeyupHandler = this.onKeyupHandler.bind(this);
  }

  onChangeHandler(e) {
    const { onChangeFunc } = this.props;
    onChangeFunc(e);
    this.setAutoSearchTimerId();
  }

  onKeyupHandler(e) {
    const { autoSearchTimerId } = this.state;
    const { value, onSearchFunc } = this.props;

    if (e.keyCode === 13 && value.length) {
      if (autoSearchTimerId) {
        clearTimeout(autoSearchTimerId);
      }
      onSearchFunc();
    }
  }

  setAutoSearchTimerId() {
    const { autoSearchTimerId } = this.state;
    const { onSearchFunc, autoSearchDelay } = this.props;

    if (autoSearchTimerId) {
      clearTimeout(autoSearchTimerId);
    }

    this.setState({
      autoSearchTimerId: setTimeout(() => {
        onSearchFunc();
      }, autoSearchDelay)
    });
  }

  render() {
    const { placeholder, status, value, className } = this.props;

    return (
      <SearchWrapper className={className}>
        <Input
          placeholder={placeholder}
          label="search"
          hideLabel
          inputId="search"
          type="search"
          value={value}
          name="search"
          onChangeFunc={this.onChangeHandler}
          onKeyUpFunc={this.onKeyupHandler}
          status={status}
          width="100%"
        />
        <SearchIcon />
      </SearchWrapper>
    );
  }
}

const SearchWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  svg.search {
    position: absolute;
    top: 0.7rem;
    right: 0.7rem;
  }
`;

Search.propTypes = {
  onSearchFunc: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  autoSearchDelay: PropTypes.number,
  status: PropTypes.string,
  onChangeFunc: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

Search.defaultProps = {
  autoSearchDelay: 400,
  status: 'muted'
};

export default Search;
