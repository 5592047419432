import React, { useState } from "react";
import {
  Heading,
  Form,
  Input as SsgInput,
  PageHeader,
  Button,
  Alert,
  Scrim,
  Throbber
} from "Components";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { archiveUser } from "Actions/user";
import styled from "styled-components";

const ArchiveUser = props => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { data, archiveUser, cancel, modal, fetchUsers } = props;
  const handleArchiveUser = () => {
    setError(null);
    setLoading(true);
    archiveUser({
      ...data,
      status: "archived"
    })
      .then(() => {
        cancel(); // Close modal
      })
      .catch(err => {
        const msg = `Error archiving user: ${err}`;
        setError(msg);
      })
      .finally((...args) => {
        setLoading(false);
      });
  };

  return (
    <ArchiveUserWrapper>
      Are you sure you want to archive this user?
      <ButtonWrapper>
        <Button
          type="button"
          onClickFunc={handleArchiveUser}
          isFetching={isLoading}
        >
          Yes
        </Button>
        <Button type="button" color="secondary" outline onClickFunc={cancel}>
          No
        </Button>
      </ButtonWrapper>
      <Alert
        alertIsOpen={error !== null}
        color="danger"
        onCloseFunc={() => setError(null)}
      >
        {error}
      </Alert>
    </ArchiveUserWrapper>
  );
};

const ArchiveUserWrapper = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
`;

const mapStateToProps = state => ({
  modal: state.modal
});

const mapDispatchToProps = dispatch => ({
  archiveUser: data => dispatch(archiveUser(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ArchiveUser));
