import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "Components";
import { withTranslation } from "react-i18next";
import { Button } from "Components";
import { colors, space } from "Theme";
import styled from "styled-components";

const PartTable = (props) => {
  const {
    data,
    columns,
    expandedComponent: ExpandedComponent,
    button = {
      text: "Add Resource",
      onClick: () => console.warn(`Not implemented`),
    },
  } = props;
  return (
    <StyledContainer>
      <StyledButton onClickFunc={button.onClick}>{button.text}</StyledButton>
      <StyledDataTable
        defaultSortField="value"
        noHeader
        expandableRows={!!ExpandedComponent}
        expandableRowsComponent={
          ExpandedComponent ? <ExpandedComponent /> : null
        }
        expandOnRowClicked
        pagination
        data={data}
        columns={columns}
        paginationServer // Doesn't need this, but we get a re-render error if we disable it
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: 2rem;
  margin-left: 2rem;
  flex-direction: column;
  display: flex;
`;

const StyledButton = styled(Button)`
  align-self: end;
  margin: 0;
`;

const StyledDataTable = styled(DataTable)`
  padding-bottom: 10rem;
  padding-top: 0;
  .rdt_TableHeadRow {
    background-color: ${colors.secondary.lighter};
  }
  .rdt_ExpanderRow {
    border-left: 4px solid ${colors.primary.base};
  }
  .rdt_Pagination {
    display: none;
  }
  > div > div:first-child {
    top: ${space[0]}rem;
  }
`;

PartTable.propTypes = {
  data: PropTypes.object,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      selector: PropTypes.string,
      grow: PropTypes.number,
      cell: PropTypes.node,
    })
  ),
  t: PropTypes.func,
  expandedComponent: PropTypes.node,
};

export default withTranslation()(PartTable);
