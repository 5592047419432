import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Badge, DataTable, Heading } from "Components";
import { useTranslation } from "react-i18next";
import { formatDate } from "Components/Utils";
import { showModal } from "Actions/modal";
import { connect } from "react-redux";

const ServiceCenterInvites = ({ invitations }) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: t("table.name"),
      selector: "email",
      cell: row => row.email
    },
    {
      name: t("table.invited_at"),
      selector: "created_at",
      cell: row => formatDate(row.created_at)
    }
  ];

  return (
    <Fragment>
      <Heading size={6}>{t("service_center.heading_invites")}</Heading>
      <DataTable
        title=""
        data={invitations}
        columns={columns}
        pagination={false}
        noHeader
        overflowY
        defaultSortField="email"
      />
    </Fragment>
  );
};

ServiceCenterInvites.propTypes = {
  invitations: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      created_at: PropTypes.string
    })
  ).isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  showModal: data => dispatch(showModal(data))
});

export const farts = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceCenterInvites);

export default ServiceCenterInvites;
