import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BootstrapInput from '@bootstrap-styled/v4/lib/Input';
import BSFormGroup from '@bootstrap-styled/v4/lib/Form/FormGroup';
import BSFormFeedback from '@bootstrap-styled/v4/lib/Form/FormFeedback';
import { makeTheme } from 'bootstrap-styled/lib/theme';
import { colors, bootstrapTheme } from 'Theme';
import Label from '../Label';

const Checkbox = ({
  status,
  onChangeFunc,
  feedbackMsg,
  placeholder,
  isDisabled,
  label,
  name,
  inputId,
  theme,
  value
}) => (
  <CheckboxWrapper status={status}>
    {status === 'muted' ? (
      <BSFormGroup check>
        <BootstrapInput
          id={inputId}
          type="checkbox"
          checked={value}
          name={name}
          placeholder={placeholder}
          onChange={onChangeFunc}
          theme={theme}
          disabled={isDisabled}
        />
        <Label htmlFor={inputId} check>
          {label}
        </Label>
        <BSFormFeedback>{feedbackMsg}</BSFormFeedback>
      </BSFormGroup>
    ) : (
      <BSFormGroup check color={status}>
        <BootstrapInput
          id={inputId}
          type="checkbox"
          state={status}
          checked={value}
          name={name}
          placeholder={placeholder}
          onChange={onChangeFunc}
          theme={theme}
          disabled={isDisabled}
        />
        <Label htmlFor={inputId} check>
          {label}
        </Label>
        <BSFormFeedback>{feedbackMsg}</BSFormFeedback>
      </BSFormGroup>
    )}
  </CheckboxWrapper>
);

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  theme: PropTypes.shape(),
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['success', 'danger', 'warning', 'muted']).isRequired,
  feedbackMsg: PropTypes.string,
  onChangeFunc: PropTypes.func
};

Checkbox.defaultProps = {
  placeholder: '',
  isDisabled: false,
  theme: makeTheme(bootstrapTheme),
  feedbackMsg: '',
  onChangeFunc: () => {}
};

const CheckboxWrapper = styled.div`
  position: relative;
  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 16px;
    width: 16px;
    + label {
      margin-left: 1.5rem;
      &::before {
        position: absolute;
        top: 3px;
        left: 0;
        content: '';
        height: 16px;
        width: 16px;
        border: 1px solid
          ${props =>
            props.status === 'danger' ? colors.red.base : colors.gray.base};
      }
      &::after {
        position: absolute;
        top: 6px;
        left: 7px;
        content: '';
        border: 2px solid ${colors.black.base};
        border-left: 0;
        border-top: 0;
        height: 7px;
        opacity: 0;
        transform: rotate(45deg);
        transition: opacity 0.2s ease-in-out;
        width: 3px;
      }
    }
    &:checked + label::after {
      opacity: 1;
    }
    &:not(:disabled):focus + label::before {
      outline: 2px solid ${colors.primary.lightest};
    }
    label {
      margin-left: 1.25rem;
    }
  }
  .form-control-feedback {
    color: ${props =>
      props.status === 'danger' ? colors.red.base : colors.gray.base};
    font-size: 0.75rem;
    margin-top: 0.25rem;
  }
`;

export default Checkbox;
