export default {
  en: {
    ticket_list: {
      button: {
        create: 'Create Ticket'
      },
      error: {
        403: 'You do not have the necessary permissions to access this content.',
        500: 'A technical issue prevented us from loading the list of tickets. Our staff has been notified of the error'
      }
    }
  },
  de: {
    ticket_list: {
      button: {
        create: 'Ticket erstellen'
      },
      error: {
        403: 'Sie haben nicht die erforderlichen Berechtigungen, um auf diesen Inhalt zuzugreifen.',
        500: 'Ein technisches Problem hat uns daran gehindert, die Ticketliste zu laden. Unsere Mitarbeiter wurden über den Fehler informiert'
      }
    }
  },
  jp: {
    ticket_list: {
      button: {
        create: 'チケットを作成する'
      },
      error: {
        403: 'このコンテンツにアクセスするために必要な権限がありません。',
        500: '技術的な問題により、チケットのリストを読み込めませんでした。エラーが発生したことがスタッフに通知されました'
      }
    }
  },
  it: {
    ticket_list: {
      button: {
        create: 'Crea biglietto'
      },
      error: {
        403: 'Non hai le autorizzazioni necessarie per accedere a questo contenuto.',
        500: "Un problema tecnico ci ha impedito di caricare l'elenco dei biglietti. Il nostro personale è stato informato dell'errore"
      }
    }
  }
};
