import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Row from "@bootstrap-styled/v4/lib/Row";
import Col from "@bootstrap-styled/v4/lib/Col";
import { breakpoints, colors, space, typography } from "Theme";
import { formatDate } from "Components/Utils";
import MessageHeader from "Components/Message/MessageHeader";
import { Paragraph } from "Components";

const parseLocalImagePath = fullPath => {
  return fullPath.replace("/var/www/public", "");
};

const TicketDetails = ({
  ticket,
  customer,
  apiDomainUrl,
  detailColNum,
  user
}) => {
  const { t } = useTranslation();
  const [attributes, setAttributes] = useState([]);
  const [diagnosis, setDiagnosis] = useState([]);
  useEffect(() => {
    const updateAttributes = attrs => {
      const attributes = [];
      const issues = [];
      attrs.forEach(attribute =>
        attribute.attribute_name === "diagnosis"
          ? issues.push(attribute)
          : attributes.push(attribute)
      );
      setAttributes(attributes);
      setDiagnosis(issues);
    };

    if (ticket && ticket.parts && ticket.parts.attributes) {
      updateAttributes(ticket.parts.attributes);
    }
  }, [ticket]);

  return (
    <MessageWrapper>
      <MessageHeader
        name={customer.name}
        img={customer.profile_photo_url}
        createdDate={formatDate(ticket.created_at)}
        isServiceCenterUser={user.level === "service_center_user"}
      />
      <MessageBody>
        <Row>
          <Col md={detailColNum}>
            <Detail>
              <Paragraph size={6}>
                {t(`ticket.labels.part_type_label`)}
              </Paragraph>
              <DetailValue>{ticket.parts?.type.name}</DetailValue>
            </Detail>
          </Col>
          {!!ticket.parts?.model && (
            <Col md={detailColNum}>
              <Detail>
                <Paragraph size={6}>{t(`ticket.labels.part_model`)}</Paragraph>
                <DetailValue>{ticket.parts?.model.name}</DetailValue>
              </Detail>
            </Col>
          )}
          {attributes.filter(attr => attr.attribute_name !== "diagnosis")
            .length > 0 && (
            <Col md={detailColNum}>
              <Detail>
                <Paragraph size={6}>
                  {t(`ticket.labels.part_attributes`)}
                </Paragraph>
                {attributes.map(
                  attribute =>
                    attribute.attribute_name !== "diagnosis" && (
                      <DetailValue key={attribute.id}>
                        <Attribute>
                          {attribute.attribute_name
                            .replace(/_/g, " ")
                            .split(" ")
                            .map(
                              word =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                          : {attribute.attribute_value}
                        </Attribute>
                      </DetailValue>
                    )
                )}
              </Detail>
            </Col>
          )}
          {diagnosis.length > 0 && (
            <Col md={detailColNum}>
              <Detail>
                <Paragraph size={6}>{t(`ticket.labels.diagnosis`)}</Paragraph>
                <DetailValue>{diagnosis[0].attribute_value}</DetailValue>
              </Detail>
            </Col>
          )}
          {ticket.purchase_date && (
            <Col md={detailColNum}>
              <Detail>
                <Paragraph size={6}>
                  {t(`ticket.labels.date_purchased`)}
                </Paragraph>
                <DetailValue>
                  {formatDate(ticket.purchase_date, true)}
                </DetailValue>
              </Detail>
            </Col>
          )}
          {ticket.bicycle_make && (
            <Col md={detailColNum}>
              <Detail>
                <Paragraph size={6}> {t(`ticket.labels.bike_make`)} </Paragraph>
                <DetailValue>{ticket.bicycle_make.name}</DetailValue>
              </Detail>
            </Col>
          )}
          {ticket.bike_serial_number && (
            <Col md={detailColNum}>
              <Detail>
                <Paragraph size={6}>
                  {t(`ticket.labels.bike_serial_number`)}
                </Paragraph>
                <DetailValue>{ticket.bike_serial_number}</DetailValue>
              </Detail>
            </Col>
          )}
        </Row>
        {/* Ticket Files */}
        <FilesWrapper>
          {ticket.images &&
            ticket.images.map(ticketImage => {
              const { image } = ticketImage;
              return (
                <TicketImage key={image.id}>
                  <a
                    href={
                      image.storage_type === "s3"
                        ? image.s3_object_path
                        : `${apiDomainUrl}${parseLocalImagePath(
                            image.filesystem_object_path
                          )}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={
                        image.storage_type === "s3"
                          ? image.s3_object_path
                          : `${apiDomainUrl}${parseLocalImagePath(
                              image.filesystem_object_path
                            )}`
                      }
                      alt={t(`ticket.alt.part_image`)}
                      width="156"
                    />
                  </a>
                </TicketImage>
              );
            })}
        </FilesWrapper>
      </MessageBody>
      <MessageText>{ticket.description}</MessageText>
    </MessageWrapper>
  );
};

TicketDetails.propTypes = {
  detailColNum: PropTypes.number.isRequired,
  user: PropTypes.shape({
    level: PropTypes.string
  }).isRequired,
  ticket: PropTypes.shape({
    user_id: PropTypes.number,
    created_at: PropTypes.string,
    description: PropTypes.string,
    bike_serial_number: PropTypes.string,
    bicycle_make: PropTypes.shape({
      name: PropTypes.string
    }),
    purchase_date: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        storage_type: PropTypes.string,
        s3_object_image_path: PropTypes.string,
        filesystem_object_path: PropTypes.string
      })
    ),
    parts: PropTypes.shape({
      serial_number: PropTypes.string,
      diagnosis: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      }),
      type: PropTypes.shape({
        name: PropTypes.string,
        part_model: PropTypes.string
      }),
      attributes: PropTypes.shape({})
    })
  }).isRequired,
  customer: PropTypes.shape({
    name: PropTypes.string,
    profile_photo_url: PropTypes.string
  }).isRequired,
  apiDomainUrl: PropTypes.string.isRequired
};

const { fonts } = typography;
const Detail = styled.div`
  background-color: ${colors.white.base};
  padding-bottom: ${space[2]}rem;
  p {
    margin: 0;
  }
`;
const DetailValue = styled.span`
  font-stretch: normal;
  font-weight: bold;
`;
const Attribute = styled.span`
  display: block;
`;
const FilesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${space[2]}rem;
`;
const TicketImage = styled.div`
  img {
    margin-right: ${space[2]}rem;
    margin-bottom: ${space[2]}rem;
    width: 150px;
  }
`;
const MessageWrapper = styled.div`
  margin-bottom: ${space[3] + space[1]}rem;
  background-color: ${colors.white.base};
  padding: ${space[2]}rem;
  position: relative;
  left: -${space[1] * 5}rem;
  &:last-child {
    margin-bottom: 0;
  }
`;
const MessageBody = styled.div`
  padding-left: ${space[1]}rem;
  @media screen and (min-width: ${breakpoints.sm}) {
    padding-left: ${space[1] * 6}rem;
  }
`;
const MessageText = styled.div`
  font-stretch: normal;
  font-family: ${fonts.sansSerif};
  padding: ${space[1]}rem;
  @media screen and (min-width: ${breakpoints.sm}) {
    padding-left: ${space[1] * 6}rem;
  }
`;

export default TicketDetails;
