import {
  FETCH_SERVICE_CENTERS_SUCCESS,
  SEARCH_SERVICE_CENTERS_SUCCESS
} from 'Actions/serviceCenter/types';

const initialState = {
  list: [],
  pagination: {},
  scopes: []
};

const serviceCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SERVICE_CENTERS_SUCCESS: {
      return {
        ...state,
        list: [...action.payload.data],
        pagination: { ...action.payload.pagination }
      };
    }
    case SEARCH_SERVICE_CENTERS_SUCCESS: {
      return {
        ...state,
        list: [...action.payload.data],
        pagination: { ...action.payload.pagination }
      };
    }
    default:
      return state;
  }
};

export default serviceCenterReducer;
