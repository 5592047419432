export default {
  en: {
    dealership_users: {
      account_info: 'Account Information',
      contact_info: 'Contact Information',
      location_info: 'Location Information',
      labels: {
        create_users: 'Create',
        filter_users: 'Filter',
        search_users: 'Search...'
      },
      buttons: {
        update: 'Update User',
        delete: 'Delete User'
      },
      helper_text: {
        level: 'Account levels determine user permissions',
        primary_location: 'The main location for this user'
      }
    }
  },
  de: {
    dealership_users: {
      account_info: 'Kontoinformationen',
      contact_info: 'Kontaktinformationen',
      location_info: 'Standortinformationen',
      labels: {
        create_users: 'Schaffen',
        filter_users: 'Filter',
        search_users: 'Suche...'
      },
      buttons: {
        update: 'Benutzer aktualisieren',
        delete: 'Benutzer löschen'
      },
      helper_text: {
        level: 'Kontoebenen bestimmen Benutzerberechtigungen',
        primary_location: 'Der Hauptstandort für diesen Benutzer'
      }
    }
  },
  jp: {
    dealership_users: {
      account_info: '口座情報',
      contact_info: '連絡先',
      location_info: '位置情報',
      labels: {
        create_users: '作成',
        filter_users: 'フィルター',
        search_users: '検索...'
      },
      buttons: {
        update: 'ユーザーの更新',
        delete: 'ユーザーを削除'
      },
      helper_text: {
        level: 'アカウントレベルによってユーザー権限が決まります',
        primary_location: 'このユーザーの主な場所'
      }
    }
  },
  it: {
    dealership_users: {
      account_info: 'Informazioni account',
      contact_info: 'Informazioni sui contatti',
      location_info: 'Informazioni sulla posizione',
      labels: {
        create_users: 'Creare',
        filter_users: 'Filtro',
        search_users: 'Ricerca...'
      },
      buttons: {
        update: 'Aggiorna utente',
        delete: 'Elimina utente'
      },
      helper_text: {
        level: "I livelli di account determinano le autorizzazioni dell'utente",
        primary_location: 'La posizione principale per questo utente'
      }
    }
  }
};
