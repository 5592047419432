import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Option from '@bootstrap-styled/v4/lib/Option';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showModal } from 'Actions/modal';
import * as modalTypes from 'Components/Modal/types';
import {
  fetchTickets,
  fetchScopes,
  fetchTicketsByScope,
  createTicketReset,
  searchTickets,
  addFilter,
  removeFilter,
} from 'Actions/ticket';
import { acceptInviteReset } from 'Actions/user';
import {
  Alert,
  Badge,
  Button,
  DataTable,
  Input as SsgInput,
  Link,
  Scrim,
  Search
} from 'Components';
import { breakpoints } from 'Theme';
import { formatDate, setBadgeColorByStatus } from 'Components/Utils';
import { withTranslation } from 'react-i18next';

class TicketList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isSearching: false,
      searchKeyword: '',
      pageNumber: 1,
      perPage: 25,
      paginationResetDefaultPage: false,
      sort: {
        by: 'updated_at',
        order: 'desc'
      },
      hasError: false,
      error: ''
    };
  }

  async componentDidMount() {
    const { pageNumber, sort } = this.state;
    const { fetchScopes, t, user } = this.props;
    // check for dealer_user role
    await this.checkForInvite(user);

    await fetchScopes().then(async () => {
      await this.fetchData(pageNumber, true, sort);
    }).catch(errStatus => {
      const error = t(`ticketlist.error.${errStatus}`);
      this.setState(() => ({ error }));
    });
  }

  componentWillUnmount() {
    const { createTicketReset } = this.props;
    createTicketReset();
  }

  handlePageChange = async page => {
    const { searchKeyword, perPage } = this.state;
    const { searchTicketsData, ticket: { filters } } = this.props;

    this.setState(state => ({
      ...state,
      isFetching: true
    }));

    if (searchKeyword) {
      const queryData = {
        search: searchKeyword,
        paginationPerPage: perPage,
        page
      };
      if (filters.length > 0) {
        queryData.scope = filters.join('+');
      }
      searchTicketsData(queryData)
        .then(() => this.setState(state => ({ ...state, isFetching: false })))
        .catch(() => {
          // TODO: Catch errors
        });
    } else {
      this.fetchData(page);
    }
  };

  handleSort = (column, sortDirection) => {
    const sort = {
      by: column.selector,
      order: sortDirection
    };
    this.setState(
      state => ({
        ...state,
        pageNumber: 1,
        sort,
        paginationResetDefaultPage: true
      }),
      () => {
        this.fetchData(1, false, sort);
      }
    );
  };

  handleRowsPerPageChange = (currRowsPer, pageNumber) => {
    this.setState(
      state => ({ ...state, perPage: currRowsPer, pageNumber }),
      () => {
        this.fetchData(pageNumber);
      }
    );
  };

  checkForInvite = async user => {
    // ssg-59: if invite_token exists, show acceptance modal
    if (user.dealership && user.dealership.inviteToken) {
      this.showAcceptModal();
    }
  };

  handleSearchChange = e => {
    const {
      target: { value }
    } = e;
    this.setState(state => ({ ...state, searchKeyword: value }));
  };

  onSearch = () => {
    const {
      searchKeyword,
      perPage: paginationPerPage,
      pageNumber: page
    } = this.state;
    const { searchTicketsData } = this.props;

    this.setState(state => ({
      ...state,
      isSearching: true
    }));
    const queryData = {
      search: searchKeyword,
      paginationPerPage,
      page
    };
    searchTicketsData(queryData)
      .catch(() => {
        // TODO: add err catch cb
      })
      .then(() => this.setState(state => ({ ...state, isSearching: false })));
  };

  showAcceptModal = () => {
    const { showModal, t } = this.props;
    const modalData = {
      type: modalTypes.CONFIRM_ACCEPT_DEALER_INVITE,
      title: t('modal.title.accept_dealer_invite')
    };
    showModal(modalData);
  };

  showTicketModal = () => {
    const { showModal, t } = this.props;
    const modalData = {
      type: modalTypes.FORM_CREATE_TICKET,
      title: t('modal.title.create_ticket')
    };
    showModal(modalData);
  };

  fetchData = async (
    pageNumber = null,
    shouldOverWriteResults = false,
    sortConfig = {}
  ) => {
    const { fetchTickets, fetchTicketsByScope, history, ticket: { filters } } = this.props;
    const { perPage, searchKeyword } = this.state;

    const pageData = {};
    if (pageNumber) {
      pageData.page = pageNumber;
    } else {
      pageData.page = 1;
    }
    // Set pagination to 25 rows per page
    pageData.paginationPerPage = perPage;

    if (Object.keys(sortConfig).length > 0) {
      pageData.sort = sortConfig;
    } else {
      const { sort } = this.state;
      pageData.sort = sort;
    }

    // We either fetch and paginate the total list of tickets, or if the user has filters applied
    // we paginate the filtered results
    const { t } = this.props;
    if (!filters.length) {
      if (searchKeyword) {
        pageData.search = searchKeyword;
      }
      this.setState(() => ({
        isFetching: true
      }));
      await fetchTickets(pageData)
        .catch(errorStatus => {
          if (errorStatus === 403) {
            return history.push('/tickets');
          }
          const error = t(`ticket_list.error.${errorStatus}`);
          return this.setState(() => ({ error, hasError: true }));
        })
        .finally(() => {
          this.setState(state => ({ ...state, isFetching: false }));
        });
    } else {
      this.setState(() => ({
        isFetching: true,
        searchKeyword: ''
      }));
      await fetchTicketsByScope({
        ...pageData,
        scope: filters.join('+'),
        shouldOverWriteResults // To support pagination of previously applied filters
      })
        .catch(errorStatus => {
          if (errorStatus === 403) {
            return history.push('/tickets');
          }
          const error = t(`ticket_list.error.${errorStatus}`);
          return this.setState(() => ({ error, hasError: true }));
        })
        .finally(() => {
          this.setState(state => ({ ...state, isFetching: false }));
        });
    }

    this.setState(() => ({
      isFetching: false
    }));
  };

  addFilter = async event => {
    const { value } = event.target;
    const {
      addFilter
    } = this.props;
    
    await addFilter(value)
    
    this.fetchData(1, true)
  };

  removeFilter = async event => {
    const scopeToRemove = event.currentTarget.getAttribute('handle');
    const {
      removeFilter
    } = this.props;
    
    await removeFilter(scopeToRemove)
    
    this.fetchData(null, true)
  };

  render() {
    const {
      isFetching,
      isSearching,
      perPage,
      paginationResetDefaultPage,
      hasError,
      error,
      searchKeyword
    } = this.state;
    const {
      ticket: { list, pagination, scopes, filters },
      user: { dealership, level: userLevel },
      history,
      t,
      status: { CREATE_TICKET, ACCEPT_INVITE },
      createTicketReset,
      acceptInviteReset
    } = this.props;
    const columns = [
      {
        name: t('table.claim'),
        selector: 'uuid',
        cell: row => <Link href={`/ticket/${row.id}`}>{row.id}</Link>,
        grow: 1,
      },
      {
        name: t('table.status'),
        selector: 'status',
        minWidth: '200px',
        grow: 1,
        cell: row => (
          <Badge
            label={t(`data.status.${row.status.toLowerCase()}`)}
            pill
            color={setBadgeColorByStatus(row.status.toLowerCase())}
            inTable
          />
        )
      },
      {
        name: t('table.diagnosis'),
        grow: 4,
        selector: row => {
          if (row.diagnosis) {
            if (row.diagnosis.part_model_attribute) {
              return row.diagnosis.part_model_attribute.value;
            }
            if (row.diagnosis.part_model_attribute_value) {
              return row.diagnosis.part_model_attribute_value;
            }
            if (row.diagnosis.part_type_attribute) {
              return row.diagnosis.part_type_attribute.value;
            }
            if (row.diagnosis.part_type_attribute_value) {
              return row.diagnosis.part_type_attribute_value;
            }
          }
          return '';
        }
      },
      {
        name: t('table.customer'),
        selector: row => (row.customer ? row.customer.name : ''),
        grow:1.5
      }
    ];
    if (userLevel === 'service_center_user') {
      columns.push({
        name: t('table.dealership'),
        selector: row => (row.dealer ? row.dealer.name : ''),
        grow: 2
      });
    }
    columns.push({
      name: t('table.submitted'),
      selector: 'created_at',
      compact: true,
      maxWidth: '90px',
      cell: row => formatDate(row.created_at, true)
    });
    columns.push({
      name: t('table.last_updated'),
      selector: 'updated_at',
      maxWidth: '170px',
      cell: row => formatDate(row.updated_at)
    });

    return (
      <Fragment>
        <Scrim isShown={isFetching || isSearching} />
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center w-100">
          <InputsWrapper>
            <SsgInput
              label={t('form.filter')}
              hideLabel
              inputId="filters"
              onChangeFunc={this.addFilter}
              type="select"
              value=" "
              status="muted"
              name="filters"
              width="max-content"
            >
              <Option value=" ">{t('form.filter')}</Option>
              {scopes &&
                scopes.map(aScope => (
                  <Option key={aScope} value={aScope}>
                    {t(`data.status.${aScope.toLowerCase()}`)}
                  </Option>
                ))}
            </SsgInput>
            <Search
              value={searchKeyword}
              label={t('form.labels.search')}
              status="muted"
              placeholder={t('form.placeholders.search')}
              onSearchFunc={this.onSearch}
              onChangeFunc={this.handleSearchChange}
            />
          </InputsWrapper>
          <CreateButton onClickFunc={() => this.showTicketModal()}>
            {t('ticket_list.button.create')}
          </CreateButton>
        </div>
        {filters &&
          filters.map(aFilter => (
            <Badge
              key={aFilter}
              label={t(`data.status.${aFilter.toLowerCase()}`)}
              pill
              onRemove={e => this.removeFilter(e)}
              isRemovable
              color={setBadgeColorByStatus(aFilter.toLowerCase())}
              handle={aFilter.toLowerCase()}
            />
          ))}
        <Alert
          alertIsOpen={hasError}
          color="warning"
          onCloseFunc={() => this.setState(() => ({ hasError: false }))}
        >
          {error}
        </Alert>

        <Alert
          alertIsOpen={CREATE_TICKET === 'success'}
          color="success"
          onCloseFunc={() => createTicketReset()}
        >
          {t('create_ticket.success')}
        </Alert>
        {dealership && (
          <Alert
            alertIsOpen={ACCEPT_INVITE === 'success'}
            color="success"
            onCloseFunc={() => acceptInviteReset()}
          >
            {t('accept_invite_dealer_user.success', {
              dealer_name: `${dealership.name}`
            })}
          </Alert>
        )}
        <DataTable
          title=""
          data={list || []}
          columns={columns}
          pointerOnHover
          highlightOnHover
          pagination
          noHeader
          defaultSortField="updated_at"
          onRowClicked={param => {
            history.push(`/ticket/${param.id}`);
          }}
          onChangePage={this.handlePageChange}
          paginationServer
          paginationResetDefaultPage={paginationResetDefaultPage}
          paginationTotalRows={pagination ? pagination.total : 0}
          paginationPerPage={perPage}
          sortServer
          onSort={this.handleSort}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          progressPending={isFetching || isSearching}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  ticket: state.ticket,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  showModal: data => dispatch(showModal(data)),
  fetchTickets: data => dispatch(fetchTickets(data)),
  fetchScopes: () => dispatch(fetchScopes()),
  fetchTicketsByScope: data => dispatch(fetchTicketsByScope(data)),
  createTicketReset: () => dispatch(createTicketReset()),
  acceptInviteReset: () => dispatch(acceptInviteReset()),
  searchTicketsData: data => dispatch(searchTickets(data)),
  addFilter: data => dispatch(addFilter(data)),
  removeFilter: data => dispatch(removeFilter(data))
});

TicketList.propTypes = {
  fetchTickets: PropTypes.func.isRequired,
  fetchScopes: PropTypes.func.isRequired,
  fetchTicketsByScope: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  createTicketReset: PropTypes.func.isRequired,
  acceptInviteReset: PropTypes.func.isRequired,
  searchTicketsData: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.object,
    push: PropTypes.func
  }).isRequired,
  ticket: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    pagination: PropTypes.object,
    scopes: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  user: PropTypes.shape({
    dealership: PropTypes.shape({
      name: PropTypes.string
    }),
    level: PropTypes.string,
    isLoggedIn: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired
  }).isRequired,
  status: PropTypes.shape({
    CREATE_TICKET: PropTypes.string.isRequired,
    ACCEPT_INVITE: PropTypes.string
  }).isRequired,
  t: PropTypes.func.isRequired
};

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  input[type='search'] {
    border-left: 0;
  }
  @media screen and (min-width: ${breakpoints.sm}) {
    margin-right: 1rem;
  }
`;

const CreateButton = styled(Button)`
  width: 100%;
  @media screen and (min-width: ${breakpoints.sm}) {
    width: max-content;
  }
`;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TicketList))
);
