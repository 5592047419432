import { api, retryApiCall } from '../api';
import {
  FETCH_DEALERSHIPS_LOADING,
  FETCH_DEALERSHIPS_ERROR,
  FETCH_DEALERSHIPS_SUCCESS,
  FETCH_SCOPES_LOADING,
  FETCH_SCOPES_ERROR,
  FETCH_SCOPES_SUCCESS,
  FETCH_DEALERSHIPS_BY_SCOPE_LOADING,
  FETCH_DEALERSHIPS_BY_SCOPE_ERROR,
  FETCH_DEALERSHIPS_BY_SCOPE_SUCCESS,
  FETCH_DEALERSHIP_DETAIL_LOADING,
  FETCH_DEALERSHIP_DETAIL_ERROR,
  FETCH_DEALERSHIP_DETAIL_SUCCESS,
  CREATE_DEALER_LOCATION_LOADING,
  CREATE_DEALER_LOCATION_ERROR,
  CREATE_DEALER_LOCATION_SUCCESS,
  UPDATE_DEALERSHIP_LOADING,
  UPDATE_DEALERSHIP_ERROR,
  UPDATE_DEALERSHIP_SUCCESS,
  SET_DEALER,
  INVITE_USER_LOADING,
  INVITE_USER_ERROR,
  INVITE_USER_SUCCESS,
  INVITE_USER_RESET,
  FETCH_DEALERSHIPS_ALL_LOADING,
  FETCH_DEALERSHIPS_ALL_ERROR,
  FETCH_DEALERSHIPS_ALL_SUCCESS,
  SEARCH_DEALERS_LOADING,
  SEARCH_DEALERS_SUCCESS,
  SEARCH_DEALERS_ERROR,
  UPDATE_DEALER_USER_LOADING,
  UPDATE_DEALER_USER_SUCCESS,
  UPDATE_DEALER_USER_ERROR,
  ADD_DEALER_FILTER,
  REMOVE_DEALER_FILTER
} from './types';

export const dealershipCallTypes = {};
const apiCall = retryApiCall.use(dealershipCallTypes);

export const setDealership = data => ({
  type: SET_DEALER,
  payload: data
});

/**
 * Fetch a List of Dealerships Associated to current user's Service Center
 */
export const fetchDealershipsLoading = data => ({
  type: FETCH_DEALERSHIPS_LOADING,
  payload: data
});

export const fetchDealershipsSuccess = data => ({
  type: FETCH_DEALERSHIPS_SUCCESS,
  payload: data
});

export const fetchDealershipsFailure = data => ({
  type: FETCH_DEALERSHIPS_ERROR,
  payload: data
});

export const fetchDealerships = data => dispatch => {
  dispatch(apiCall(fetchDealershipsLoading, fetchDealerships, data));
  return api
    .get(`dealerships`, {
      params: {
        ...data
      }
    })
    .then(res => {
      dispatch(fetchDealershipsSuccess(res.data));
      return res.data;
    })
    .catch(err => {
      dispatch(fetchDealershipsFailure());
      return Promise.reject(err.response.status);
    });
};

/**
 * Fetch All Dealerships for Service Center User when creating a new ticket on behalf of a dealership user
 */
export const fetchDealershipsAllLoading = data => ({
  type: FETCH_DEALERSHIPS_ALL_LOADING,
  payload: data
});

export const fetchDealershipsAllFailure = data => ({
  type: FETCH_DEALERSHIPS_ALL_ERROR,
  payload: data
});

export const fetchDealershipsAllSuccess = data => ({
  type: FETCH_DEALERSHIPS_ALL_SUCCESS,
  payload: data
});

export const fetchDealershipsAll = data => dispatch => {
  dispatch(apiCall(fetchDealershipsAllLoading, fetchDealershipsAll, data));
  return api
    .get('/dealerships/all', { ...data })
    .then(res => {
      dispatch(fetchDealershipsAllSuccess(res.data));
      return res.data;
    })
    .catch(err => {
      dispatch(fetchDealershipsAllFailure(err));
      return err.response.status;
    });
};

/**
 * Fetch Dealership Details
 */

export const fetchDealershipDetailLoading = data => ({
  type: FETCH_DEALERSHIP_DETAIL_LOADING,
  payload: data
});

export const fetchDealershipDetailFailure = data => ({
  type: FETCH_DEALERSHIP_DETAIL_ERROR,
  payload: data
});

export const fetchDealershipDetailSuccess = data => ({
  type: FETCH_DEALERSHIP_DETAIL_SUCCESS,
  payload: data
});

export const fetchDealershipDetails = data => dispatch => {
  dispatch(apiCall(fetchDealershipDetailLoading, fetchDealershipDetails, data));
  const endpoint = `/dealership/${data.id}`;
  return api
    .get(endpoint, {
      ...data
    })
    .then(res => {
      // @TODO: Must change these
      dispatch(fetchDealershipDetailSuccess(res.data));
      return res.data;
    })
    .catch(err => {
      dispatch(fetchDealershipDetailFailure(err));
      return Promise.reject(err);
    });
};

/**
 * Fetch a List of Dealerships Based on their respective Status [read: scope]
 */
export const fetchDealershipsByScopeLoading = data => ({
  type: FETCH_DEALERSHIPS_BY_SCOPE_LOADING,
  payload: data
});

export const fetchDealershipsByScopeSuccess = data => ({
  type: FETCH_DEALERSHIPS_BY_SCOPE_SUCCESS,
  payload: data
});

export const fetchDealershipsByScopeFailure = data => ({
  type: FETCH_DEALERSHIPS_BY_SCOPE_ERROR,
  payload: data
});

export const fetchDealershipsByScope = data => dispatch => {
  dispatch(
    apiCall(fetchDealershipsByScopeLoading, fetchDealershipsByScope, data)
  );
  return api
    .get(`/dealerships/scope/${data.scope}`, {
      params: { ...data }
    })
    .then(res => {
      dispatch(fetchDealershipsByScopeSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(fetchDealershipsByScopeFailure());
      }
      return Promise.reject(errStatus);
    });
};

/**
 * Fetch All Potential Statuses [read: scopes] for Dealerships
 */

export const fetchScopesLoading = data => {
  return {
    type: FETCH_SCOPES_LOADING,
    payload: data
  };
};

export const fetchScopesSuccess = data => ({
  type: FETCH_SCOPES_SUCCESS,
  payload: data
});

export const fetchScopesFailure = data => ({
  type: FETCH_SCOPES_ERROR,
  payload: data
});

export const fetchScopes = data => dispatch => {
  dispatch(apiCall(fetchScopesLoading, fetchScopes, data));
  return api
    .get('/dealerships/scopes', {
      ...data
    })
    .then(res => {
      dispatch(fetchScopesSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(fetchScopesFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

/**
 * Create a Location associated with a specific Dealership
 */
export const createDealerLocationLoading = data => ({
  type: CREATE_DEALER_LOCATION_LOADING,
  payload: data
});

export const createDealerLocationSuccess = data => ({
  type: CREATE_DEALER_LOCATION_SUCCESS,
  payload: data
});

export const createDealerLocationFailure = data => ({
  type: CREATE_DEALER_LOCATION_ERROR,
  payload: data
});

export const createDealerLocation = (id, data) => dispatch => {
  dispatch(apiCall(createDealerLocationLoading, createDealerLocation, data));
  return api
    .put(`/dealership/${id}/location`, {
      id,
      ...data
    })
    .then(res => {
      dispatch(createDealerLocationSuccess(res.data));
      return res.data;
    })
    .catch(err => {
      dispatch(createDealerLocationFailure());
      return Promise.reject(err.response.status);
    });
};

/**
 * Invite an existing user to join [read: associate themself with] a dealership
 */
export const inviteUserLoading = data => ({
  type: INVITE_USER_LOADING,
  payload: data
});

export const inviteUserFailure = data => ({
  type: INVITE_USER_ERROR,
  payload: data
});

export const inviteUserSuccess = data => ({
  type: INVITE_USER_SUCCESS,
  payload: data
});

export const inviteUserReset = () => ({
  type: INVITE_USER_RESET
});

export const inviteUser = data => dispatch => {
  dispatch(apiCall(inviteUserLoading, inviteUser, data));
  const endpoint = `/dealer/${data.dealerId}/invite`;
  return api
    .post(endpoint, {
      ...data
    })
    .then(res => {
      dispatch(inviteUserSuccess(res.data));
      return res.data;
    })
    .catch(err => {
      dispatch(inviteUserFailure());
      return Promise.reject(err);
    });
};

/**
 * Update existing Dealership with new details
 */
export const updateDealerLoading = data => ({
  type: UPDATE_DEALERSHIP_LOADING,
  payload: data
});

export const updateDealerFailure = data => ({
  type: UPDATE_DEALERSHIP_ERROR,
  payload: data
});

export const updateDealerSuccess = data => ({
  type: UPDATE_DEALERSHIP_SUCCESS,
  payload: data
});

export const updateDealer = data => dispatch => {
  dispatch(apiCall(updateDealerLoading, updateDealer, data));
  const endpoint = `/dealership/${data.id}`;
  return api
    .patch(endpoint, {
      ...data
    })
    .then(res => {
      dispatch(updateDealerSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(updateDealerFailure());
      }
      return Promise.reject(errStatus);
    });
};

export const searchDealersLoading = data => ({
  type: SEARCH_DEALERS_LOADING,
  payload: data
});

export const searchDealersFailure = data => ({
  type: SEARCH_DEALERS_ERROR,
  payload: data
});

export const searchDealersSuccess = data => ({
  type: SEARCH_DEALERS_SUCCESS,
  payload: data
});

export const searchDealers = data => dispatch => {
  dispatch(apiCall(searchDealersLoading, searchDealers, data));
  return api
    .get(`/dealerships/search`, { params: { ...data } })
    .then(res => {
      dispatch(searchDealersSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(searchDealersFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

/**
 * Update existing Dealership User with new details
 */

export const updateDealerUserLoading = data => ({
  type: UPDATE_DEALER_USER_LOADING,
  payload: data
});

export const updateDealerUserFailure = data => ({
  type: UPDATE_DEALER_USER_ERROR,
  payload: data
});

export const updateDealerUserSuccess = data => ({
  type: UPDATE_DEALER_USER_SUCCESS,
  payload: data
});

export const updateDealerUser = data => dispatch => {
  dispatch(apiCall(updateDealerUserLoading, updateDealerUser, data));
  const endpoint = `/dealer/${data.dealerId}/user/${data.userId}`;
  return api
    .put(endpoint, {
      ...data
    })
    .then(res => {
      dispatch(updateDealerUserSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(updateDealerUserFailure());
      }
      return Promise.reject(errStatus);
    });
};

export const addDealerFilter = data => ({
  type: ADD_DEALER_FILTER,
  payload: data
});

export const removeDealerFilter = data => ({
  type: REMOVE_DEALER_FILTER,
  payload: data
});

export const addFilter = data => dispatch => {
  dispatch(addDealerFilter(data))
};

export const removeFilter = data => dispatch => {
  dispatch(removeDealerFilter(data))
}