import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DataTable, Input } from 'Components';
import { space, colors } from 'Theme';
import { formatCentsToDollars, formatDate } from 'Components/Utils';
import { useTranslation } from 'react-i18next';

const getPartTypeNameById = (id, types) => {
  let partName;
  types.forEach(type => {
    if (type.id === id) partName = type.name;
  });
  return partName;
};

const OrderTable = ({ order, isLoading, partTypes }) => {
  const columns = [
    {
      name: 'Item',
      selector: 'item_number',
      sortable: true
    },
    {
      name: 'Category',
      selector: 'part_type_id',
      sortable: true,
      cell: row => getPartTypeNameById(row.part_type_id, partTypes)
    },
    {
      name: 'Price ea.',
      selector: 'price_each',
      sortable: true,
      cell: row => formatCentsToDollars(Number(row.price_each))
    },
    {
      name: 'Quantity',
      selector: 'quantity',
      sortable: true
    },
    {
      name: 'Last Updated',
      selector: 'updated_at',
      sortable: true,
      cell: row => formatDate(row.updated_at)
    },
    {
      name: 'Total',
      selector: 'order_total',
      sortable: true,
      right: true,
      cell: row => formatCentsToDollars(Number(row.order_total))
    }
  ];
  const { parts } = order;
  const { t } = useTranslation();
  return (
    <Fragment>
      <DataTable
        data={parts}
        columns={columns}
        defaultSortField="item_number"
        progressPending={isLoading}
        pagination={false}
      />
      <OrderInfo>
        <Input
          label={t('ticket.labels.order.tracking_number')}
          inputId="tracking-number"
          name="tracking_number"
          value={order.tracking_number}
          status="muted"
          isDisabled
        />
        <LineItems>
          <tbody>
            <tr>
              <td>{t('ticket.headings.shipping')}:</td>
              <td>{formatCentsToDollars(Number(order.shipping_price))}</td>
            </tr>
            <tr>
              <td>{t('ticket.labels.order.order_total')}:</td>
              <td>{formatCentsToDollars(Number(order.total_price))}</td>
            </tr>
          </tbody>
        </LineItems>
      </OrderInfo>
    </Fragment>
  );
};

OrderTable.propTypes = {
  order: PropTypes.shape({
    tracking_number: PropTypes.string,
    shipping_price: PropTypes.string,
    total_price: PropTypes.string,
    parts: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  partTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired
};

const OrderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${space[1]}rem ${space[1] * 3}rem;
  border-top: 1px solid ${colors.secondary.lighter};
  background-color: ${colors.white.base};
  margin-top: -${space[2]}rem;
  margin-bottom: ${space[2] * 3}rem;
`;

const LineItems = styled.table`
  tr {
    td:first-child {
      font-weight: bold;
      text-align: left;
    }
    td:last-child {
      text-align: right;
      padding-left: ${space[2]}rem;
    }
  }
`;

export default OrderTable;
