import React, { Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { formatPhoneNumber } from "Components/Utils";
import { colors, space } from "Theme";
import Heading from "../Heading";
import Link from "../Link";
import Paragraph from "../Paragraph";

const InfoCard = ({ title, person, isCustomer, avatarAlt }) => {
  const shouldShowAddress = isCustomer
    ? person.level === "consumer"
    : person.address !== null;

  return (
    <InfoCardWrapper>
      <Heading size={5}>{title}</Heading>
      {isCustomer ? (
        <ProfilePicture className="profile-picture">
          <img
            src={person.profile_photo_url}
            alt={avatarAlt}
            width="32"
            className="mb-1"
          />
        </ProfilePicture>
      ) : null}
      <Heading size={6}>{person.name}</Heading>
      {!isCustomer &&
      person.address.dealer_location.name.toLowerCase() !== "default" ? (
        <Heading size={6}>{person.address.dealer_location.name}</Heading>
      ) : null}
      {shouldShowAddress && (
        <Paragraph>
          {person.address.address_one} <br />
          {person.address.address_two ? (
            <Fragment>
              {person.address.address_two}
              <br />
            </Fragment>
          ) : null}{" "}
          {person.address.city}, {person.address.state} {person.address.zip}{" "}
          <br />
          {person.address.country} <br />
        </Paragraph>
      )}
      <EmailLink href={`mailto:${person.email}`} external>
        {person.email} <br />
      </EmailLink>
      <br />
      <Link
        href={`tel:${person.phone ? person.phone : person.address.phone}`}
        external
      >
        {formatPhoneNumber(person.phone ? person.phone : person.address.phone)}{" "}
        <br />
      </Link>
    </InfoCardWrapper>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  person: PropTypes.shape({
    name: PropTypes.string,
    profile_photo_url: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    level: PropTypes.string,
    address: PropTypes.shape({
      address_one: PropTypes.string,
      address_two: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      country: PropTypes.string,
      phone: PropTypes.string,
      dealer_location: PropTypes.shape({
        name: PropTypes.string
      })
    })
  }).isRequired,
  isCustomer: PropTypes.bool,
  avatarAlt: PropTypes.string
};

InfoCard.defaultProps = {
  isCustomer: false,
  avatarAlt: ""
};

const InfoCardWrapper = styled.div`
  background-color: ${colors.white.base};
  margin-bottom: ${space[2]}rem;
  padding: ${space[2]}rem;
  h5 {
    margin-bottom: ${space[1] * 3}rem;
  }
  h6 {
    margin-bottom: ${space[2]}rem;
  }
  .profile-picture {
    display: block;
    margin-bottom: ${space[1]}rem;
  }
  p,
  a {
    display: block;
    margin-bottom: ${space[1]}rem;
    font-stretch: normal;
  }
`;
const ProfilePicture = styled.div`
  width: ${props => (props.inMessage ? space[1] * 4 : space[1] * 7)}rem;
  height: ${props => (props.inMessage ? space[1] * 4 : space[1] * 7)}rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: ${space[1]}rem;
  img {
    width: 100%;
    height: 100%;
  }
`;
const EmailLink = styled(Link)`
  > a {
    word-wrap: anywhere;
    white-space: pre-wrap;
  }
`;

export default InfoCard;
