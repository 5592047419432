import { api, retryApiCall } from "../api";
import {
  FETCH_SERVICE_CENTERS_LOADING,
  FETCH_SERVICE_CENTERS_SUCCESS,
  FETCH_SERVICE_CENTERS_ERROR,
  SEARCH_SERVICE_CENTERS_LOADING,
  SEARCH_SERVICE_CENTERS_ERROR,
  SEARCH_SERVICE_CENTERS_SUCCESS,
  FETCH_SERVICE_CENTER_LOADING,
  FETCH_SERVICE_CENTER_SUCCESS,
  FETCH_SERVICE_CENTER_ERROR,
  INVITE_SERVICE_CENTER_TECH_LOADING,
  INVITE_SERVICE_CENTER_TECH_SUCCESS,
  INVITE_SERVICE_CENTER_TECH_ERROR,
  ACCEPT_TECH_INVITE_LOADING,
  ACCEPT_TECH_INVITE_ERROR,
  ACCEPT_TECH_INVITE_SUCCESS
} from "./types";

export const serviceCenterCallTypes = {};
export const apiCall = retryApiCall.use(serviceCenterCallTypes);

/**
 * Fetch a List of Service Centers
 */
export const fetchServiceCentersLoading = data => ({
  type: FETCH_SERVICE_CENTERS_LOADING,
  payload: data
});

export const fetchServiceCentersSuccess = data => ({
  type: FETCH_SERVICE_CENTERS_SUCCESS,
  payload: data
});

export const fetchServiceCentersFailure = data => ({
  type: FETCH_SERVICE_CENTERS_ERROR,
  payload: data
});

export const fetchServiceCenters = data => dispatch => {
  dispatch(apiCall(fetchServiceCentersLoading, fetchServiceCenters, data));
  return api
    .get(`service-centers`, {
      params: {
        ...data
      }
    })
    .then(res => {
      dispatch(fetchServiceCentersSuccess(res.data));
    })
    .catch(err => {
      dispatch(fetchServiceCentersFailure());
      return Promise.reject(err.response.status);
    });
};

export const searchServiceCentersLoading = data => ({
  type: SEARCH_SERVICE_CENTERS_LOADING,
  payload: data
});

export const searchServiceCentersFailure = data => ({
  type: SEARCH_SERVICE_CENTERS_ERROR,
  payload: data
});

export const searchServiceCentersSuccess = data => ({
  type: SEARCH_SERVICE_CENTERS_SUCCESS,
  payload: data
});

export const searchServiceCenters = data => dispatch => {
  dispatch(apiCall(searchServiceCentersLoading, searchServiceCenters, data));
  return api
    .get(`/service-centers/search`, { params: { ...data } })
    .then(res => {
      dispatch(searchServiceCentersSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(searchServiceCentersFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

export const fetchServiceCenterLoading = data => ({
  type: FETCH_SERVICE_CENTER_LOADING,
  payload: data
});

export const fetchServiceCenterSuccess = data => ({
  type: FETCH_SERVICE_CENTER_SUCCESS,
  payload: data
});

export const fetchServiceCenterFailure = data => ({
  type: FETCH_SERVICE_CENTER_ERROR,
  payload: data
});

export const fetchServiceCenter = data => dispatch => {
  dispatch(apiCall(fetchServiceCenterLoading, fetchServiceCenter, data));
  return api
    .get(`service-centers/${data.id}`)
    .then(res => {
      dispatch(fetchServiceCenterSuccess(res.data));
      return res.data;
    })
    .catch(err => {
      dispatch(fetchServiceCenterFailure());
      return Promise.reject(err.response.status);
    });
};

export const inviteServiceCenterTechLoading = data => ({
  type: INVITE_SERVICE_CENTER_TECH_LOADING,
  payload: data
});

export const inviteServiceCenterTechSuccess = data => ({
  type: INVITE_SERVICE_CENTER_TECH_SUCCESS,
  payload: data
});

export const inviteServiceCenterTechFailure = data => ({
  type: INVITE_SERVICE_CENTER_TECH_ERROR,
  payload: data
});

export const inviteServiceCenterTech = data => dispatch => {
  dispatch(
    apiCall(inviteServiceCenterTechLoading, inviteServiceCenterTech, data)
  );
  return api
    .post(`service-centers/invite`, {
      ...data
    })
    .then(res => {
      dispatch(inviteServiceCenterTechSuccess(res.data));
      return res.data;
    })
    .catch(err => {
      dispatch(inviteServiceCenterTechFailure());
      return Promise.reject(err.response.status);
    });
};

export const acceptTechInviteLoading = data => ({
  type: INVITE_SERVICE_CENTER_TECH_LOADING,
  payload: data
});

export const acceptTechInviteSuccess = data => ({
  type: INVITE_SERVICE_CENTER_TECH_SUCCESS,
  payload: data
});

export const acceptTechInviteFailure = data => ({
  type: INVITE_SERVICE_CENTER_TECH_ERROR,
  payload: data
});

export const acceptTechInvite = data => dispatch => {
  dispatch(apiCall(acceptTechInviteLoading, acceptTechInvite, data));
  return api
    .post(`service-centers/invite/accept`, {
      ...data
    })
    .then(res => {
      dispatch(acceptTechInviteSuccess(res.data));
      return res.data;
    })
    .catch(err => {
      dispatch(acceptTechInviteFailure());
      return Promise.reject(err.response.status);
    });
};
