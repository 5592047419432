export default {
  en: {
    password_reset: {
      heading_main: 'Reset Password',
      heading_sub: 'Reset your SRS warranty account',
      success: {
        updated_main: 'Password Updated Successfully!',
        updated_sub: 'Password Updated Successfully!'
      },
      error: {
        404: "We don't have an account matching that email address in our system."
      },
      buttons: {
        submit: 'Update Password',
        sign_in: 'Back to Sign In'
      }
    }
  },
  de: {
    password_reset: {
      heading_main: 'Passwort zurücksetzen',
      heading_sub: 'Setzen Sie Ihr SRS-Garantiekonto zurück',
      success: {
        updated_main: 'Passwort erfolgreich aktualisiert!',
        updated_sub: 'Passwort erfolgreich aktualisiert!'
      },
      error: {
        404: 'Wir haben in unserem System kein Konto, das mit dieser E-Mail-Adresse übereinstimmt.'
      },
      buttons: {
        submit: 'Passwort erneuern',
        sign_in: 'Zurück zu Anmelden'
      }
    }
  },
  jp: {
    password_reset: {
      heading_main: 'パスワードを再設定する',
      heading_sub: 'SRS保証アカウントをリセットします',
      success: {
        updated_main: 'パスワードが正常に更新されました！',
        updated_sub: 'パスワードが正常に更新されました！'
      },
      error: {
        404: 'システムにそのメールアドレスと一致するアカウントがありません。'
      },
      buttons: {
        submit: 'パスワードの更新',
        sign_in: 'サインインに戻る'
      }
    }
  },
  it: {
    password_reset: {
      heading_main: 'Resetta la password',
      heading_sub: 'Reimposta il tuo account di garanzia SRS',
      success: {
        updated_main: 'Password aggiornata con successo!',
        updated_sub: 'Password aggiornata con successo!'
      },
      error: {
        404: "Non abbiamo un account che corrisponda a quell'indirizzo email nel nostro sistema."
      },
      buttons: {
        submit: 'Aggiorna password',
        sign_in: 'Aggiorna password'
      }
    }
  }
};
