import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@bootstrap-styled/v4/lib/Form/FormGroup';
import { connect } from 'react-redux';
import {
  Heading,
  Form,
  Input as TextInput,
  Button,
  Paragraph,
  Alert,
  Link
} from 'Components';
import { signUpRequest, resendVerifyCode } from 'Actions/user';
import { useTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components';
import Label from '../../components/Forms/Label';
import { typography } from 'Theme';
import { api } from '../../actions/api';

const mapStateToProps = state => ({
  user: state.user,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  signUpRequestData: data => dispatch(signUpRequest(data)),
  resendVerifyCode: data => dispatch(resendVerifyCode(data))
});

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      hasSignedUp: false,
      hasResendVerifyCode: false,
      hasError: false,
      error: '',
      form: {
        email: '',
        service_center_id: ''
      },
      serviceCenters: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResendVerifyCode = this.handleResendVerifyCode.bind(this);
  }

  componentDidMount() {
    api.get("/service-centers").then(({ data }) => {
      this.setState({ serviceCenters: data.data, form: { ...this.state.form, service_center_id: data.data[0].id } });
    });
  }

  handleChange(event) {
    const { form } = this.state;
    form[event.target.name] = event.target.value;
    this.setState(() => ({
      form
    }));
  }

  async handleSubmit() {
    const { form } = this.state;
    const { signUpRequestData } = this.props;

    this.setState(() => ({
      isFetching: true
    }));

    await signUpRequestData(form).catch(errorStatus => {
      const { t } = this.props;
      const error = t(`sign_up.error.${errorStatus}`);
      this.setState(() => ({ error }));
    });

    let hasSignedUp = false;
    let hasError = false;
    const {
      status: { SIGN_UP }
    } = this.props;
    if (SIGN_UP === 'success') {
      hasSignedUp = true;
    } else {
      hasError = true;
    }
    this.setState(() => ({
      hasSignedUp,
      hasError,
      isFetching: false,
    }));
  }

  async handleResendVerifyCode() {
    const { form } = this.state;
    const { resendVerifyCode } = this.props;

    this.setState(() => ({
      isFetching: true
    }));

    await resendVerifyCode({ email: form.email }).catch(errorStatus => {
      const { t } = this.props;
      const error = 'Failed resend verify code.'; // fix
      this.setState(() => ({ error }));
    });

    let hasResendVerifyCode = false;
    let hasError = false;
    const {
      status: { RESEND_VERIFYCODE }
    } = this.props;
    if (RESEND_VERIFYCODE === 'success') {
      hasResendVerifyCode = true;
    } else {
      hasError = true;
    }
    this.setState(() => ({
      hasResendVerifyCode,
      hasError,
      isFetching: false,
    }));
  }

  render() {
    const { isFetching, form, hasSignedUp, hasResendVerifyCode, hasError, error } = this.state;
    const { t } = this.props;

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {hasSignedUp === true ? (
              <FinishAccountNotice isFetching={isFetching} isSent={hasResendVerifyCode} onSubmit={this.handleResendVerifyCode} />
            ) : (
              <Form
                onSubmitFunc={this.handleSubmit}
                validation={{
                  email: [
                    {
                      rule: 'required',
                      messages: {
                        error: t('form.required_feedback'),
                        success: ''
                      }
                    },
                    {
                      rule: 'email',
                      messages: {
                        error: t('form.invalid.email_address'),
                        success: t('form.valid.email_address')
                      }
                    }
                  ]
                }}
                ignoreRequiredOnBlur
                render={status => (
                  <Fragment>
                    <FormGroup>
                      <Heading size={1}>{t('sign_up.heading_main')}</Heading>
                      <Heading size={6}>{t('sign_up.heading_sub')}</Heading>
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="service_center_id">{t('form.labels.region')}</Label>
                      <StyledSelect
                        name="service_center_id"
                        id="service_center_id"
                        onChange={this.handleChange}
                        value={this.state.form.service_center_id}
                      >
                        {this.state.serviceCenters.map((r) => (<option value={r.id} key={r.id}>{r.name}</option>))}
                      </StyledSelect>
                      <small class="form-text text-muted">
                        {t('sign_up.region_helper1')}
                        <a href="https://www.srsuntour.com/service/#c2717">
                          <small>{t('sign_up.region_helper2')}</small>
                        </a>
                        {t('sign_up.region_helper3')}
                      </small>
                    </FormGroup>
                    <FormGroup>
                      <TextInput
                        label={t('form.labels.email')}
                        inputId="guest-email"
                        type="email"
                        name="email"
                        value={form.email}
                        onChangeFunc={this.handleChange}
                        status={status.email.status}
                        feedbackMsg={status.email.feedback}
                        width="100%"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Alert
                        alertIsOpen={hasError}
                        color="danger"
                        onCloseFunc={() => {
                          this.setState(() => ({ hasError: false }));
                        }}
                      >
                        {error}
                      </Alert>
                      <Button
                        className="w-100 mt-4 mb-2"
                        color="primary"
                        type="submit"
                        isFetching={isFetching}
                      >
                        {t('sign_up.buttons.register')}
                      </Button>
                      <Link href="/" width="100%">
                        {t('sign_up.buttons.sign_in')}
                      </Link>
                    </FormGroup>
                  </Fragment>
                )}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

SignUp.propTypes = {
  signUpRequestData: PropTypes.func.isRequired,
  status: PropTypes.shape({
    SIGN_UP: PropTypes.string.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};

const FinishAccountNotice = ({ isFetching, isSent, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Heading size={1}>Check Your Email</Heading>
      <Paragraph>{t('sign_up.success.account_created')}</Paragraph>

      {isSent ?
        <Paragraph className="text-center">{t('sign_up.buttons.sent_verifycode')}</Paragraph> :
        <Button
          className="w-100 mt-4 mb-2"
          color="primary"
          type="submit"
          isFetching={isFetching}
          onClickFunc={!isSent && onSubmit}
        >
          {t('sign_up.buttons.resend_verifycode')}
        </Button>
      }

      <Link to="/" width="100%">
        {t('sign_up.buttons.sign_in')}
      </Link>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SignUp));

const StyledSelect = styled("select")`
  width: 100%;
  padding: 0.5rem;
`

const { fonts, fontSizes } = typography;

const RequiredText = styled("span")`
  font-family: ${fonts.sansSerif};
  font-size: ${fontSizes[0]}rem;
  text-align: left;
  color: #999999;
`