import { api, retryApiCall } from '../api';
import {
  FETCH_BIKE_MAKES_LOADING,
  FETCH_BIKE_MAKES_ERROR,
  FETCH_BIKE_MAKES_SUCCESS
} from './types';

export const bikeCallTypes = {};
const apiCall = retryApiCall.use(bikeCallTypes);

export const fetchBikeMakesLoading = data => ({
  type: FETCH_BIKE_MAKES_LOADING,
  payload: data
});

export const fetchBikeMakesFailure = data => ({
  type: FETCH_BIKE_MAKES_ERROR,
  payload: data
});

export const fetchBikeMakesSuccess = data => ({
  type: FETCH_BIKE_MAKES_SUCCESS,
  payload: data
});

export const fetchBikeMakes = () => dispatch => {
  dispatch(apiCall(fetchBikeMakesLoading, fetchBikeMakes, {}));
  return api
    .get('/bicyclemakes')
    .then(res => dispatch(fetchBikeMakesSuccess(res.data)))
    .catch(err => dispatch(fetchBikeMakesFailure(err)));
};
