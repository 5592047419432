import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Option from '@bootstrap-styled/v4/lib/Option';
import Row from '@bootstrap-styled/v4/lib/Row';
import Col from '@bootstrap-styled/v4/lib/Col';
import { hideModal } from 'Actions/modal';
import { updateTicketDetails } from 'Actions/ticket';
import {
  fetchPartTypes,
  fetchPartTypeAttributes,
  resetPartTypeAttributes,
  fetchPartModelAttributes,
  resetPartModelAttributes,
  fetchModelDiagnoses,
  fetchPartTypeDiagnoses,
  resetDiagnoses
} from 'Actions/part';
import {
  Alert,
  Button,
  Form,
  Heading,
  HR,
  Input as SsgInput,
  Scrim,
  Throbber
} from 'Components';
import { space, colors } from 'Theme';

class UpdateTicketDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      isSubmitting: false,
      hasSubmitError: false,
      submitErrorMsg: '',
      form: {
        part_serial: '',
        part_type: '',
        part_model: '',
        diagnosis: '',
        attributes: {}
      },
      models: []
    };
  }

  async componentDidMount() {
    const {
      ticket: {
        current: {
          parts: {
            serial_number: serialNumber,
            type: partType,
            model: partModel,
            attributes
          }
        }
      },
      part: { types },
      fetchPartTypes,
      fetchPartModelAttributes,
      fetchPartTypeAttributes,
      fetchModelDiagnoses,
      fetchPartTypeDiagnoses
    } = this.props;
    const { form } = this.state;
    const newForm = { ...form };
    // Set required form fields
    newForm.part_serial = serialNumber || '';
    newForm.part_type = partType.id;
    newForm.part_model = partModel.id;

    // If types exist in Redux store, set form.models for current ticket part type...
    // otherwise fetchPartTypes and then do the same
    if (types.length === 0) {
      await fetchPartTypes();
    }
    const {
      part: { types: partTypes }
    } = this.props;
    partTypes.forEach(type => {
      if (type.id === parseInt(partType.id, 10)) {
        this.setState(state => ({ ...state, models: type.models }));
      }
    });

    // There should already be a part type and model selected on mount for existing ticket
    await fetchPartTypeDiagnoses({ partTypeId: partType.id });
    await fetchModelDiagnoses({ modelId: partModel.id });
    await fetchPartModelAttributes({ modelId: partModel.id });
    await fetchPartTypeAttributes({ typeId: partType.id });

    const {
      part: { typeAttributes, modelAttributes }
    } = this.props;
    const allAttrs = { ...typeAttributes, ...modelAttributes };
    Object.keys(allAttrs).forEach(key => {
      newForm.attributes[key] = '';
    });

    // Set current ticket attributes...
    let diagnosis = '';
    if (attributes.length > 0) {
      attributes.forEach(attr => {
        if (attr.attribute_name !== 'diagnosis') {
          if (attr.attribute_type === 'part_type') {
            newForm.attributes[attr.attribute_name] =
              attr.part_type_attribute_id;
          } else {
            newForm.attributes[attr.attribute_name] =
              attr.part_model_attribute_id;
          }
        } else if (attr.attribute_type === 'part_type') {
          diagnosis = attr.part_type_attribute_id;
        } else {
          diagnosis = attr.part_model_attribute_id;
        }
      });
      newForm.diagnosis = diagnosis;
    }

    return this.setState(state => ({
      ...state,
      form: newForm,
      isFetching: false
    }));
  }

  componentWillUnmount() {
    const {
      resetPartTypeAttributes,
      resetPartModelAttributes,
      resetDiagnoses
    } = this.props;
    resetPartTypeAttributes();
    resetPartModelAttributes();
    resetDiagnoses();
  }

  onSubmit = () => {
    const {
      ticket,
      part: { typeAttributes, modelAttributes },
      updateTicketDetails,
      hideModal,
      t
    } = this.props;
    const { form } = this.state;
    const newForm = { ...form };
    newForm.part_type = newForm.part_type.toString();
    newForm._method = 'PUT';
    newForm.status = 'updated';
    newForm.attributes.diagnosis = newForm.diagnosis;
    this.setState(state => ({ ...state, isSubmitting: true }));

    const revisedAttrs = {};
    const allAttributes = { ...typeAttributes, ...modelAttributes };
    Object.entries(newForm.attributes).forEach(([key, value]) => {
      const filtered =
        allAttributes[key] &&
        allAttributes[key].filter(
          opt => opt.id.toString() === value.toString()
        );

      if (filtered) {
        const type =
          filtered[0] && !filtered[0].part_type_id ? 'part_model' : 'part_type';

        revisedAttrs[key] = {
          id: value,
          type
        };
      }
    });
    newForm.attributes = revisedAttrs;
    let submitErrorMsg = '';
    let hasSubmitError = false;
    updateTicketDetails({
      id: ticket.current.id,
      claim_part_id: ticket.current.parts.id,
      ...newForm
    })
      .catch(errorStatus => {
        if (errorStatus !== 401) {
          submitErrorMsg = t(`create_ticket.error.${errorStatus}`);
          hasSubmitError = true;
        }
      })
      .finally(() => {
        const {
          status: { UPDATE_TICKET_DETAILS }
        } = this.props;
        if (UPDATE_TICKET_DETAILS === 'success') {
          hasSubmitError = false;
        }
        this.setState(
          state => ({
            ...state,
            isSubmitting: false,
            hasSubmitError,
            submitErrorMsg
          }),
          () => {
            if (hasSubmitError === false) {
              hideModal();
            }
          }
        );
      });
  };

  onChange = async e => {
    const {
      target: { name, value }
    } = e;
    const { form } = this.state;
    const newForm = { ...form };
    newForm[name] = value;
    this.setState(state => ({ ...state, form: newForm }));
  };

  onChangePartType = async e => {
    const {
      target: { value: partTypeId }
    } = e;
    const { form } = this.state;
    const {
      part: { types, diagnoses },
      fetchPartTypeAttributes,
      resetPartModelAttributes,
      fetchPartTypeDiagnoses,
      resetDiagnoses
    } = this.props;

    if (diagnoses.length > 0) {
      resetDiagnoses();
    }
    const newForm = { ...form };
    newForm.part_type = parseInt(partTypeId, 10);
    newForm.part_model = '';
    newForm.diagnosis = '';
    newForm.attributes = {};
    // Set form.models for the selected part type
    let newModels = [];
    types.forEach(type => {
      if (type.id === parseInt(partTypeId, 10)) {
        newModels = type.models;
      }
    });
    this.setState(state => ({ ...state, form: newForm, models: newModels }));
    await resetPartModelAttributes();
    await fetchPartTypeAttributes({ typeId: partTypeId });
    const {
      part: { typeAttributes }
    } = this.props;

    Object.keys(typeAttributes).forEach(key => {
      newForm.attributes[key] = '';
    });
    fetchPartTypeDiagnoses({ partTypeId });

    this.setState(state => ({ ...state, form: newForm }));
  };

  onChangePartModel = async e => {
    const { value } = e.target;
    const {
      part: {
        typeAttributes,
        diagnoses: prevDiagnoses,
        modelAttributes: prevModelAttrs
      },
      fetchPartModelAttributes,
      fetchModelDiagnoses
    } = this.props;
    const { form } = this.state;
    const newForm = { ...form };
    const prevAllAttrs = { ...typeAttributes, ...prevModelAttrs };
    newForm.part_model = value;
    this.setState(state => ({ ...state, form: newForm }));

    // Save the whole attribute object of any currently selected attributes
    const selectedAttrs = {};
    Object.keys(prevAllAttrs).forEach(key => {
      if (newForm.attributes[key]) {
        const filteredOptions = prevAllAttrs[key].filter(
          opt => opt.id.toString() === newForm.attributes[key].toString()
        );
        const targetOption = filteredOptions[0];
        selectedAttrs[key] = targetOption;
      }
    });

    // Get All the PartModelAttributes associated with this new Model No.
    await fetchPartModelAttributes({ modelId: value });
    const {
      part: { modelAttributes }
    } = this.props;

    // Create empty state values for each newly acquired PartModelAttribute
    const allAttrs = { ...typeAttributes, ...modelAttributes };
    Object.keys(allAttrs).forEach(key => {
      // If there is a current selection for this attribute...
      if (selectedAttrs[key]) {
        const optionVals = allAttrs[key].map(option => option.value);
        // If the "value" [read: human readable label] for the current selection
        // for this attributes matches any of the values in the new PartModel's options
        // for this attribute...
        if (optionVals.includes(selectedAttrs[key].value)) {
          allAttrs[key].forEach(option => {
            // ...find the option with the matching "value" [read: label]
            if (
              option.value.toString() === selectedAttrs[key].value.toString()
            ) {
              // ...and set state for the current attribute to equal the id of the new
              // PartModel's option with a matching "value" [read: label]
              newForm.attributes[key] = option.id;
            }
          });
        } else {
          newForm.attributes[key] = '';
        }
      } else {
        // If the form state attributes object doesn't have a key for this attribute,
        // set the value to an empty string to start
        newForm.attributes[key] = '';
      }
    });
    this.setState(state => ({ ...state, form: newForm }));

    // Now, do all the same for the diagnosis...
    if (newForm.diagnosis) {
      const filteredDiagnoses = prevDiagnoses.filter(option => {
        return option.id.toString() === newForm.diagnosis.toString();
      });
      const selectedDiagnosis = filteredDiagnoses[0];

      // Get Diagnoses for this Model No.
      await fetchModelDiagnoses({ modelId: value });
      const {
        part: { diagnoses }
      } = this.props;
      const optionVals = diagnoses.map(option => option.value);
      if (optionVals.includes(selectedDiagnosis.value)) {
        diagnoses.forEach(diagnosis => {
          if (
            diagnosis.value.toString() === selectedDiagnosis.value.toString()
          ) {
            this.setState(state => ({
              ...state,
              form: { ...newForm, diagnosis: diagnosis.id }
            }));
          }
        });
      } else {
        this.setState(state => ({
          ...state,
          form: { ...newForm, diagnosis: '' }
        }));
      }
    } else {
      this.setState(state => ({
        ...state,
        form: { ...newForm, diagnosis: '' }
      }));
      await fetchModelDiagnoses({ modelId: value });
    }
  };

  onChangeModelAttr = e => {
    const {
      target: { name, value }
    } = e;
    const { form } = this.state;
    const newForm = { ...form };
    newForm.attributes[name] = value;
    this.setState(state => ({ ...state, form: newForm }));
  };

  render() {
    const {
      isFetching,
      form,
      submitErrorMsg,
      hasSubmitError,
      isSubmitting,
      models
    } = this.state;

    const {
      t,
      part: { types: partTypes, modelAttributes, typeAttributes, diagnoses },
      status: {
        FETCH_PART_TYPE_ATTRIBUTES,
        FETCH_PART_MODEL_ATTRIBUTES,
        FETCH_MODEL_DIAGNOSES,
        FETCH_PART_TYPE_DIAGNOSES
      },
      hideModal
    } = this.props;

    let allAttributes = {};
    if (!form.part_model) {
      allAttributes = typeAttributes;
    } else {
      allAttributes = { ...typeAttributes, ...modelAttributes };
    }
    const genericRequiredRule = {
      rule: 'required',
      messages: {
        error: t('form.required_feedback'),
        success: t('form.required')
      }
    };

    return isFetching ? (
      <Fragment>
        <Scrim isShown={isFetching} inModal />
        <Throbber isFullPage />
      </Fragment>
    ) : (
      <Fragment>
        <Form
          onSubmitFunc={() => this.onSubmit()}
          validation={{
            part_type: [genericRequiredRule],
            part_model: [genericRequiredRule],
            diagnosis: [genericRequiredRule]
          }}
          render={status => (
            <Fragment>
              <Col md={3}>
                <Heading size={5}>{t('create_ticket.heading.part')}</Heading>
              </Col>
              {/* Serial Number Selector */}
              <Col md={9}>
                <Fragment>
                  <Row>
                    <Col md={6}>
                      <SsgInput
                        label={t('create_ticket.label.part_serial')}
                        type="text"
                        name="part_serial"
                        value={form.part_serial}
                        onChangeFunc={this.onChange}
                        status="muted"
                        helperText={t('create_ticket.helper_text.part_serial')}
                        inputId="part-serial"
                        width="100%"
                      />
                    </Col>
                  </Row>
                  <Row>
                    {/* Part Type Selector */}
                    <Col md={6}>
                      <SsgInput
                        label={t('create_ticket.label.part_type')}
                        inputId="part-type"
                        name="part_type"
                        type="select"
                        value={form.part_type}
                        onChangeFunc={this.onChangePartType}
                        status={status.part_type.status}
                        feedbackMsg=""
                        width="100%"
                        isRequired
                      >
                        <Fragment>
                          <Option disabled value="">
                            {t('create_ticket.placeholder.part_type')}
                          </Option>
                          {partTypes &&
                            partTypes.length &&
                            partTypes.map(partType => (
                              <Option key={partType.id} value={partType.id}>
                                {partType.name}
                              </Option>
                            ))}
                        </Fragment>
                      </SsgInput>
                    </Col>
                    {/* Part Model Selector */}
                    <Col md={6}>
                      {models && (
                        <SsgInput
                          label={t('create_ticket.label.part_model')}
                          inputId="part-model"
                          name="part_model"
                          type="select"
                          value={form.part_model}
                          onChangeFunc={this.onChangePartModel}
                          status={status.part_model.status}
                          feedbackMsg=""
                          width="100%"
                          isRequired
                          isDisabled={
                            FETCH_PART_MODEL_ATTRIBUTES === 'loading' ||
                            !models ||
                            models.length === 0
                          }
                        >
                          <Fragment>
                            <Option disabled value="">
                              {t('create_ticket.placeholder.part_model')}
                            </Option>
                            {models &&
                              models.map(model => (
                                <Option key={model.id} value={model.id}>
                                  {model.name}{' '}
                                  {FETCH_PART_MODEL_ATTRIBUTES === 'loading'
                                    ? t('form.placeholders.loading')
                                    : ''}
                                </Option>
                              ))}
                          </Fragment>
                        </SsgInput>
                      )}
                    </Col>
                  </Row>
                  {/* Part Attributes Selector */}
                  <SectionRow>
                    {Object.entries(allAttributes).filter(
                      // eslint-disable-next-line no-unused-vars
                      ([key, value]) => key !== 'diagnosis'
                    ).length > 0 && (
                      <Col md={12}>
                        <Heading size={6}>
                          {t('create_ticket.subheading.part_details')}
                        </Heading>
                      </Col>
                    )}
                    {Object.entries(allAttributes).length > 0 &&
                      Object.entries(allAttributes).map(
                        ([key, value]) =>
                          key !== 'diagnosis' && (
                            <Col key={key} md={6}>
                              <SsgInput
                                type="select"
                                label={t(`create_ticket.label.${key}`)}
                                inputId={key}
                                name={key}
                                value={form.attributes[key]}
                                defaultValue=""
                                onChangeFunc={this.onChangeModelAttr}
                                width="100%"
                                status="muted"
                                isDisabled={
                                  FETCH_PART_TYPE_ATTRIBUTES === 'loading' ||
                                  FETCH_PART_MODEL_ATTRIBUTES === 'loading'
                                }
                              >
                                <Option disabled value="">
                                  {t(`create_ticket.placeholder.${key}`)}
                                </Option>
                                {value.map(option => (
                                  <Option key={option.id} value={option.id}>
                                    {option.value}
                                  </Option>
                                ))}
                              </SsgInput>
                            </Col>
                          )
                      )}
                  </SectionRow>
                  <SectionRow>
                    <Col md={6}>
                      <Heading size={6}>
                        {t('create_ticket.subheading.part_diagnosis')}
                      </Heading>
                      <SsgInput
                        type="select"
                        label={t(`create_ticket.label.diagnosis`)}
                        inputId="diagnosis"
                        name="diagnosis"
                        value={form.diagnosis}
                        defaultValue=""
                        onChangeFunc={this.onChange}
                        width="100%"
                        status={status.diagnosis.status}
                        isRequired
                        isDisabled={
                          !diagnoses ||
                          diagnoses.length === 0 ||
                          FETCH_PART_TYPE_ATTRIBUTES === 'loading' ||
                          FETCH_PART_MODEL_ATTRIBUTES === 'loading' ||
                          FETCH_MODEL_DIAGNOSES === 'loading' ||
                          FETCH_PART_TYPE_DIAGNOSES === 'loading'
                        }
                      >
                        <Option disabled value="">
                          {FETCH_PART_TYPE_ATTRIBUTES === 'loading' ||
                          FETCH_PART_MODEL_ATTRIBUTES === 'loading' ||
                          FETCH_MODEL_DIAGNOSES === 'loading' ||
                          FETCH_PART_TYPE_DIAGNOSES === 'loading'
                            ? t('form.placeholders.loading')
                            : t('create_ticket.placeholder.diagnosis')}
                        </Option>
                        {diagnoses &&
                          diagnoses.length > 0 &&
                          diagnoses.map(option => (
                            <Option key={option.id} value={option.id}>
                              {option.value}
                            </Option>
                          ))}
                      </SsgInput>
                    </Col>
                  </SectionRow>
                </Fragment>
              </Col>
              <HR />
              <Alert
                alertIsOpen={hasSubmitError}
                color="danger"
                onCloseFunc={() => {
                  this.setState(() => ({ hasSubmitError: false }));
                }}
              >
                {submitErrorMsg}
              </Alert>
              <ButtonWrapper>
                <Button
                  className="cancel"
                  color="secondary"
                  type="button"
                  onClickFunc={() => {
                    hideModal();
                  }}
                  width="min-content"
                >
                  {t('create_ticket.buttons.cancel')}
                </Button>
                <Button
                  className="mt-4 mb-2"
                  color="primary"
                  type="submit"
                  isFetching={isSubmitting}
                  width="min-content"
                >
                  {t('create_ticket.buttons.update_ticket')}
                </Button>
              </ButtonWrapper>
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ticket: state.ticket,
  status: state.status,
  part: state.part,
  bike: state.bike
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  fetchPartTypes: () => dispatch(fetchPartTypes()),
  fetchPartModelAttributes: data => dispatch(fetchPartModelAttributes(data)),
  resetPartModelAttributes: () => dispatch(resetPartModelAttributes()),
  fetchPartTypeAttributes: data => dispatch(fetchPartTypeAttributes(data)),
  resetPartTypeAttributes: () => dispatch(resetPartTypeAttributes()),
  fetchModelDiagnoses: data => dispatch(fetchModelDiagnoses(data)),
  fetchPartTypeDiagnoses: data => dispatch(fetchPartTypeDiagnoses(data)),
  resetDiagnoses: () => dispatch(resetDiagnoses()),
  updateTicketDetails: data => dispatch(updateTicketDetails(data))
});

UpdateTicketDetails.propTypes = {
  part: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.shape({})),
    part: PropTypes.shape({
      id: PropTypes.string
    }),
    modelAttributes: PropTypes.shape({}),
    typeAttributes: PropTypes.shape({}),
    diagnoses: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  status: PropTypes.shape({
    UPDATE_TICKET_DETAILS: PropTypes.string,
    FETCH_PART_TYPES: PropTypes.string.isRequired,
    FETCH_PART_MODEL_ATTRIBUTES: PropTypes.string.isRequired,
    FETCH_PART_TYPE_ATTRIBUTES: PropTypes.string.isRequired,
    FETCH_MODEL_DIAGNOSES: PropTypes.string.isRequired,
    FETCH_PART_TYPE_DIAGNOSES: PropTypes.string.isRequired
  }).isRequired,
  ticket: PropTypes.shape({
    current: PropTypes.shape({
      id: PropTypes.number,
      parts: PropTypes.shape({
        id: PropTypes.number,
        serial_number: PropTypes.string,
        type: PropTypes.shape({
          id: PropTypes.number,
          models: PropTypes.shape({})
        }),
        model: PropTypes.shape({
          id: PropTypes.number
        }),
        attributes: PropTypes.arrayOf(PropTypes.shape())
      })
    })
  }).isRequired,
  /** Actions */
  hideModal: PropTypes.func.isRequired,
  fetchPartTypes: PropTypes.func.isRequired,
  updateTicketDetails: PropTypes.func.isRequired,
  // Attributes
  fetchPartModelAttributes: PropTypes.func.isRequired,
  resetPartModelAttributes: PropTypes.func.isRequired,
  fetchPartTypeAttributes: PropTypes.func.isRequired,
  resetPartTypeAttributes: PropTypes.func.isRequired,
  // Diagnoses
  fetchModelDiagnoses: PropTypes.func.isRequired,
  fetchPartTypeDiagnoses: PropTypes.func.isRequired,
  resetDiagnoses: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: ${space[1] * 3}rem;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${space[5]}rem;
  .btn-secondary {
    color: ${colors.black.base};
    border-color: ${colors.black.base};
    background: none;
    width: 90%;
  }
`;

const SectionRow = styled(Row)`
  margin: 1rem 0;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UpdateTicketDetails));
