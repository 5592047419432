import { api, retryApiCall } from "../api";
import {
  FETCH_TICKETS_LOADING,
  FETCH_TICKETS_ERROR,
  FETCH_TICKETS_SUCCESS,
  FETCH_SCOPES_LOADING,
  FETCH_SCOPES_SUCCESS,
  FETCH_SCOPES_ERROR,
  FETCH_TICKETS_BY_SCOPE_LOADING,
  FETCH_TICKETS_BY_SCOPE_ERROR,
  FETCH_TICKETS_BY_SCOPE_SUCCESS,
  CREATE_TICKET,
  CREATE_TICKET_ERROR,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_RESET,
  UPDATE_TICKET_STATUS_LOADING,
  UPDATE_TICKET_STATUS_SUCCESS,
  UPDATE_TICKET_STATUS_ERROR,
  CREATE_TICKET_MESSAGE,
  CREATE_TICKET_MESSAGE_SUCCESS,
  CREATE_TICKET_MESSAGE_ERROR,
  FETCH_TICKET_DETAILS_SUCCESS,
  FETCH_TICKET_DETAILS_ERROR,
  FETCH_TICKET_DETAILS_LOADING,
  UPDATE_TICKET_DETAILS_SUCCESS,
  UPDATE_TICKET_DETAILS_ERROR,
  UPDATE_TICKET_DETAILS_LOADING,
  UPDATE_TICKET_MESSAGE_SUCCESS,
  UPDATE_TICKET_MESSAGE_ERROR,
  UPDATE_TICKET_MESSAGE_LOADING,
  RESET_UPDATE_TICKET_DETAILS,
  SEARCH_TICKETS_LOADING,
  SEARCH_TICKETS_SUCCESS,
  SEARCH_TICKETS_ERROR,
  ADD_TICKET_FILTER,
  REMOVE_TICKET_FILTER
} from "./types";

export const ticketCallTypes = {};
const apiCall = retryApiCall.use(ticketCallTypes);

// Fetch All Tickets
export const fetchTicketsLoading = data => ({
  type: FETCH_TICKETS_LOADING,
  payload: data
});

export const fetchTicketsFailure = data => ({
  type: FETCH_TICKETS_ERROR,
  payload: data
});

export const fetchTicketsSuccess = data => ({
  type: FETCH_TICKETS_SUCCESS,
  payload: data
});

export const fetchTickets = data => dispatch => {
  dispatch(apiCall(fetchTicketsLoading, fetchTickets, data));
  return api
    .get(`/claims`, {
      params: {
        ...data
      }
    })
    .then(res => {
      dispatch(fetchTicketsSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(fetchTicketsFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

// Fetch All Scopes
export const fetchScopesLoading = data => ({
  type: FETCH_SCOPES_LOADING,
  payload: data
});

export const fetchScopesFailure = data => ({
  type: FETCH_SCOPES_ERROR,
  payload: data
});

export const fetchScopesSuccess = data => ({
  type: FETCH_SCOPES_SUCCESS,
  payload: data
});

export const fetchScopes = data => dispatch => {
  dispatch(apiCall(fetchScopesLoading, fetchScopes, data));
  return api
    .get("/claims/scopes", {
      ...data
    })
    .then(res => {
      dispatch(fetchScopesSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(fetchScopesFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

// Fetch Tickets By Scope
export const fetchTicketsByScopeLoading = data => ({
  type: FETCH_TICKETS_BY_SCOPE_LOADING,
  payload: data
});

export const fetchTicketsByScopeFailure = data => ({
  type: FETCH_TICKETS_BY_SCOPE_ERROR,
  payload: data
});

export const fetchTicketsByScopeSuccess = data => ({
  type: FETCH_TICKETS_BY_SCOPE_SUCCESS,
  payload: data
});

export const fetchTicketsByScope = data => dispatch => {
  dispatch(apiCall(fetchTicketsByScopeLoading, fetchTicketsByScope, data));
  return api
    .get(`/claims/scope/${data.scope}`, {
      params: { ...data }
    })
    .then(res => {
      dispatch(fetchTicketsByScopeSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(fetchTicketsByScopeFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

// Search Tickets
export const searchTicketsLoading = data => ({
  type: SEARCH_TICKETS_LOADING,
  payload: data
});

export const searchTicketsSuccess = data => ({
  type: SEARCH_TICKETS_SUCCESS,
  payload: data
});

export const searchTicketsFailure = data => ({
  type: SEARCH_TICKETS_ERROR,
  payload: data
});

export const searchTickets = data => dispatch => {
  dispatch(apiCall(searchTicketsLoading, searchTickets, data));
  return api
    .get(`/claims/search`, {
      params: { ...data }
    })
    .then(res => {
      dispatch(searchTicketsSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(searchTicketsFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

// Create Ticket
export const createTicketLoading = data => ({
  type: CREATE_TICKET,
  payload: data
});

export const createTicketFailure = data => ({
  type: CREATE_TICKET_ERROR,
  payload: data
});

export const createTicketSuccess = data => ({
  type: CREATE_TICKET_SUCCESS,
  payload: data
});

export const createTicketReset = () => ({
  type: CREATE_TICKET_RESET
});

export const createTicket = data => dispatch => {
  dispatch(apiCall(createTicketLoading, createTicket, data));
  return api
    .post("/claim", {
      ...data
    })
    .then(res => {
      dispatch(createTicketSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(createTicketFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

export const fetchTicketDetailsLoading = data => ({
  type: FETCH_TICKET_DETAILS_LOADING,
  payload: data
});

export const fetchTicketDetailsFailure = data => ({
  type: FETCH_TICKET_DETAILS_ERROR,
  payload: data
});

export const fetchTicketDetailsSuccess = data => ({
  type: FETCH_TICKET_DETAILS_SUCCESS,
  payload: data
});

export const fetchTicketDetails = data => dispatch => {
  dispatch(fetchTicketDetailsLoading(data));
  return api
    .get(`/claim/${data.id}`, {
      ...data
    })
    .then(res => {
      dispatch(fetchTicketDetailsSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(fetchTicketDetailsFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

export const updateTicketDetailsLoading = data => ({
  type: UPDATE_TICKET_DETAILS_LOADING,
  payload: data
});

export const updateTicketDetailsFailure = data => ({
  type: UPDATE_TICKET_DETAILS_ERROR,
  payload: data
});

export const updateTicketDetailsSuccess = data => ({
  type: UPDATE_TICKET_DETAILS_SUCCESS,
  payload: data
});

export const updateTicketMessageLoading = data => ({
  type: UPDATE_TICKET_MESSAGE_LOADING,
  payload: data
});

export const updateTicketMessageFailure = data => ({
  type: UPDATE_TICKET_MESSAGE_ERROR,
  payload: data
});

export const updateTicketMessageSuccess = data => ({
  type: UPDATE_TICKET_MESSAGE_SUCCESS,
  payload: data
});

export const updateTicketDetails = data => dispatch => {
  dispatch(apiCall(updateTicketDetailsLoading, updateTicketDetails, data));
  const endpoint = `/claim/${data.id}`;
  // const params = JSON.stringify({ ...data });
  // sleep for 2 seconds
  return api
    .post(endpoint, { ...data })
    .then(res => {
      dispatch(updateTicketDetailsSuccess(res.data.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(updateTicketDetailsFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

export const updateTicketMessage = data => dispatch => {
  dispatch(apiCall(updateTicketMessageLoading, updateTicketMessage, data));
  const endpoint = `/message/${data.message.id}`;
  return api
    .patch(endpoint, { ...data })
    .then(res => {
      dispatch(updateTicketMessageSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(updateTicketMessageFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};
export const resetUpdateTicketDetails = () => ({
  type: RESET_UPDATE_TICKET_DETAILS
});

export const createTicketMessageLoading = data => ({
  type: CREATE_TICKET_MESSAGE,
  payload: data
});

export const createTicketMessageFailure = data => ({
  type: CREATE_TICKET_MESSAGE_ERROR,
  payload: data
});

export const createTicketMessageSuccess = data => ({
  type: CREATE_TICKET_MESSAGE_SUCCESS,
  payload: data
});

export const createTicketMessage = data => dispatch => {
  dispatch(createTicketMessageLoading(data));
  return api
    .post(`/claim/${data.id}/message`, {
      ...data
    })
    .then(res => {
      dispatch(createTicketMessageSuccess(res.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(createTicketMessageFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

export const updateTicketStatusLoading = data => ({
  type: UPDATE_TICKET_STATUS_LOADING,
  payload: data
});

export const updateTicketStatusFailure = data => ({
  type: UPDATE_TICKET_STATUS_ERROR,
  payload: data
});

export const updateTicketStatusSuccess = data => ({
  type: UPDATE_TICKET_STATUS_SUCCESS,
  payload: data
});

export const updateTicketStatus = data => dispatch => {
  dispatch(apiCall(updateTicketStatusLoading, updateTicketStatus, data));
  return api
    .put(`/claim/${data.id}`, {
      ...data
    })
    .then(res => {
      dispatch(updateTicketStatusSuccess(res.data.data));
      return res.data;
    })
    .catch(errStatus => {
      if (errStatus !== 401) {
        dispatch(updateTicketStatusFailure(errStatus));
      }
      return Promise.reject(errStatus);
    });
};

export const addTicketFilter = data => ({
  type: ADD_TICKET_FILTER,
  payload: data
});

export const removeTicketFilter = data => ({
  type: REMOVE_TICKET_FILTER,
  payload: data
});

export const addFilter = data => dispatch => {
  dispatch(addTicketFilter(data));
};

export const removeFilter = data => dispatch => {
  dispatch(removeTicketFilter(data));
};
