/* eslint-disable prettier/prettier */
export const FETCH_TICKETS_LOADING = "FETCH_TICKETS_LOADING";
export const FETCH_TICKETS_SUCCESS = "FETCH_TICKETS_SUCCESS";
export const FETCH_TICKETS_ERROR = "FETCH_TICKETS_ERROR";

export const FETCH_SCOPES_SUCCESS = "FETCH_SCOPES_SUCCESS";
export const FETCH_SCOPES_ERROR = "FETCH_SCOPES_ERROR";
export const FETCH_SCOPES_LOADING = "FETCH_SCOPES_LOADING";

export const FETCH_TICKETS_BY_SCOPE_SUCCESS = "FETCH_TICKETS_BY_SCOPE_SUCCESS";
export const FETCH_TICKETS_BY_SCOPE_ERROR = "FETCH_TICKETS_BY_SCOPE_ERROR";
export const FETCH_TICKETS_BY_SCOPE_LOADING = "FETCH_TICKETS_BY_SCOPE_LOADING";

export const CREATE_TICKET = "CREATE_TICKET";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_ERROR = "CREATE_TICKET_ERROR";
export const CREATE_TICKET_RESET = "CREATE_TICKET_RESET";

export const CREATE_TICKET_MESSAGE = "CREATE_TICKET_MESSAGE";
export const CREATE_TICKET_MESSAGE_SUCCESS = "CREATE_TICKET_MESSAGE_SUCCESS";
export const CREATE_TICKET_MESSAGE_ERROR = "CREATE_TICKET_MESSAGE_ERROR";

export const UPDATE_TICKET_STATUS_LOADING = "UPDATE_TICKET_STATUS";
export const UPDATE_TICKET_STATUS_SUCCESS = "UPDATE_TICKET_STATUS_SUCCESS";
export const UPDATE_TICKET_STATUS_ERROR = "UPDATE_TICKET_STATUS_ERROR";

export const UPDATE_TICKET_MESSAGE_LOADING = "UPDATE_TICKET_MESSAGE_LOADING";
export const UPDATE_TICKET_MESSAGE_SUCCESS = "UPDATE_TICKET_MESSAGE_SUCCESS";
export const UPDATE_TICKET_MESSAGE_ERROR = "UPDATE_TICKET_MESSAGE_ERROR";

export const FETCH_TICKET_DETAILS_LOADING = "FETCH_TICKET_DETAILS_LOADING";
export const FETCH_TICKET_DETAILS_SUCCESS = "FETCH_TICKET_DETAILS_SUCCESS";
export const FETCH_TICKET_DETAILS_ERROR = "FETCH_TICKET_DETAILS_ERROR";

export const SEARCH_TICKETS_LOADING = "SEARCH_TICKETS_LOADING";
export const SEARCH_TICKETS_SUCCESS = "SEARCH_TICKETS_SUCCESS";
export const SEARCH_TICKETS_ERROR = "SEARCH_TICKETS_ERROR";

export const UPDATE_TICKET_DETAILS_LOADING = "UPDATE_TICKET_DETAILS_LOADING";
export const UPDATE_TICKET_DETAILS_SUCCESS = "UPDATE_TICKET_DETAILS_SUCCESS";
export const UPDATE_TICKET_DETAILS_ERROR = "UPDATE_TICKET_DETAILS_ERROR";
export const RESET_UPDATE_TICKET_DETAILS = "RESET_UPDATE_TICKET_DETAILS";

export const ADD_TICKET_FILTER = "ADD_TICKET_FILTER";
export const REMOVE_TICKET_FILTER = "REMOVE_TICKET_FILTER";
