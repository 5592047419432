import { createGlobalStyle } from 'styled-components';
import { fonts } from './typography';

export default createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }
  body, ol li, ul li, button {
    font-family: ${fonts.sansSerif};
  }
  body {
    font-size: 1rem;
    line-height: 1.5;
    font-stretch: condensed;
    margin: 0;
  }
  ol, ul {
    margin-left: 1.25rem;
    padding-left: 1.25rem;
    li {
      font-size: 1rem;
      line-height: 1.6;
      font-stretch: normal;
    }
  }
`;
