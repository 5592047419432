import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'Theme';

const Chevron = ({ size, color, direction }) => {
  let InnerElements = () => (
    <g id="srs-chevron" transform="translate(8.000000, 2.000000)">
      <polyline id="srs-chevron" points="0 0 7 10 0 20" />
    </g>
  );
  switch (direction) {
    case 'left':
      InnerElements = () => (
        <g id="srs-chevron" transform="translate(8.000000, 2.000000)">
          <polyline
            transform="translate(3.500000, 10.000000) scale(-1, 1) translate(-3.500000, -10.000000) "
            points="0 1.77635684e-15 7 10 0 20"
          />
        </g>
      );
      break;
    case 'left-end':
      InnerElements = () => (
        <g id="icon/srs-chevron" transform="translate(4.000000, 2.000000)">
          <polyline
            transform="translate(12.500000, 10.000000) scale(-1, 1) translate(-12.500000, -10.000000) "
            points="9 0 16 10 9 20"
          />
          <path d="M1,0 L1,20" />
        </g>
      );
      break;
    case 'right-end':
      InnerElements = () => (
        <g id="icon/srs-chevron" transform="translate(4.000000, 2.000000)">
          <polyline points="0 0 7 10 0 20" />
          <path d="M15,0 L15,20" />
        </g>
      );
      break;
    default:
      break;
  }
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id={`icon/srs-chevron${direction}`} stroke={color} strokeWidth="2">
          <InnerElements />
        </g>
      </g>
    </svg>
  );
};

Chevron.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.string
};

Chevron.defaultProps = {
  size: '24px',
  color: colors.gray.darkest,
  direction: 'right'
};

export default Chevron;
