import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { fetchServiceCenter, updateServiceCenter } from "Actions/serviceCenter";
import styled from "styled-components";
import { showModal } from "Actions/modal";
import * as modalTypes from "Components/Modal/types";
import {
  Heading,
  Form,
  Input as SsgInput,
  Checkbox,
  Button,
  Alert,
  Scrim,
  Throbber
} from "Components";
import Users from "./Users";
import Invites from "./Invites";
import { space, colors, breakpoints, headerHeight } from "Theme";
import Row from "@bootstrap-styled/v4/lib/Row";
import Col from "@bootstrap-styled/v4/lib/Col";

const ServiceCenter = props => {
  const [isLoading, setLoading] = useState(true); // We start loading so we can fetch the service center data
  const [error, setError] = useState(null);
  const [serviceCenter, setServiceCenter] = useState({});
  const {
    match: {
      params: { id }
    },
    t,
    user
  } = props;

  // Fetch service center data when component mounts
  useEffect(() => {
    getServiceCenterData();
  }, []);
  const getServiceCenterData = () => {
    props
      .fetchServiceCenter({ id })
      .then(data => {
        setServiceCenter(data);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => setLoading(false));
  };
  // Only super admins can view and see this page
  // TODO: Replace with actual error
  if (user.level !== "super_admin") return <p>Bad user</p>;

  // A function for activating the modal to invite a technician
  const inviteTech = event => {
    const { showModal } = props;
    const modalData = {
      type: modalTypes.FORM_INVITE_SERVICE_CENTER_TECH,
      title: t("modal.title.add_tech"),
      data: {
        serviceCenterId: id,
        onInvite: getServiceCenterData
      }
    };
    showModal(modalData);
  };

  const toggleRegistration = () => {
    console.log("farts");
    props
      .updateServiceCenter({
        id,
        registration_enabled: serviceCenter.registration_enabled ? 0 : 1
      })
      .then(() => getServiceCenterData())
      .catch(err => console.error("Error toggling registration"));
  };

  return isLoading ? (
    <Fragment>
      <Scrim isShown={isLoading} />
      <StyledThrobber isFullPage />
    </Fragment>
  ) : (
    <Fragment>
      <AlertWrapper>
        <Alert
          alertIsOpen={error !== null}
          color="danger"
          onCloseFunc={() => setError(null)}
        >
          {error}
        </Alert>
      </AlertWrapper>
      <Heading size={4}>
        {t("service_center.heading_main", {
          service_center_name: `${serviceCenter.name}`
        })}
      </Heading>
      <Section>
        <Row>
          <Col md={4}>
            <Heading size={6}>{t("service_center.heading_info")}</Heading>
          </Col>
          <Col md={8}>
            <Row>
              <Col md={6}>
                <SsgInput
                  label={t("form.labels.company")}
                  name="name"
                  value={serviceCenter.name}
                  inputId="service-center-name"
                  placeholder="Service Center"
                  width="100%"
                  isDisabled
                />
              </Col>
              <Col md={6}>
                <SsgInput
                  label={t("form.labels.phone")}
                  type="tel"
                  name="phone"
                  value={serviceCenter.phone}
                  inputId="service-center-phone"
                  width="100%"
                  isDisabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <SsgInput
                  label={t("form.labels.email")}
                  type="email"
                  name="email"
                  value={serviceCenter.email}
                  inputId="service-center-email"
                  width="100%"
                  isDisabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>
      <Section>
        <Row>
          <Col md={4}>
            <Heading size={6}>{t("service_center.settings_info")}</Heading>
          </Col>
          <Col md={8}>
            <Checkbox
              label={t("service_center.settings.registration_info")}
              inputId="newsletter"
              name="newsletter"
              onChangeFunc={toggleRegistration}
              value={Boolean(serviceCenter.registration_enabled)}
            />
          </Col>
        </Row>
      </Section>
      <Section>
        <Users techs={serviceCenter.members} />
        <ButtonWrapper>
          <Button
            color="secondary"
            outline
            type="button"
            onClickFunc={inviteTech}
            width="max-content"
          >
            {t("service_center.buttons.add_user")}
          </Button>
        </ButtonWrapper>
      </Section>
      <Section>
        <Invites invitations={serviceCenter.invitations} />
      </Section>
    </Fragment>
  );
};

const StyledThrobber = styled(Throbber)`
  top: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${space[1] * 3}rem;
`;

const AlertWrapper = styled.div`
  margin-top: calc(${headerHeight}px + ${space[1]}rem);
  margin-bottom: ${space[3]}rem;
`;

const Section = styled.section`
  padding-top: ${space[1] * 3}rem;
  padding-bottom: ${space[2] * 2}rem;
  margin-bottom: ${space[1]}rem;
  border-bottom: 2px solid ${colors.gray.base};
  @media screen and (min-width: ${breakpoints.md}) {
    padding-top: ${space[1] * 7}rem;
    padding-bottom: ${space[4]}rem;
  }
`;

const mapStateToProps = state => ({
  user: state.user,
  modal: state.modal
});

// TODO: Add dispatch for inviting users to the service center
const mapDispatchToProps = dispatch => ({
  fetchServiceCenter: data => dispatch(fetchServiceCenter(data)),
  showModal: data => dispatch(showModal(data)),
  updateServiceCenter: data => dispatch(updateServiceCenter(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ServiceCenter))
);
