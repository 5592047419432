import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@bootstrap-styled/v4/lib/Form/FormGroup';
import FormFeedback from '@bootstrap-styled/v4/lib/Form/FormFeedback';
import Label from '@bootstrap-styled/v4/lib/Label';

const RadioGroup = ({ label, status, feedbackMsg, children }) => {
  const bootstrapStatus = status === 'muted' ? '' : status;
  return (
    <FormGroup color={bootstrapStatus}>
      <Label>{label}</Label>
      {children}
      <FormFeedback>{feedbackMsg}</FormFeedback>
    </FormGroup>
  );
};

RadioGroup.propTypes = {
  label: PropTypes.string,
  status: PropTypes.oneOf(['success', 'danger', 'warning', 'muted']).isRequired,
  feedbackMsg: PropTypes.string,
  children: PropTypes.node.isRequired
};

RadioGroup.defaultProps = {
  label: '',
  feedbackMsg: ''
};

export default RadioGroup;
