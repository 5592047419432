export default {
  en: {
    sidebar: {
      tickets: "Tickets",
      service_center: "Service Center",
      service_techs: "Technicians",
      service_settings: "Settings",
      dealers: "Dealerships",
      service_centers: "Service Centers",
      users: "Users",
      reports: "Reports",
      dealer: "Dealership",
      acct: "Account",
      parts_manager: "Parts Manager",
    },
  },
  de: {
    sidebar: {
      tickets: "Eintrittskarten",
      service_center: "Bedienung Gebäude",
      service_techs: "Techniker",
      service_settings: "Einstellungen",
      dealers: "Händler",
      users: "Benutzer",
      reports: "Berichte",
      dealer: "Händler",
      acct: "Konto",
    },
  },
  jp: {
    sidebar: {
      tickets: "切符売場",
      service_center: "サービスセンター",
      service_techs: "技術者",
      service_settings: "設定",
      dealers: "ディーラー",
      users: "ユーザー",
      reports: "レポート",
      dealer: "ディーラー",
      acct: "アカウント",
    },
  },
  it: {
    sidebar: {
      tickets: "Biglietti",
      service_center: "Centro Servizi",
      service_techs: "Tecnici",
      service_settings: "Impostazioni",
      dealers: "Concessionari",
      service_centers: "Centri di servizio",
      users: "Utenti",
      reports: "Rapporti",
      dealer: "Concessionario",
      acct: "Account",
    },
  },
};
