import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Option from "@bootstrap-styled/v4/lib/Option";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
  fetchServiceCenters,
  searchServiceCenters
} from "Actions/serviceCenter";
import {
  Alert,
  Badge,
  DataTable,
  Input,
  Link,
  Scrim,
  Search
} from "Components";
import {
  titleCase,
  setBadgeColorByStatus,
  formatPhoneNumber
} from "Components/Utils";

class ServiceCenterList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      filters: [],
      searchKeyword: "",
      isSearching: false,
      pageNumber: 1,
      perPage: 25,
      paginationResetDefaultPage: false,
      sort: {
        by: "name",
        order: "asc"
      },
      hasError: false,
      error: ""
    };
  }

  async componentDidMount() {
    const { pageNumber } = this.state;
    await this.fetchData(pageNumber);
  }

  handlePageChange = async page => {
    const { searchKeyword, perPage, filters, sort } = this.state;
    const { searchServiceCenters } = this.props;
    if (searchKeyword) {
      const queryData = {
        search: searchKeyword,
        paginationPerPage: perPage,
        page
      };
      if (filters.length > 0) {
        queryData.scope = filters.join("+");
      }
      searchServiceCenters(queryData).catch(err => {
        console.error(`Error searching service centers ${err.message}`);
      });
    } else {
      this.fetchData(page, false, sort);
    }
  };

  handleRowsPerPageChange = (currRowsPer, pageNumber) => {
    this.setState(
      state => ({ ...state, perPage: currRowsPer, pageNumber }),
      () => {
        this.fetchData(pageNumber);
      }
    );
  };

  handleSearchChange = e => {
    const {
      target: { value }
    } = e;
    this.setState(state => ({ ...state, searchKeyword: value }));
  };

  onSearch = () => {
    const {
      searchKeyword,
      perPage: paginationPerPage,
      pageNumber: page,
      sort
    } = this.state;
    const { searchServiceCenters } = this.props;

    this.setState(state => ({
      ...state,
      isSearching: true,
      filters: [] // Clear filtered scopes on search
    }));

    const queryData = {
      search: encodeURIComponent(searchKeyword),
      paginationPerPage,
      page,
      sort
    };
    searchServiceCenters(queryData)
      .catch(err => {
        console.error(`An Error Occured During Search: ${err.message}`);
      })
      .then(() => this.setState(state => ({ ...state, isSearching: false })));
  };

  handleSort = (column, sortDirection) => {
    const sort = {
      by: column.selector,
      order: sortDirection
    };
    this.setState(
      state => ({
        ...state,
        pageNumber: 1,
        sort,
        paginationResetDefaultPage: true
      }),
      () => {
        this.fetchData(1, false, sort);
      }
    );
  };

  fetchData = async (pageNumber = null, sortConfig = {}) => {
    const { history, fetchServiceCenters } = this.props;
    const { perPage } = this.state;

    const pageData = {};
    if (pageNumber) {
      pageData.page = pageNumber;
    } else {
      pageData.page = 1;
    }
    // Set pagination to 25 rows per page
    pageData.paginationPerPage = perPage;
    if (Object.keys(sortConfig).length > 0) {
      pageData.sort = sortConfig;
    } else {
      const { sort } = this.state;
      pageData.sort = sort;
    }

    // We either fetch and paginate the total list of dealerships, or if the user has filters applied
    // we paginate the filtered results
    const { t } = this.props;
    await fetchServiceCenters(pageData).catch(errorStatus => {
      if (errorStatus === 403) {
        return history.push("/");
      }
      const error = t(`service_center_list.error.${errorStatus}`);
      return this.setState(() => ({ error, hasError: true }));
    });

    this.setState(() => ({
      isFetching: false
    }));
  };

  addFilter = async event => {
    const { filters, sort } = this.state;
    const { value } = event.target;
    // If this is the first filter being applied the table should be cleared and replaced with
    // only the new records
    this.setState(
      state => ({
        ...state,
        pageNumber: 1,
        paginationResetDefaultPage: true
      }),
      () => {
        if (!filters.includes(value)) {
          const shouldOverwriteResults = filters.length === 0;
          filters.push(value);
          // Now also get records by that query scope
          this.fetchData(1, shouldOverwriteResults, sort).then(() => {
            this.setState(state => ({
              ...state,
              filters
            }));
          });
        }
      }
    );
  };

  removeFilter = async event => {
    const { filters } = this.state;
    const scopeToRemove = event.currentTarget.getAttribute("handle");
    const newFilters = filters.filter(aScope => aScope !== scopeToRemove);

    this.setState(
      () => ({
        filters: newFilters
      }),
      () => {
        // Refresh data from refreshed scopes. Removing a filter requires a re-fetch, adding a filter means
        // data is merged.
        const shouldOverWriteResults = true;
        const startPage = null;
        this.fetchData(startPage, shouldOverWriteResults);
      }
    );
  };

  render() {
    const {
      isFetching,
      isSearching,
      filters,
      searchKeyword,
      perPage,
      hasError,
      error,
      paginationResetDefaultPage
    } = this.state;
    const { serviceCenter, setDealership, history, t } = this.props;
    const { list, pagination, scopes } = serviceCenter;

    const columns = [
      {
        id: "name",
        name: t("table.name"),
        selector: "name",
        grow: 2,
        cell: row => <Link href={`/service-center/${row.id}`}>{row.name}</Link>
      },
      {
        id: "phone",
        name: t("table.phone"),
        selector: "phone",
        cell: row => (row.phone ? formatPhoneNumber(row.phone) : "")
      },
      {
        id: "address",
        name: t("table.address"),
        selector: "address.address_one",
        cell: row => {
          const street = row.address.address_two
            ? `${row.address.address_one}, ${row.address.address_two}, `
            : `${row.address.address_one}, `;
          return (
            <Fragment>
              {street}
              {row.address.city}, {row.address.state} {row.address.zip}
            </Fragment>
          );
        },
        sortable: false,
        grow: 2
      },
      {
        id: "country",
        name: t("table.country"),
        selector: "address.country",
        sortable: false,
        cell: row =>
          row.address && row.address.country !== "undefined"
            ? row.address.country
            : ""
      }
    ];

    return (
      <Fragment>
        <Scrim isShown={isFetching || isSearching} />
        <InputsWrapper>
          <Input
            inputId="filters"
            onChangeFunc={this.addFilter}
            type="select"
            label={t("form.labels.filters")}
            hideLabel
            value=" "
            status="success"
            name="filters"
            width="max-content"
          >
            <Option value=" ">{t("form.filter")}</Option>
            {scopes &&
              scopes.map(aScope => (
                <Option key={aScope} value={aScope}>
                  {titleCase(t(`data.status.${aScope.toLowerCase()}`))}
                </Option>
              ))}
          </Input>
          <Search
            value={searchKeyword}
            label={t("form.labels.search")}
            status="muted"
            placeholder={t("form.placeholders.search")}
            onSearchFunc={this.onSearch}
            onChangeFunc={e => this.handleSearchChange(e)}
          />
        </InputsWrapper>

        {filters &&
          filters.map(aFilter => (
            <Badge
              key={aFilter}
              label={aFilter}
              handle={aFilter.toLowerCase()}
              pill
              onRemove={e => this.removeFilter(e)}
              isRemovable
              color={setBadgeColorByStatus(aFilter.toLowerCase())}
            />
          ))}
        <Alert
          alertIsOpen={hasError}
          color="warning"
          onCloseFunc={() => this.setState(() => ({ hasError: false }))}
        >
          {error}
        </Alert>

        <DataTable
          title=""
          data={list || []}
          columns={columns}
          pointerOnHover
          highlightOnHover
          pagination
          noHeader
          defaultSortField="name"
          onRowClicked={param => {
            setDealership({ id: param.id });
            history.push(`/service-centers/${param.id}`);
          }}
          onChangePage={this.handlePageChange}
          paginationServer
          paginationResetDefaultPage={paginationResetDefaultPage}
          paginationTotalRows={pagination ? pagination.total : 0}
          paginationPerPage={perPage}
          sortServer
          onSort={this.handleSort}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          progressPending={isFetching || isSearching}
        />
      </Fragment>
    );
  }
}

ServiceCenterList.propTypes = {
  fetchServiceCenters: PropTypes.func.isRequired,
  searchServiceCenters: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.object,
    push: PropTypes.func
  }).isRequired,
  serviceCenter: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    pagination: PropTypes.object,
    scopes: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  dealership: state.dealership,
  serviceCenter: state.serviceCenter,
  status: state.status
});

const mapDispatchToProps = dispatch => ({
  setDealership: data => dispatch(setDealership(data)),
  searchServiceCenters: data => dispatch(searchServiceCenters(data)),
  fetchServiceCenters: data => dispatch(fetchServiceCenters(data))
});

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  input[type="search"] {
    border-left: 0;
  }
`;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ServiceCenterList))
);
