import {
  FETCH_DEALERSHIPS_SUCCESS,
  FETCH_DEALERSHIPS_ALL_SUCCESS,
  FETCH_SCOPES_SUCCESS,
  FETCH_DEALERSHIPS_BY_SCOPE_LOADING,
  FETCH_DEALERSHIPS_BY_SCOPE_SUCCESS,
  FETCH_DEALERSHIP_DETAIL_SUCCESS,
  SET_DEALER,
  CREATE_DEALER_LOCATION_SUCCESS,
  SEARCH_DEALERS_SUCCESS,
  UPDATE_DEALERSHIP_SUCCESS,
  UPDATE_DEALER_USER_SUCCESS,
  INVITE_USER_SUCCESS,
  ADD_DEALER_FILTER,
  REMOVE_DEALER_FILTER
} from "Actions/dealership/types";

const initialState = {
  list: [],
  dealership: {},
  locations: [],
  users: [],
  pagination: {},
  scopes: [],
  tickets: [],
  filters: ["active", "pending"]
};

const dealershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEALERSHIPS_SUCCESS: {
      return {
        ...state,
        list: [...action.payload.data],
        pagination: { ...action.payload.pagination }
      };
    }
    case FETCH_DEALERSHIPS_ALL_SUCCESS: {
      return {
        ...state,
        list: [...action.payload]
      };
    }
    case FETCH_SCOPES_SUCCESS: {
      return {
        ...state,
        scopes: action.payload
      };
    }
    case FETCH_DEALERSHIPS_BY_SCOPE_LOADING: {
      let { list } = state;
      if (action.payload.shouldOverwriteResults) {
        list = [];
      }
      return {
        ...state,
        list
      };
    }
    case FETCH_DEALERSHIPS_BY_SCOPE_SUCCESS: {
      return {
        ...state,
        list: [...action.payload.data],
        pagination: { ...action.payload.pagination }
      };
    }
    case SEARCH_DEALERS_SUCCESS: {
      return {
        ...state,
        list: [...action.payload.data],
        pagination: { ...action.payload.pagination }
      };
    }
    case FETCH_DEALERSHIP_DETAIL_SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case SET_DEALER: {
      return {
        ...state,
        ...action.payload
      };
    }
    case CREATE_DEALER_LOCATION_SUCCESS: {
      return {
        ...state,
        locations: [
          ...state.locations,
          {
            ...action.payload.data.location,
            address: action.payload.data.address
          }
        ]
      };
    }
    case UPDATE_DEALERSHIP_SUCCESS: {
      return {
        ...state,
        dealership: {
          ...state.dealership,
          ...action.payload
        }
      };
    }
    case UPDATE_DEALER_USER_SUCCESS: {
      const newUserList = [...state.users];
      newUserList.forEach(user => {
        if (user.dealer_user.id === action.payload.id) {
          const newUser = user;
          newUser.dealer_user = action.payload;
        }
      });
      return {
        ...state,
        users: newUserList
      };
    }
    case INVITE_USER_SUCCESS: {
      return {
        ...state,
        users: action.payload
      };
    }
    case ADD_DEALER_FILTER: {
      if (!state.filters.includes(action.payload)) {
        return {
          ...state,
          filters: [...state.filters, action.payload]
        };
      }

      return state;
    }
    case REMOVE_DEALER_FILTER: {
      return {
        ...state,
        filters: state.filters.filter(
          filterItem => filterItem !== action.payload
        )
      };
    }
    default:
      return state;
  }
};

export default dealershipReducer;
