import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, space } from 'Theme';
import { Badge, DataTable, Heading, Dropdown } from 'Components';
import { useTranslation } from 'react-i18next';
import { DropdownItem } from '@bootstrap-styled/v4';
import {
  formatDate,
  formatPhoneNumber,
  setBadgeColorByStatus
} from 'Components/Utils';
import { showModal } from 'Actions/modal';
import { connect } from 'react-redux';
import * as modalTypes from 'Components/Modal/types';
import { OverflowIcon } from 'Assets/images/icons';

const DealerUsers = ({ users, ownerId, showModal, loggedinUser }) => {
  const { t } = useTranslation();

  const onUpdateUser = user => {
    const modalData = {
      type: modalTypes.FORM_UPDATE_DEALER_USER,
      title: t('modal.title.update_user'),
      data: user
    };
    showModal(modalData);
  };

  let admin = false;
  users.forEach(user => {
    if (
      (user.id === loggedinUser.id && user.dealer_user.is_admin === 1) ||
      loggedinUser.level === 'service_center_user'
    ) {
      admin = true;
    }
  });

  const columns = [
    {
      name: t('table.name'),
      selector: 'name',
      cell: row => {
        if (row.id === ownerId) {
          return (
            <Fragment>
              {row.name}{' '}
              <Badge label={t(`data.badge.owner`)} pill color="info" inTable />
            </Fragment>
          );
        }
        return row.name;
      }
    },
    {
      name: t('table.location'),
      selector: 'dealer_user.dealer_location.name'
    },
    {
      name: t('table.phone'),
      selector: 'phone',
      cell: row => (row.phone ? formatPhoneNumber(row.phone) : '')
    },
    {
      name: t('table.last_activity'),
      selector: 'updated_at',
      cell: row => formatDate(row.updated_at)
    },
    {
      // ssg-10: UI was displaying user status when it should show dealer_user status
      name: t('table.status'),
      selector: 'dealer_user.status',
      cell: row =>
        row.dealer_user ? (
          <Badge
            label={t(`data.status.${row.dealer_user.status.toLowerCase()}`)}
            pill
            color={setBadgeColorByStatus(row.dealer_user.status.toLowerCase())}
            inTable
          />
        ) : null,
      compact: true
    },
    {
      compact: true,
      allowOverflow: true,
      cell: row =>
        (admin &&
          row.id !== loggedinUser.id &&
          row.dealer_user.status !== 'pending') ||
        loggedinUser.level === 'service_center_user' ? (
          <Fragment>
            <StyledDropdown size="sm" toggle={<OverflowIcon />}>
              <DropdownItem tag="a" onClick={() => onUpdateUser(row)}>
                {t('table.dropdown.edit')}
              </DropdownItem>
            </StyledDropdown>
          </Fragment>
        ) : null
    }
  ];

  let noPhoneNumbers = true;
  users.forEach(user => {
    if (user.phone) {
      noPhoneNumbers = false;
    }
  });

  if (noPhoneNumbers) {
    columns.splice(2, 1);
  }
  return (
    <Fragment>
      <Heading size={6}>{t('dealership.heading_users')}</Heading>
      <DataTable
        title=""
        data={users}
        columns={columns}
        pagination={false}
        noHeader
        hasDetailsView
        overflowY
        defaultSortField="name"
      />
    </Fragment>
  );
};

DealerUsers.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      phone: PropTypes.string,
      updated_at: PropTypes.string,
      dealer_user: PropTypes.shape({
        status: PropTypes.string,
        dealer_location: PropTypes.shape({
          name: PropTypes.string
        })
      })
    })
  ).isRequired,
  ownerId: PropTypes.number.isRequired,
  showModal: PropTypes.func.isRequired,
  loggedinUser: PropTypes.shape({
    id: PropTypes.number,
    level: PropTypes.string
  }).isRequired
};

const StyledDropdown = styled(Dropdown)`
  width: ${space[1] * 5}rem;
  height: ${space[1] * 5}rem;
  .dropdown-menu {
    top: -50%;
    right: 1.5rem;
  }
  .dropdown-item {
    text-decoration: none;
    &:last-child {
      // border-top: 1px solid ${colors.gray.lighter};
    }
  }
`;

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  showModal: data => dispatch(showModal(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerUsers);
