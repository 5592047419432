import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import createIdleMiddleware from "redux-idle-callback";
import axios from "axios";
import { api, refreshToken } from "Actions/api";
import * as actions from "Actions/index";
import { logout, autoLogOut } from "Actions/user";

import createRootReducer from "./reducers";
import actionLogger from "./middleware/actionLogger";

export const history = createBrowserHistory();

export const initialState = {
  user: {
    isLoggedIn: false,
    token: ""
  },
  status: {
    LOGIN: "none",
    SEND_PASSWORD_RESET: "none",
    RESET_PASSWORD: "none",
    SIGN_UP: "none",
    FINISH_ACCOUNT_SETUP: "none",
    CREATE_TICKET: "none",
    FETCH_DEALERSHIP_DETAIL: "none",
    FETCH_PART_MODEL_ATTRIBUTES: "none",
    FETCH_PART_BY_SERIAL: "none",
    FETCH_PART_TYPES: "none",
    FETCH_BIKE_MAKES: "none",
    FETCH_MODEL_DIAGNOSES: "none",
    FETCH_PART_TYPE_ATTRIBUTES: "none",
    UPDATE_TICKET_STATUS: "none"
  }
};

const composeMiddleware =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = initialState => {
  const store = createStore(
    createRootReducer(history), // rootReducer with router state
    initialState,
    composeMiddleware(
      applyMiddleware(
        routerMiddleware(history),
        thunk,
        logger,
        actionLogger,
        createIdleMiddleware({
          // Signed out after 4 hours of inactivity
          idleTime: 1.44e7,
          // Polls for inactivity every 5 seconds
          intervalTime: 5000,
          alwaysOn: false,
          idleAction: autoLogOut
        })
      )
    )
  );
  return store;
};

const store = configureStore(initialState);

// Automagically attempt to refresh user tokens if they are unauthenticated. Users have 1 hour
// refresh tokens that have expired before total expiration.
const createAxiosResponseInterceptor = () => {
  const intercepter = api.interceptors.response.use(
    response => {
      return Promise.resolve(response);
    },
    async error => {
      if (error.response.status === 401) {
        axios.interceptors.response.eject(intercepter);
        const actionToRetry = store.getState().actions.list.pop();
        return await refreshToken()
          .catch(refreshErr => {
            // Another error (403) on a token refresh attempt indicates the token is fully-expired
            if (refreshErr === 403) {
              store.dispatch(logout(window.location.pathname));
              Promise.reject(refreshErr);
            } else {
              // If were able to successfully refresh the user's token, we retry the original
              // request that caused the 401 by making the
              // return Promise.resolve(api(error.config));
              Object.keys(actions.apiCallTypes).forEach(typeGroup => {
                if (
                  Object.prototype.hasOwnProperty.call(
                    actions.apiCallTypes[typeGroup],
                    actionToRetry.type
                  )
                ) {
                  const { parameters } = actionToRetry.payload;
                  const args = parameters ? { ...parameters } : "";
                  store.dispatch(
                    actions.apiCallTypes[typeGroup][actionToRetry.type].call(
                      this,
                      args
                    )
                  );
                }
              });
            }
          })
          .then(response => {
            error.response.config.headers.Authorization = `Bearer ${response.token}`;
            return axios(error.response.config);
          })
          .finally(() => createAxiosResponseInterceptor());
      } else {
        return Promise.reject(error.response.status);
      }
    }
  );
};

createAxiosResponseInterceptor();

export default store;
