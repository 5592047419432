import { dealershipCallTypes } from './dealership';
import { userCallTypes } from './user';
import { usersCallTypes } from './users';
import { ticketCallTypes } from './ticket';
import { bikeCallTypes } from './bike';
import { partCallTypes } from './part';
import { orderCallTypes } from './order';
import { serviceCenterCallTypes } from './serviceCenter';

export * from './user';
export * from './dealership';
export const apiCallTypes = {
  dealershipCallTypes,
  userCallTypes,
  usersCallTypes,
  ticketCallTypes,
  bikeCallTypes,
  partCallTypes,
  orderCallTypes,
  serviceCenterCallTypes
};
